import React from "react";
import PropTypes from "prop-types";
import { MediaGallery } from "../../components/MediaGallery";
import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter";
// import { useInteraction } from "../../sharedComponents/Hooks/Interaction";
// import { Img } from "../../sharedComponents/Images";

const MediaConnector = ({ data }) => {
	const images = data?.images?.map(image => ({
		...CloudinaryImageAdapter({
			cloudinaryImage: image,
			maxWidth: 1200,
			title: image.title,
			desc: image.title,
			sizes: "(max-width:400px) 80vw, 1200px",
			imageQuality: [1, 2, 3],
			disableAutoGravity: true,
		}),
	}));

	const thumbnail = data?.images?.map(image => ({
		src: `https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto,c_fill,g_center,h_100,w_100/v1644953992/${image?.public_id}`,
		alt: image?.public_id,
		width: image?.width,
		height: image?.height,
	}));

	return (
		<div>
			<MediaGallery gallery={images} thumb={thumbnail} />
		</div>
	);
};

MediaConnector.propTypes = {
	data: PropTypes.shape({
		images: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				title: PropTypes.string,
				file: PropTypes.shape({
					url: PropTypes.string,
				}),
			})
		),
	}),
};
MediaConnector.defaultProps = {
	data: null,
};

export default MediaConnector;
export { MediaConnector };
