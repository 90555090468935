import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../Icons/Icons";
import { Section } from "../Section";
import { LanguageToggle } from "../LanguageToggle";
import * as styles from "./Footer.module.scss";

const Footer = ({
	className,
	columns,
	socialLinks,
	otherLinks,
	copyrightText,
	altLangPages,
	locale,
	renderLanguageToggle,
	renderLinks,
	renderImageLinks,
	renderIconLinks,
	labels,
}) => {
	const scrollToTop = () => {
		if (typeof window !== "undefined") {
			window.scrollTo({
				top: 0,
			});
		}
	};
	return (
		<Section as="footer" className={`${className} ${styles.footer}`}>
			{!!columns?.length && (
				<div className={styles.footerSections}>
					{columns?.map(column => (
						<div className={cx(styles.footerSection, styles.tab)}>
							<input type="checkbox" id={column.id} />
							<label htmlFor={column.id} className={cx(styles.footerSectionTitle, styles.tabLabel)}>
								{column.title}
								<span className={styles.tabLabelChevron} />
							</label>
							<div className={styles.tabContent}>
								<ul>
									{column.links.map(item => (
										<li key={item.id}>{renderLinks(item, styles.footerLinks)}</li>
									))}
								</ul>
							</div>
						</div>
					))}
				</div>
			)}
			<div className={styles.footerMobilePadding}>
				<button onClick={scrollToTop} type="button" className={styles.toTop}>
					{labels?.backToTop}
				</button>
				{!!socialLinks?.length && (
					<div className={styles.social}>
						<ul className={styles.socialNav}>
							{socialLinks?.map(item => (
								<li key={item.id}>
									{renderIconLinks(item, {
										// eslint-disable-next-line jsx-a11y/img-redundant-alt
										icon: (
											<>
												<Icon name={item.iconName} />
												<span className="sr-only">{item.iconName}</span>
											</>
										),
									})}
								</li>
							))}
						</ul>
					</div>
				)}
				{!!otherLinks?.length && (
					<div className={styles.footerCorps}>
						<ul>
							{otherLinks?.map(item => (
								<li key={item.id}>
									{renderImageLinks(item, {
										imageClassName: styles.footerCorpsImage,
										// eslint-disable-next-line jsx-a11y/img-redundant-alt
										image: (
											<img
												src={item?.image?.[0]?.secure_url}
												className={styles.footerCorpsImage}
												alt={item?.imageAltText}
												width={item?.width}
												height={item?.height}
											/>
										),
									})}
								</li>
							))}
						</ul>
					</div>
				)}
				<div className={styles.footerBottom}>
					{copyrightText && (
						<div className={styles.footerCopyright}>
							<p>{copyrightText}</p>
						</div>
					)}
					{renderLanguageToggle && (
						<div className={styles.footerLang}>
							<Icon name="globe" className={styles.footerLangIcon} />{" "}
							{renderLanguageToggle(
								altLangPages,
								{
									currentLanguage: styles?.langToggleCurrent,
									altLanguage: styles?.langToggleAlt,
								},
								locale
							)}
						</div>
					)}
				</div>
			</div>
		</Section>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
	altLangPages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
			lang: PropTypes.string.isRequired,
		})
	),
	locale: PropTypes.string,
	labels: PropTypes.shape({
		backToTop: PropTypes.string,
	}),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			links: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					title: PropTypes.string,
					url: PropTypes.string,
				})
			),
		})
	).isRequired,

	socialLinks: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			image: PropTypes.arrayOf(
				PropTypes.shape({
					secure_url: PropTypes.string,
				})
			),
			imageAltText: PropTypes.string,
			target: PropTypes.string,
			url: PropTypes.string,
		})
	).isRequired,
	otherLinks: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			image: PropTypes.arrayOf(
				PropTypes.shape({
					secure_url: PropTypes.string,
				})
			),
			imageAltText: PropTypes.string,
			target: PropTypes.string,
			url: PropTypes.string,
		})
	).isRequired,
	copyrightText: PropTypes.string.isRequired,
	renderLanguageToggle: PropTypes.func,
	renderLinks: PropTypes.func,
	renderImageLinks: PropTypes.func,
	renderIconLinks: PropTypes.func,
};

Footer.defaultProps = {
	className: undefined,
	altLangPages: undefined,
	labels: {
		backToTop: "Back to top",
	},
	locale: undefined,
	renderLinks: (link, className) => (
		<a className={className} href={link.url} target={link.target}>
			{link.title}
		</a>
	),
	renderImageLinks: (link, { imageClassName, image }) => (
		<a className={imageClassName} href={link.url} target={link.target}>
			{image}
		</a>
	),
	renderIconLinks: (link, { className, icon }) => (
		<a className={className} href={link.url} target={link.target}>
			{icon}
		</a>
	),
	renderLanguageToggle: (altLangPages, classNames, locale) => (
		<LanguageToggle altLangPages={altLangPages} classNames={classNames} locale={locale} />
	),
};

export default Footer;
export { Footer };
