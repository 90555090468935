// extracted by mini-css-extract-plugin
export var container = "SplitContainer-module--container--3BfeK";
export var mobile1 = "SplitContainer-module--mobile1--2boAK";
export var mobile2 = "SplitContainer-module--mobile2--1WnSM";
export var mobile3 = "SplitContainer-module--mobile3--26lKz";
export var mobile4 = "SplitContainer-module--mobile4--3M1ln";
export var mobile5 = "SplitContainer-module--mobile5--1USyY";
export var mobile6 = "SplitContainer-module--mobile6--2P3oI";
export var mobile7 = "SplitContainer-module--mobile7--gUjv4";
export var mobile8 = "SplitContainer-module--mobile8--2lVqt";
export var mobile9 = "SplitContainer-module--mobile9--1ig9A";
export var mobile10 = "SplitContainer-module--mobile10--1RD_A";
export var mobile11 = "SplitContainer-module--mobile11--3Z-4e";
export var mobile12 = "SplitContainer-module--mobile12--2SSA0";
export var tablet1 = "SplitContainer-module--tablet1--2P2rA";
export var tablet2 = "SplitContainer-module--tablet2--f3-k_";
export var tablet3 = "SplitContainer-module--tablet3--7Nxev";
export var tablet4 = "SplitContainer-module--tablet4--1dsUd";
export var tablet5 = "SplitContainer-module--tablet5--23rqk";
export var tablet6 = "SplitContainer-module--tablet6--1Tjr6";
export var tablet7 = "SplitContainer-module--tablet7--2rzDQ";
export var tablet8 = "SplitContainer-module--tablet8--3bvY4";
export var tablet9 = "SplitContainer-module--tablet9--2gCiW";
export var tablet10 = "SplitContainer-module--tablet10--162wF";
export var tablet11 = "SplitContainer-module--tablet11--YWlCN";
export var tablet12 = "SplitContainer-module--tablet12--2jwIo";
export var desktop1 = "SplitContainer-module--desktop1--TuElJ";
export var desktop2 = "SplitContainer-module--desktop2--3jhRp";
export var desktop3 = "SplitContainer-module--desktop3--Pt_kP";
export var desktop4 = "SplitContainer-module--desktop4--7h2Xn";
export var desktop5 = "SplitContainer-module--desktop5--318D4";
export var desktop6 = "SplitContainer-module--desktop6--3MJQb";
export var desktop7 = "SplitContainer-module--desktop7--Hv1ZM";
export var desktop8 = "SplitContainer-module--desktop8--3nWgy";
export var desktop9 = "SplitContainer-module--desktop9--2toxM";
export var desktop10 = "SplitContainer-module--desktop10--3lgiZ";
export var desktop11 = "SplitContainer-module--desktop11--3zOVY";
export var desktop12 = "SplitContainer-module--desktop12--3fSu0";