import React from "react";
import PropTypes from "prop-types";
import { CloudinaryVideo } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/VideoPlayer/index.css";

const VideoPlayer = ({ className, cloudName, publicId, lang, posterUrl, ...props }) => (
	<CloudinaryVideo
		className={className}
		lang={lang}
		cloudName={cloudName}
		publicId={publicId}
		posterUrl={posterUrl}
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
);

VideoPlayer.propTypes = {
	className: PropTypes.string,
	cloudName: PropTypes.string,
	posterUrl: PropTypes.string,
	publicId: PropTypes.string.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]),
};

VideoPlayer.defaultProps = {
	className: undefined,
	lang: "en",
	cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
	posterUrl: "",
};

export default VideoPlayer;
export { VideoPlayer };
