import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as dayjs from "dayjs";
import cx from "classnames";
import { RCL as useTranslation } from "../../../RCL";
import { Button } from "../../../Button";
import { GatewayField } from "../GatewayField";
import { DateField } from "../DateField";

import * as styles from "../Flights.module.scss";

let focusLastRow = false;
let focusNextRow = false;

const TemplateInputs = ({
	fdRowIndex,
	fdColumnIndex,
	flightData,
	setFlightData,
	flightDataTemplate,
	language,
	flightMode,
}) => {
	const dictionary = {
		"travelling-from": useTranslation({ searchKey: "travelling-from" }),
		"going-to-sov": useTranslation({ searchKey: "going-to-sov" }),
		"departing-date": useTranslation({ searchKey: "departing-date" }),
		"return-date": useTranslation({ searchKey: "return-date" }),
	};

	/* /////////////////////////////////////////////////////////////
	// Flight row management
	///////////////////////////////////////////////////////////// */

	const rowLimit = 5;

	const updateNextLegGateway = (row, input, selected, data) => {
		const updatedFlightData = data;
		let departureIndex;

		const departureLegInput = updatedFlightData[row].filter((fdItem, fdIndex) => {
			const filterMatch = fdItem.input === input;
			if (filterMatch) {
				departureIndex = fdIndex;
				return filterMatch;
			}
			return false;
		});

		const [inputSettings] = departureLegInput;
		inputSettings.selected = selected;
		updatedFlightData[row][departureIndex] = inputSettings;

		return updatedFlightData;
	};

	const updateInput = data => {
		if (JSON.stringify(flightData) !== JSON.stringify(data)) {
			setFlightData([...data]);
		}
	};

	const addRow = (row, input, selected, data) => {
		focusNextRow = true;

		// Append a leg to the DOM and update the first gateway if preceding leg has a valid departure gateway
		let updatedFlightData = data;

		const updatedDataTemplate = flightDataTemplate.map((fdtItem, fdtIndex) => {
			if (fdtItem?.type === "typeahead") {
				const typeaheadId =
					fdtItem.input === "gateway"
						? `flight-gateway-${row}-${fdtIndex}`
						: `flight-depart-${row}-${fdtIndex}`;

				// eslint-disable-next-line no-param-reassign
				fdtItem.id = typeaheadId;
			}
			return fdtItem;
		});

		updatedFlightData.push(updatedDataTemplate);
		updatedFlightData = updateNextLegGateway(row, input, selected, data);
		setFlightData([...updatedFlightData]);
	};

	const removeRow = () => {
		focusLastRow = true;

		// Remove last leg
		const updatedFlightData = JSON.parse(JSON.stringify(flightData));
		updatedFlightData.pop();
		setFlightData([...updatedFlightData]);
	};

	let updatedFlightData = JSON.parse(JSON.stringify(flightData));
	let component = null;
	const inputConfig = updatedFlightData[fdRowIndex][fdColumnIndex];
	const isLastLeg =
		fdRowIndex === updatedFlightData.length - 1 && updatedFlightData.length !== rowLimit;

	if (inputConfig?.type === "typeahead") {
		const typeaheadId =
			inputConfig.input === "gateway"
				? `flight-gateway-${fdRowIndex}-${fdColumnIndex}`
				: `flight-depart-${fdRowIndex}-${fdColumnIndex}`;

		inputConfig.id = typeaheadId;

		component = (
			<GatewayField
				key={typeaheadId}
				id={typeaheadId}
				label={
					inputConfig?.input === "gateway"
						? dictionary["travelling-from"]
						: dictionary["going-to-sov"]
				}
				selected={inputConfig.selected}
				error={inputConfig.error}
				resetError={() => {
					inputConfig.error = false;
				}}
				onChange={selection => {
					inputConfig.selected = selection;

					if (
						inputConfig.input === "destination" &&
						updatedFlightData[fdRowIndex + 1] !== undefined
					) {
						updatedFlightData = updateNextLegGateway(
							fdRowIndex + 1,
							"gateway",
							inputConfig.selected,
							updatedFlightData
						);
					}

					updatedFlightData[fdRowIndex][fdColumnIndex] = inputConfig;
					updateInput(updatedFlightData);
				}}
			/>
		);
	} else if (inputConfig?.type === "date") {
		const dateId =
			inputConfig.input === "return"
				? `flight-return-date-${fdRowIndex}-${fdColumnIndex}`
				: `flight-depart-date-${fdRowIndex}-${fdColumnIndex}`;

		const defaultSelected =
			inputConfig.selected !== undefined ? inputConfig.selected : inputConfig.default;

		// Update data
		inputConfig.id = dateId;

		component = (
			<DateField
				key={dateId}
				id={dateId}
				label={
					inputConfig.input === "depart" ? dictionary["departing-date"] : dictionary["return-date"]
				}
				language={language}
				selected={inputConfig.input === "return" ? undefined : dayjs(defaultSelected)}
				minDate={dayjs(inputConfig.minDate)}
				error={inputConfig.error}
				onChange={selection => {
					inputConfig.selected = dayjs(selection).format("YYYY-MM-DD");

					updatedFlightData[fdRowIndex][fdColumnIndex] = inputConfig;
					updateInput(updatedFlightData);
				}}
				disabled={inputConfig.input === "return"}
				placeholder={flightMode === "oneway" || flightMode === "multicity" ? "" : "YYYY-MM-DD"}
			/>
		);
	} else if (inputConfig?.type === "placeholder") {
		component = (
			<React.Fragment>
				{updatedFlightData.length - 1 <= rowLimit && (
					<Button
						key={`flight-addrow-${fdRowIndex}-${fdColumnIndex}`}
						disabled={!isLastLeg}
						type="button"
						className={cx(styles.addRow)}
						theme="primary"
						onClick={() =>
							addRow(fdRowIndex + 1, "gateway", inputConfig.selected, updatedFlightData)
						}
					>
						+
					</Button>
				)}
				{fdRowIndex === updatedFlightData.length - 1 && updatedFlightData.length !== 1 && (
					<Button
						key={`flight-removerow-${fdRowIndex}-${fdColumnIndex}`}
						type="button"
						className={cx(styles.removeRow)}
						theme="primary"
						onClick={() => removeRow()}
					>
						&ndash;
					</Button>
				)}
			</React.Fragment>
		);
	}

	useEffect(() => {
		if (focusNextRow) {
			// Automatic focus on new leg input
			// NOTE: Need a better way to reference this input via ref
			if (flightData[flightData.length - 1]) {
				document.getElementById(flightData[flightData.length - 1][0].id).focus();
				focusNextRow = false;
			}
		} else if (focusLastRow) {
			// Automatic focus on previous Add button
			// NOTE: There is no way to do this
			focusLastRow = false;
		}
	}, [flightData]);

	return component;
};

TemplateInputs.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	fdRowIndex: PropTypes.number.isRequired,
	fdColumnIndex: PropTypes.number.isRequired,
	flightData: PropTypes.array.isRequired,
	setFlightData: PropTypes.func.isRequired,
	flightDataTemplate: PropTypes.array.isRequired,
	flightMode: PropTypes.string.isRequired,
};

export default TemplateInputs;
export { TemplateInputs };
