import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { FocusOn } from "react-focus-on";
import { motion, useReducedMotion } from "framer-motion";
import { SlideInMenuHeader } from "./Header";
import { SlideInMenuSubMenu } from "./SubMenu";
import { HeaderGateway } from "../Gateway";
import { sideAnimation } from "../../../utils/animations";
import * as styles from "./SlideInMenu.module.scss";

const defaultLabels = {
	openMenu: "Open menu",
	closeMenu: "Close menu",
	openGatewayMenu: "Open gateway menu",
	changeGateway: "Change gateway",
	gatewayHeader: "Gateway",
};

const SlideInMenu = ({
	title,
	labels: providedLabels,
	menuLinks,
	renderMenuLink,
	currentGateway,
	handleChangeGateway,
	languageToggle,
}) => {
	const labels = { ...defaultLabels, ...providedLabels };
	const hamburgerMenuButtonRef = useRef(null);
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const shouldReduceMotion = useReducedMotion();

	const handleToggleMenu = () => {
		setIsMenuOpened(!isMenuOpened);
	};

	const unmountTrap = () => {
		setIsMenuOpened(false);

		// On close menu, set focus back to hamburger menu button
		if (hamburgerMenuButtonRef.current) {
			setTimeout(() => {
				hamburgerMenuButtonRef.current.focus();
			}, 0);
		}
	};

	return (
		<nav aria-label={title}>
			<button
				type="button"
				className={styles.hamburgerMenuButton}
				onClick={handleToggleMenu}
				aria-label={labels.openMenu}
				aria-expanded={isMenuOpened}
				aria-controls="main-menu-nav"
				ref={hamburgerMenuButtonRef}
			>
				<span />
				<span />
				<span />
			</button>
			<div id="main-menu-nav" className={cx(styles.wrapper, isMenuOpened && styles.wrapperOpen)}>
				<FocusOn
					enabled={isMenuOpened}
					shouldIgnore={el => !(el.id === "gatsby-focus-wrapper" && el.tabIndex === -1)}
					onClickOutside={handleToggleMenu}
					onEscapeKey={handleToggleMenu}
					className={styles.container}
					gapMode="padding"
				>
					<SlideInMenuHeader
						id="main-menu-nav"
						title={title}
						labels={{
							closeMenu: labels.closeMenu,
						}}
						isOpen={isMenuOpened}
						handleClose={unmountTrap}
					/>
					<SlideInMenuSubMenu
						menuLinks={menuLinks}
						menuOpenState={isMenuOpened}
						unmountTrap={unmountTrap}
						renderMenuLink={renderMenuLink}
					/>
					<motion.div
						initial={false}
						variants={{ ...sideAnimation(shouldReduceMotion ? 0 : 0.4, shouldReduceMotion) }}
						animate={isMenuOpened ? "open" : "closed"}
					>
						<HeaderGateway
							labels={{
								closeMenu: labels.closeMenu,
								openGatewayMenu: labels.openGatewayMenu,
								changeGateway: labels.changeGateway,
								gatewayHeader: labels.gatewayHeader,
							}}
							currentGateway={currentGateway}
							handleChangeGateway={handleChangeGateway}
						/>
						{languageToggle}
					</motion.div>
				</FocusOn>
			</div>
		</nav>
	);
};

SlideInMenu.propTypes = {
	title: PropTypes.string,
	labels: PropTypes.shape({
		openMenu: PropTypes.string,
		closeMenu: PropTypes.string,
		openGatewayMenu: PropTypes.string,
		changeGateway: PropTypes.string,
		gatewayHeader: PropTypes.string,
	}),
	menuLinks: PropTypes.arrayOf(PropTypes.object),
	renderMenuLink: PropTypes.func,
	currentGateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	handleChangeGateway: PropTypes.func.isRequired,
	languageToggle: PropTypes.node,
};
SlideInMenu.defaultProps = {
	title: "Menu",
	labels: defaultLabels,
	menuLinks: [],
	renderMenuLink: (link, { classNames }) => (
		<a href={link.url} target={link.target} className={classNames?.link}>
			{link.linkText}
		</a>
	),
	currentGateway: {
		label: "Toronto",
		value: "YYZ",
	},
	languageToggle: undefined,
};

export default SlideInMenu;
export { SlideInMenu };
