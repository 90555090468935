import { useState, useEffect } from "react";
import { debounce } from "throttle-debounce";

function useIsMobile() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleSetIsMobile = debounce(150, () => {
			if (window.innerWidth <= 992) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		});

		handleSetIsMobile();

		window.addEventListener("resize", handleSetIsMobile);

		return () => {
			window.removeEventListener("resize", handleSetIsMobile);
		};
	}, []);

	return isMobile;
}

export default useIsMobile;
export { useIsMobile };
