// import { motion, useReducedMotion } from "framer-motion";

// const SOVAnimated = ({type, tag}) => {
//   type = "slide", "stagger", "fade", "zoom"
//   const shouldReduceMotion = useReducedMotion();

//   let animation = {
//     opacity: 1
//   }

//   if (shouldReduceMotion) {
//     animation = {
//       opacity: 0
//     }
//   }

//   switch (type) {
//     case: "slide":
//       animation = {
//         opacity: 1,
//         transform: "translateX(0)"
//       }
//       break;
//   }

//   return <motion[tag] variants={{...animation}}></motion[tag]>

// }

const sideAnimation = (delay = 0, shouldReduce = false) => ({
	open: {
		opacity: 1,
		visibility: "visible",
		transition: {
			delay,
			type: "spring",
			stiffness: 220,
			damping: 40,
		},
	},
	closed: {
		opacity: shouldReduce ? 1 : 0,
		visibility: shouldReduce ? "visible" : "hidden",
		transition: shouldReduce
			? "none"
			: {
					delay: 0.2,
					type: "spring",
					stiffness: 800,
					damping: 40,
					transitionEnd: {
						display: "none",
					},
			  },
	},
});

const staggerList = (shouldReduce = false) => ({
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: { stiffness: 1000, velocity: -100 },
		},
	},
	closed: {
		y: shouldReduce ? 0 : 20,
		opacity: shouldReduce ? 1 : 0,
		transition: {
			y: { stiffness: 1000 },
		},
	},
});

export default sideAnimation;
export { sideAnimation, staggerList };
