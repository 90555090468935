// extracted by mini-css-extract-plugin
export var mainContainer = "MediaGallery-module--mainContainer--2cqPf";
export var media = "MediaGallery-module--media--3dDPQ";
export var main = "MediaGallery-module--main--4QomA";
export var footer = "MediaGallery-module--footer--2MKKE";
export var options = "MediaGallery-module--options--3dNzK";
export var thumbs = "MediaGallery-module--thumbs--3t6r8";
export var img = "MediaGallery-module--img--9efoi";
export var miscContainer = "MediaGallery-module--miscContainer--14pIt";
export var misc = "MediaGallery-module--misc--2i5l9";
export var map = "MediaGallery-module--map--3vbeo";