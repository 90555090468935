/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { KeyCode } from "../../../util/keyCodes";
import * as styles from "./SliderSlide.module.scss";

const SliderSlide = ({ image, tabIndex, index, slideSelected, onClick, className, isModal }) => {
	const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
	return (
		<>
			{isModal && (
				<img
					className={styles.img}
					src={image.data.src}
					data-srcSet={image.data.srcSet}
					alt={image.alt || image.title}
					width={image.width}
					height={image.height}
					loading="lazy"
				/>
			)}
			{!isModal && (
				<div
					tabIndex={tabIndex}
					role="button"
					aria-label={`Slide ${index}`}
					data-testid={`Slide${index}`}
					className={cx(styles.slide, "swiper-zoom-container", className)}
					onFocus={() => {
						setCurrentFocusIndex(index);
					}}
					onClick={
						// Swiper slides are stacked, only opacity makes it fade in and fade out. On click, only the first slide clicked. To fix the issue on click, we pick the active slide
						onClick
					}
					onKeyDown={e => {
						if (e.key === KeyCode.CODE_ENTER) {
							slideSelected(currentFocusIndex, true);
						}
					}}
				>
					<img
						className={styles.img}
						src={image.data.src}
						data-srcSet={image.data.srcSet}
						alt={image.alt || image.title}
						width={image.width}
						height={image.height}
						loading="lazy"
					/>
				</div>
			)}
		</>
	);
};

SliderSlide.propTypes = {
	image: PropTypes.shape({
		title: PropTypes.string,
		data: PropTypes.shape({
			aspectRatio: PropTypes.number,
			sizes: PropTypes.string,
			src: PropTypes.string,
			srcSet: PropTypes.string,
		}),
		alt: PropTypes.string.isRequired,
		width: PropTypes.number,
		height: PropTypes.number,
		src: PropTypes.string,
	}).isRequired,
	tabIndex: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	slideSelected: PropTypes.func,
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string.isRequired,
	isModal: PropTypes.bool,
};
SliderSlide.defaultProps = {
	slideSelected: null,
	isModal: false,
};
export default SliderSlide;
export { SliderSlide };
