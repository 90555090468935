import React from "react";
import PropTypes from "prop-types";
import { LinkList } from "../../components/LinkList";
// eslint-disable-next-line import/no-cycle
import { Link } from "../../sharedComponents/Link";

const LinkListConnector = ({ data }) => (
	<LinkList
		list={data?.listLink}
		renderLink={(link, { className }) => (
			<Link className={className} to={link.url}>
				{link.linkText}
			</Link>
		)}
	/>
);

LinkListConnector.propTypes = {
	data: PropTypes.object.isRequired,
};

export default LinkListConnector;
export { LinkListConnector };
