/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useCallback, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import FocusTrap from "focus-trap-react";
import { SelectInput, Link } from "@sunwing/shared-components";
import { InputText } from "../InputText";
import { RCL as useTranslation } from "../RCL";
import { Row, Cell } from "../Grid";
// eslint-disable-next-line import/no-cycle
import { AlertConnector as Alert } from "../Alert";
// eslint-disable-next-line import/no-cycle
import { Button } from "../Button";
import { Icon } from "../Icons";
import * as styles from "./EmailAcquisitionForm.module.scss";
// Leverage Searchbox CSS
import * as inputStyles from "../SearchBox/Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as ddStyles from "../SearchBox/Common/CSS/Dropdown/Dropdown.module.scss";

import "./Widget/Desktop/DesktopEmbed.module.scss";

const fallbackLabels = {
	title: "Subscribe now!",
	subtitle:
		"Sign up for access to exclusive SellOffVacation deals, promotional offers and vacation inspiration",
	email: "Email",
	gateway: "Gateway",
	submit: "Sign-up",
	unsubscribe: "You may unsubscribe at any time.",
	errorMessage: "Your email could not be confirmed. Please try again.",
	emailValidation: "Please enter a valid email",
	gatewayValidation: "Please select a gateway",
	confirmTitle: "Thank you!",
	confirmMessage: "<p>You have successfully joined our mailing list.</p>",
	confirmClose: "Close",
	privacyText: "Privacy policy",
	privacyUrl: "https://www.selloffvacations.com/en/privacy-policy",
	contactText: "Contact us",
	contactUrl: "https://www.selloffvacations.com/en/contact-us",
	marketingimage:
		"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto,h_372/selloffvacations-prod/ALL%20INCLUSIVE%20VACATIONS/all-inclusive-vacations-032.jpg",
};

// Needed for tracking form activity
const FORM_TIME_START = Math.floor(new Date().getTime() / 1000);
const defaultFormId = "sign-up";

const EmailAcquisitionForm = forwardRef(
	(
		{
			classNames,
			config,
			mode,
			setWidgetOpen,
			lang,
			userEmail,
			setUserEmail,
			userGateway,
			setUserGateway,
			setRegistered,
			isRegistered,
			setHasSubmitted,
			exitIntentActive,
			handleExitDismiss,
			embed,
		},
		ref
	) => {
		const [emailIsValid, setEmailIsValid] = useState(true);
		const [emailError, setEmailError] = useState("");
		const emailRegex =
			/^(([^&<>()[\]\\.,;:\s@'“‘”’]+(\.[^&<>()[\]\\.,;:\s@'“‘”’]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		// Gateway states
		const [isFocus, setFocus] = useState(false);

		const [gatewayError, setGatewayError] = useState("");
		const [gatewayIsValid, setGatewayIsValid] = useState(true);
		const [gatewayData, setGatewayData] = useState([]);

		const [isSuccess, setIsSuccess] = useState(false);
		const [isError, setIsError] = useState();
		const [isSubmitting, setIsSubmitting] = useState(false);

		const [fieldId, setFieldId] = useState();

		const formRef = useRef();
		const [formHeight, setFormHeight] = useState(null);

		const defaultLabels = {
			title: useTranslation({ searchKey: "subscribe-to-our-sov-newsletter" }),
			subtitle: useTranslation({
				searchKey: "sign-up-for-access-to-exclusive-sov-deals-promotional-offers",
			}),
			email: useTranslation({ searchKey: "email" }),
			gateway: useTranslation({ searchKey: "preferred-departure-city" }),
			submit: useTranslation({ searchKey: "sign-up" }),
			unsubscribe: useTranslation({
				searchKey: "you-may-unsubscribe-at-any-time-sov",
			}),
			errorMessage: useTranslation({
				searchKey: "your-email-could-not-be-confirmed-please-try-again",
			}),
			emailValidation: useTranslation({
				searchKey: "please-enter-a-valid-email",
			}),
			gatewayValidation: useTranslation({
				searchKey: "please-select-a-gateway",
			}),
			confirmTitle: useTranslation({
				searchKey: "sign-up-confirmation-title",
			}),
			confirmMessage: useTranslation({
				searchKey: "you-have-successfully-signed-up-to-become-a-sov-member",
			}),
			confirmClose: useTranslation({
				searchKey: "sign-up-confirmation-close",
			}),
			privacyText: useTranslation({ searchKey: "privacy-policy" }),
			privacyUrl: useTranslation({ searchKey: "privacy-policy-link-sov" }),
			contactText: useTranslation({ searchKey: "contact-us" }),
			contactUrl: useTranslation({ searchKey: "contact-us-link-sov" }),
			marketingimage: useTranslation({
				searchKey: "sign-up-for-access-to-exclusive-sov-deals-image",
			}),
		};

		let labels = {
			...fallbackLabels,
			...defaultLabels,
		};

		let marketingContent = {};

		switch (config?.type) {
			case "inline":
				if (mode === "mobile") {
					marketingContent = {
						title: config?.marketing?.inline?.mobileWidget?.title || labels.title,
						subtitle: config?.marketing?.inline?.mobileWidget?.subtitle || labels.subtitle,
					};

					if (exitIntentActive) {
						marketingContent = {
							marketingimage: config?.marketing?.exit?.mobileWidget?.image || labels.marketingimage,
							title: config?.marketing?.exit?.mobileWidget?.title || marketingContent.title,
							subtitle:
								config?.marketing?.exit?.mobileWidget?.subtitle || marketingContent.subtitle,
						};
					}
				} else if (mode === "desktop") {
					marketingContent = {
						marketingimage:
							config?.marketing?.inline?.desktopWidget?.image || labels.marketingimage,
						title: config?.marketing?.inline?.desktopWidget?.title || labels.title,
						subtitle: config?.marketing?.inline?.desktopWidget?.subtitle || labels.subtitle,
					};

					if (exitIntentActive) {
						marketingContent = {
							marketingimage:
								config?.marketing?.exit?.desktopWidget?.image || labels.marketingimage,
							title: config?.marketing?.exit?.desktopWidget?.title || marketingContent.title,
							subtitle:
								config?.marketing?.exit?.desktopWidget?.subtitle || marketingContent.subtitle,
						};
					}
				}
				break;

			default:
				if (mode === "mobile") {
					marketingContent = {
						title: config?.marketing?.default?.mobileWidget?.title || labels.title,
						subtitle: config?.marketing?.default?.mobileWidget?.subtitle || labels.subtitle,
					};

					if (exitIntentActive) {
						marketingContent = {
							marketingimage: config?.marketing?.exit?.mobileWidget?.image || labels.marketingimage,
							title: config?.marketing?.exit?.mobileWidget?.title || marketingContent.title,
							subtitle:
								config?.marketing?.exit?.mobileWidget?.subtitle || marketingContent.subtitle,
						};
					}
				} else if (mode === "desktop") {
					marketingContent = {
						marketingimage:
							config?.marketing?.default?.desktopWidget?.image || labels.marketingimage,
						title: config?.marketing?.default?.desktopWidget?.title || labels.title,
						subtitle: config?.marketing?.default?.desktopWidget?.subtitle || labels.subtitle,
					};

					if (exitIntentActive) {
						marketingContent = {
							marketingimage:
								config?.marketing?.exit?.desktopWidget?.image || labels.marketingimage,
							title: config?.marketing?.exit?.desktopWidget?.title || marketingContent.title,
							subtitle:
								config?.marketing?.exit?.desktopWidget?.subtitle || marketingContent.subtitle,
						};
					}
				}

				break;
		}

		if (marketingContent?.marketingimage) {
			const needsCropping = /\/v[\d]+\//.test(marketingContent.marketingimage);

			if (needsCropping) {
				const getMatch = marketingContent.marketingimage.match(/\/(v[\d]+)\//);
				marketingContent.marketingimage = marketingContent.marketingimage.replace(
					getMatch[1],
					"f_auto,q_auto,h_372"
				);
			}
		}

		labels = {
			...labels,
			...marketingContent,
		};

		const getGatewaysArray = useCallback(async () => {
			const baseAPI = process.env.GATSBY_SVHANDLER_API_BASE;

			const apiConfig = {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			};

			const apiRequest = new Request(`${baseAPI}/gateways/sov/${lang}`);

			fetch(apiRequest, apiConfig)
				.then(res => {
					if (!res.ok) {
						if (res.status !== 200) {
							return res.json().then(json => {
								throw new Error(`${json.toString()} `);
							});
						}
					}
					return res.json();
				})
				.then(res => {
					const gateways = res?.gateways?.map(gateway => ({
						value: `${gateway.name} (${gateway.code})`,
						label: `${gateway.name} (${gateway.code})`,
					}));

					setGatewayData(gateways);

					// Store gateways for session
					sessionStorage.setItem(`${fieldId}-sign-up-gateways`, JSON.stringify(gateways));
				})
				.catch(err => {
					console.error("getGatewaysArray function error: ", err);
				});
		}, [fieldId, lang]);

		const validateForm = () => {
			let isValidInput = true;
			setIsError();

			if (userGateway === undefined) {
				setGatewayError(labels.gatewayValidation);
				setGatewayIsValid(false);
				isValidInput = false;
			}

			if (!emailRegex.test(userEmail)) {
				setEmailError(labels.emailValidation);
				setEmailIsValid(false);
				isValidInput = false;
			}

			setHasSubmitted(true);

			return isValidInput;
		};

		const handleChangeEmail = event => {
			setEmailError("");
			setUserEmail(event.target.value);

			if (event.target.value.length > 0) {
				if (!emailRegex.test(event.target.value)) {
					setEmailIsValid(false);
					setEmailError(labels.emailValidation);
				} else {
					setEmailIsValid(true);
				}

				setHasSubmitted(true);
			} else if (!emailIsValid && event.target.value.length <= 0) {
				setEmailIsValid(false);
				setEmailError(labels.emailValidation);
			}
		};

		const handleChangeGateway = data => {
			setGatewayError("");

			if (data.value) {
				setGatewayIsValid(true);
				setUserGateway(data);
			} else {
				setGatewayIsValid(false);
			}

			setHasSubmitted(true);
		};

		const sendAnalytics = () => {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "email-form-submitted",
			});
		};

		const handleSubmit = event => {
			event.preventDefault();

			const isValidForm = validateForm();

			// Submit to FA
			if (isValidForm && config?.service) {
				setIsSubmitting(true);

				// Create the key / value pair for payload
				const customFieldsTemp = config.customFields;
				const customFieldKeys = Object.keys(customFieldsTemp);
				const customPayload = {};
				customFieldKeys.forEach(key => {
					let storedValue = "";

					switch (key) {
						case "email":
							storedValue = userEmail;
							break;

						case "preferred_gateways_name":
							storedValue = userGateway?.value
								? userGateway.value.split("(")[0].trimEnd()
								: storedValue;
							break;

						case "preferred_gateways_code":
							storedValue = userGateway?.value
								? userGateway.value.split("(")[1].split(")")[0]
								: storedValue;
							storedValue = storedValue.split(",")[0];

							break;

						case "campaign_code":
							if (exitIntentActive) {
								storedValue = config?.marketing?.exit?.campaign_code || storedValue;
							}
							break;

						default:
							break;
					}

					customPayload[customFieldsTemp[key].id] = storedValue || customFieldsTemp[key].value;
				});

				const tempPayload = { ...config.hiddenFields, ...customPayload };
				const payload = new FormData();

				const fields = Object.keys(tempPayload);

				fields.forEach(key => {
					payload.append(key, tempPayload[key]);
				});

				payload.append("tfa_dbTimeStarted", FORM_TIME_START);

				const apiConfig = {
					method: "POST",
					headers: new Headers(),
					body: payload,
				};

				const apiRequest = new Request(config.service);

				fetch(apiRequest, apiConfig)
					.then(res => {
						if (!res.ok) {
							if (res.status !== 200) {
								throw res.status;
							}
						}
						return res.status;
					})
					.then(res => {
						if (res === 200) {
							if (mode === "desktop") {
								// Capture form height to set min height of Confirmation message
								setFormHeight(formRef.current.getBoundingClientRect().height);
							}

							setIsSuccess(true);
							// Track if the form was saved for the browser session
							sessionStorage.setItem(`${config.id}-registration`, 1);
							setRegistered("2");

							// Clear global form fields
							setUserEmail("");
							setUserGateway("");

							// Resize screen
							setHasSubmitted(true);

							sendAnalytics();

							handleExitDismiss();
						}

						return res;
					})
					.catch(err => {
						console.error("form submission failed: ", err);
						setIsError(labels.errorMessage);
					})
					.finally(() => {
						setIsSubmitting(false);
					});
			}
		};

		const handleInputFocusSelect = event => {
			if (event?.target) {
				setTimeout(() => {
					// Select input
					/* Add a delay to allow focus to pickup selection on Safari browsers */
					event.target.select();
				}, 100);
			}
		};

		useEffect(() => {
			// Setup hidden Form Assembly fields for payload submission
			if (!fieldId && config?.hiddenFields) {
				const hidden = Object.keys(config?.hiddenFields);

				if (hidden?.length) {
					const hiddenArray = [];

					hidden.forEach(key => {
						hiddenArray.push({ id: key, value: config.hiddenFields[key] });
					});
				}

				if (config?.id?.length) {
					setFieldId(config.id);
				} else {
					setFieldId(defaultFormId);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (fieldId && gatewayData.length === 0) {
				const storedGateways = JSON.parse(sessionStorage.getItem(`${fieldId}-sign-up-gateways`));

				if (storedGateways?.length > 0) {
					setGatewayData(storedGateways);
				} else {
					(async () => {
						await getGatewaysArray();
					})();
				}
			}
		}, [fieldId, gatewayData, getGatewaysArray]);

		const renderedComponent = (
			<div className={cx(styles.formSection, classNames?.formSection)} ref={ref}>
				<Row cols={2} className={styles.formWrapper}>
					<Cell className={cx(styles.imageBanner, exitIntentActive ? styles.exitIntent : null)}>
						<img src={labels.marketingimage} alt="" />
						{mode === "mobile" && (
							<Button
								onClick={e => {
									e.preventDefault();
									setWidgetOpen(false);
									handleExitDismiss();
								}}
								noStyle
								iconPlacement="left"
								icon="close--n"
								disabled={isSubmitting}
								className={styles.closeIcon}
							/>
						)}
					</Cell>
					<Cell className={styles.formContainer}>
						{isRegistered !== "2" && (
							<form acceptCharset="UTF-8" noValidate onSubmit={e => handleSubmit(e)} ref={formRef}>
								{isError && <Alert className={styles.signUpFormAlert}>{isError}</Alert>}

								<Row
									cols={1}
									className={cx(styles.formElements, exitIntentActive ? styles.exitIntent : null)}
								>
									<Cell>
										{mode === "mobile" && !exitIntentActive && (
											<Button
												onClick={e => {
													e.preventDefault();
													setWidgetOpen(false);
													handleExitDismiss();
												}}
												noStyle
												iconPlacement="left"
												icon="close--n"
												disabled={isSubmitting}
												className={styles.closeIcon}
											/>
										)}

										<span className={styles.heading}>{labels.title}</span>
										<div
											className={styles.subtitle}
											dangerouslySetInnerHTML={{ __html: labels.subtitle }}
										/>

										<div className={cx(styles.inputWrapper, classNames?.inputWrapper)}>
											<div className={styles.inputContainer}>
												{fieldId && (
													<InputText
														label={labels.email}
														placeholder={labels.email}
														type="email"
														value={userEmail}
														onChange={event => {
															if (event.value) {
																setEmailIsValid(true);
																setEmailError("");
																setUserEmail(event.value);
																setHasSubmitted(true);
															}
														}}
														onBlur={e => handleChangeEmail(e)}
														error={emailError}
														id={`email-${fieldId}`}
														name={`email-${fieldId}`}
														disabled={isSubmitting}
													/>
												)}
											</div>
											<div className={styles.inputContainer}>
												{fieldId && (
													<>
														{mode !== "mobile" && (
															<SelectInput
																id={`gateway-${fieldId}`}
																name={`gateway-${fieldId}`}
																label={labels.gateway}
																type="dropdown"
																data={gatewayData}
																icon={<span className={inputStyles.chevron} />}
																placeholder={labels.gateway}
																showAllSuggestionsOnClickIcon={true}
																onBlur={() => {
																	setFocus(false);
																}}
																onFocus={event => {
																	handleInputFocusSelect(event);
																	setFocus(true);
																}}
																onChange={selection => {
																	if (
																		selection?.value !== userGateway?.value &&
																		selection != null
																	) {
																		setGatewayIsValid(true);
																		handleChangeGateway(selection);
																		setFocus(false);
																	}
																}}
																className={cx(
																	inputStyles.inputWrapper,
																	ddStyles.dropdown,
																	isFocus ? ddStyles.focus : ddStyles.standard,
																	!gatewayIsValid
																		? cx(inputStyles.inputWrapperError, styles.inputError)
																		: ""
																)}
																classNames={{
																	open: cx(
																		ddStyles.open,
																		inputStyles.open,
																		mode === "mobile"
																			? styles.dropdownMobile
																			: styles.dropdownDesktop
																	),
																	input: cx(ddStyles.input, styles.gatewayInput),
																	value: ddStyles.value,
																	selected: ddStyles.selected,
																	highlighted: ddStyles.highlighted,
																	noResults: ddStyles.noResults,
																	listbox: ddStyles.listbox,
																}}
																selectedItem={userGateway}
																disabled={isSubmitting}
															/>
														)}
														{mode === "mobile" && (
															<div className={styles.nativeSelectContainer}>
																<select
																	id={`gateway-${fieldId}`}
																	name={`gateway-${fieldId}`}
																	className={cx(
																		styles.nativeSelect,
																		!gatewayIsValid ? styles.nativeSelectError : ""
																	)}
																	onChange={selection => {
																		// Get selection from data
																		const selectedGatewayData = gatewayData.find(
																			gateway => gateway.value === selection.target.value
																		);

																		if (
																			selection?.target?.value !== userGateway?.value &&
																			selection != null
																		) {
																			setGatewayIsValid(true);
																			handleChangeGateway(selectedGatewayData);
																		}
																	}}
																	value={userGateway?.value}
																>
																	<option>{labels.gateway}</option>
																	{gatewayData &&
																		gatewayData.map(gateway => (
																			<option value={gateway.value} key={gateway.value}>
																				{gateway.label}
																			</option>
																		))}
																</select>
																<Icon
																	name="arrow-thick-down"
																	hidden={true}
																	className={styles.chevron}
																/>
															</div>
														)}
													</>
												)}
												{gatewayError && (
													<div
														role="alert"
														id={`gateway-${fieldId}-error`}
														className={styles.errorText}
													>
														{gatewayError}
													</div>
												)}
											</div>
										</div>

										<div
											className={cx(
												styles.inputContainer,
												styles.splitContainer,
												mode === "desktop" && embed === false && styles.modal
											)}
										>
											<div className={styles.submitButton}>
												<Button type="submit" size="md" disabled={isSubmitting}>
													{labels.submit}
												</Button>
											</div>
											<div className={styles.legal}>
												{labels?.unsubscribe && (
													<div className={styles.subscribe}>{labels?.unsubscribe}</div>
												)}
												<div className={styles.links}>
													<Link
														to={labels.privacyUrl}
														className={cx(styles.alignRight, styles.link)}
														target="_blank"
													>
														{labels.privacyText}
													</Link>
													<span className={cx(styles.divider, styles.alignRight)}>|</span>
													<Link
														to={labels.contactUrl}
														className={cx(styles.alignRight, styles.link)}
														target="_blank"
													>
														{labels.contactText}
													</Link>
												</div>
											</div>
										</div>
									</Cell>
								</Row>
							</form>
						)}
						{(isSuccess || isRegistered === "2") && (
							<Row
								cols={1}
								className={cx(
									mode === "desktop" && styles.formElements,
									styles.confirmationWrapper
								)}
							>
								<Cell>
									<div
										className={cx(styles.confirmation)}
										style={{ minHeight: formHeight || `${formHeight}px` }}
									>
										{mode === "desktop" && (
											<span className={styles.heading}>{labels.confirmTitle}</span>
										)}
										<div
											className={styles.message}
											dangerouslySetInnerHTML={{ __html: labels.confirmMessage }}
										/>

										{(mode === "mobile" || (mode === "desktop" && config.type === "default")) && (
											<Button
												theme={mode === "mobile" ? "secondary" : "primary"}
												onClick={e => {
													e.preventDefault();
													setWidgetOpen(false);
													if (isSuccess || isRegistered === "2") {
														setRegistered("1");
														setIsSuccess(false);
													}
												}}
												outline={mode === "mobile" ?? true}
												type="button"
												full={mode === "mobile" ?? true}
												size="md"
												className={styles.confirmButton}
											>
												{labels.confirmClose}
											</Button>
										)}
									</div>
								</Cell>
							</Row>
						)}
					</Cell>
				</Row>
			</div>
		);

		if (mode === "mobile") {
			return (
				<FocusTrap
					focusTrapOptions={{
						clickOutsideDeactivates: true,
						escapeDeactivates: false,
					}}
				>
					{renderedComponent}
				</FocusTrap>
			);
		}
		return renderedComponent;
	}
);

EmailAcquisitionForm.propTypes = {
	config: PropTypes.object.isRequired,
	mode: PropTypes.string,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	setHasSubmitted: PropTypes.func,
	classNames: PropTypes.object,
	exitIntentActive: PropTypes.bool,
	handleExitDismiss: PropTypes.func.isRequired,
	embed: PropTypes.bool,
};

EmailAcquisitionForm.defaultProps = {
	mode: undefined,
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
	setHasSubmitted: () => {},
	classNames: undefined,
	exitIntentActive: false,
	embed: true,
};

export default EmailAcquisitionForm;
export { EmailAcquisitionForm };
