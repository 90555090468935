import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { RCL as useTranslation } from "../../../RCL";
import { getCookie } from "../../../../utils/helper";
// eslint-disable-next-line import/no-cycle
import { Button } from "../../../Button";
// eslint-disable-next-line import/no-cycle
import { Modal } from "../../../Modal";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionForm } from "../../EmailAcquisitionForm";
import * as styles from "./Desktop.module.scss";

const Desktop = ({
	config,
	mode,
	isWidgetOpen,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	setExitIntentActive,
	exitIntentActive,
	exitIntentCookieName,
	exitIntentEnabled,
	handleExitDismiss,
	embed,
}) => {
	// Determines when the widget is at the starting location to reveal / slide out
	const [isInit, setInit] = useState(true);
	const [horizontalPosition, setHorizontalPosition] = useState(null);
	const [viewportWidth, setViewportWidth] = useState(null);
	const [viewportHeight, setViewportHeight] = useState(null);
	const [viewportHeightPrev, setViewportHeightPrev] = useState(null);
	const widgetRef = useRef();

	// Modal controls
	const [isOpen, setIsOpen] = useState(isWidgetOpen);

	const fallbackLabels = {
		title: "Subscribe now!",
		subtitle:
			"Sign up for access to exclusive SellOffVacation deals, promotional offers and vacation inspiration",
		submit: "Sign up",
		marketingimage:
			"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto,h_372/SellOffVacations/Image%20Gallery/Backgrounds/shutterstock_1019613943.jpg",
	};

	const defaultLabels = {
		title: useTranslation({ searchKey: "subscribe-to-our-sov-newsletter" }),
		subtitle: useTranslation({
			searchKey: "sign-up-for-access-to-exclusive-sov-deals-promotional-offers",
		}),
		submit: useTranslation({ searchKey: "sign-up" }),
		marketingimage: useTranslation({
			searchKey: "sign-up-for-access-to-exclusive-sov-deals-image",
		}),
	};

	let labels = {
		...fallbackLabels,
		...defaultLabels,
	};

	const marketingContent = {
		marketingimage:
			config?.marketing?.default?.desktopWidget?.marketingimage || labels.marketingimage,
		title: config?.marketing?.default?.desktopWidget?.title || labels.title,
		subtitle: config?.marketing?.default?.desktopWidget?.subtitle || labels.subtitle,
	};

	labels = {
		...labels,
		...marketingContent,
	};

	const handleDisplayForm = () => {
		setIsOpen(true);
		setWidgetOpen(true);

		// Hide widgets upon successful registration
		if (sessionStorage.getItem(`${config.id}-registration`) === "1") {
			setRegistered("1");
		}
	};

	const handleScroll = useCallback(() => {
		// Continue tracking viewport width with fallback
		setViewportWidth(window.innerWidth || document.documentElement.clientWidth);
	}, []);

	const handleResize = () => {
		// Continue tracking viewport width with fallback
		setViewportWidth(window.innerWidth || document.documentElement.clientWidth);
		// Track viewport height for exit intent
		setViewportHeight(window.innerHeight || document.documentElement.clientHeight);
	};

	const handleExitTracking = event => {
		if (event.clientY <= 0 && !isWidgetOpen && !isRegistered) {
			if (!getCookie(exitIntentCookieName)) {
				setWidgetOpen(true);
				setExitIntentActive(true);
			}
		}
	};

	useEffect(() => {
		// Events
		window.addEventListener("resize", handleResize, false);
		window.addEventListener("scroll", handleScroll, false);
		if (exitIntentEnabled) {
			document.querySelector("body").addEventListener("mouseleave", handleExitTracking, false);
		}

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("scroll", handleScroll);
			if (exitIntentEnabled) {
				document.querySelector("body").removeEventListener("mouseleave", handleExitTracking);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, isRegistered]);

	useEffect(() => {
		/* Handle the display of the widget and modal when resizing the viewport
			 isWidgetOpen: tracks if the UI was open or closed by user, and will be used to restore UI state
			 across mobile and desktop components
		*/

		if (isOpen && (!isWidgetOpen || viewportWidth < viewportBreakpoint)) {
			setIsOpen(false);
		} else if (!isOpen && isWidgetOpen && viewportWidth >= viewportBreakpoint) {
			setIsOpen(true);
		}
	}, [isOpen, isWidgetOpen, viewportBreakpoint, viewportWidth]);

	useEffect(() => {
		/* Handle the display of the exit intent widget when resizing the viewport on Tablet */

		if (exitIntentEnabled && document?.getElementsByTagName("body")) {
			if (
				viewportHeight !== null &&
				viewportHeightPrev - viewportHeight > 30 &&
				!getCookie(exitIntentCookieName) &&
				!isWidgetOpen &&
				!isRegistered
			) {
				setWidgetOpen(true);
				setExitIntentActive(true);
			}

			setViewportHeightPrev(window.innerHeight);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		exitIntentCookieName,
		exitIntentEnabled,
		isRegistered,
		isWidgetOpen,
		viewportBreakpoint,
		viewportHeight,
		viewportHeightPrev,
		viewportWidth,
	]);

	useEffect(() => {
		// Initialize the widget position
		if (isInit) {
			setTimeout(() => {
				// Init to setup UI and animate to starting position
				handleResize();

				setTimeout(() => {
					// Display the widget
					setHorizontalPosition(0);
				}, 1000);
			}, 2000);

			setInit(false);
		}
	}, [isInit]);

	return (
		<>
			{viewportWidth >= viewportBreakpoint && (
				<React.Fragment>
					{config.type !== "exit" && config.type !== "inline" && (
						<div
							ref={widgetRef}
							className={styles.desktopWidgetWrapper}
							style={{
								right: `${horizontalPosition}px`,
							}}
						>
							<Button
								className={styles.container}
								onClick={handleDisplayForm}
								iconPlacement="left"
								icon="email--o"
							>
								<span className={styles.heading}>{labels.title}</span>
							</Button>
						</div>
					)}

					<Modal
						overlayClassName={styles.modalWrapper}
						isPadding={false}
						contentLabel="Email sign up form"
						container="lg"
						isOpen={isOpen}
						onClose={() => {
							setIsOpen(false);
							setWidgetOpen(false);

							// Hide widgets upon successful registration
							if (sessionStorage.getItem(`${config.id}-registration`) === "1") {
								setRegistered("1");
							}

							handleExitDismiss();
						}}
					>
						<Modal.Content className={styles.modalContent}>
							<EmailAcquisitionForm
								embed={embed}
								config={config}
								mode={mode}
								setWidgetOpen={setWidgetOpen}
								lang={lang}
								userEmail={userEmail}
								setUserEmail={setUserEmail}
								userGateway={userGateway}
								setUserGateway={setUserGateway}
								setRegistered={setRegistered}
								isRegistered={isRegistered}
								setExitIntentActive={setExitIntentActive}
								exitIntentActive={exitIntentActive}
								exitIntentCookieName={exitIntentCookieName}
								exitIntentEnabled={exitIntentEnabled}
								handleExitDismiss={handleExitDismiss}
							/>
						</Modal.Content>
					</Modal>
				</React.Fragment>
			)}
		</>
	);
};

Desktop.propTypes = {
	config: PropTypes.object.isRequired,
	mode: PropTypes.string.isRequired,
	isWidgetOpen: PropTypes.bool.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	setExitIntentActive: PropTypes.func.isRequired,
	exitIntentActive: PropTypes.bool.isRequired,
	exitIntentCookieName: PropTypes.string.isRequired,
	exitIntentEnabled: PropTypes.bool.isRequired,
	handleExitDismiss: PropTypes.func.isRequired,
	embed: PropTypes.bool,
};

Desktop.defaultProps = {
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
	embed: true,
};

export default Desktop;
export { Desktop };
