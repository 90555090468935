import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import * as dayjs from "dayjs";
import { RCL as useTranslation } from "../../RCL";
import { Button } from "../../Button";
import { Heading } from "../../Heading";

// Field Components
import { FlightMode } from "./FlightModeField";
import { GatewayField } from "./GatewayField";
import { DateField } from "./DateField";
import { ClassField } from "./ClassField";
import RoomGuestsField from "../Common/Components/RoomsAndGuests";
import { TemplateInputs } from "./TemplateInputs";

import * as parentStyles from "../SearchBox.module.scss";
import * as styles from "./Flights.module.scss";

const cx = classNames.bind(styles);

const Flights = ({ language, config }) => {
	const dictionary = {
		// Class dropdown
		"economy-class": useTranslation({ searchKey: "economy-class" }),
		"premium-class": useTranslation({ searchKey: "premium-class" }),
		"business-class": useTranslation({ searchKey: "business-class" }),
		"first-class": useTranslation({ searchKey: "first-class" }),

		// Headings
		"sb-flights-heading": useTranslation({ searchKey: "sb-flights-heading" }),
		"sb-flights-subheading": useTranslation({ searchKey: "sb-flights-subheading" }),

		// Common input labels
		"travelling-from": useTranslation({ searchKey: "travelling-from" }),
		"going-to-sov": useTranslation({ searchKey: "going-to-sov" }),
		"departing-date": useTranslation({ searchKey: "departing-date" }),
		"return-date": useTranslation({ searchKey: "return-date" }),
		class: useTranslation({ searchKey: "class" }),
		search: useTranslation({ searchKey: "search" }),
		"round-trip-flight": useTranslation({ searchKey: "round-trip-flight" }),
		"oneway-flight": useTranslation({ searchKey: "oneway-flight" }),
		"multicity-flight": useTranslation({ searchKey: "multicity-flight" }),
	};

	// Gateway states
	const [gatewaySelected, setSelectedGateway] = useState(null);
	const [isValidGateway, setIsValidGateway] = useState(true);

	// Destination states
	const [destinationSelected, setSelectedDestination] = useState(null);
	const [isValidDestination, setIsValidDestination] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Flight mode radio toggle
	///////////////////////////////////////////////////////////// */

	const [flightMode, setFlightMode] = useState("roundtrip");
	const [isValidFlightMode, setIsValidFlightMode] = useState(true);
	const flightModeData = [
		{
			id: "RT",
			label: dictionary["round-trip-flight"],
			value: "roundtrip",
		},
		{
			id: "OW",
			label: dictionary["oneway-flight"],
			value: "oneway",
		},
		{
			id: "MC",
			label: dictionary["multicity-flight"],
			value: "multicity",
		},
	];

	/* /////////////////////////////////////////////////////////////
	// Departure & Return date calendars
	///////////////////////////////////////////////////////////// */

	// Departure date states
	let startDate = dayjs().startOf("day").add(2, "days");
	const departureMinDate = dayjs()
		.startOf("day")
		.add(config.calendar.minDate + config.calendar.minRange, "days");
	const departureMaxDate = dayjs(departureMinDate).add(config.calendar.maxDate, "days");

	// Adjust date initialization for departure calendar
	if (dayjs(departureMinDate).isAfter(startDate)) {
		startDate = departureMinDate;
	}

	const [departureDate, setDepartureDate] = useState(startDate);
	const [isValidDepartureDate, setIsValidDepartureDate] = useState(true);

	// Return date states
	const [returnDate, setReturnDate] = useState(
		dayjs(departureDate).add(config.calendar.maxRange, "days")
	);
	const [isValidReturnDate, setIsValidReturnDate] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Flight row data
	///////////////////////////////////////////////////////////// */

	const flightDataTemplate = [
		{
			type: "typeahead",
			input: "gateway",
			id: "flight-gateway-0-0",
			selected: undefined,
			default: undefined,
			error: false,
			className: cx("hideDDButton"),
		},
		{
			type: "typeahead",
			input: "destination",
			id: "flight-depart-0-1",
			selected: undefined,
			default: undefined,
			error: false,
			className: cx("hideDDButton"),
		},
		{
			type: "date",
			input: "depart",
			id: "flight-return-date-0-2",
			selected: undefined,
			default: dayjs(departureDate).format("YYYY-MM-DD"),
			minDate: dayjs(departureDate).format("YYYY-MM-DD"),
			error: false,
		},
		{
			type: "date",
			input: "return",
			id: "flight-depart-date-0-3",
			selected: undefined,
			default: undefined,
			minDate: undefined,
			error: false,
		},
		{
			type: "placeholder",
		},
	];

	const [flightData, setFlightData] = useState([flightDataTemplate]);

	/* /////////////////////////////////////////////////////////////
	// Class type drop-down data
	///////////////////////////////////////////////////////////// */

	const classTypeOptions = [
		{ value: "Y", label: dictionary["economy-class"], id: dictionary["economy-class"] },
		{ value: "S", label: dictionary["premium-class"], id: dictionary["premium-class"] },
		{ value: "C", label: dictionary["business-class"], id: dictionary["business-class"] },
		{ value: "F", label: dictionary["first-class"], id: dictionary["first-class"] },
	];

	const [classTypeData, setClassTypeData] = useState(classTypeOptions);
	const [classType, setClassType] = useState(classTypeOptions[0]);
	const [isValidClassType, setIsValidClassType] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Rooms states
	///////////////////////////////////////////////////////////// */

	const [selectedRoomsOccupancies, setSelectedRoomsOccupancies] = useState({
		rooms: 1,
		adults: 2,
		children: [],
		infants: 0,
	});

	const [isValidSelectedRoomsOccupancies, setIsValidSelectedRoomsOccupancies] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Form validation logic
	///////////////////////////////////////////////////////////// */

	const validateSearchCriteria = () => {
		let isValid = true;

		// Check if flightMode has a value
		const validFlightMode = !!isValidFlightMode;
		const findValue = flightModeData.filter(fm => fm.value === flightMode);

		if (!validFlightMode || findValue.length <= 0) {
			isValid = false;
			setIsValidFlightMode(false);
		}

		if (
			isValid &&
			(flightMode === "roundtrip" || flightMode === "oneway" || flightMode === "multicity")
		) {
			const validClassType = classType != null || false;

			if (!validClassType) {
				isValid = validClassType;
				setIsValidClassType(false);
			}

			const validGateway = gatewaySelected != null || false;

			if (!validGateway) {
				isValid = validGateway;
				setIsValidGateway(false);
			}

			const validDestination = destinationSelected != null || false;

			if (!validDestination) {
				isValid = validDestination;
				setIsValidDestination(false);
			}

			const validDepartureDate = departureDate != null || false;

			if (!validDepartureDate) {
				isValid = validDepartureDate;
				setIsValidDepartureDate(false);
			}

			if (flightMode === "roundtrip") {
				const validReturnDate = returnDate != null || false;

				if (!validReturnDate) {
					isValid = validReturnDate;
					setIsValidReturnDate(false);
				}
			}

			const validPax = selectedRoomsOccupancies != null || false;

			if (!validPax) {
				isValid = validPax;
				setIsValidSelectedRoomsOccupancies(false);
			}

			if (flightMode === "multicity") {
				const updatedFlightData = JSON.parse(JSON.stringify(flightData));

				let legError = false;

				// Handle error checking for the flight array
				updatedFlightData.forEach(leg => {
					leg.forEach(legItem => {
						if (legItem.type === "typeahead") {
							if (legItem.input === "gateway" || legItem.input === "destination") {
								const validGatewayCustom = legItem.selected != null || false;

								if (!validGatewayCustom) {
									isValid = validGatewayCustom;
									legError = true;
									// eslint-disable-next-line no-param-reassign
									legItem.error = true;
								}
							}
						} else if (legItem.type === "date") {
							if (legItem.input === "depart") {
								const validDateCustom = legItem.selected != null || false;

								if (!validDateCustom) {
									isValid = validDateCustom;
									legError = true;
									// eslint-disable-next-line no-param-reassign
									legItem.error = true;
								}
							}
						}
					});
				});

				// Update flightData only if anything requires it
				if (legError) {
					setFlightData([...updatedFlightData]);
				}
			}
		}

		return isValid;
	};

	const handleFormSubmission = event => {
		event.preventDefault();

		// Validate all fields based on flightMode
		if (!validateSearchCriteria()) return;

		// Set up dataLayer tracking if not available
		if (!window.dataLayer) {
			window.dataLayer = [];
		}

		// Track flight leg data
		const trackingArray = [];

		let bookingPathURL = "";

		// Retrieve the pax data
		const { adults, children, infants } = selectedRoomsOccupancies;

		let childAges = "";
		children.forEach(child => {
			childAges += `&ages[]=${child}`;
		});

		if (infants > 0) {
			for (let infant = 0; infant < infants; infant++) {
				childAges += "&ages[]=0";
			}
		}

		// Create the common departure leg
		const leg1 = `&leg[]=${gatewaySelected.value};${destinationSelected.value};${dayjs(
			departureDate
		).format("YYYY-MM-DD")}`;

		if (flightMode === "roundtrip") {
			const leg2 = `&leg[]=${destinationSelected.value};${gatewaySelected.value};${dayjs(
				returnDate
			).format("YYYY-MM-DD")}`;

			bookingPathURL = `https://flight.selloffvacations.com/selloff/flights/search?adults=${adults}&children=${
				children.length + infants
			}${childAges}&trip=${flightMode}&preferedClass=${
				classType.value
			}&viewtype=R&lang=${language}${leg1}${leg2}`;

			window.dataLayer.push({
				event: "flight_search",
				flight_search_dep_date: departureDate.format("YYYY-MM-DD"), // YYYY-MM-DD
				flight_search_dep_month: departureDate.format("MMMM"),
				flight_search_duration: returnDate.diff(departureDate, "day"),
				flight_search_mode: flightMode,
				flight_search_type: classType.value,
				flight_search_from: gatewaySelected.label, // always proper name
				flight_search_to: destinationSelected.label, // always proper name
				flight_search_num_adult: selectedRoomsOccupancies.adults,
				flight_search_num_children:
					selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length,
				flight_search_num_rooms: selectedRoomsOccupancies.rooms,
				flight_search_total_pax:
					selectedRoomsOccupancies.adults +
					selectedRoomsOccupancies.infants +
					selectedRoomsOccupancies.children.length,
			});
		} else if (flightMode === "oneway") {
			bookingPathURL = `https://flight.selloffvacations.com/selloff/flights/search?adults=${adults}&children=${
				children.length + infants
			}${childAges}&trip=${flightMode}&preferedClass=${
				classType.value
			}&viewtype=R&lang=${language}${leg1}`;

			window.dataLayer.push({
				event: "flight_search",
				flight_search_dep_date: departureDate.format("YYYY-MM-DD"), // YYYY-MM-DD
				flight_search_dep_month: departureDate.format("MMMM"),
				flight_search_duration: 0,
				flight_search_mode: flightMode,
				flight_search_type: classType.value,
				flight_search_from: gatewaySelected.label, // always proper name
				flight_search_to: destinationSelected.label, // always proper name
				flight_search_num_adult: selectedRoomsOccupancies.adults,
				flight_search_num_children:
					selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length,
				flight_search_num_rooms: selectedRoomsOccupancies.rooms,
				flight_search_total_pax:
					selectedRoomsOccupancies.adults +
					selectedRoomsOccupancies.infants +
					selectedRoomsOccupancies.children.length,
			});
		} else if (flightMode === "multicity") {
			let legs = "";

			// Generate all array stored legs queries
			flightData.forEach(leg => {
				legs += `&leg[]=${leg[0].selected.value};${leg[1].selected.value};${leg[2].selected}`;

				trackingArray.push({
					dep_date: dayjs(leg[2].selected).format("YYYY-MM-DD"), // YYYY-MM-DD
					dep_month: dayjs(leg[2].selected).format("MMMM"),
					from: leg[0].selected.label, // always proper name
					to: leg[1].selected.label, // always proper name
				});
			});

			bookingPathURL = `https://flight.selloffvacations.com/selloff/flights/search?adults=${adults}&children=${
				children.length + infants
			}${childAges}&trip=${flightMode}&preferedClass=${
				classType.value
			}&viewtype=R&lang=${language}${leg1}${legs}`;

			// Get the last leg data for tracking
			const lastLeg = trackingArray[trackingArray.length - 1];
			const dateDiff = dayjs(lastLeg.dep_date).diff(departureDate, "day");

			window.dataLayer.push({
				event: "flight_search",
				flight_search_dep_date: departureDate.format("YYYY-MM-DD"), // YYYY-MM-DD
				flight_search_dep_month: departureDate.format("MMMM"),
				flight_search_mode: flightMode,
				flight_search_type: classType.value,
				flight_search_from: gatewaySelected.label, // always proper name
				flight_search_num_adult: selectedRoomsOccupancies.adults,
				flight_search_num_children:
					selectedRoomsOccupancies.infants + selectedRoomsOccupancies.children.length,
				flight_search_num_rooms: selectedRoomsOccupancies.rooms,
				flight_search_total_pax:
					selectedRoomsOccupancies.adults +
					selectedRoomsOccupancies.infants +
					selectedRoomsOccupancies.children.length,

				// Determined based on last leg
				flight_search_to: lastLeg.to, // always proper name
				flight_search_duration: dateDiff,
			});
		}

		window.open(bookingPathURL, "_blank");
	};

	/* /////////////////////////////////////////////////////////////
	// State updates
	///////////////////////////////////////////////////////////// */

	// Update this option b/c it is the only one that applies
	useEffect(() => {
		if (flightMode === "multicity") {
			setClassTypeData([classTypeOptions[0]]);
			setClassType(classTypeOptions[0]);
		} else {
			setClassTypeData(classTypeOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flightMode]);

	// Update return date when departure date is greater
	useEffect(() => {
		if (dayjs(departureDate).isAfter(returnDate)) {
			setReturnDate(departureDate);
		}

		// Update datepicker changes for invalid dates on newer legs
		const updatedFlightData = JSON.parse(JSON.stringify(flightData));
		let isUpdated = false;
		let newerMinDate = departureDate;
		let prevSelected = departureDate;

		updatedFlightData.forEach(fdRow => {
			fdRow.forEach(fdColumn => {
				if (fdColumn.type === "date" && fdColumn.input === "depart") {
					newerMinDate = dayjs(newerMinDate).isBefore(fdColumn.selected)
						? fdColumn.selected
						: newerMinDate;

					if (dayjs(newerMinDate).isAfter(fdColumn.selected)) {
						// eslint-disable-next-line no-param-reassign
						fdColumn.selected = dayjs(newerMinDate).format("YYYY-MM-DD");
						// eslint-disable-next-line no-param-reassign
						fdColumn.minDate = dayjs(prevSelected).format("YYYY-MM-DD");

						isUpdated = true;
					} else if (
						dayjs(fdColumn.minDate).isBefore(prevSelected) ||
						dayjs(fdColumn.minDate).isAfter(prevSelected)
					) {
						// eslint-disable-next-line no-param-reassign
						fdColumn.minDate = dayjs(prevSelected).format("YYYY-MM-DD");

						isUpdated = true;
					}
				}

				prevSelected = fdColumn?.selected || prevSelected;
			});
		});

		if (isUpdated) {
			isUpdated = false;
			setFlightData([...updatedFlightData]);
		}
	}, [departureDate, flightData, returnDate]);

	useEffect(() => {
		/* NOTE: Updates first leg only */
		// Updates the gateway field selection (when empty), once previous leg departure has been selected
		const departureLegs = JSON.parse(JSON.stringify(flightData));
		let departureIndex;
		const departureLegInput = departureLegs[0].filter((fdItem, fdIndex) => {
			const filterMatch = fdItem.type === "typeahead" && fdItem.input === "gateway";
			if (filterMatch) {
				departureIndex = fdIndex;
				return filterMatch;
			}
			return false;
		});

		if (
			destinationSelected != null &&
			departureLegInput?.length > 0 &&
			departureLegInput[0]?.selected === undefined
		) {
			// Update the gateway field
			const [inputSettings] = departureLegInput;
			inputSettings.selected = destinationSelected;
			departureLegs[0][departureIndex] = inputSettings;
			setFlightData([...departureLegs]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destinationSelected]);

	return (
		<React.Fragment>
			<Heading as="div" size="h2">
				{dictionary["sb-flights-heading"]}
			</Heading>
			{dictionary["sb-flights-subheading"] && (
				<p className={cx(parentStyles.subheading)}>{dictionary["sb-flights-subheading"]}</p>
			)}
			<div id="sov-search-box">
				<form
					acceptCharset="UTF-8"
					noValidate
					className={cx("searchForm")}
					onSubmit={handleFormSubmission}
				>
					<div className={cx("formRow", "flightMode")}>
						<FlightMode
							// className={Object.assign(parentStyles, styles)}
							id="flight-mode"
							language={language}
							data={flightModeData}
							label=""
							resetError={() => setIsValidFlightMode(true)}
							error={!isValidFlightMode}
							onChange={setFlightMode}
							selectedOption={flightMode}
						/>
					</div>
					<div className={cx("formRowWrapper", flightMode === "multicity" ? "multiple" : "single")}>
						<div className={cx("formFields")}>
							<div
								className={cx("formRow", "staticRow", flightMode === "multicity" ? "multiple" : "")}
							>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<GatewayField
											id="flights-gateways"
											label={dictionary["travelling-from"]}
											selected={gatewaySelected}
											error={!isValidGateway}
											resetError={() => setIsValidGateway(true)}
											onChange={setSelectedGateway}
										/>
									</div>
								</div>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<GatewayField
											id="flights-destinations"
											label={dictionary["going-to-sov"]}
											selected={destinationSelected}
											error={!isValidDestination}
											resetError={() => setIsValidDestination(true)}
											onChange={setSelectedDestination}
										/>
									</div>
								</div>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<DateField
											id="flights-departure-date"
											label={dictionary["departing-date"]}
											language={language}
											selected={departureDate}
											minDate={departureMinDate}
											maxDate={departureMaxDate}
											error={!isValidDepartureDate}
											onChange={setDepartureDate}
										/>
									</div>
								</div>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<DateField
											id="flights-return-date"
											label={dictionary["return-date"]}
											language={language}
											selected={flightMode === "multicity" ? undefined : returnDate}
											minDate={departureDate}
											maxDate={departureMaxDate}
											disabled={flightMode === "oneway" || flightMode === "multicity"}
											error={!isValidReturnDate}
											onChange={setReturnDate}
											placeholder={
												flightMode === "oneway" || flightMode === "multicity" ? "" : "YYYY-MM-DD"
											}
										/>
									</div>
								</div>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<ClassField
											id="flights-class"
											label={dictionary.class}
											selected={classType}
											data={classTypeData}
											error={!isValidClassType}
											resetError={() => setIsValidClassType(true)}
											onChange={setClassType}
										/>
									</div>
								</div>
								<div className={cx("formColumn")}>
									<div className={cx("inputContainer")}>
										<RoomGuestsField
											id="flights-passengers"
											selectedRoomsOccupancies={selectedRoomsOccupancies}
											error={isValidSelectedRoomsOccupancies}
											onChange={setSelectedRoomsOccupancies}
											language={language}
											roomsMax={1}
											classNames={styles}
											adultsMax={6}
											childrenMax={4}
											maxChildAge={11}
											searchType="Flights"
										/>
									</div>
								</div>
							</div>
							{flightMode === "multicity" &&
								flightData.length > 0 &&
								flightData.map((fdRow, fdRowIndex) => (
									<div className={cx("formRow", "dynamicRow")}>
										{fdRow.map((fdItem, fdColumnIndex) => (
											<div className={cx("formColumn")}>
												<TemplateInputs
													fdRowIndex={fdRowIndex}
													fdColumnIndex={fdColumnIndex}
													flightData={flightData}
													setFlightData={setFlightData}
													flightDataTemplate={flightDataTemplate}
													language={language}
													flightMode={flightMode}
												/>
											</div>
										))}
									</div>
								))}
						</div>
						<div className={cx("searchButton")}>
							<Button
								type="submit"
								full
								size="md"
								theme="primary"
								defaultStyle={true}
								className={parentStyles.searchButton}
							>
								{dictionary.search}
							</Button>
						</div>
					</div>
				</form>
			</div>
		</React.Fragment>
	);
};

Flights.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	config: PropTypes.shape({
		calendar: PropTypes.shape({
			minRange: PropTypes.number,
			maxRange: PropTypes.number,
			minDate: PropTypes.number,
			maxDate: PropTypes.number,
		}),
	}),
};

Flights.defaultProps = {
	config: {
		calendar: {
			minRange: 1,
			maxRange: 7,
			minDate: 0,
			maxDate: 365,
		},
	},
};

export default Flights;
export { Flights };
