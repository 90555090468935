import React, { createElement } from "react";

const parseChildrenBySlots = children => {
	const result = new Map();

	React.Children.forEach(children, child => {
		const slotType = child?.props?.slot;

		if (slotType) {
			const { slot, ...childProps } = child.props;
			const childWithoutSlotProp = createElement(child.type, {
				...childProps,
				key: result.get(slotType)?.length
					? `${slotType}-${result.get(slotType)?.length}`
					: `${slotType}-0`,
			});

			if (result.has(slotType)) {
				result.set(slotType, [...result.get(slotType), childWithoutSlotProp]);
			} else {
				result.set(slotType, [childWithoutSlotProp]);
			}
		}
	});

	return {
		get(slotType, wrapper) {
			const childrenBySlotType = result.get(slotType);

			if (!childrenBySlotType) {
				return null;
			}

			if (!wrapper) {
				return childrenBySlotType;
			}

			return wrapper(childrenBySlotType);
		},
	};
};
export default parseChildrenBySlots;
export { parseChildrenBySlots };
