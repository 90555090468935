import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { A11y, Autoplay, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { SliderNavigation } from "./Navigation/SliderNavigation";
import { SliderSlide } from "./Slide/SliderSlide";
import * as styles from "./ThumbSlider.module.scss";

const ThumbSlider = ({
	slides,
	a11y,
	navigationClasses,
	className,
	setThumbsSlider,
	slideClasses,
}) => {
	const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);
	const swiperRef = useRef();
	return (
		<Swiper
			onSwiper={swiper => {
				swiperRef.current = swiper;
				setIsSwiperLoaded(true);
				setThumbsSlider(swiper);
			}}
			modules={[Navigation, A11y, Autoplay, Thumbs]}
			navigation={{
				nextEl: ".thumbNavigationNextButton",
				prevEl: ".thumbNavigationPrevButton",
			}}
			className={cx(className, styles.thumbSlider, styles.MediaGallery_ThumbSlider)}
			slidesPerView="auto"
			watchSlidesVisibility={true}
			watchSlidesProgress={true}
			watchOverflow={true}
			a11y={a11y}
			spaceBetween={3}
		>
			<>
				{slides?.map(slide => (
					<SwiperSlide key={slide.id} className={cx(styles.thumbSlide, slideClasses)}>
						<SliderSlide image={slide} />
					</SwiperSlide>
				))}
				<SliderNavigation
					slot="container-start"
					swiperRefLoaded={isSwiperLoaded}
					swiperRef={swiperRef}
					navigationClasses={navigationClasses}
				/>
			</>
		</Swiper>
	);
};

ThumbSlider.propTypes = {
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string,
			subTitle: PropTypes.string,
			desktopImage: PropTypes.shape({
				src: PropTypes.string.isRequired,
				srcSet: PropTypes.string.isRequired,
			}),
			mobileImage: PropTypes.shape({
				src: PropTypes.string.isRequired,
				srcSet: PropTypes.string.isRequired,
			}),
			imageTitle: PropTypes.string.isRequired,
		})
	).isRequired,
	className: PropTypes.string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		stopSliderLabel: PropTypes.string,
		startSliderLabel: PropTypes.string,
	}),
	setThumbsSlider: PropTypes.func.isRequired,
	navigationClasses: PropTypes.shape({
		button: PropTypes.string,
		prevButton: PropTypes.string,
		nextButton: PropTypes.string,
	}),
	slideClasses: PropTypes.string,
};

ThumbSlider.defaultProps = {
	className: "",
	a11y: {
		prevSlideLabel: "Previous slide",
		nextSlideLabel: "Next slide",
		firstSlideLabel: "This is the first slide",
		lastSlideLabel: "This is the last slide",
		paginationBulletLabel: "Go to slide {{index}}",
		stopSliderLabel: "Stop slider",
		startSliderLabel: "Start slider",
	},
	navigationClasses: {},
	slideClasses: "",
};

export default ThumbSlider;
export { ThumbSlider };
