import React from "react";
import PropTypes from "prop-types";
import iconsList from "@sunwing/icons/dist/sov/icons.json";
import { Icon } from "../../components/Icons";

const IconConnector = ({ name, title, hidden }) => (
	<Icon name={name} title={title} hidden={hidden} />
);
IconConnector.displayName = "Icon";

IconConnector.propTypes = {
	name: PropTypes.oneOf(iconsList).isRequired,
	title: PropTypes.string,
	hidden: PropTypes.bool,
};
IconConnector.defaultProps = {
	title: undefined,
	hidden: true,
};

export default IconConnector;
export { IconConnector, iconsList };
