// extracted by mini-css-extract-plugin
export var normal = "Section-module--normal--35NrL";
export var top = "Section-module--top--3Waai";
export var bottom = "Section-module--bottom--2ozoP";
export var light = "Section-module--light--y2Ghk";
export var transparent = "Section-module--transparent--Aspdd";
export var dark = "Section-module--dark--OsnAf";
export var section__container = "Section-module--section__container--8R1mb";
export var full = "Section-module--full--3rEg4";
export var section__title = "Section-module--section__title--3SkrK";
export var section__content = "Section-module--section__content--2N_oH";
export var bgImg = "Section-module--bgImg--2QBGu";
export var sectionBackgroundImageContainer = "Section-module--sectionBackgroundImageContainer--zUsb5";
export var wrapperRelative = "Section-module--wrapperRelative--3KAhb";
export var contentRelative = "Section-module--contentRelative--26C05";