/* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useState, useReducer } from "react";
import React, { useState, useReducer } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { RadioGroup, RadioCheckboxGroup } from "@sunwing/shared-components";
import { Icon } from "../../../Icons";
import { RCL as useTranslation } from "../../../RCL";
import * as styles from "./FilterForm.module.scss";
import { Button } from "../../../Button";
import { HotelSearch } from "./HotelSearch";

const defaultRadioClassNames = {
	input: styles.radioGroupInput,
	label: styles.radioGroupLabel,
};
const defaultCheckClassNames = {
	input: styles.checkGroupInput,
	label: styles.checkGroupLabel,
};

const initialState = {
	no_hotel: null,
	star: null,
	price_max: null,
	direct_flight: null,
	hotelFeature: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "no_hotel":
			return { ...state, no_hotel: action.no_hotel };
		case "price_max":
			return { ...state, price_max: action.price_max };
		case "hotelFeature":
			return { ...state, hotelFeature: action.hotelFeature };
		case "direct_flight":
			return { ...state, direct_flight: action.direct_flight };
		case "star":
			return {
				...state,
				star: action.star,
			};
		case "clear":
			return { ...initialState };
		default:
			throw new Error();
	}
};

const FilterForm = ({
	language,
	onSaveFilter,
	id,
	radioGroupClassNamesProp: providedRadioClassNames,
	checkBoxClassNamesProp: providedCheckClassNames,
	gateway,
	destination,
	isGatewayChanged,
	setGatewayChanged,
	isDestinationChanged,
	setDestinationChanged,
}) => {
	const dictionary = {
		"filter-star-rating": useTranslation({ searchKey: "filter-star-rating" }),
		"clear-filters": useTranslation({ searchKey: "clear-filters" }),
		"price-range-per-person": useTranslation({ searchKey: "price-range-per-person" }),
		"hotel-must-have-features": useTranslation({ searchKey: "hotel-must-have-features" }),
		"find-a-hotel": useTranslation({ searchKey: "find-a-hotel" }),
		"flight-itinerary": useTranslation({ searchKey: "flight-itinerary" }),
		"apply-filter": useTranslation({ searchKey: "apply-filter" }),

		"less-than-1000": useTranslation({ searchKey: "less-than-1000" }),
		"less-than-1500": useTranslation({ searchKey: "less-than-1500" }),
		"less-than-2000": useTranslation({ searchKey: "less-than-2000" }),

		"all-inclusive": useTranslation({ searchKey: "all-inclusive" }),
		family: useTranslation({ searchKey: "family" }),
		"beach-front": useTranslation({ searchKey: "beach-front" }),
		"golf-course": useTranslation({ searchKey: "golf-course" }),
		"adults-only": useTranslation({ searchKey: "adults-only" }),
		"mini-club": useTranslation({ searchKey: "mini-club" }),
		spa: useTranslation({ searchKey: "spa" }),
		casino: useTranslation({ searchKey: "casino" }),

		"non-stop-flights": useTranslation({ searchKey: "non-stop-flights" }),
		"direct-flight": useTranslation({ searchKey: "direct-flight" }),
	};

	const priceMax = [
		{
			id: "1000",
			label: dictionary["less-than-1000"],
			value: "1000",
		},
		{
			id: "1500",
			label: dictionary["less-than-1500"],
			value: "1500",
		},
		{
			id: "2000",
			label: dictionary["less-than-2000"],
			value: "2000",
		},
	];

	const hotelFeature = [
		{
			id: "all_inclusive",
			label: dictionary["all-inclusive"],
			value: "all_inclusive",
		},
		{
			id: "family",
			label: dictionary.family,
			value: "family",
		},
		{
			id: "beach",
			label: dictionary["beach-front"],
			value: "beach",
		},
		{
			id: "golf",
			label: dictionary["golf-course"],
			value: "golf",
		},
		{
			id: "adults_only",
			label: dictionary["adults-only"],
			value: "adults_only",
		},
		{
			id: "spa",
			label: dictionary.spa,
			value: "spa",
		},
		{
			id: "mini_club",
			label: dictionary["mini-club"],
			value: "mini_club",
		},
		{
			id: "casino",
			label: dictionary.casino,
			value: "casino",
		},
	];

	const starRating = [
		{
			id: "3",
			label: (
				<span>
					<span className={styles.sbRating}>3 + </span>{" "}
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
				</span>
			),
			value: "3",
		},
		{
			id: "4",
			label: (
				<span>
					<span className={styles.sbRating}>4 +</span>{" "}
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
				</span>
			),
			value: "4",
		},
		{
			id: "5",
			label: (
				<span>
					<span className={styles.sbRating}>5 </span>{" "}
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
					<Icon name="star" className={styles.starRating} />
				</span>
			),
			value: "5",
		},
	];

	const flightItinerary = [
		{
			id: "NonStop",
			label: dictionary["non-stop-flights"],
			value: "NonStop",
		},
		{
			id: "DirectFlight",
			label: dictionary["direct-flight"],
			value: "Direct",
		},
	];

	const [state, dispatch] = useReducer(reducer, initialState);
	const [errorState, setErrorState] = useState({ errorMessages: "" });

	const onSubmitHandler = event => {
		event.preventDefault();
		if (
			state.HotelFeature === null &&
			state.price_max === null &&
			state.no_hotel === null &&
			state.star === null &&
			state.direct_flight === null
		) {
			setErrorState({ errorMessages: "There is no filter selected" });
			console.warn("Error Message", errorState.errorMessages);
		}
		onSaveFilter(state);
	};

	const radioGroupClassNames = {
		...defaultRadioClassNames,
		...providedRadioClassNames,
	};

	const checkBoxClassNames = {
		...defaultCheckClassNames,
		...providedCheckClassNames,
	};

	return (
		<div className={styles.filterForm}>
			<form
				acceptCharset="UTF-8"
				noValidate
				id={id}
				className={styles.packagesForm}
				onSubmit={onSubmitHandler}
			>
				<div className={styles.formRow}>
					<div className={cx(styles.formColumn, styles.right)}>
						<div className={styles.inputContainerFirst}>
							<Button
								type="reset"
								size="md"
								theme="secondary"
								defaultStyle={true}
								onClick={() => {
									dispatch({ type: "clear" });
									onSaveFilter(initialState);
								}}
							>
								{dictionary["clear-filters"]}
							</Button>
						</div>
					</div>
				</div>
				<div className={styles.formRow}>
					<div className={styles.formColumn}>
						<div className={styles.inputContainer}>
							<label className={styles.sectionLabel} htmlFor="price_max">
								{dictionary["price-range-per-person"]}
							</label>
							<RadioGroup
								name="price_max"
								classNames={{ ...radioGroupClassNames }}
								options={priceMax}
								type="radio"
								selected={state.price_max}
								onChange={selection => {
									if (selection !== null) {
										dispatch({ type: "price_max", price_max: selection });
									}
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.formRow}>
					<div className={styles.formColumn}>
						<div className={styles.inputContainer}>
							<label className={styles.sectionLabel} htmlFor="hotelFeature">
								{dictionary["hotel-must-have-features"]}
							</label>
							<RadioCheckboxGroup
								className={styles.radioCheckboxGroup}
								name="hotelFeature"
								classNames={{ ...checkBoxClassNames }}
								options={hotelFeature}
								selected={state.hotelFeature}
								type="checkbox"
								onChange={selection => {
									if (selection !== null) {
										dispatch({ type: "hotelFeature", hotelFeature: selection });
									}
								}}
							/>
						</div>
					</div>
				</div>

				{/* star rating */}
				<div className={styles.formRow}>
					<div className={styles.formColumn}>
						<div className={styles.inputContainer}>
							<label className={styles.sectionLabel} htmlFor="starRating">
								{dictionary["filter-star-rating"]}
							</label>
							<RadioGroup
								name="starRating"
								classNames={{ ...radioGroupClassNames }}
								options={starRating}
								selected={state.star}
								type="radio"
								onChange={selection => {
									dispatch({ type: "star", star: selection });
								}}
							/>
						</div>
					</div>
				</div>
				<div className={styles.formRow}>
					<div className={styles.formColumn}>
						<div className={styles.inputContainer}>
							<HotelSearch
								id="filter-hotelSearch"
								label={dictionary["find-a-hotel"]}
								placeholder={dictionary["find-a-hotel"]}
								language={language}
								selected={state.no_hotel}
								onChange={dispatch}
								gatewaySelected={gateway}
								destination={destination}
								isGatewayChanged={isGatewayChanged}
								setGatewayChanged={setGatewayChanged}
								isDestinationChanged={isDestinationChanged}
								setDestinationChanged={setDestinationChanged}
							/>
						</div>
					</div>
				</div>

				<div className={styles.formRow}>
					<div className={styles.formColumn}>
						<div className={styles.inputContainer}>
							<label className={styles.sectionLabel} htmlFor="FlightItinerary">
								{dictionary["flight-itinerary"]}
							</label>
							<RadioGroup
								name="FlightItinerary"
								classNames={{ ...radioGroupClassNames }}
								options={flightItinerary}
								selected={state.direct_flight}
								type="radio"
								onChange={selection => {
									if (selection !== null) {
										dispatch({ type: "direct_flight", direct_flight: selection });
									}
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.stickyBottom}>
					<Button type="submit" full size="md" theme="primary" defaultStyle={true}>
						{dictionary["apply-filter"]}
					</Button>
				</div>
			</form>
		</div>
	);
};

FilterForm.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	radioGroupClassNamesProp: PropTypes.shape({
		error: PropTypes.string,
		input: PropTypes.string,
		radio: PropTypes.string,
		label: PropTypes.string,
	}),
	checkBoxClassNamesProp: PropTypes.shape({
		error: PropTypes.string,
		input: PropTypes.string,
		radio: PropTypes.string,
		label: PropTypes.string,
	}),
	id: PropTypes.string.isRequired,
	onSaveFilter: PropTypes.func.isRequired,
	gateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	destination: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	isGatewayChanged: PropTypes.bool.isRequired,
	setGatewayChanged: PropTypes.func.isRequired,
	isDestinationChanged: PropTypes.bool.isRequired,
	setDestinationChanged: PropTypes.func.isRequired,
};

FilterForm.defaultProps = {
	radioGroupClassNamesProp: defaultRadioClassNames,
	checkBoxClassNamesProp: defaultCheckClassNames,
	gateway: { label: "YYZ", value: "YYZ" },
	destination: { label: "", value: "" },
};

export default FilterForm;
export { FilterForm };
