/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Button as ButtonComponent } from "@sunwing/shared-components";
import { Icon } from "../Icons";
import * as styles from "./Button.module.scss";

const Button = ({
	children,
	type,
	label,
	to,
	target,
	onClick,
	icon,
	iconPlacement,
	theme,
	size,
	disabled,
	full,
	noStyle,
	defaultStyle,
	className,
	asText,
	align,
}) => {
	const commonStyle = cx({
		[styles[size]]: size,
		[styles.empty]: !label,
		[styles.disabled]: disabled,
		[styles.noStyle]: noStyle,
		[styles[`${iconPlacement}`]]: (icon && iconPlacement) || iconPlacement === "right",
		[className]: className,
	});

	const classes = cx({
		[styles.btn]: defaultStyle,
		[commonStyle]: commonStyle,
		[styles[theme]]: theme,
		[styles.full]: full,
		[className]: className,
	});

	const noStyleClasses = cx(commonStyle);

	// Common props for ButtonComponent
	const commonButtonProps = {
		type,
		label: children || label,
		to,
		target,
		onClick,
		icon: icon && <Icon name={icon} />,
		iconPlacement,
		theme: false,
		size,
		disabled,
		full,
		asText,
		noStyle,
		className: noStyle ? noStyleClasses : classes,
	};

	if (align === "center" || align === "right") {
		return (
			<div className={cx({ [styles[align]]: align })}>
				<ButtonComponent {...commonButtonProps} />
			</div>
		);
	}

	return <ButtonComponent {...commonButtonProps} />;
};

Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	type: PropTypes.oneOf(["button", "submit", "reset"]),
	label: PropTypes.string,
	to: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	iconPlacement: PropTypes.oneOf(["right", "left"]),
	theme: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"]),
	size: PropTypes.oneOf(["sm", "md", "lg", "auto"]),
	disabled: PropTypes.bool,
	full: PropTypes.bool,
	noStyle: PropTypes.bool,
	defaultStyle: PropTypes.bool,
	asText: PropTypes.bool,
	className: PropTypes.string,
	align: PropTypes.oneOf(["center", "left", "right"]),
};
Button.defaultProps = {
	children: undefined,
	type: "button",
	label: undefined,
	to: undefined,
	target: "_self",
	onClick: undefined,
	icon: undefined,
	iconPlacement: undefined,
	theme: "primary",
	size: "md",
	disabled: false,
	full: false,
	noStyle: false,
	asText: false,
	defaultStyle: true,
	className: undefined,
	align: undefined,
};

export default Button;
export { Button };
