import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { RadioGroup } from "../../Common/Components/Radio/RadioGroup";

import * as parentStyles from "../../SearchBox.module.scss";
import * as styles from "../Cruises.module.scss";

const CruiseModeField = ({ id, data, label, error, onChange, resetError, selectedOption }) => (
	<RadioGroup
		className={cx(styles.cruiseToggle, parentStyles.radio)}
		id={id}
		label={label}
		name={id}
		hideLabel={true}
		onChange={selection => {
			resetError(true);
			onChange(selection);
		}}
		selectedOption={selectedOption}
		error={error}
		options={data}
		inline={true}
	/>
);

CruiseModeField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	selectedOption: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func.isRequired,
};

CruiseModeField.defaultProps = {
	error: false,
};

export default CruiseModeField;
export { CruiseModeField };
