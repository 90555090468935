import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../PageContext";
// eslint-disable-next-line import/no-cycle
import { TableConnector } from "../Table/TableConnector";
import { FormAssemblyConnector } from "../FormAssembly";
import { SearchBoxConnector } from "../SearchBox/SearchBoxConnector";
import { AnchorTagConnector } from "../Anchor/AnchorTagConnector";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionConnector } from "../EmailAcquisitionForm";

const ComponentBlock = ({ data }) => {
	const content = data?.configuration && JSON.parse(data?.configuration?.internal?.content);
	const { locale } = useContext(PageContext);
	const lang = locale?.split("-")[0] || "en";

	switch (data?.componentType) {
		case "table":
			return <TableConnector data={content} />;
		case "form-assembly":
			return <FormAssemblyConnector data={content} />;
		case "search-box":
			return <SearchBoxConnector data={content} language={lang} type={data.type} />;
		case "anchor-tag":
			return <AnchorTagConnector data={content} />;
		case "signup-form-assembly":
			return <EmailAcquisitionConnector data={content} language={lang} />;
		default:
			return <></>;
	}
};

/* Declare page prop */
ComponentBlock.propTypes = {
	data: PropTypes.object.isRequired,
};

export default ComponentBlock;
export { ComponentBlock };
