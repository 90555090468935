import React, { useState } from "react";
import PropTypes from "prop-types";
import { MediaGallery } from "../MediaGallery";
import { MediaGalleryModal } from "../MediaGalleryModal";

const MediaGalleryEmbedded = ({
	gallery,
	thumb,
	logo,
	video,
	map,
	detailMap,
	a11y,
	className,
	accountId,
	lang,
	playerId,
	sliderClasses,
	mapButton,
	videoButton,
	closeButton,
	modalClasses,
	pagination,
	footerClasses,
	embeddedFooterClasses,
	otherMiscButton,
	onModalOpen,
	onModalClose,
}) => {
	const [showModal, setShowModal] = useState(false);
	const [videoSelected, setVideoSelected] = useState(false);
	const [mapSelected, setMapSelected] = useState(false);
	const [activeSlideIndex, setaActiveSlideIndex] = useState(0);
	const onClose = currentSlideIndex => {
		setaActiveSlideIndex(currentSlideIndex);
		setMapSelected(false);
		setVideoSelected(false);
		setShowModal(false);
		if (onModalClose) {
			onModalClose();
		}
	};
	const onVideoOpen = () => {
		setVideoSelected(true);
		setMapSelected(false);
		setShowModal(true);
		if (onModalOpen) {
			onModalOpen();
		}
	};
	const onMapOpen = () => {
		setShowModal(true);
		setMapSelected(true);
		setVideoSelected(false);
		if (onModalOpen) {
			onModalOpen();
		}
	};

	return (
		<>
			<MediaGallery
				pagination={pagination}
				gallery={gallery}
				thumb={thumb}
				logo={logo}
				video={video}
				map={map}
				detailMap={detailMap}
				a11y={a11y}
				mainSliderClasses={sliderClasses?.main}
				thumbSliderClasses={sliderClasses?.thumb}
				className={className}
				accountId={accountId}
				lang={lang}
				playerId={playerId}
				isModal={false}
				embeddedFooterClasses={embeddedFooterClasses}
				activeSlideIndex={activeSlideIndex}
				videoButton={videoButton}
				mapButton={mapButton}
				onMapOpen={onMapOpen}
				onVideoOpen={onVideoOpen}
				footerClasses={footerClasses}
				otherMiscButton={otherMiscButton}
				slideSelected={slideIndex => {
					setShowModal(true);
					setaActiveSlideIndex(slideIndex);
					if (onModalOpen) {
						onModalOpen();
					}
				}}
			/>
			<MediaGalleryModal
				pagination={pagination}
				modalClasses={modalClasses}
				gallery={gallery}
				thumb={thumb}
				video={video}
				map={map}
				detailMap={detailMap}
				a11y={a11y}
				sliderClasses={sliderClasses}
				className={className}
				accountId={accountId}
				lang={lang}
				playerId={playerId}
				isMapOpen={mapSelected}
				isVideoOpen={videoSelected}
				setIsOpen={onClose}
				activeSlideIndex={activeSlideIndex}
				videoButton={videoButton}
				closeButton={closeButton}
				mapButton={mapButton}
				footerClasses={footerClasses}
				isOpen={showModal}
				slideSelected={slideIndex => {
					setShowModal(true);
					setaActiveSlideIndex(slideIndex);
				}}
			/>
		</>
	);
};

MediaGalleryEmbedded.propTypes = {
	gallery: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,

			fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			title: PropTypes.string,
			alt: PropTypes.string.isRequired,

			imageTitle: PropTypes.string.isRequired,

			source: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			src: PropTypes.string,
		})
	),
	thumb: PropTypes.arrayOf(
		PropTypes.shape({
			alt: PropTypes.string.isRequired,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	),
	logo: PropTypes.arrayOf(PropTypes.object),
	video: PropTypes.shape({
		cloudName: PropTypes.string,
		publicId: PropTypes.string,
	}),
	map: PropTypes.shape({
		id: PropTypes.string.isRequired,

		fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		title: PropTypes.string,
		alt: PropTypes.string.isRequired,

		imageTitle: PropTypes.string.isRequired,

		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		src: PropTypes.string,
	}),
	detailMap: PropTypes.shape({
		zoom: PropTypes.number,
		markers: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				longitude: PropTypes.string,
				latitude: PropTypes.string,
				link: PropTypes.shape({ url: PropTypes.string, linkText: PropTypes.string }),
			})
		),
	}),
	className: PropTypes.string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		stopSliderLabel: PropTypes.string,
		startSliderLabel: PropTypes.string,
	}),

	lang: PropTypes.oneOf(["en", "fr"]),
	accountId: PropTypes.string,
	playerId: PropTypes.string,

	sliderClasses: PropTypes.shape({
		main: PropTypes.shape({
			slide: PropTypes.string,
			slider: PropTypes.string,
			navigation: PropTypes.shape({
				button: PropTypes.string,
				nextButton: PropTypes.string,
				prevButton: PropTypes.string,
			}),
		}),
		thumb: PropTypes.shape({
			slide: PropTypes.string,
			navigation: PropTypes.shape({
				button: PropTypes.string,
				nextButton: PropTypes.string,
				prevButton: PropTypes.string,
			}),
		}),
	}).isRequired,
	mapButton: PropTypes.node,
	videoButton: PropTypes.node,
	closeButton: PropTypes.node,
	modalClasses: PropTypes.shape({
		content: PropTypes.string,
		modal: PropTypes.string,
		wrapper: PropTypes.string,
		overlay: PropTypes.string,
		close: PropTypes.string,
	}).isRequired,
	pagination: PropTypes.func,
	footerClasses: PropTypes.shape({
		nav: PropTypes.string,
		wrapper: PropTypes.string,
		thumbs: PropTypes.string,
		miscContainer: PropTypes.string,
		miscButtons: PropTypes.string,
	}).isRequired,
	embeddedFooterClasses: PropTypes.shape({
		nav: PropTypes.string,
		wrapper: PropTypes.string,
		thumbs: PropTypes.string,
		miscContainer: PropTypes.string,
		miscButtons: PropTypes.string,
		otherButtons: PropTypes.string,
	}).isRequired,
	otherMiscButton: PropTypes.node,
	onModalClose: PropTypes.func,
	onModalOpen: PropTypes.func,
};

MediaGalleryEmbedded.defaultProps = {
	className: "",
	a11y: {
		prevSlideLabel: "Previous slide",
		nextSlideLabel: "Next slide",
		firstSlideLabel: "This is the first slide",
		lastSlideLabel: "This is the last slide",
		paginationBulletLabel: "Go to slide {{index}}",
		stopSliderLabel: "Stop slider",
		startSliderLabel: "Start slider",
	},
	gallery: null,
	thumb: null,
	logo: {},
	video: null,
	map: null,
	detailMap: null,
	lang: "en",
	accountId: null,
	playerId: null,
	mapButton: null,
	videoButton: null,
	closeButton: null,
	pagination: null,
	otherMiscButton: null,
	onModalClose: null,
	onModalOpen: null,
};

export default MediaGalleryEmbedded;
export { MediaGalleryEmbedded };
