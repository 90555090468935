import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Modal } from "@sunwing/shared-components";
import { MediaGallery } from "../MediaGallery";
import * as styles from "./MediaGalleryModal.module.scss";

const MediaGalleryModal = ({
	gallery,
	thumb,
	video,
	map,
	detailMap,
	a11y,
	className,
	accountId,
	lang,
	playerId,
	isOpen,
	setIsOpen,
	activeSlideIndex,
	slideSelected,
	closeButton,
	modalClasses,
	sliderClasses,
	videoButton,
	mapButton,
	isMapOpen,
	pagination,
	isVideoOpen,
	footerClasses,
}) => {
	const [slider, setSlider] = useState(null);
	return (
		<Modal
			contentLabel="content details"
			className={modalClasses?.modal}
			overlayClassName={modalClasses?.overlay}
			isOpen={isOpen}
			onClose={() => {
				setIsOpen(slider.activeIndex);
			}}
		>
			<Modal.Content style={{ zIndex: "3" }} className={cx(modalClasses?.wrapper)}>
				<div className={styles.modalPadding}>
					<MediaGallery
						pagination={pagination}
						gallery={gallery}
						thumb={thumb}
						video={video}
						map={map}
						detailMap={detailMap}
						a11y={a11y}
						className={cx(modalClasses?.content, className)}
						accountId={accountId}
						lang={lang}
						playerId={playerId}
						isModal={true}
						activeSlideIndex={activeSlideIndex}
						slideSelected={slideSelected}
						setSlider={setSlider}
						mainSliderClasses={sliderClasses?.main}
						thumbSliderClasses={sliderClasses?.thumb}
						videoButton={videoButton}
						closeButton={closeButton}
						mapButton={mapButton}
						footerClasses={footerClasses}
						isMapOpen={isMapOpen}
						isVideoOpen={isVideoOpen}
					/>
				</div>
			</Modal.Content>
			<Modal.Close className={modalClasses?.close}>{closeButton}</Modal.Close>
		</Modal>
	);
};
MediaGalleryModal.propTypes = {
	gallery: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,

			fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			title: PropTypes.string,
			alt: PropTypes.string.isRequired,

			imageTitle: PropTypes.string.isRequired,

			source: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			src: PropTypes.string,
		})
	),
	thumb: PropTypes.arrayOf(
		PropTypes.shape({
			alt: PropTypes.string.isRequired,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	),
	video: PropTypes.shape({
		cloudName: PropTypes.string,
		publicId: PropTypes.string,
	}),
	map: PropTypes.shape({
		id: PropTypes.string.isRequired,

		fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		title: PropTypes.string,
		alt: PropTypes.string.isRequired,

		imageTitle: PropTypes.string.isRequired,

		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		src: PropTypes.string,
	}),
	detailMap: PropTypes.shape({
		zoom: PropTypes.number,
		markers: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				longitude: PropTypes.string,
				latitude: PropTypes.string,
				link: PropTypes.shape({ url: PropTypes.string, linkText: PropTypes.string }),
			})
		),
	}),
	className: PropTypes.string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		stopSliderLabel: PropTypes.string,
		startSliderLabel: PropTypes.string,
	}),

	lang: PropTypes.oneOf(["en", "fr"]),
	accountId: PropTypes.string,
	playerId: PropTypes.string,

	sliderClasses: PropTypes.shape({
		main: PropTypes.shape({
			slide: PropTypes.string,
			slider: PropTypes.string,
			navigation: PropTypes.shape({
				button: PropTypes.string,
				nextButton: PropTypes.string,
				prevButton: PropTypes.string,
			}),
		}),
		thumb: PropTypes.shape({
			slide: PropTypes.string,
			navigation: PropTypes.shape({
				button: PropTypes.string,
				nextButton: PropTypes.string,
				prevButton: PropTypes.string,
			}),
		}),
	}).isRequired,
	mapButton: PropTypes.node,
	videoButton: PropTypes.node,
	closeButton: PropTypes.node,
	modalClasses: PropTypes.shape({
		content: PropTypes.string,
		modal: PropTypes.string,
		wrapper: PropTypes.string,
		overlay: PropTypes.string,
		close: PropTypes.string,
	}).isRequired,
	pagination: PropTypes.func,
	footerClasses: PropTypes.shape({
		nav: PropTypes.string,
		wrapper: PropTypes.string,
		thumbs: PropTypes.string,
		miscContainer: PropTypes.string,
		miscButtons: PropTypes.string,
	}).isRequired,

	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	activeSlideIndex: PropTypes.number,
	slideSelected: PropTypes.func,
	isMapOpen: PropTypes.bool,
	isVideoOpen: PropTypes.bool,
};

MediaGalleryModal.defaultProps = {
	className: "",
	a11y: {
		prevSlideLabel: "Previous slide",
		nextSlideLabel: "Next slide",
		firstSlideLabel: "This is the first slide",
		lastSlideLabel: "This is the last slide",
		paginationBulletLabel: "Go to slide {{index}}",
		stopSliderLabel: "Stop slider",
		startSliderLabel: "Start slider",
	},
	gallery: null,
	thumb: null,
	video: null,
	map: null,
	detailMap: null,
	lang: "en",
	accountId: null,
	playerId: null,
	mapButton: null,
	videoButton: null,
	closeButton: null,
	pagination: null,
	activeSlideIndex: 0,
	slideSelected: null,
	isMapOpen: false,
	isVideoOpen: false,
};
export default MediaGalleryModal;
export { MediaGalleryModal };
