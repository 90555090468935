import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionForm } from "../../EmailAcquisitionForm";
import * as styles from "./DesktopEmbed.module.scss";

const DesktopEmbed = ({
	type,
	config,
	mode,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	embed,
}) => {
	const [viewportWidth, setViewportWidth] = useState(null);

	const handleResize = () => {
		if (config?.type === "inline") {
			clearAllBodyScrollLocks();
		}
		// Continue tracking viewport width with fallback
		setViewportWidth(window.innerWidth || document.documentElement.clientWidth);
	};

	useEffect(() => {
		// Resize event
		window.addEventListener("resize", handleResize, false);

		// Init to display desktop form
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (viewportWidth) {
		return (
			<>
				{viewportWidth >= viewportBreakpoint && (
					<EmailAcquisitionForm
						embed={embed}
						classNames={styles}
						type={type}
						config={config}
						mode={mode}
						setWidgetOpen={setWidgetOpen}
						lang={lang}
						userEmail={userEmail}
						setUserEmail={setUserEmail}
						userGateway={userGateway}
						setUserGateway={setUserGateway}
						setRegistered={setRegistered}
						isRegistered={isRegistered}
					/>
				)}
			</>
		);
	}

	return null;
};

DesktopEmbed.propTypes = {
	type: PropTypes.string,
	mode: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	embed: PropTypes.bool,
};

DesktopEmbed.defaultProps = {
	type: undefined,
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
	embed: true,
};

export default DesktopEmbed;
export { DesktopEmbed };
