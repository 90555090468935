import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as styles from "./Dots.module.scss";

const DotsLoader = ({ className }) => (
	<div className={cx(className, styles.dots)}>
		<span />
		<span />
		<span />
		<span />
	</div>
);

DotsLoader.propTypes = {
	className: PropTypes.string,
};

DotsLoader.defaultProps = {
	className: undefined,
};

export default DotsLoader;
export { DotsLoader };
