import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { motion, useReducedMotion } from "framer-motion";
import { FocusOn } from "react-focus-on";
import { SlideInMenuHeader } from "../../SlideInMenu/Header";
import { staggerList } from "../../../../utils/animations";
import * as styles from "./HeaderGatewayMenu.module.scss";

const variantsUl = {
	open: {
		overflow: "auto",
		transition: {
			delayChildren: 0.2,
			staggerChildren: 0.05,
		},
	},
	closed: {
		overflow: "hidden",
		transition: {
			staggerChildren: 0.05,
			staggerDirection: -1,
		},
	},
};

const HeaderGatewayMenu = ({
	id,
	labels,
	gatewaysGroup,
	currentGateway,
	isOpened,
	handleChangeGateway,
	handleGatewayMenu,
}) => {
	const [isFocusTrapped, setIsFocusTrapped] = useState(false);
	const handleCloseMenu = () => {
		setIsFocusTrapped(false);
		handleGatewayMenu(false);
	};

	const handleGatewayOptions = gateway => {
		handleChangeGateway(gateway);
		handleCloseMenu();
	};
	const shouldReduceMotion = useReducedMotion();
	useEffect(() => {
		if (isOpened) {
			setTimeout(() => {
				setIsFocusTrapped(isOpened);
			}, 50);
		}
	}, [isOpened]);
	return (
		<FocusOn
			enabled={isFocusTrapped}
			shouldIgnore={el => !(el.id === "gatsby-focus-wrapper" && el.tabIndex === -1)}
			onClickOutside={handleCloseMenu}
			onEscapeKey={handleCloseMenu}
			className={cx(styles.menu, isOpened && styles.menuOpen)}
		>
			<SlideInMenuHeader
				id={id}
				title={labels.gatewayHeader}
				labels={{
					closeMenu: labels.closeMenu,
				}}
				isOpen={isOpened}
				handleClose={handleCloseMenu}
			/>
			<motion.ul
				className={styles.container}
				id={id}
				initial={false}
				variants={variantsUl}
				animate={isOpened ? "open" : "closed"}
			>
				{gatewaysGroup.map(gateways => (
					<motion.li
						className={styles.province}
						key={gateways.label}
						variants={{ ...staggerList(shouldReduceMotion) }}
					>
						<span className={styles.provinceLabel}>{gateways.label}</span>
						<ul className={styles.gatewayCityContainer}>
							{gateways.gateways.map(gateway => (
								<li key={gateway.value}>
									{currentGateway.value === gateway.value ? (
										<span className={styles.currentGateway}>{gateway.label}</span>
									) : (
										<button
											type="button"
											className={styles.gatewayCity}
											onClick={() => handleGatewayOptions(gateway)}
											value={gateway.value}
										>
											{gateway.label}
										</button>
									)}
								</li>
							))}
						</ul>
					</motion.li>
				))}
			</motion.ul>
		</FocusOn>
	);
};

HeaderGatewayMenu.propTypes = {
	id: PropTypes.string.isRequired,
	labels: PropTypes.shape({
		gatewayHeader: PropTypes.string,
		closeMenu: PropTypes.string,
	}),
	gatewaysGroup: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			name: PropTypes.string,
			gateways: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string,
					value: PropTypes.string,
				})
			),
		})
	).isRequired,
	currentGateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}).isRequired,
	isOpened: PropTypes.bool,
	handleChangeGateway: PropTypes.func.isRequired,
	handleGatewayMenu: PropTypes.func.isRequired,
};

HeaderGatewayMenu.defaultProps = {
	labels: {
		closeMenu: "Close menu",
		gatewayHeader: "Gateway",
	},
	isOpened: false,
};

export default HeaderGatewayMenu;
export { HeaderGatewayMenu };
