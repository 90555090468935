import React from "react";
import PropType from "prop-types";
import { FormAssembly } from "./FormAssembly";

const FormAssemblyConnector = ({ data }) => {
	let renderedComponent = null;

	if (data?.iframeUrl) {
		renderedComponent = <FormAssembly config={data} />;
	}

	return renderedComponent;
};

FormAssemblyConnector.propTypes = {
	data: PropType.object,
};

FormAssemblyConnector.defaultProps = {
	data: null,
};

export default FormAssemblyConnector;
export { FormAssemblyConnector };
