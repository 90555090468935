// extracted by mini-css-extract-plugin
export var btn = "Button-module--btn--2EgyS";
export var disabled = "Button-module--disabled--3jiwL";
export var full = "Button-module--full--3ZM2X";
export var right = "Button-module--right--2pAJo";
export var hasLabel = "Button-module--hasLabel--2B-Pm";
export var sm = "Button-module--sm--1S54Q";
export var md = "Button-module--md--MbEBN";
export var empty = "Button-module--empty--1IaaR";
export var lg = "Button-module--lg--1Wco-";
export var primary = "Button-module--primary--1Znlo";
export var secondary = "Button-module--secondary--2lup3";
export var asText = "Button-module--asText--3AVbB";
export var tertiary = "Button-module--tertiary--1vQAb";
export var quaternary = "Button-module--quaternary--FOERo";
export var noStyle = "Button-module--noStyle--fio1Q";
export var activeLink = "Button-module--activeLink--1swXn";
export var center = "Button-module--center--3qPjW";