import React from "react";
import PropTypes from "prop-types";
import { SearchBox } from "./SearchBox";

const SearchBoxConnector = ({ data, language, type }) => (
	<SearchBox language={language} type={type} appSettings={data} />
);

SearchBoxConnector.propTypes = {
	data: PropTypes.object.isRequired,
	language: PropTypes.string,
	type: PropTypes.string,
};

SearchBoxConnector.defaultProps = {
	language: "en",
	type: "packages",
};

export default SearchBoxConnector;
export { SearchBoxConnector };
