/* eslint-disable no-unused-vars */
import Tippy from "@tippyjs/react";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { parseChildrenBySlots } from "../../utils";
import { Icon } from "../Icons";
import { LanguageToggle } from "../LanguageToggle";
import { Section } from "../Section";
import { SlideInMenu } from "./SlideInMenu";
import * as styles from "./Header.module.scss";
import { WelcomeBar } from "../Affiliates/WelcomeBar";

const defaultLabels = {
	backToHome: "Back to home",
	connectWithUs: "Connect with us",
	travelAlert: "Travel alert",
	openMenu: "Open menu",
	closeMenu: "Close menu",
	openGatewayMenu: "Open gateway menu",
	changeGateway: "Change gateway",
	gatewayHeader: "Gateway",
	welcomeLabel: "Welcome company name",
	exit: "Exit",
	contact: "1-800-000-000",
};

const Header = ({
	locale,
	labels: providedLabels,
	homePageUrl,
	displayBackToHome,
	logoOnly,
	logo,
	logoLink,
	renderLinkLogo,
	ctaMessageLink,
	links,
	renderLinks,
	contactUsSlug,
	alerts,
	travelAlertsUrl,
	menuLinks,
	renderMenuLink,
	pageType,
	currentGateway,
	handleChangeGateway,
	altLangPages,
	renderLanguageToggle,
	isMobile,
	isAffiliate,
	affiliateState,
	children,
	setExitModal,
}) => {
	// TODO: Temporary solution for setting "active" state on nav link based on the booking search box type
	const navLinkSelectedType =
		{
			lastminute: "2rJ9pt4xG9HIj9LsT2Ods9",
			packages: "6BYcKbPXE3tA1Gg0Ll7gMq",
			flights: "74iwo9JUXyeL1VzL3YzrbS",
			hotels: "47YJEkKZBOqhcuJCgbXUoJ",
			cruises: "1RgkOlLozz4yf28lbJPoKM",
			other: "",
		}[pageType?.toLowerCase()] ?? "6BYcKbPXE3tA1Gg0Ll7gMq"; // packages by default

	const labels = { ...defaultLabels, ...providedLabels };
	const slots = parseChildrenBySlots(children);

	return (
		<>
			{isAffiliate && affiliateState !== "EXPIRED" && (
				<WelcomeBar
					lang={locale}
					labels={{ welcomeLabel: labels.welcomeLabel, exit: labels.exit }}
					setExitModal={setExitModal}
				/>
			)}
			<header className="page__header">
				{homePageUrl && displayBackToHome && (
					<Section padding="none" className={styles.headerAlternateLink}>
						{renderLinks({ url: homePageUrl, target: "_self" }, null, {
							children: (
								<>
									<Icon name="arrow-left" />
									{labels.backToHome}
								</>
							),
						})}
					</Section>
				)}
				<Section padding="none" theme={null} className={styles.header}>
					{ctaMessageLink && (
						<div className={styles.headerCtaMessageWrapper}>
							{renderLinks(
								{ url: ctaMessageLink.url, target: ctaMessageLink.target },
								cx(styles.headerCtaMessageLink, {
									[styles[`${ctaMessageLink.iconPlacement}`]]: ctaMessageLink.iconPlacement,
								}),
								{
									children: (
										<>
											{ctaMessageLink.iconName && ctaMessageLink.iconPlacement === "left" && (
												<Icon name={ctaMessageLink.iconName} className={styles.ctaIcon} />
											)}
											{ctaMessageLink.linkText}
											{ctaMessageLink.iconName && ctaMessageLink.iconPlacement === "right" && (
												<Icon name={ctaMessageLink.iconName} className={styles.ctaIcon} />
											)}
										</>
									),
								}
							)}
						</div>
					)}
					<div className={styles.headerWrapper}>
						<div className={styles.headerImage}>
							{logoLink &&
								logo &&
								renderLinkLogo(
									{ url: logoLink, target: "_self" },
									{
										width: logo.width,
										height: logo.height,
										src: logo.url,
										alt: logo.alt,
									}
								)}
							{!logoLink && logo && (
								<img src={logo.url} alt={logo.alt} width={logo.width} height={logo.height} />
							)}
						</div>
						{isAffiliate && (
							<div className={styles.affiliate}>
								<h3 className={styles.affiliate__text}>{labels.needAssistance}</h3>
								<div className={styles.affiliate__button}>
									<Icon name="phone" />
									<a href={`tel:${labels.contact}`}>{labels.contact}</a>
								</div>
							</div>
						)}

						{!logoOnly && !isAffiliate && (
							<>
								{links && !!links.length && (
									<nav className={styles.headerNav}>
										<ul className={styles.headerNavItems}>
											{links.map(link => (
												<li key={link.linkText}>
													{renderLinks(
														link,
														cx(styles.headerNavLink, {
															[styles.headerNavLinkActive]:
																navLinkSelectedType === link.contentful_id,
														}),
														{
															children: (
																<>
																	{link?.iconName && link?.iconName !== "" && (
																		<Icon
																			name={link.iconName}
																			className={styles.headerNavLinkIcon}
																		/>
																	)}
																	<span className={styles.headerNavLinkText}>{link.linkText}</span>
																</>
															),
														}
													)}
												</li>
											))}
										</ul>
									</nav>
								)}
								<div className={styles.headerSecondaryNav}>
									{renderLinks(
										{ url: contactUsSlug, target: "_self" },
										styles.headerBtnInfoWrapper,
										{
											children: (
												<>
													<Icon name="phone" title={labels.connectWithUs} />
													<span className="sr-only">{labels.connectWithUs}</span>
												</>
											),
										}
									)}

									<Tippy
										role="tooltip"
										placement="bottom"
										touch={!isMobile}
										content={<div className={styles.tooltip}>{labels.travelAlert}</div>}
									>
										{renderLinks(
											{ url: travelAlertsUrl, target: "_self" },
											`${styles.headerBtn} ${styles.headerBtnInfo}`,
											{
												children: (
													<>
														{alerts >= 1 && (
															<span className={styles?.headerBtnNotification}>{alerts}</span>
														)}
														<span className={styles?.headerBtnInfoWrapper}>
															<Icon name="alert" />
														</span>
													</>
												),
											}
										)}
									</Tippy>

									{menuLinks && (
										<SlideInMenu
											title={labels.menu}
											labels={{
												openMenu: labels.openMenu,
												closeMenu: labels.closeMenu,
												openGatewayMenu: labels.openGatewayMenu,
												changeGateway: labels.changeGateway,
												gatewayHeader: labels.gatewayHeader,
											}}
											menuLinks={menuLinks}
											renderMenuLink={renderMenuLink}
											currentGateway={currentGateway}
											handleChangeGateway={handleChangeGateway}
											languageToggle={renderLanguageToggle(
												altLangPages,
												{
													container: styles.languageSwitcher,
													currentLanguage: styles.languageSwitcherCurrentLanguage,
													altLanguage: styles.languageSwitcherAltLanguage,
												},
												locale
											)}
										/>
									)}
								</div>
							</>
						)}
					</div>
				</Section>
				{slots.get("widget", slot => (
					<Section padding="none" className={styles.widget}>
						{slot}
					</Section>
				))}
			</header>
		</>
	);
};

Header.propTypes = {
	locale: PropTypes.string,
	labels: PropTypes.shape({
		backToHome: PropTypes.string,
		connectWithUs: PropTypes.string,
		travelAlert: PropTypes.string,
		menu: PropTypes.string,
		openMenu: PropTypes.string,
		closeMenu: PropTypes.string,
		openGatewayMenu: PropTypes.string,
		changeGateway: PropTypes.string,
		gatewayHeader: PropTypes.string,
		welcomeLabel: PropTypes.string,
		exit: PropTypes.string,
		needAssistance: PropTypes.string,
		contact: PropTypes.string,
	}),
	homePageUrl: PropTypes.string,
	displayBackToHome: PropTypes.bool,
	logoOnly: PropTypes.bool,
	logo: PropTypes.shape({
		url: PropTypes.string,
		alt: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	logoLink: PropTypes.string,
	renderLinkLogo: PropTypes.func,
	ctaMessageLink: PropTypes.shape({
		linkText: PropTypes.string,
		url: PropTypes.string,
		target: PropTypes.string,
		iconName: PropTypes.string,
		iconPlacement: PropTypes.oneOf(["right", "left"]),
	}),
	links: PropTypes.arrayOf(PropTypes.object),
	renderLinks: PropTypes.func,
	contactUsSlug: PropTypes.string,
	alerts: PropTypes.number,
	travelAlertsUrl: PropTypes.string.isRequired,
	menuLinks: PropTypes.arrayOf(PropTypes.object),
	renderMenuLink: PropTypes.func,
	pageType: PropTypes.oneOf(["LastMinute", "Packages", "Flights", "Hotels", "Cruises", "Other"]),
	currentGateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	handleChangeGateway: PropTypes.func.isRequired,
	altLangPages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			lang: PropTypes.string,
			path: PropTypes.string,
		})
	).isRequired,
	renderLanguageToggle: PropTypes.func,
	isMobile: PropTypes.bool,
	isAffiliate: PropTypes.bool,
	affiliateState: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	setExitModal: PropTypes.func,
};
Header.defaultProps = {
	locale: "en-CA",
	labels: defaultLabels,
	homePageUrl: "",
	displayBackToHome: false,
	logoOnly: false,
	logo: undefined,
	logoLink: "",
	renderLinkLogo: (link, logo) => (
		<a href={link.url} target={link.target}>
			<img src={logo.src} alt={logo.alt} />
		</a>
	),
	ctaMessageLink: undefined,
	links: [],
	renderLinks: (link, className, { children }) => (
		<a className={className} href={link.url} target={link.target}>
			{children}
		</a>
	),
	contactUsSlug: "",
	alerts: null,
	menuLinks: undefined,
	renderMenuLink: (link, { className }) => (
		<a className={className} href={link.url} target={link.target}>
			{link.linkText}
		</a>
	),
	pageType: "Packages",
	currentGateway: {
		label: "Toronto",
		value: "YYZ",
	},
	renderLanguageToggle: (altLangPages, classNames, locale) => (
		<LanguageToggle altLangPages={altLangPages} classNames={classNames} locale={locale} />
	),
	isMobile: false,
	isAffiliate: false,
	affiliateState: undefined,
	children: undefined,
	setExitModal: () => {},
};

export default Header;
export { Header };
