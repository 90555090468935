// extracted by mini-css-extract-plugin
export var slider__wrapper = "ComponentsSlider-module--slider__wrapper--2oMDB";
export var button = "ComponentsSlider-module--button--1-ibf";
export var moreLink = "ComponentsSlider-module--moreLink--3NGa7";
export var moreLinkText = "ComponentsSlider-module--moreLinkText--AUg7w";
export var activeLink = "ComponentsSlider-module--activeLink--2B3cm";
export var moreLinkIcon = "ComponentsSlider-module--moreLinkIcon--1NCfQ";
export var bullet = "ComponentsSlider-module--bullet--1UIUM";
export var bulletActive = "ComponentsSlider-module--bulletActive--3ryOO";
export var slide = "ComponentsSlider-module--slide--UvmQ2";
export var buttonNext = "ComponentsSlider-module--buttonNext--1Ug8X";
export var buttonPrev = "ComponentsSlider-module--buttonPrev--3UHe0";
export var swiper = "ComponentsSlider-module--swiper--3QPlh";
export var hasShadow = "ComponentsSlider-module--hasShadow--34r_P";