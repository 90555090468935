import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as dayjs from "dayjs";
import { InputWrapper } from "../../Common/Components/InputWrapper";
import { DatePicker } from "../../Common/Components/DatePicker";
import * as parentStyles from "../../SearchBox.module.scss";

const DateField = ({
	id,
	label,
	language,
	selected,
	minDate,
	maxDate,
	disabled,
	error,
	onChange,
	placeholder,
}) => {
	const datePickerRef = useRef();

	const [isOpen, setOpen] = useState(false);

	return (
		<InputWrapper
			ref={datePickerRef}
			id={id}
			label={label}
			error={error}
			className={cx(
				parentStyles.date,
				disabled ? parentStyles.dateDisabled : undefined,
				isOpen ? "opened" : ""
			)}
		>
			{({ isFocused, handleOnFocus, handleOnBlur }) => (
				<DatePicker
					inputRef={datePickerRef}
					locale={language}
					id={id}
					isFocused={isFocused}
					selectedDate={selected}
					disabled={disabled}
					minDate={minDate}
					maxDate={maxDate}
					onFocus={() => {
						handleOnFocus();
					}}
					onBlur={() => {
						handleOnBlur();
						setOpen(false);
					}}
					onChange={value => {
						onChange(value);
						setOpen(false);
					}}
					onOpen={open => {
						setOpen(open);
					}}
					placeholder={placeholder}
				/>
			)}
		</InputWrapper>
	);
};

DateField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	disabled: PropTypes.bool,
	selected: PropTypes.instanceOf(dayjs),
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	placeholder: PropTypes.string,
};

DateField.defaultProps = {
	disabled: undefined,
	selected: undefined,
	error: false,
	placeholder: undefined,
	minDate: undefined,
	maxDate: undefined,
};

export default DateField;
export { DateField };
