import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { PaginatedCollection, TileSlider, TileCollection } from "../../components/CardCollection";
import { ITEMS_PER_ROW, NAVIGATION_TYPE, PAGINATION_TYPE, CARD_VARIANT } from "../../const/const";

const CollectionConnector = ({ data }) => {
	if (!data?.cards?.length) {
		return null;
	}

	const tryConvertBooleanValue = value => {
		if (value === "true") return true;
		if (value === "false") return false;
		return value;
	};
	switch (data.variant) {
		case "paginated":
			return (
				<PaginatedCollection items={data.cards} cardsPerPage={data.cardsPerPage} link={data.link} />
			);
		case "slider":
			return (
				<TileSlider
					id={data.id}
					items={data.cards}
					itemsPerRow={data.itemsPerRow}
					navigationType={tryConvertBooleanValue(data.navigationType)}
					paginationType={tryConvertBooleanValue(data.paginationType)}
					link={data.link}
				/>
			);
		case "grid":
		default:
			return (
				<TileCollection
					items={data.cards}
					itemsPerRow={data.itemsPerRow}
					sortAlphabetically={data.sortAlphabetically}
				/>
			);
	}
};

CollectionConnector.propTypes = {
	data: PropTypes.shape({
		cards: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				heading: PropTypes.string,
				headerAs: PropTypes.string,
				subheading: PropTypes.string,
				variant: PropTypes.oneOf(CARD_VARIANT),
				content: PropTypes.shape({
					raw: PropTypes.string,
				}),
				image: PropTypes.arrayOf(
					PropTypes.shape({
						public_id: PropTypes.string,
						secure_url: PropTypes.string,
					})
				),
				icon: PropTypes.string,
				link: PropTypes.shape({
					variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
					size: PropTypes.oneOf(["sm", "md", "lg"]),
					linkText: PropTypes.string,
					url: PropTypes.string.isRequired,
					target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top", "EXTERNAL_MODAL"])
						.isRequired,
					image: PropTypes.arrayOf(
						PropTypes.shape({
							public_id: PropTypes.string,
							secure_url: PropTypes.string,
						})
					),
					imageAltText: PropTypes.string,
					iconName: PropTypes.string,
					type: PropTypes.oneOf(["link", "button"]),
					align: PropTypes.oneOf(["left", "center", "right"]),
					modal: PropTypes.arrayOf(PropTypes.object),
				}),
				logo: PropTypes.arrayOf(
					PropTypes.shape({
						public_id: PropTypes.string,
						secure_url: PropTypes.string,
					})
				),
			})
		).isRequired,
		variant: PropTypes.oneOf(["paginated", "grid", "slider"]).isRequired,
		sortAlphabetically: PropTypes.bool,
		theme: PropTypes.oneOf(["default", "info"]).isRequired,
		link: PropTypes.shape({
			variant: PropTypes.oneOf(["primary", "secondary"]).isRequired,
			size: PropTypes.oneOf(["sm", "md", "lg"]),
			linkText: PropTypes.string,
			url: PropTypes.string.isRequired,
			target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top", "EXTERNAL_MODAL"]).isRequired,
			image: PropTypes.arrayOf(
				PropTypes.shape({
					public_id: PropTypes.string,
					secure_url: PropTypes.string,
				})
			),
			imageAltText: PropTypes.string,
			iconName: PropTypes.string,
			type: PropTypes.oneOf(["link", "button"]),
			align: PropTypes.oneOf(["left", "center", "right"]),
			modal: PropTypes.arrayOf(PropTypes.object),
		}),
		cardsPerPage: PropTypes.number.isRequired,
		itemsPerRow: PropTypes.oneOf(ITEMS_PER_ROW).isRequired,
		navigationType: PropTypes.oneOf(NAVIGATION_TYPE).isRequired,
		paginationType: PropTypes.oneOf(PAGINATION_TYPE).isRequired,
		title: PropTypes.string,
		id: PropTypes.string,
	}).isRequired,
};

export default CollectionConnector;
export { CollectionConnector };
