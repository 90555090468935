// extracted by mini-css-extract-plugin
export var infoCard = "InfoCard-module--infoCard--yMS1n";
export var info = "InfoCard-module--info--HV4Jq";
export var infoHeading = "InfoCard-module--infoHeading--736sY";
export var infoContent = "InfoCard-module--infoContent--E2Tez";
export var infoIcon = "InfoCard-module--infoIcon--2MxZ_";
export var infoCardLink = "InfoCard-module--infoCardLink--2oVPH";
export var horizontal = "InfoCard-module--horizontal--1DAbg";
export var horizontalSubheading = "InfoCard-module--horizontalSubheading--3VSad";
export var horizontalBtn = "InfoCard-module--horizontalBtn--139Wq";
export var button = "InfoCard-module--button--1_Owh";
export var infoButton = "InfoCard-module--infoButton--2BCAq";