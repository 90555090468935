import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";
import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

const DestinationField = ({ label, id, selected, error, resetError, onChange, cruiseMode }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });
	const allDestinationRCLKey = useTranslation({ searchKey: "all-destinations" });

	const [isFocus, setFocus] = useState(false);
	const [destinationData, setDestinationData] = useState([]);
	const [isInit, setInit] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Destination typeahead logic
	///////////////////////////////////////////////////////////// */

	const transformDestinationData = (_destinations, _keys) => {
		// Transform data from API response to type-ahead data format
		let template = [];

		_keys.forEach((key, countryIndex) => {
			const countryItem = _destinations[key];

			template.push({
				value: countryItem.value,
				label:
					countryItem.text.toLowerCase() === "all destinations"
						? allDestinationRCLKey
						: countryItem.text,
				id: countryItem.id,
			});

			const regionItems = countryItem.s;

			if (regionItems?.length === undefined) {
				template[countryIndex].items = [];
				const regionKeys = Object.keys(regionItems);

				regionKeys.forEach(regionKey => {
					const regionItem = regionItems[regionKey];

					const regionSubItems = regionItem.s;

					if (regionSubItems && regionSubItems?.length === undefined) {
						const regionSupKeys = Object?.keys(regionSubItems);

						regionSupKeys.forEach(regionSubKey => {
							const regionSubItem = regionSubItems[regionSubKey];

							template[countryIndex].items.push({
								value: regionSubItem.value,
								label: regionSubItem.text,
								id: regionSubItem.id,
								className: cx(taStyles.childOption),
							});
						});
					}

					template[countryIndex].items.push({
						value: regionItem.value,
						label: regionItem.text,
						id: regionItem.id,
						className: cx(taStyles.childOption),
					});
				});
			}
		});

		let sortedArray = [template[0]];

		template.shift();

		// Sort cruise lines by label
		template = template.sort((a, b) => {
			const sortMethod = (a.label > b.label) - (b.label > a.label);
			return sortMethod;
		});

		sortedArray = [...sortedArray, ...template];
		return sortedArray;
	};

	const loadDestinationData = async () => {
		try {
			const option = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			};

			let url = "/api/cruise-destinations";
			if (cruiseMode === "river") {
				url = "/api/cruise-river-destinations";
			}

			const response = await fetch(url, option);
			const returnObject = await response.json();

			resetError(true);
			return returnObject;
		} catch (err) {
			resetError(true);
			console.error("Failed to load ports: ", error);
			return null;
		}
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Input initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		// Initial component load sequence
		(async () => {
			onChange(null);
			const destinations = await loadDestinationData();
			const data = destinations?.d || null;
			const keys = data ? Object.keys(destinations?.d) : [];

			if (keys.length > 0 && data) {
				setDestinationData(transformDestinationData(data, keys));
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cruiseMode]);
	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={
				<span className={inputStyles.chevron}>
					<span className="sr-only">{GetDictionary("a11y-open-menu")}</span>
				</span>
			}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				taStyles.wide,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: cx(taStyles.open, inputStyles.open),
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={destinationData}
			selectedItem={selected === null ? destinationData[0] : selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onEscapeResetSelected={false}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== selected) {
					onChange(selection);
					resetError(true);
					setInit(false);

					if (isInit || (selection?.value === "" && !isFocus)) {
						setFocus(false);
					} else {
						setFocus(true);
					}
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

DestinationField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	cruiseMode: PropTypes.string.isRequired,
};

DestinationField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
};

export default DestinationField;
export { DestinationField };
