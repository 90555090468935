// extracted by mini-css-extract-plugin
export var wrapper = "InputText-module--wrapper--1hiM-";
export var input = "InputText-module--input--3sHq0";
export var label = "InputText-module--label--2tVlx";
export var helperText = "InputText-module--helperText--3lRg4";
export var errorText = "InputText-module--errorText--SmHpj";
export var error = "InputText-module--error--14jLo";
export var prefixSuffixWrapper = "InputText-module--prefixSuffixWrapper--2yXgn";
export var prefix = "InputText-module--prefix--F9o1G";
export var suffix = "InputText-module--suffix--va473";
export var tippyLabel = "InputText-module--tippyLabel--1S1of";
export var sovIcon = "InputText-module--sov-icon--3Dalz";
export var tippyMessage = "InputText-module--tippyMessage--3J8sS";