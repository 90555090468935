import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import cx from "classnames";
import { MediaGalleryModal as MediaGalleryComponent } from "../../sharedComponents/MediaGallery";
import { Icon } from "../Icons";
import { RCL, RCL as useTranslation } from "../RCL";
import * as modalStyles from "../Modal/Modal.module.scss";
import * as styles from "./MediaGallery.module.scss";
import "./ThumbSlider.scss";

const MediaGalleryModal = ({
	gallery,
	video,
	map,
	lang,
	isOpen,
	setIsOpen,
	isVideoOpen,
	isMapOpen,
	accountId,
	playerId,
}) => {
	const dictionary = {
		"gallery-pagination": useTranslation({ searchKey: "gallery-pagination" }),
	};
	const [showContent, setShowContent] = useState(true);
	useEffect(() => {
		if (gallery.length <= 1) {
			setShowContent(false);
		}
	}, [gallery.length]);

	return (
		<MediaGalleryComponent
			className={styles.mediaGallery}
			gallery={gallery}
			a11y={{
				prevSlideLabel: "prevSlideLabel",
				nextSlideLabel: "nextSlideLabel",
				firstSlideLabel: "firstSlideLabel",
				lastSlideLabel: "lastSlideLabel",
				paginationBulletLabel: "paginationBulletLabel {{index}}",
				stopSliderLabel: "stopSliderLabel",
				startSliderLabel: "startSliderLabel",
			}}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			accountId={accountId}
			playerId={playerId}
			lang={lang}
			isModal={true}
			video={video}
			isVideoOpen={isVideoOpen}
			isMapOpen={isMapOpen}
			map={map}
			pagination={(swiper, current, total) => {
				let paginationText = dictionary["gallery-pagination"];
				paginationText = paginationText?.replace("{0}", current).replace("{1}", total);

				if (total > 1) {
					return `<span class="${cx("pagination")}">${paginationText}</span>`;
				}

				return null;
			}}
			mapButton={
				// TODO:: RCL
				<>
					<Icon name="map" />
					<RCL searchKey="map" />
				</>
			}
			closeButton={<Icon name="close--n" className="swg-text-3xl" />}
			modalClasses={{
				content: styles.modalContent,
				modal: styles.modal,
				wrapper: styles.modalContentWrapper,
				overlay: modalStyles.overlay,
				close: modalStyles.close,
			}}
			videoButton={
				// TODO:: RCL
				<>
					<Icon name="video" />
					<RCL searchKey="video" />
				</>
			}
			footerClasses={{
				nav: cx(styles.nav, !showContent ? styles.hide : undefined),
				wrapper: styles.wrapper,
				thumbs: styles.thumbs,
				miscContainer: styles.miscContainer,
				miscButtons: styles.misc,
			}}
			sliderClasses={{
				main: {
					slide: styles.mainSlide,
					slider: styles.mainSlider,
					navigation: {
						button: cx(styles.mainNavigationButton, !showContent ? styles.hide : undefined),
						nextButton: styles.mainNavigationNextButton,
						prevButton: styles.mainNavigationPrevButton,
					},
				},
				thumb: {
					slide: styles.thumbSlide,
					navigation: {
						button: styles.thumbNavigationButton,
						nextButton: styles.thumbNavigationNextButton,
						prevButton: styles.thumbNavigationPrevButton,
					},
				},
			}}
		/>
	);
};

MediaGalleryModal.propTypes = {
	gallery: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			source: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			alt: PropTypes.string,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	).isRequired,
	lang: PropTypes.oneOf(["en", "fr"]),
	video: PropTypes.shape({
		videoId: PropTypes.string,
		playlistId: PropTypes.string,
	}),
	map: PropTypes.shape({
		fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		alt: PropTypes.string,
		src: PropTypes.string,
	}),
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	isVideoOpen: PropTypes.bool,
	isMapOpen: PropTypes.bool,
	accountId: PropTypes.string,
	playerId: PropTypes.string,
};
MediaGalleryModal.defaultProps = {
	lang: "en",
	video: null,
	map: null,
	isVideoOpen: false,
	isMapOpen: false,
	accountId: process.env.GATSBY_BRIGHTCOVE_ACCOUNT_ID,
	playerId: process.env.GATSBY_BRIGHTCOVE_PLAYER_ID,
};
export default MediaGalleryModal;
export { MediaGalleryModal };
