import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@gatsbyjs/reach-router";
import { Header } from "../../components/Header";
import { useIsMobile } from "../../utils/useIsMobile";
import { PageContext } from "../../components/PageContext";
import { RCL as useTranslation } from "../../components/RCL";
import { Link } from "../../sharedComponents/Link";
import { ComponentBlock } from "../../components/ComponentBlock";
import { languageToggleAdapter } from "../../components/LanguageToggle";
import { LanguageToggleConnector } from "../LangugeToggleConnector";
import { LOCAL_STORAGE_KEY_PREFERRED_GATEWAY } from "../../const/const";
import { setBookingSearchBox } from "../../components/Header/BookingSearchBox";
import gatewaysGroup from "../../components/Header/Gateway/Menu/HeaderGateways.mock.json";

const HeaderConnector = ({
	headerNavigation,
	altLangPages,
	homepagePaths,
	alerts,
	travelAlertsUrl,
	pageType,
	isAffiliate,
	affiliateCode,
	affiliateName,
	setExitModal,
	affiliateState,
}) => {
	const [currentGateway, setCurrentGateway] = useState({
		label: "Toronto",
		value: "YYZ",
	});
	const isMobile = useIsMobile();
	const pageContext = useContext(PageContext);
	const { locale, contactUsSlug } = pageContext;
	const { pathname } = useLocation();
	const lang = locale?.toLowerCase()?.substring(0, 2) === "fr" ? "fr" : "en";

	const getHomepageUrl = () => {
		const homepageUrl = homepagePaths?.edges?.find(edge => {
			const homepageLocale = edge?.node?.context?.locale?.toUpperCase()?.substring(0, 2);
			return homepageLocale === lang.toUpperCase();
		});

		return homepageUrl?.node?.path ?? "/";
	};

	const homePageUrl = getHomepageUrl();
	let logoLinkPageUrl;

	if (headerNavigation?.logoLink?.slug) {
		logoLinkPageUrl =
			headerNavigation?.logoLink?.slug !== "homepage"
				? homePageUrl + headerNavigation?.logoLink?.slug
				: homePageUrl;
	}

	const handleChangeGateway = gateway => {
		localStorage.setItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY, JSON.stringify(gateway));
		// Set default gateway (temporary) in the booking search box
		// (localStorage above is picked up and used later)
		setBookingSearchBox({
			from: gateway.value,
			force: true,
		});
		setCurrentGateway(gateway);
	};

	useEffect(() => {
		let defaultGateway = { label: "Toronto", value: "YYZ" };

		try {
			const storedGateway = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY));
			if (storedGateway) {
				const foundGateway = gatewaysGroup.find(group =>
					group.gateways.some(gateway => gateway.value === storedGateway.value)
				);

				if (foundGateway) {
					defaultGateway = storedGateway;
				}
			}
		} catch (err) {
			console.error(err);
		}

		setCurrentGateway(defaultGateway);
	}, []);

	return (
		<Header
			lang={lang}
			locale={locale}
			labels={{
				backToHome: useTranslation({ searchKey: "back-to-home-sov" }),
				connectWithUs: useTranslation({ searchKey: "contact-us" }),
				travelAlert: useTranslation({ searchKey: "travel-alert" }),
				menu: "Menu",
				openMenu: useTranslation({ searchKey: "a11y-open-menu" }),
				closeMenu: useTranslation({ searchKey: "a11y-close-menu" }),
				openGatewayMenu: useTranslation({ searchKey: "a11y-open-menu" }),
				changeGateway: useTranslation({ searchKey: "change-gateway" }),
				gatewayHeader: useTranslation({ searchKey: "gateway-header" }),
				welcomeLabel: useTranslation({
					searchKey: "affiliate-welcome",
					replace: { "company-name": affiliateName },
				}),
				exit: useTranslation({
					searchKey: "exit",
				}),
				needAssistance: useTranslation({ searchKey: "need-assistance" }),
				contact: useTranslation({ searchKey: "affiliate-contact-number" }),
			}}
			homePageUrl={homePageUrl}
			displayBackToHome={
				headerNavigation?.logoLink?.slug !== "homepage" && headerNavigation?.logoLink?.slug
			}
			logoOnly={headerNavigation?.displayLogoOnly}
			logo={{
				width: headerNavigation?.logo?.[0].width,
				height: headerNavigation?.logo?.[0].height,
				alt: useTranslation({ searchKey: "sov-logo" }),
				url:
					headerNavigation?.logo?.[0]?.format === "svg"
						? headerNavigation?.logo?.[0]?.original_secure_url.replace(
								"/image/upload/",
								"/image/upload/f_svg/"
						  )
						: headerNavigation?.logo?.[0]?.secure_url,
			}}
			logoLink={logoLinkPageUrl}
			ctaMessageLink={headerNavigation?.ctaMessageLink}
			links={headerNavigation?.links}
			renderLinkLogo={(link, logo) => (
				<Link to={link.url} target={link.target}>
					<img src={logo.src} alt={logo.alt} width={logo.width} height={logo.height} />
				</Link>
			)}
			renderLinks={(link, className, { children }) => (
				<Link to={link.url} target={link.target} className={className}>
					{children}
				</Link>
			)}
			contactUsSlug={contactUsSlug}
			alerts={alerts}
			travelAlertsUrl={travelAlertsUrl}
			menuLinks={headerNavigation?.menu?.[0]?.menuItems}
			renderMenuLink={(link, { className, activeClassName }) => (
				<Link
					activeClassName={activeClassName}
					partiallyActive={link.url === pathname}
					to={link.url}
					target={link.target}
					className={className}
				>
					{link.linkText}
				</Link>
			)}
			pageType={pageType}
			currentGateway={currentGateway}
			handleChangeGateway={handleChangeGateway}
			altLangPages={languageToggleAdapter(altLangPages)}
			renderLanguageToggle={(_altLangPages, classNames) => (
				<LanguageToggleConnector
					altLangPages={_altLangPages}
					classNames={classNames}
					locale={locale}
				/>
			)}
			isMobile={isMobile}
			isAffiliate={isAffiliate}
			setExitModal={setExitModal}
			affiliateCode={affiliateCode}
			affiliateState={affiliateState}
		>
			<ComponentBlock
				slot="widget"
				data={{ lang, type: pageType?.toLowerCase(), ...headerNavigation?.widget }}
			/>
		</Header>
	);
};

HeaderConnector.propTypes = {
	altLangPages: PropTypes.shape({
		edges: PropTypes.arrayOf(
			PropTypes.shape({
				node: PropTypes.shape({
					context: PropTypes.shape({
						id: PropTypes.string,
						locale: PropTypes.string,
					}),
					path: PropTypes.string,
				}),
			})
		),
	}).isRequired,
	headerNavigation: PropTypes.object,
	homepagePaths: PropTypes.shape({
		edges: PropTypes.arrayOf(
			PropTypes.shape({
				node: PropTypes.shape({
					context: PropTypes.shape({
						locale: PropTypes.string,
					}),
					path: PropTypes.string,
				}),
			})
		),
	}).isRequired,
	alerts: PropTypes.number.isRequired,
	travelAlertsUrl: PropTypes.string.isRequired,
	pageType: PropTypes.oneOf(["LastMinute", "Packages", "Flights", "Hotels", "Cruises", "Other"]),
	isAffiliate: PropTypes.bool,
	affiliateName: PropTypes.string,
	affiliateCode: PropTypes.string,
	setExitModal: PropTypes.func,
	affiliateState: PropTypes.string,
};

HeaderConnector.defaultProps = {
	headerNavigation: undefined,
	pageType: "Packages",
	isAffiliate: false,
	affiliateCode: undefined,
	affiliateName: undefined,
	setExitModal: () => {},
	affiliateState: undefined,
};

export default HeaderConnector;
export { HeaderConnector };
