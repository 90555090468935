import React from "react";
import { string, objectOf, number, oneOfType, shape, object, array } from "prop-types";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";

const Img = ({ className, style, image, title, alt, source, src, wrapperClassName, svg }) => {
	if (image) {
		return (
			<>
				{image.preLoad && (
					<Helmet>
						<link
							rel="preload"
							as="image"
							href={image?.data?.src}
							imagesrcset={image?.data?.srcSet}
							imagesizes={image?.data?.sizes}
						/>
					</Helmet>
				)}
				<GatsbyImage
					className={className}
					style={style}
					alt={alt}
					placeholder="blurred"
					width={image?.width}
					height={image?.height}
					image={{
						layout: "constrained",
						width: image?.width,
						height: image?.height,
						images: {
							type: "image",
							sources: [
								{
									...image.data,
								},
							],
						},
					}}
					title={title}
				/>
			</>
		);
	}
	if (src || svg?.dataURI)
		return <img src={src || svg?.dataURI} alt={alt} title={title} className={wrapperClassName} />;
	if (source) {
		return (
			<picture>
				<source srcSet={source.srcSet} sizes={source.sizes} />
				<img
					src={source.src}
					alt={alt}
					title={title}
					style={style}
					className={className}
					srcSet={source.srcSet}
					sizes={source.sizes}
					effect="blur"
				/>
			</picture>
		);
	}
	return <></>;
};

Img.propTypes = {
	image: oneOfType([object, array]),
	title: string,
	alt: string.isRequired,
	source: shape({
		src: string,
		srcSet: string,
		sizes: string,
	}),
	svg: shape({
		dataURI: string,
	}),
	className: string,
	style: objectOf(oneOfType([string, number])),
	src: string,
	wrapperClassName: string,
};
Img.defaultProps = {
	className: "",
	style: {},
	image: undefined,
	svg: undefined,
	title: undefined,
	source: undefined,
	src: undefined,
	/**
	 * The image lazy load component has a wrapper (span) we can add class to customize its style
	 */
	wrapperClassName: "",
};

export default Img;
export { Img };
