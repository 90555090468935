/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { Button } from "../Button";
import { Modal } from "../Modal";
import * as styles from "./ExitModal.module.scss";
import { RCL as useTranslation } from "../RCL";
import { PageContext } from "../PageContext";
import { removeCookie } from "../../utils/helper";

const ExitModal = ({ isOpen, setExitModal, linkURL }) => {
	const { lang, setAlias } = useContext(PageContext);

	const dict = {
		exitLabel: useTranslation({ searchKey: "affiliate-modal-exit-message" }),
		exitBody: useTranslation({ searchKey: "affiliate-modal-exit" }),
		continueBrowsingButton: useTranslation({ searchKey: "continue-browsing" }),
		exitButton: useTranslation({ searchKey: "affiliate-exit" }),
	};

	return (
		<Modal isOpen={isOpen} className={styles.ExitModal} onClose={() => setExitModal(false)}>
			<Modal.Content className={styles.ExitModal__content}>
				<h1 className={styles.ExitModal__title}>{dict.exitLabel}</h1>
				<p className={styles.ExitModal__body}>{dict.exitBody}</p>

				<div className={styles.ExitModal__buttongroup}>
					<Button
						label={dict.continueBrowsingButton}
						className={styles.ExitModal__button}
						onClick={() => setExitModal(false)}
					/>
					<Button
						theme="secondary"
						label={dict.exitButton}
						className={styles.ExitModal__button}
						// eslint-disable-next-line prettier/prettier
						onClick={() => {
							removeCookie("affiliate-session", "/", ".selloffvacations.com");
							setAlias(null);
							const url = linkURL ?? `/${lang}/`;
							navigate(url);
						}}
					/>
				</div>
			</Modal.Content>
		</Modal>
	);
};

ExitModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setExitModal: PropTypes.func.isRequired,
	linkURL: PropTypes.string.isRequired,
};
export default ExitModal;
export { ExitModal };
