import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { Contact } from "../../components/Contact";

const ContactConnector = ({ data }) => (
	<Contact
		title={data.displayTitle}
		address={data.address}
		addressLink={data.addressLink}
		googleReviewLink={data.googleReviewLink}
		timings={data.timings}
		note={data.note}
		phones={data.phones}
		variant={data.variant}
	/>
);

ContactConnector.propTypes = {
	data: PropTypes.shape({
		node_locale: PropTypes.string,
		title: PropTypes.string,
		displayTitle: PropTypes.string,
		address: PropTypes.string,
		addressLink: PropTypes.string,
		googleReviewLink: PropTypes.shape({
			childMarkdownRemark: PropTypes.shape({
				rawMarkdownBody: PropTypes.string,
			}),
		}),
		phones: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				label: PropTypes.string,
				content: PropTypes.shape({
					childMarkdownRemark: PropTypes.shape({
						html: PropTypes.string,
					}),
				}),
			})
		),
		timings: PropTypes.arrayOf(
			PropTypes.shape({
				close: PropTypes.bool,
				closeAt: PropTypes.string,
				friday: PropTypes.bool,
				monday: PropTypes.bool,
				openAt: PropTypes.string,
				saturday: PropTypes.bool,
				sunday: PropTypes.bool,
				thursday: PropTypes.bool,
				tuesday: PropTypes.bool,
				wednesday: PropTypes.bool,
			})
		),
		note: PropTypes.shape({
			childMarkdownRemark: PropTypes.shape({
				html: PropTypes.string,
			}),
		}),
		variant: PropTypes.oneOf(["primary", "secondary"]),
	}),
};
ContactConnector.defaultProps = {
	data: {},
};

export default ContactConnector;
export { ContactConnector };
