/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Keyboard, Navigation, Pagination, Mousewheel } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/mousewheel";
import "swiper/css/a11y";
import "swiper/css/keyboard";
import * as styles from "./ComponentsSlider.module.scss";

const defaultLabels = {
	prevSlideLabel: "Previous slide",
	nextSlideLabel: "Next slide",
	firstSlideLabel: "This is the first slide",
	lastSlideLabel: "This is the last slide",
	paginationBulletLabel: "Go to slide {{index}}",
	firstSlideMessage: "This is the first slide",
	lastSlideMessage: "This is the last slide",
	prevSlideMessage: "Previous slide",
	nextSlideMessage: "Next slide",
	paginationBulletMessage: "Go to slide {{index}}",
	slideLabelMessage: "{{index}} of {{slidesLength}}",
};

const ComponentsSlider = ({
	id,
	slides,
	className,
	labels: providedLabels,
	slidesPerView: itemsPerView,
	boxShadow,
}) => {
	const labels = { ...defaultLabels, ...providedLabels };

	const commonBreakpoints = {
		360: {
			slidesPerView: 1.1,
		},
	};

	const swiperConfig = {
		modules: [A11y, Keyboard, Pagination, Navigation, Mousewheel],
		keyboard: {
			enabled: true,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
			bulletElement: "button",
		},
		speed: 400,
		loop: false,
		spaceBetween: 16, // If BoxShadow blur is more than 10-12, this should be updated with higher value. e.g 24
		navigation: {
			prevEl: `#prev-${id}`,
			nextEl: `#next-${id}`,
		},
		a11y: {
			firstSlideMessage: labels.firstSlideMessage,
			lastSlideMessage: labels.lastSlideMessage,
			prevSlideMessage: labels.prevSlideMessage,
			nextSlideMessage: labels.nextSlideMessage,
			paginationBulletMessage: labels.paginationBulletMessage,
			slideLabelMessage: labels.slideLabelMessage,
		},
		breakpoints: (() => {
			// eslint-disable-next-line default-case
			switch (itemsPerView) {
				case 1:
					return 1;
				case 2:
					return {
						...commonBreakpoints,
						600: {
							slidesPerView: 2,
						},
					};
				case 3:
					return {
						...commonBreakpoints,
						600: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
					};
				case 4:
					return {
						...commonBreakpoints,
						600: {
							slidesPerView: 2,
						},
						768: {
							slidesPerView: 3,
						},
						1024: {
							slidesPerView: 4,
						},
					};
			}
		})(),
	};

	const shouldHideNavigation = slides.length <= itemsPerView;

	return (
		<div className={styles.slider__wrapper}>
			<Swiper
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...swiperConfig}
				className={cx(className, styles.swiper, {
					[styles.hasShadow]: boxShadow,
				})}
				style={boxShadow ? { "--slider-offset": `${boxShadow.blur}px` } : null}
			>
				{slides.map(slide => (
					<SwiperSlide key={slide.id} className={styles.slide}>
						{slide.view}
					</SwiperSlide>
				))}
				<div className="swiper-pagination" />
			</Swiper>
			{!shouldHideNavigation && (
				<>
					<div id={`prev-${id}`} className="swiper-prev swiper-button-prev" />
					<div id={`next-${id}`} className="swiper-next swiper-button-next" />
				</>
			)}
		</div>
	);
};

ComponentsSlider.propTypes = {
	id: PropTypes.string.isRequired,
	slides: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			view: PropTypes.node.isRequired,
		}).isRequired
	).isRequired,
	className: PropTypes.string,

	/**
	 * If supplying breakpoints, this will be used as the default size
	 */
	slidesPerView: PropTypes.number,
	boxShadow: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.number,
		PropTypes.shape({
			x: PropTypes.number,
			y: PropTypes.number,
			blur: PropTypes.number,
			spread: PropTypes.number,
			opacity: PropTypes.number,
		}),
	]),
	/**
	 *  Used for aria labels
	 */
	labels: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		firstSlideMessage: PropTypes.string,
		lastSlideMessage: PropTypes.string,
		prevSlideMessage: PropTypes.string,
		nextSlideMessage: PropTypes.string,
		paginationBulletMessage: PropTypes.string,
		slideLabelMessage: PropTypes.string,
	}),
};

ComponentsSlider.defaultProps = {
	className: undefined,
	slidesPerView: 1,
	boxShadow: false,
	labels: defaultLabels,
};

export default ComponentsSlider;
export { ComponentsSlider };
