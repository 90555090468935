// extracted by mini-css-extract-plugin
export var stepper = "StepperInput-module--stepper--2vyl8";
export var stepperLabel = "StepperInput-module--stepperLabel--1vX4H";
export var description = "StepperInput-module--description--2amnb";
export var childAgesContainer = "StepperInput-module--childAgesContainer--1qWrH";
export var childAges = "StepperInput-module--childAges--1z5lL";
export var ageSelector = "StepperInput-module--ageSelector--3Aj3H";
export var ageError = "StepperInput-module--ageError--3hDO9";
export var stepperWrapper = "StepperInput-module--stepperWrapper--CnHGg";
export var button = "StepperInput-module--button--gGRkJ";
export var input = "StepperInput-module--input--1jMiG";
export var ariaLive = "StepperInput-module--ariaLive--38Stk";
export var helperText = "StepperInput-module--helperText--D--Yk";