/* eslint-disable react-hooks/rules-of-hooks */
import * as dayjs from "dayjs";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { DotsLoader } from "../Loader/Dots";
import useInterval from "../../utils/useInterval";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { PageContext } from "../PageContext";
import { RCL as useTranslation } from "../RCL";
import * as styles from "./TimedOutModal.module.scss";

const WarningModal = ({ isOpen, setIsOpen, refresh, setAffiliateState }) => {
	const ctx = useContext(PageContext);

	const [time, setTime] = useState();

	const formatTimeString = date => {
		const sessionExpiryTime = dayjs(ctx?.alias?.expiryTime);
		if (date.diff(sessionExpiryTime, "minutes") === 0) {
			return `${Math.abs(date.diff(sessionExpiryTime, "seconds").toString())} ${
				ctx.lang === "en" ? "seconds" : "secondes"
			}`;
		}
		return `${Math.abs(date.diff(sessionExpiryTime, "minutes").toString())} minutes`;
	};

	// timer set up to run every second
	useInterval(() => {
		setTime(formatTimeString(dayjs()));
	}, 1000);

	return (
		<Modal
			isOpen={isOpen}
			className={styles.timedOutModal}
			onClose={() => {
				setIsOpen(false);
				setAffiliateState("ACTIVE");
			}}
		>
			<Modal.Content className={styles.timedOutModal__content}>
				<h1 className={styles.timedOutModal__title}>
					{useTranslation({ searchKey: "session-warning" })}
				</h1>
				{!time ? (
					<DotsLoader />
				) : (
					<p className={styles.timedOutModal__body}>
						{useTranslation({
							searchKey: "session-warning-message",
							replace: { time },
						})}
					</p>
				)}

				<div className={styles.timedOutModal__buttongroup}>
					<Button
						label={useTranslation({ searchKey: "continue-browsing" })}
						className={styles.timedOutModal__button}
						onClick={refresh}
					/>
					<Button
						theme="secondary"
						label={useTranslation({ searchKey: "affiliate-exit" })}
						className={styles.timedOutModal__button}
						onClick={() => {
							navigate(`/${ctx.lang}/`);
						}}
					/>
				</div>
			</Modal.Content>
		</Modal>
	);
};

WarningModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	refresh: PropTypes.func,
	setAffiliateState: PropTypes.func.isRequired,
};

WarningModal.defaultProps = {
	refresh: () => undefined,
};

export default WarningModal;
export { WarningModal };
