import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as styles from "./Radio.module.scss";

const Radio = ({ value, className, onChange, label, disabled, selected, error, id }) => {
	const classes = cx(styles.radio, {
		[styles.radioError]: error,
		[className]: className,
	});
	const handleOnChange = event => {
		onChange(event.target.value);
	};
	return (
		<div className={classes}>
			<input
				type="radio"
				onChange={handleOnChange}
				name={`radio-${id}`}
				id={`radio-${id}`}
				className={styles.radio__control}
				value={value}
				checked={selected}
				disabled={disabled}
			/>
			<span className={styles.radio__ui} />
			<label className={styles.radio__label} htmlFor={`radio-${id}`}>
				{label}
			</label>
		</div>
	);
};

Radio.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func,
	selected: PropTypes.bool,
	error: PropTypes.bool,
	className: PropTypes.string,
};

Radio.defaultProps = {
	label: "Option 1",
	className: "",
	onChange: () => true,
	disabled: false,
	selected: false,
	error: false,
	value: "",
};

export default Radio;
export { Radio };
