/* eslint-disable testing-library/await-async-query */
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { findByType } from "@sunwing/shared-components/dist/utils/findByType";
import { Modal as ModalComponent } from "@sunwing/shared-components";
import { Icon } from "../Icons";
import * as styles from "./Modal.module.scss";

const containerSize = {
	fluid: "fluid",
	md: "md",
	lg: "lg",
	xl: "xl",
};
const Modal = ({
	children,
	className,
	isOpen,
	contentLabel,
	overlayClassName,
	onClose,
	container,
	simple,
	closeClassName,
	isPadding,
}) => {
	const renderContent = () => {
		const content = findByType(children, ModalComponent.Content);
		if (!content) {
			return null;
		}
		return (
			<ModalComponent.Content
				className={cx(
					styles.content,
					content.props.className,
					container,
					!isPadding ? styles.noPadding : ""
				)}
				style={content.props.style}
			>
				{content.props.children}
			</ModalComponent.Content>
		);
	};

	const renderTrigger = () => {
		const trigger = findByType(children, ModalComponent.Trigger);
		if (!trigger) {
			return null;
		}
		return (
			<ModalComponent.Trigger className={cx(trigger.props.className)} style={trigger.props.style}>
				{trigger.props.children}
			</ModalComponent.Trigger>
		);
	};
	return (
		<ModalComponent
			className={cx(
				styles.modal,
				simple && styles.simple,
				container && styles[container],
				className
			)}
			isOpen={isOpen}
			labels={contentLabel}
			onClose={onClose}
			overlayClassName={cx(styles.overlay, overlayClassName)}
		>
			<ModalComponent.Close className={cx(styles.close, closeClassName)}>
				<Icon name="close--n" />
			</ModalComponent.Close>
			{renderTrigger()}
			{renderContent()}
		</ModalComponent>
	);
};

Modal.Content = ModalComponent.Content;
Modal.Close = ModalComponent.Close;
Modal.Trigger = ModalComponent.Trigger;

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	simple: PropTypes.bool,
	/**
	 * To control modal state externally
	 */
	isOpen: PropTypes.bool,
	/**
	 * Accessibility label
	 */
	contentLabel: PropTypes.string.isRequired,
	overlayClassName: PropTypes.string,
	onClose: PropTypes.func,
	container: PropTypes.oneOf(Object.keys(containerSize)),
	closeClassName: PropTypes.string,
	isPadding: PropTypes.bool,
};
Modal.defaultProps = {
	className: undefined,
	isOpen: false,
	simple: false,
	overlayClassName: undefined,
	onClose: null,
	container: containerSize.lg,
	closeClassName: undefined,
	isPadding: true,
};

export default Modal;
export { Modal, containerSize };
