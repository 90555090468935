import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import * as dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import en from "rc-picker/lib/locale/en_US";
import fr from "rc-picker/lib/locale/fr_FR";
import RCDatePicker from "rc-picker";
import { Icon } from "../../../../Icons";
import * as styles from "./styles.module.scss";

const DatePicker = React.forwardRef(
	(
		{
			inputRef,
			className,
			locale,
			format,
			selectedDate,
			minDate,
			maxDate,
			disabled,
			onFocus,
			onBlur,
			onOpen,
			onChange,
			isDefaultValue,
			isOpenUI,
			placeholder,
		},
		ref
	) => {
		const [isOpened, setIsOpened] = useState(false);
		const datePickerRef = useRef(ref);

		useEffect(() => {
			if (isOpened) {
				disableBodyScroll(datePickerRef.current);
				onOpen(true);
			} else {
				clearAllBodyScrollLocks();
				onOpen(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isOpened, ref]);

		return (
			<RCDatePicker
				direction="rtl"
				ref={datePickerRef}
				prefixCls={styles.swgPicker}
				generateConfig={dayjsGenerateConfig}
				picker="date"
				locale={locale === "fr" ? { ...fr, monthFormat: "MMMM" } : { ...en, monthFormat: "MMMM" }}
				format={format}
				// Replicate default month cell render but with different formatting
				monthCellRender={month => (
					<div className={`${styles.swgPicker}-cell-inner`}>{month.format("MMM")}</div>
				)}
				className={cx(className, styles.inputGroup, isOpened && isOpenUI && styles.inputGroupOpen)}
				value={selectedDate}
				defaultValue={isDefaultValue ? selectedDate : ""}
				open={isOpened}
				disabled={disabled}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange}
				onOpenChange={open => {
					setIsOpened(open);
					if (open) {
						onFocus();
					}
				}}
				getPopupContainer={() => inputRef?.current}
				disabledDate={
					current =>
						current.isBefore(minDate || dayjs().startOf("day")) ||
						current.isAfter(maxDate || dayjs().startOf("day").add(365, "days"))
					// eslint-disable-next-line react/jsx-curly-newline
				}
				allowClear={false}
				inputReadOnly={true}
				clearIcon={null}
				suffixIcon={
					<Icon
						name="calendar-alt"
						className={styles.calendarIcon}
						onClick={() => {
							if (!disabled) {
								setIsOpened(!isOpened);
								if (!isOpened) {
									onFocus();
								}
							}
						}}
					/>
				}
				superPrevIcon={
					<Icon
						name="arrow-left"
						className={cx(styles.navigationIcon, styles.navigationIconPrev)}
					/>
				}
				superNextIcon={
					<Icon
						name="arrow-right"
						className={cx(styles.navigationIcon, styles.navigationIconNext)}
					/>
				}
				prevIcon={
					<Icon
						name="arrow-left"
						className={cx(styles.navigationIcon, styles.navigationIconPrev)}
					/>
				}
				nextIcon={
					<Icon
						name="arrow-right"
						className={cx(styles.navigationIcon, styles.navigationIconNext)}
					/>
				}
				placeholder={placeholder}
			/>
		);
	}
);

DatePicker.propTypes = {
	inputRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	className: PropTypes.string,
	locale: PropTypes.oneOf(["en", "fr"]),
	format: PropTypes.string,
	selectedDate: PropTypes.instanceOf(dayjs),
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
	disabled: PropTypes.bool,
	onOpen: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	isDefaultValue: PropTypes.bool,
	isOpenUI: PropTypes.bool,
	placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
	className: undefined,
	locale: "en",
	format: "D MMM YYYY",
	selectedDate: undefined,
	minDate: undefined,
	maxDate: undefined,
	disabled: false,
	onOpen: () => {},
	onFocus: () => {},
	onBlur: () => {},
	isDefaultValue: true,
	isOpenUI: true,
	placeholder: "",
};

export default DatePicker;
export { DatePicker };
