import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Cell, Row } from "../../Grid";
import { Icon } from "../../Icons";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../../FluidSections";
import * as styles from "./PaginatedCollection.module.scss";

const PaginatedCollection = ({ items, cardsPerPage, pagesPerGroup }) => {
	const [allowPrevious, setAllowPrevious] = useState(false);
	const [allowNext, setAllowNext] = useState(false);
	const [paginationItems, setPaginationItems] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const prevCurrentPage = useRef();

	const totalPaginatedEntries = Math.ceil(items?.length / cardsPerPage);
	const chunkedPaginatedEntries = [];

	let index = 0;
	while (index < items.length) {
		chunkedPaginatedEntries.push(items.slice(index, cardsPerPage + index));
		index += cardsPerPage;
	}

	const handlePrevNextPageLogic = page => {
		if (currentPage > 1) {
			setAllowPrevious(true);
		} else {
			setAllowPrevious(false);
		}

		if (currentPage < totalPaginatedEntries) {
			setAllowNext(true);
		} else {
			setAllowNext(false);
		}

		setCurrentPage(page);
	};

	const setupPaginationItems = () => {
		const pagination = [];
		const index = Math.floor(currentPage / pagesPerGroup - 0.1);
		const chunkedPaginatedSections = [];

		let sectionIndex = 0;
		while (sectionIndex < chunkedPaginatedEntries.length) {
			chunkedPaginatedSections.push(chunkedPaginatedEntries.slice(sectionIndex, 5 + sectionIndex));
			sectionIndex += 5;
		}

		for (let i = 1; i <= chunkedPaginatedSections[index].length; i++) {
			pagination.push({
				type: "page",
				number: index * pagesPerGroup + i,
				label: index * pagesPerGroup + i,
			});
		}

		setPaginationItems(pagination);
		handlePrevNextPageLogic(currentPage);
	};

	useEffect(() => {
		prevCurrentPage.current = currentPage;

		if (paginationItems.length === 0) {
			setupPaginationItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationItems]);

	useEffect(() => {
		if (prevCurrentPage.current !== currentPage) {
			setupPaginationItems();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	const handlePage = page => {
		handlePrevNextPageLogic(page);
	};

	const handleFirstPage = () => {
		setCurrentPage(1);
	};

	const handleLastPage = () => {
		setCurrentPage(totalPaginatedEntries);
	};

	const handlePrevPage = () => {
		setCurrentPage(currentPage - 1);
	};

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	return (
		<div>
			{totalPaginatedEntries && (
				<div>
					<Row cols={3} className={cx(styles.entriesGroup)}>
						{chunkedPaginatedEntries[currentPage - 1].map(item => (
							<Cell>
								<FluidSections data={[item]} useWrapper={false} />
							</Cell>
						))}
					</Row>
				</div>
			)}
			{totalPaginatedEntries > 1 && (
				<ul className={cx(styles.pagination)}>
					<li>
						<button
							className={cx(styles.prevBtnRotate)}
							type="button"
							onClick={handleFirstPage}
							disabled={!allowPrevious}
							aria-label="Go to beginning"
						>
							<Icon name="arrow-double" className={cx(styles.icon)} />
						</button>
					</li>
					<li>
						<button
							type="button"
							onClick={handlePrevPage}
							disabled={!allowPrevious}
							aria-label="Go to previous"
						>
							<Icon name="arrow-left" className={cx(styles.icon)} />
						</button>
					</li>
					{paginationItems?.map(pagination => (
						<li>
							<button
								type="button"
								className={cx(`${currentPage === pagination?.number ? styles.active : ""}`)}
								onClick={() => handlePage(pagination?.number)}
								disabled={currentPage === pagination?.number}
							>
								{pagination?.label}
							</button>
						</li>
					))}
					<li>
						<button
							type="button"
							onClick={handleNextPage}
							disabled={!allowNext}
							aria-label="Go next"
						>
							<Icon name="arrow-right" className={cx(styles.icon)} />
						</button>
					</li>
					<li>
						<button
							type="button"
							onClick={handleLastPage}
							disabled={!allowNext}
							aria-label="Go to last"
						>
							<Icon name="arrow-double" className={cx(styles.icon)} />
						</button>
					</li>
				</ul>
			)}
		</div>
	);
};

PaginatedCollection.propTypes = {
	items: PropTypes.array.isRequired,
	cardsPerPage: PropTypes.number,
	pagesPerGroup: PropTypes.number,
};
PaginatedCollection.defaultProps = {
	cardsPerPage: 6,
	pagesPerGroup: 5,
};

export default PaginatedCollection;
export { PaginatedCollection };
