import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Icon } from "../Icons";
import * as styles from "./Accordion.module.scss";

const ToggleTemplate = ({
	isActive,
	children,
	variant,
	customClass,
	iconActiveClass,
	iconInactiveClass,
	isDisabled,
	icon,
}) => (
	<span
		className={cx(
			styles[`${variant}ToggleContainer`],
			isDisabled && styles.disabled,
			customClass || "",
			isActive
				? styles[`${variant}ToggleContainerActive`]
				: styles[`${variant}ToggleContainerInactive`]
		)}
	>
		{icon && <Icon name={icon} className={styles.tabIcon} />}
		{children}
		<span className={cx(styles.accordionChevron, isActive ? iconActiveClass : iconInactiveClass)} />
	</span>
);

ToggleTemplate.propTypes = {
	isActive: PropTypes.bool,
	children: PropTypes.node,
	variant: PropTypes.string,
	customClass: PropTypes.string,
	iconActiveClass: PropTypes.string,
	iconInactiveClass: PropTypes.string,
	isDisabled: PropTypes.bool,
	icon: PropTypes.string,
};

ToggleTemplate.defaultProps = {
	isActive: false,
	children: undefined,
	variant: "",
	customClass: undefined,
	iconActiveClass: "",
	iconInactiveClass: "",
	isDisabled: false,
	icon: undefined,
};

export default ToggleTemplate;
export { ToggleTemplate };
