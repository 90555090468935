import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as styles from "./SelectInput.module.scss";

const SelectInput = ({
	id,
	label,
	value,
	options,
	error,
	onChange,
	// Presentation
	displayInline,
	className,
	customLabelClass,
	customSelectClass,
}) => {
	const [internalValue, setInternalValue] = useState(value);
	const handleOnChange = e => {
		const newValue = e.target.value;

		setInternalValue(newValue);
		onChange(newValue);
	};

	return (
		<div
			className={cx(
				className,
				styles.containerSelectInput,
				displayInline && styles.inline,
				error && styles.error
			)}
			data-valid={!error}
		>
			<label htmlFor={id} className={cx(customLabelClass, styles.label)}>
				{label}
			</label>
			<div className={styles.selectWrapper}>
				<select
					id={id}
					className={cx(customSelectClass, styles.select)}
					value={internalValue}
					onChange={handleOnChange}
				>
					{options.map(option => (
						<option key={option.value} value={option.value} disabled={option.value === "--"}>
							{option.label}
						</option>
					))}
				</select>
				<span className={styles.chevron} />
			</div>
		</div>
	);
};

SelectInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	).isRequired,
	error: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	// Presentation
	displayInline: PropTypes.bool,
	className: PropTypes.string,
	customLabelClass: PropTypes.string,
	customSelectClass: PropTypes.string,
};

SelectInput.defaultProps = {
	error: false,
	onChange: () => {},
	// Presentation
	displayInline: false,
	className: undefined,
	customLabelClass: undefined,
	customSelectClass: undefined,
};

export default SelectInput;
export { SelectInput };
