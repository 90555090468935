import React, { useContext } from "react";
import PropTypes from "prop-types";
import { PageContext } from "../../components/PageContext";
import { Link } from "../../sharedComponents/Link";
import { Footer } from "../../components/Footer";
import { languageToggleAdapter } from "../../components/LanguageToggle";
import { LanguageToggleConnector } from "../LangugeToggleConnector";
import { RCL } from "../../components/RCL";

const FooterConnector = ({ data, altLangPages }) => {
	const { locale } = useContext(PageContext);

	const columns = data?.linksList?.map(list => ({
		id: list.id,
		title: list.displayTitle,
		links: list.entries.map(entry => ({
			id: entry.id,
			url: entry.url,
			title: entry.linkText,
			target: entry.target,
		})),
	}));

	const thisYear = new Date().getFullYear();

	return (
		<Footer
			className="page__footer"
			columns={columns}
			socialLinks={data.socialLinks}
			otherLinks={data.otherLinks}
			labels={{
				backToTop: <RCL searchKey="back-to-top-sov" />,
			}}
			altLangPages={languageToggleAdapter(altLangPages)}
			renderLanguageToggle={(_altLangPages, classNames) => (
				<LanguageToggleConnector
					altLangPages={_altLangPages}
					classNames={classNames}
					locale={locale}
				/>
			)}
			copyrightText={data.footerCopyright.replace("{{date}}", thisYear)}
			renderLinks={(item, className) => (
				<Link to={item.url} target={item.target} className={className}>
					{item.title}
				</Link>
			)}
			renderImageLinks={(item, { imageClassName }) => (
				<Link to={item.url} target={item.target}>
					<img
						src={item?.image?.[0]?.secure_url}
						className={imageClassName}
						alt={item?.imageAltText}
						width={item?.image?.[0].width}
						height={item?.image?.[0].height}
					/>
				</Link>
			)}
			renderIconLinks={(item, { className, icon }) => (
				<Link to={item.url} target={item.target} className={className}>
					{icon}
				</Link>
			)}
		/>
	);
};

FooterConnector.propTypes = {
	data: PropTypes.shape({
		linksList: PropTypes.arrayOf(
			PropTypes.shape({
				entries: PropTypes.arrayOf(
					PropTypes.shape({
						id: PropTypes.string,
						linkText: PropTypes.string,
						target: PropTypes.string,
						url: PropTypes.string,
					})
				),
			})
		),
		socialLinks: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				image: PropTypes.arrayOf(
					PropTypes.shape({
						secure_url: PropTypes.string,
					})
				),
				imageAltText: PropTypes.string,
				target: PropTypes.string,
				url: PropTypes.string,
			})
		),
		otherLinks: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				image: PropTypes.arrayOf(
					PropTypes.shape({
						secure_url: PropTypes.string,
					})
				),
				imageAltText: PropTypes.string,
				target: PropTypes.string,
				url: PropTypes.string,
			})
		),
		footerCopyright: PropTypes.string,
	}).isRequired,
	altLangPages: PropTypes.shape({
		id: PropTypes.string,
		lang: PropTypes.string,
		path: PropTypes.string,
	}).isRequired,
};

export default FooterConnector;
export { FooterConnector };
