/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { Button } from "../Button";
import { Modal } from "../Modal";
import * as styles from "./TimedOutModal.module.scss";
import { RCL as useTranslation } from "../RCL";
import { PageContext } from "../PageContext";
import { removeCookie } from "../../utils/helper";

const TimedOutModal = ({ isOpen }) => {
	const { lang, setAlias } = useContext(PageContext);

	return (
		<Modal
			isOpen={isOpen}
			className={styles.timedOutModal}
			onClose={() => {
				setAlias();
				navigate(`/${lang}/`);
			}}
		>
			<Modal.Content className={styles.timedOutModal__content}>
				<h1 className={styles.timedOutModal__title}>
					{useTranslation({ searchKey: "session-expired" })}
				</h1>
				<p className={styles.timedOutModal__body}>
					{useTranslation({ searchKey: "session-expired-message" })}
				</p>

				<Button
					label={useTranslation({ searchKey: "modal-close" })}
					className={styles.timedOutModal__button}
					onClick={() => {
						removeCookie("affiliate-session", "/", ".selloffvacations.com");
						setAlias(null);
						navigate(`/${lang}/`);
					}}
				/>
			</Modal.Content>
		</Modal>
	);
};

TimedOutModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
};

export default TimedOutModal;
export { TimedOutModal };
