// extracted by mini-css-extract-plugin
export var defaultTabs = "Tabs-module--defaultTabs--2WZbg";
export var defaultTab = "Tabs-module--defaultTab--2JqDH";
export var defaultActive = "Tabs-module--defaultActive--1Ag2v";
export var activeTab = "Tabs-module--activeTab--n86hx";
export var defaultTabsWrapper = "Tabs-module--defaultTabsWrapper--148Eo";
export var defaultPanel = "Tabs-module--defaultPanel--33JRx";
export var medium = "Tabs-module--medium--UfsgO";
export var large = "Tabs-module--large--1kU0T";
export var genericTabs = "Tabs-module--genericTabs--qN4Hl";
export var genericTab = "Tabs-module--genericTab--3_NId";
export var genericActive = "Tabs-module--genericActive--3A1-j";
export var genericTabsWrapper = "Tabs-module--genericTabsWrapper--37WMR";
export var genericPanel = "Tabs-module--genericPanel--2JvCD";
export var iconTabsWrapper = "Tabs-module--iconTabsWrapper--1rz0K";
export var iconTabs = "Tabs-module--iconTabs--1Ye1y";
export var iconTab = "Tabs-module--iconTab--HJo_f";
export var iconIcon = "Tabs-module--iconIcon--3Qhc3";
export var iconActive = "Tabs-module--iconActive--FrjLM";
export var iconTabsRButton = "Tabs-module--iconTabsRButton--y5jlF";
export var iconTabsLButton = "Tabs-module--iconTabsLButton--294AA";
export var iconPanel = "Tabs-module--iconPanel--1N21E";