// extracted by mini-css-extract-plugin
export var sectionTitle = "Paragraph-module--sectionTitle--3v3zu";
export var sectionContent = "Paragraph-module--sectionContent--1hIK7";
export var activeLinkP = "Paragraph-module--activeLinkP--2pUs4";
export var paragraphTitle = "Paragraph-module--paragraphTitle--2lJ4c";
export var inline = "Paragraph-module--inline--thyDs";
export var paragraphContent = "Paragraph-module--paragraphContent--2r_wD";
export var list = "Paragraph-module--list--36X-a";
export var sectionFooter = "Paragraph-module--sectionFooter--rHgke";
export var activeLink = "Paragraph-module--activeLink--1lZdK";
export var center = "Paragraph-module--center--1GlB5";
export var left = "Paragraph-module--left--1iEY_";
export var right = "Paragraph-module--right--1Z1h_";
export var mobileCentered = "Paragraph-module--mobileCentered--1Ltc5";