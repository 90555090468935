import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as ddStyles from "../../Common/CSS/Dropdown/Dropdown.module.scss";

const PortField = ({
	label,
	id,
	selected,
	error,
	resetError,
	onChange,
	destination,
	cruiseLine,
	cruiseMode,
}) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });
	const anyPortRCLKey = useTranslation({ searchKey: "any-port" });
	const [isFocus, setFocus] = useState(false);
	const [portsData, setPortsData] = useState([]);

	/* /////////////////////////////////////////////////////////////
	// Ports drop-down logic
	///////////////////////////////////////////////////////////// */

	const transformPortsData = (_ports, _keys) => {
		// Transform data from API response to type-ahead data format

		let template = [];
		_keys.forEach(key => {
			const portItem = _ports[key];

			template.push({
				value: portItem.value,
				label: portItem.text.toLowerCase() === "any port" ? anyPortRCLKey : portItem.text,
				id: portItem.id,
			});
		});

		let sortedArray = [template[0]];

		template.shift();

		// Sort ports by label
		template = template.sort((a, b) => {
			const sortMethod = (a.label > b.label) - (b.label > a.label);
			return sortMethod;
		});

		sortedArray = [...sortedArray, ...template];
		return sortedArray;
	};

	const loadPortsData = async (destinationValue, cruiseValue) => {
		try {
			const getQuery = (destValue, cruiseVal) => {
				let query = "";
				if (destValue && !cruiseVal) {
					query += `${destValue}`;
				} else if (!destValue && cruiseVal) {
					query += `|${cruiseVal}`;
				} else if (destValue && cruiseVal) {
					query += `${destValue}|${cruiseVal}`;
				}

				return query;
			};

			const option = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					did: getQuery(destinationValue, cruiseValue),
				}),
			};

			let url = "/api/cruise-ports";
			if (cruiseMode === "river") {
				url = "/api/cruise-river-ports";
			}

			const response = await fetch(url, option);
			const returnObject = await response.json();

			resetError(true);
			return returnObject;
		} catch (err) {
			resetError(true);
			console.error("Failed to load ports: ", error);
			return null;
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Input initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		// Initial component load sequence
		(async () => {
			if (cruiseLine === null) return;

			const ports = await loadPortsData(destination?.value || "", cruiseLine?.value || "");
			const data = ports?.p || null;
			const keys = data ? Object.keys(ports?.p) : [];

			if (keys.length > 0 && data) {
				const sortedData = transformPortsData(data, keys);
				setPortsData(sortedData);

				if (selected && sortedData.findIndex(d => d.value === selected.value) < 0) {
					onChange(sortedData[0]);
				}
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cruiseLine]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={<span className={inputStyles.chevron} />}
			className={cx(
				inputStyles.inputWrapper,
				ddStyles.dropdown,
				ddStyles.wide,
				isFocus ? ddStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: inputStyles.container,
				value: ddStyles.value,
				open: cx(ddStyles.open, inputStyles.open),
				input: ddStyles.input,
				selected: ddStyles.selected,
				highlighted: ddStyles.highlighted,
				noResults: ddStyles.noResults,
				listbox: ddStyles.listbox,
			}}
			type="dropdown"
			data={portsData}
			selectedItem={selected === null ? portsData[0] : selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={() => {
				setFocus(true);
				resetError();
			}}
			onChange={value => {
				if (value?.value !== selected?.value) {
					resetError();
					onChange(value);
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

PortField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	destination: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	cruiseLine: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	cruiseMode: PropTypes.string.isRequired,
};

PortField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
	destination: null,
	cruiseLine: null,
};

export default PortField;
export { PortField };
