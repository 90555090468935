import PropTypes from "prop-types";

export const propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	state: PropTypes.objectOf(PropTypes.object),
	target: PropTypes.string,
	activeClassName: PropTypes.string,
	partiallyActive: PropTypes.bool,
	externalModalMessage: PropTypes.string,
};
export const defaultProps = {
	className: undefined,
	style: undefined,
	state: undefined,
	target: undefined,
	activeClassName: undefined,
	partiallyActive: false,
	externalModalMessage: undefined,
};
