import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

import toFieldAdapter from "../utils/ToField.adapter";

const ToField = ({ id, label, language, selectedTo, error, resetError, onChange }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const labels = {
		airport: GetDictionary("airport"),
		city: GetDictionary("city"),
	};

	const [isFocus, setFocus] = useState(false);

	// Destination states
	const [query, setQuery] = useState();
	const [destinationData, setDestinationData] = useState([]);

	/* /////////////////////////////////////////////////////////////
	// Destination typeahead logic
	///////////////////////////////////////////////////////////// */

	const loadDestinationData = async _userQuery => {
		const apiHeaders = new Headers();
		apiHeaders.append("Content-Type", "application/json");

		const apiConfig = {
			method: "GET",
			headers: apiHeaders,
		};

		const apiRequest = new Request(
			`${process.env.GATSBY_SVHANDLER_API_BASE}/hoteldestinations/sov/${language}/${_userQuery}`
		);

		return fetch(apiRequest, apiConfig)
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(res => {
				resetError(true);
				return res;
			})
			.catch(err => {
				console.error("Failed to load destinations: ", err);
				resetError(false);
				return null;
			});
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Destination update logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		if (query?.length > 2) {
			(async () => {
				await loadDestinationData(query)
					.then(data => {
						const [list] = toFieldAdapter(data, labels);
						setDestinationData(list);
					})
					.catch(err => console.error(err));
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: taStyles.open,
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={destinationData}
			selectedItem={selectedTo}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onInput={textEntered => {
				setQuery(textEntered);
				resetError();
			}}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null) {
					onChange(selection);
					resetError();
					setFocus(true);
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

ToField.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	selectedTo: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
};

ToField.defaultProps = {
	selectedTo: undefined,
	error: false,
	resetError: () => {},
};

export default ToField;
export { ToField };
