// extracted by mini-css-extract-plugin
export var headingH1 = "Heading-module--heading--h1--2L0Oi";
export var headingH2 = "Heading-module--heading--h2--26-7L";
export var headingH3 = "Heading-module--heading--h3--2thq3";
export var headingBase = "Heading-module--heading--base--37aGd";
export var headingDisplay = "Heading-module--heading--display--btsYq";
export var headingSmall = "Heading-module--heading--small--nBwnl";
export var headingLight = "Heading-module--heading--light--1b5rF";
export var headingNormal = "Heading-module--heading--normal--2Hygu";
export var headingMedium = "Heading-module--heading--medium--_tBtk";
export var headingSemibold = "Heading-module--heading--semibold--3lfv1";
export var headingBold = "Heading-module--heading--bold--2R06b";
export var headingLeft = "Heading-module--heading--left--QxO_T";
export var headingCenter = "Heading-module--heading--center--LsW_Y";
export var headingRight = "Heading-module--heading--right--NApn2";
export var headingUppercase = "Heading-module--heading--uppercase--2K0Hm";