const toFieldAdapter = (data, labels) => {
	const hasResults = Array.isArray(data?.items);

	const capitalizeTitle = text => {
		if (text) {
			return text.charAt(0).toUpperCase() + text.slice(1);
		}
		return text;
	};

	const list = hasResults
		? data?.items?.reduce((accumulator, item) => {
				let category = accumulator.find(a => a?.title?.toLowerCase() === item?.type?.toLowerCase());
				if (!category) {
					let title = item.type;
					switch (item.type.toLowerCase()) {
						case "airport": {
							title = labels.airport;
							break;
						}
						case "city": {
							title = labels.city;
							break;
						}
						default: {
							title = capitalizeTitle(item.type);
							break;
						}
					}
					const newLength = accumulator.push({
						title,
						items: [],
						value: "",
						label: "",
						id: "",
					});

					category = accumulator[newLength - 1];
				}

				let label = item?.name;

				if (item.type.toLowerCase() === "airport") {
					if (item.pname) {
						label = `${item.name} (${item.acode}-${item.aname}), ${item.pname}, ${item.cname}`;
					} else {
						label = `${item.name} (${item.acode}-${item.aname}), ${item.cname}`;
					}
				} else if (item.pname) {
					label = `${item.name}, ${item.pname}, ${item.cname}`;
				} else {
					label = `${item.name}, ${item.cname}`;
				}

				category.items.push({
					label: decodeURIComponent(label),
					value: item?.code,
					id: decodeURIComponent(label),
				});
				return accumulator;
		  }, [])
		: [];

	return [list];
};

export default toFieldAdapter;
