import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SlideOutPanel as SlideOutPanelComponent } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/SlideOutPanel/index.css";
import { Icon } from "../Icons";
import * as styles from "./SlideOutPanel.module.scss";

const defaultClassNames = {
	title: styles.title,
	titleContainer: styles.titleContainer,
	menuContainer: styles.menuContainer,
};

const defaultLabels = {
	title: "Menu",
	closeMenu: "Close menu",
};

const SlideOutPanel = ({
	id,
	classNames: providedClassNames,
	labels: providedLabels,
	isOpen,
	handleClose,
	focusOnShouldIgnore,
	children,
}) => {
	const classNames = { ...defaultClassNames, ...providedClassNames };
	const labels = { ...defaultLabels, ...providedLabels };
	return (
		<SlideOutPanelComponent
			id={id}
			labels={labels}
			classNames={{
				...classNames,
				menu: cx(styles.menu, classNames.menu),
				title: classNames.title,
				titleContainer: classNames.titleContainer,
				menuContainer: classNames.menuContainer,
				closeButton: cx(styles.closeButton, classNames.closeButton),
			}}
			closeIcon={<Icon name="close--n" />}
			isOpen={isOpen}
			handleClose={handleClose}
			focusOnShouldIgnore={focusOnShouldIgnore}
		>
			{children}
		</SlideOutPanelComponent>
	);
};

SlideOutPanel.propTypes = {
	id: PropTypes.string.isRequired,
	/**
	 * Providing `title` will override the default styling
	 */
	classNames: PropTypes.shape({
		menu: PropTypes.string,
		titleContainer: PropTypes.string,
		title: PropTypes.string,
		closeButton: PropTypes.string,
		menuContainer: PropTypes.string,
	}),
	labels: PropTypes.shape({
		title: PropTypes.string,
		closeMenu: PropTypes.string,
	}),
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	focusOnShouldIgnore: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SlideOutPanel.defaultProps = {
	classNames: defaultClassNames,
	labels: defaultLabels,
	focusOnShouldIgnore: () => true,
};

export default SlideOutPanel;
export { SlideOutPanel };
