import React from "react";
import PropTypes from "prop-types";
import { Tabs } from "../../components/Tabs";
import { Accordion } from "../../components/Accordion";
import { Icon } from "../../components/Icons";
import { RCL } from "../../components/RCL";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../../components/FluidSections";
import * as styles from "./TabsAndAccordion.module.scss";

const TabsAndAccordionConnector = ({ data, locale }) => {
	const _data = data.items.map(item => ({
		key: item.key,
		toggle: {
			iconComponent: item.icon,
			label: {
				heading: item.heading,
				subHeading: item.subheading,
				tag: item.tag,
			},
		},
		panel: <FluidSections data={item.content} locale={locale} useWrapper={false} />,
	}));
	if (data.type === "accordion" || data.type === "faq")
		return (
			<Accordion activeKey={data.activeKey} tabTheme={data.variantAccordion} tabData={_data} />
		);

	if (data.type === "tabs")
		return (
			<Tabs
				theme={data.variantTabs}
				activeKey={data.activeKey}
				data={_data}
				tabIconLeftComponent={
					<>
						<Icon name="arrow-left" />
						<span className="sr-only">
							<RCL searchKey="contact-us" />
						</span>
					</>
				}
				tabIconRightComponent={
					<>
						<Icon name="arrow-right" />
						<span className="sr-only">
							<RCL searchKey="contact-us" />
						</span>
					</>
				}
				paddingVertical={data?.paddingVertical}
			/>
		);
	return (
		data.type === "hybrid" && (
			<>
				<div className={styles.displayDesktop}>
					<Tabs
						activeKey={data.activeKey}
						theme={data.variantTabs}
						data={_data}
						tabIconLeftComponent={<Icon name="arrow-left" />}
						tabIconRightComponent={<Icon name="arrow-right" />}
						paddingVertical={data?.paddingVertical}
					/>
				</div>
				<div className={styles.displayMobile}>
					<Accordion activeKey={data.activeKey} tabTheme={data.variantAccordion} tabData={_data} />
				</div>
			</>
		)
	);
};

TabsAndAccordionConnector.propTypes = {
	data: PropTypes.object.isRequired,
	locale: PropTypes.string,
};

TabsAndAccordionConnector.defaultProps = {
	locale: null,
};

export default TabsAndAccordionConnector;
export { TabsAndAccordionConnector };
