import React from "react";
import { number } from "prop-types";
import * as styles from "./StarRating.module.scss";
import { Icon } from "../Icons";

const StarRating = ({ rating }) => (
	<div className={styles.rating} rating={rating}>
		{[...Array(Math.floor(rating))].map((star, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<Icon name="star" key={i} />
		))}
		{rating % 1 !== 0 && <Icon name="star-half" />}
	</div>
);

StarRating.propTypes = {
	rating: number.isRequired,
};

export default StarRating;
export { StarRating };
