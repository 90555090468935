import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Cell } from "../../Grid";
import { Button } from "../../Button";
import * as styles from "./CardsContainerGrid.module.scss";

const defaultLabels = {
	loadAll: "Load all",
	loadMore: "Load more",
};

const CardsContainerGrid = ({
	children,
	numberToShow,
	moreToShow,
	cardsPerRow,
	loadMoreVariant,
	labels: providedLabels,
}) => {
	const [displayedItems, setDisplayedItems] = useState(numberToShow);
	const [isButtonVisible, setButtonVisibility] = useState(true);
	const [remainingItems, setRemainingItems] = useState(false);
	const labels = { ...defaultLabels, ...providedLabels };

	useEffect(() => {
		if (React.Children.count(children) <= 6) {
			setButtonVisibility(false);
		}
	}, [children]);

	useEffect(() => {
		if (React.Children.count(children) - displayedItems <= moreToShow) {
			setRemainingItems(true);
		}
	}, [children, displayedItems, moreToShow]);

	const showMore = () => {
		const totalItems = React.Children.count(children);
		const itemsShowing = displayedItems + moreToShow;

		if (totalItems >= itemsShowing) {
			setDisplayedItems(itemsShowing);
		} else if (itemsShowing > totalItems) {
			setDisplayedItems(totalItems);
			setButtonVisibility(false);
		}
		if (totalItems === itemsShowing) {
			setButtonVisibility(false);
		}
		if (totalItems - itemsShowing <= moreToShow) {
			setRemainingItems(true);
		}
	};

	const handleOnClick = () => {
		showMore();
	};

	return (
		<>
			<Row cols={cardsPerRow === 3 ? 3 : 4}>
				{React.Children.map(children, (component, index) => {
					if (index < displayedItems) {
						return <Cell>{component}</Cell>;
					}
					return null;
				})}
			</Row>
			{isButtonVisible && children.length > numberToShow && (
				<Button theme={loadMoreVariant} className={styles.loadMore} onClick={handleOnClick}>
					{remainingItems ? labels.loadAll : labels.loadMore}
				</Button>
			)}
		</>
	);
};

CardsContainerGrid.propTypes = {
	numberToShow: PropTypes.number,
	moreToShow: PropTypes.number,
	cardsPerRow: PropTypes.number,
	children: PropTypes.node.isRequired,
	labels: PropTypes.shape({
		loadAll: PropTypes.string,
		loadMore: PropTypes.string,
	}),
	loadMoreVariant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
};
CardsContainerGrid.defaultProps = {
	numberToShow: 6,
	moreToShow: 6,
	cardsPerRow: 3,
	labels: defaultLabels,
	loadMoreVariant: "tertiary",
};

export default CardsContainerGrid;
export { CardsContainerGrid };
