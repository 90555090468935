import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Card, Link } from "@sunwing/shared-components";
import * as styles from "./CardAsLink.module.scss";

const CardAsLink = ({ className, LinkComponent, link, target, text, renderLink, onClick }) => (
	<Card.Link
		LinkComponent={LinkComponent}
		className={cx(styles.cardLink, className)}
		to={link}
		target={target}
		renderLink={renderLink}
		onClick={onClick}
	>
		<span className={styles.srOnly}>{text}</span>
	</Card.Link>
);

CardAsLink.propTypes = {
	LinkComponent: PropTypes.elementType,
	className: PropTypes.string,
	link: PropTypes.string.isRequired,
	target: PropTypes.string,
	text: PropTypes.string,
	renderLink: PropTypes.func,
	onClick: PropTypes.func,
};

CardAsLink.defaultProps = {
	LinkComponent: Link,
	className: undefined,
	target: "_self",
	text: undefined,
	renderLink: undefined,
	onClick: undefined,
};

export default CardAsLink;
export { CardAsLink };
