import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { setCookie } from "../../utils/helper";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionWidget } from "./Widget/EmailAcquisitionWidget";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionEmbed } from "./Widget/EmailAcquisitionEmbed";

const viewportBreakpoint = 768;
const offsetTop = 50;
const exitIntentCookieName = "_ei";

const EmailAcquisitionConnector = ({ data, language }) => {
	const [isWidgetOpen, setWidgetOpen] = useState(false);
	const [userEmail, setUserEmail] = useState("");
	const [userGateway, setUserGateway] = useState();
	const [isRegistered, setRegistered] = useState(null); // States: 1 = Saved & Closed UI | 2 = Saved | null = Init
	const [exitIntentActive, setExitIntentActive] = useState(false);
	const exitIntentEnabled = useRef(data?.marketing?.exit !== undefined); // Setting to enable / disable exit intent feature
	const [isCookieBanner, setIsCookieBanner] = useState(false);

	const handleExitDismiss = () => {
		if (exitIntentActive && exitIntentEnabled.current) {
			let expiryDays = 7;
			if (typeof data?.marketing?.exit?.expiryDays === "number") {
				expiryDays = data.marketing.exit.expiryDays;
			}

			// Set cookie date
			const expiration = new Date();
			expiration.setUTCDate(expiration.getUTCDate() + expiryDays);

			const cookieDomain = window.location.hostname.replace(/^www\./, ".");

			setCookie(exitIntentCookieName, "true", "/", cookieDomain, expiration);
			setExitIntentActive(false);
		}
	};

	useEffect(() => {
		/* Initialize if sessionStorage tracking is false */
		if (data?.id) {
			setRegistered(sessionStorage.getItem(`${data.id}-registration`));
		}

		return () => {
			// Clean up Messenger chat class
			const launcher = document?.getElementsByClassName("genesys-app");
			if (launcher.length > 0) {
				launcher[0]?.classList.remove("ea-active");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTimeout(() => {
			const cookieBanner =
				document.getElementsByTagName("body")?.[0]?.classList.value.indexOf("cookieBanner") > -1;
			if (cookieBanner) {
				setIsCookieBanner(true);
			}
		}, 100);
	}, []);

	if (data) {
		if (data?.type === "inline") {
			return (
				<EmailAcquisitionEmbed
					config={data}
					isWidgetOpen={isWidgetOpen}
					setWidgetOpen={setWidgetOpen}
					lang={language}
					userEmail={userEmail}
					setUserEmail={setUserEmail}
					userGateway={userGateway}
					setUserGateway={setUserGateway}
					setRegistered={setRegistered}
					isRegistered={isRegistered}
					viewportBreakpoint={viewportBreakpoint}
					offsetTop={offsetTop}
					setExitIntentActive={setExitIntentActive}
					exitIntentActive={exitIntentActive}
					exitIntentCookieName={exitIntentCookieName}
					exitIntentEnabled={exitIntentEnabled?.current}
					handleExitDismiss={handleExitDismiss}
				/>
			);
		}

		if (data?.type === "default" || data?.type === "exit") {
			return (
				<>
					{(isRegistered === null || isRegistered === "2") && (
						<>
							<EmailAcquisitionWidget
								config={data}
								mode="desktop"
								isWidgetOpen={isWidgetOpen}
								setWidgetOpen={setWidgetOpen}
								lang={language}
								userEmail={userEmail}
								setUserEmail={setUserEmail}
								userGateway={userGateway}
								setUserGateway={setUserGateway}
								setRegistered={setRegistered}
								isRegistered={isRegistered}
								viewportBreakpoint={viewportBreakpoint}
								offsetTop={offsetTop}
								setExitIntentActive={setExitIntentActive}
								exitIntentActive={exitIntentActive}
								exitIntentCookieName={exitIntentCookieName}
								exitIntentEnabled={exitIntentEnabled?.current}
								handleExitDismiss={handleExitDismiss}
							/>
							{!isCookieBanner && (
								<EmailAcquisitionWidget
									config={data}
									mode="mobile"
									isWidgetOpen={isWidgetOpen}
									setWidgetOpen={setWidgetOpen}
									lang={language}
									userEmail={userEmail}
									setUserEmail={setUserEmail}
									userGateway={userGateway}
									setUserGateway={setUserGateway}
									setRegistered={setRegistered}
									isRegistered={isRegistered}
									viewportBreakpoint={viewportBreakpoint}
									offsetTop={offsetTop}
									setExitIntentActive={setExitIntentActive}
									exitIntentActive={exitIntentActive}
									exitIntentCookieName={exitIntentCookieName}
									exitIntentEnabled={exitIntentEnabled?.current}
									handleExitDismiss={handleExitDismiss}
								/>
							)}
						</>
					)}
				</>
			);
		}
	}

	return null;
};

EmailAcquisitionConnector.propTypes = {
	data: PropTypes.object,
	language: PropTypes.oneOf(["en", "fr"]),
};

EmailAcquisitionConnector.defaultProps = {
	data: null,
	language: "en",
};

export default EmailAcquisitionConnector;
export { EmailAcquisitionConnector };
