import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Tabs as TabComponent, Tab } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/Tab/index.css";
import { Icon } from "../Icons";
import * as styles from "./Tabs.module.scss";

const Tabs = ({
	activeKey,
	data,
	theme,
	customTabWrapperClass,
	customActiveClass,
	customPanelClass,
	tabIconLeftComponent,
	tabIconRightComponent,
	paddingVertical,
}) => {
	const tabIconArray = [];

	if (theme) {
		data.forEach(child => {
			if (child?.toggle?.icon && (theme.includes("icon") || theme.includes("custom"))) {
				tabIconArray.push(<Icon name={child.toggle.icon} className={styles[`${theme}Icon`]} />);
			} else {
				tabIconArray.push(null);
			}
		});
	}

	const panelClasses = cx({
		[styles[`${theme}Panel`]]: theme,
		[styles[paddingVertical]]: paddingVertical,
		customActiveClass: customPanelClass,
	});

	return (
		<TabComponent
			activeKey={activeKey}
			className={cx({ [styles[`${theme}Tabs`]]: theme })}
			tabClassName={styles[`${theme}Tab`]}
			activeClassName={cx(styles[`${theme}Active`], customActiveClass)}
			panelClassName={panelClasses}
			tabsWrapperClassName={cx(styles[`${theme}TabsWrapper`], customTabWrapperClass)}
			tabIconComponents={tabIconArray}
			tabIconLeftComponent={tabIconLeftComponent}
			tabIconRightComponent={tabIconRightComponent}
		>
			{data?.map((tab, index) => {
				const key = tab?.key || null;
				return (
					<Tab
						id={key}
						key={key}
						tabKey={`${key}-${index}`}
						eventKey={key}
						title={tab?.toggle?.label?.heading}
						subTitle={tab?.toggle?.label?.subHeading}
						onHandleCustomClick={
							typeof tab?.onHandleCustomClick === "function"
								? () => tab.onHandleCustomClick(key)
								: undefined
						}
						newLabel={theme === "default" || theme === "custom" ? tab?.toggle?.label?.tag : null}
					>
						{tab?.panel}
					</Tab>
				);
			})}
		</TabComponent>
	);
};

Tabs.propTypes = {
	/**
	 * The list of themes that are branded. “<strong>custom</strong>” should be used to create a customized branded theme.
	 */
	theme: PropTypes.oneOf(["default", "generic", "icon", "custom"]),
	paddingVertical: PropTypes.oneOf(["small", "medium", "large"]),
	/**
	 * <i>Optional</i>
	 * The active tab that will be displayed by default. <i>Note: This string must be alphanumeric without whitespaces.</i>
	 */
	activeKey: PropTypes.string,
	/**
	 * <i>Optional</i>
	 * This class is added at the top level of the tab list container.
	 */
	customTabWrapperClass: PropTypes.string,
	/**
	 * <i>Optional</i>
	 * This class is added at the top level of the tab list container.
	 */
	customActiveClass: PropTypes.string,
	/**
	 * <i>Optional</i>
	 * This class is added on the panel contents container.
	 */
	customPanelClass: PropTypes.string,
	/**
	 * <i>Optional</i>
	 * The scrolling <strong>left</strong> icon over-ride. This displays when content doesn't fit the component container width.
	 * If this value is not set, a fallback icon is used by default.
	 */
	tabIconLeftComponent: PropTypes.node,
	/**
	 * <i>Optional</i>The scrolling <strong>right</strong> icon over-ride. This displays when content doesn't fit the component container width.
	 * If this value is not set, a fallback icon is used by default.
	 */
	tabIconRightComponent: PropTypes.node,
	/**
	 *<strong>Object details:</strong>
	 *
	 * ------------------------------------
	 *
	 *A unique identifier for the tab
	 *
	 key:string,
	 *Tab trigger object
	 *
	 toggle: {
	 *
	 *Label options
	 *
	 label: {
	 *
	 *The tab heading is required
	 *
	 heading: string,
	 *<i>Optional:</i>sub heading
	 *
	 subHeading: string,
	 *<i>Optional:</i> tag heading used to grab attention for the default theme
	 *
	 tag: string
	 *
	 *&nbsp;
	 },
	 *
	 *The icon library name can be used
	 *
	 icon: string,
	 *
	 *The icon library name can be used
	 *
	 iconComponent: node
	 *&nbsp;
	 },
	 *
	 *This is the tab content displayed in the panel pane
	 *
	 panel: node,
	 *
	 *<i>Optional:</i>
	 *This function when invoked is triggered before changing tabs, and requires a <i>boolean</i> return value to allow the tab change. By default, it is false, and disables the tab.
	 *
	 onHandleCustomClick: func
	 */

	data: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			toggle: PropTypes.shape({
				label: PropTypes.shape({
					heading: PropTypes.string.isRequired,
					subHeading: PropTypes.string,
					tag: PropTypes.string,
				}).isRequired,
				icon: PropTypes.string,
				iconComponent: PropTypes.node,
			}),
			panel: PropTypes.node.isRequired,
			onHandleCustomClick: PropTypes.func,
		})
	).isRequired,
};

Tabs.defaultProps = {
	theme: "default",
	paddingVertical: "small",
	activeKey: undefined,
	customTabWrapperClass: undefined,
	customActiveClass: undefined,
	customPanelClass: undefined,
	tabIconLeftComponent: undefined,
	tabIconRightComponent: undefined,
};

export default Tabs;
export { Tabs, Tab };
