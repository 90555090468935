import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SEO = ({
	title,
	description,
	author,
	robots,
	keywords,
	revisitAfterNumberOfDays,
	canonicals,
}) => (
	<Helmet>
		{title && (
			<title>
				{title} | {process.env.GATSBY_SITE_NAME}
			</title>
		)}
		{title && <meta property="og:title" content={title} />}
		{title && <meta name="twitter:title" content={title} />}
		{description?.description && <meta name="description" content={description?.description} />}
		{description?.description && (
			<meta property="og:description" content={description?.description} />
		)}
		{description?.description && (
			<meta name="twitter:description" content={description?.description} />
		)}
		{author && <meta name="author" content={author} />}
		{robots && <meta name="robots" content={robots.map(param => param)} />}
		{keywords && <meta name="keywords" content={keywords.map(param => param)} />}
		{revisitAfterNumberOfDays && (
			<meta name="revisit-after" content={`${revisitAfterNumberOfDays} days`} />
		)}
		{canonicals &&
			// eslint-disable-next-line react/no-array-index-key
			canonicals.map((canonical, index) => <link key={index} rel="canonical" href={canonical} />)}
	</Helmet>
);

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.shape({
		description: PropTypes.string,
	}),
	author: PropTypes.string,
	robots: PropTypes.arrayOf(PropTypes.string),
	keywords: PropTypes.arrayOf(PropTypes.string),
	revisitAfterNumberOfDays: PropTypes.number,
	canonicals: PropTypes.arrayOf(PropTypes.string),
};
SEO.defaultProps = {
	title: null,
	description: null,
	author: null,
	keywords: null,
	robots: ["nofollow", "noindex"],
	revisitAfterNumberOfDays: null,
	canonicals: null,
};

export default SEO;
export { SEO };
