import React, { useState } from "react";
import { shape, string, arrayOf, object, oneOf, bool } from "prop-types";
import classNames from "classnames/bind";
// import merge from "lodash.mergewith";
// eslint-disable-next-line import/no-cycle
import { Link } from "../Link";
import { Heading, HeadingAsElement, HeadingSize } from "../../components/Heading";
import * as styles from "./CollapsibleLinksList.module.scss";

let cx = classNames.bind(styles);

const defaultLabels = {
	allCategories: "All Categories",
};

const CollapsibleLinksList = ({
	listHeading,
	listHeadingLevel,
	linksGroup,
	customStyles,
	variant,
	noToggle,
	disabled,
	expanded,
	labels: providedLabels,
}) => {
	const [isVisible, setIsVisible] = useState(expanded);

	const labels = { ...defaultLabels, ...providedLabels };

	cx = classNames.bind({ ...styles, ...customStyles });

	let stateClasses = "";
	let toggledButtonClassed = "";

	if (isVisible === true) {
		stateClasses = "";
		toggledButtonClassed = cx("buttonToggledOff");
	} else {
		stateClasses = cx("isHidden");
		toggledButtonClassed = cx("buttonToggled");
	}

	return (
		<div
			className={cx(
				"CollapsibleLinksListContainer",
				"CollapsibleLinksListContainerCustom",
				`${variant}Custom`,
				variant
			)}
		>
			{(listHeading || !noToggle) && (
				<div className={cx("listContainerNav", "listContainerNavCustom")}>
					{listHeading && (
						<Heading
							as={listHeadingLevel}
							size={noToggle ? HeadingSize.h2 : HeadingSize.h1}
							className={
								noToggle
									? cx(
											"listContainerHeading",
											"listContainerHeadingCustom",
											"headingNoToggle",
											"headingNoToggleCustom"
									  )
									: cx("listContainerHeading", "listContainerHeadingCustom")
							}
						>
							{listHeading}
						</Heading>
					)}
					{!noToggle && (
						<div className={cx(listHeading ? "listContainerMarginTop" : "")}>
							<button
								type="button"
								className={cx("listContainerToggleButtons", "buttonClean", toggledButtonClassed)}
								onClick={() => {
									if (!disabled) setIsVisible(!isVisible);
								}}
							>
								{labels.allCategories}
							</button>
						</div>
					)}
				</div>
			)}
			{linksGroup && (
				<div
					className={
						noToggle
							? cx(
									"listContainer",
									"listContainerCustom",
									"containerNoToggle",
									"containerNoToggleCustom",
									stateClasses
							  )
							: cx("listContainer", "listContainerCustom", stateClasses)
					}
				>
					{variant === "classic" ? (
						<div className={cx("groupContainer")}>
							{linksGroup.map(group => (
								<div key={group.id} className={cx("group", "groupCustom")}>
									<ul className={cx("groupList", "groupListCustom", "listClean")}>
										{group.title && (
											<li>
												<p className={cx("groupHeading", "groupHeadingCustom")}>
													{group.sectionLink ? (
														<Link to={group.sectionLink}>{group.displayTitle}</Link>
													) : (
														group.displayTitle
													)}
												</p>
											</li>
										)}
										{group.entries?.map(entry => (
											<li key={entry.id}>
												{entry.onClick ? (
													<button
														type="button"
														onClick={() => {
															setIsVisible(false);
															entry.onClick();
														}}
													>
														{entry.linkText}
													</button>
												) : (
													entry.linkText && (
														<Link
															to={entry.url && entry.url}
															target={entry.target && entry.target}
															image={entry.image && entry.image}
															imageAltText={entry.imageAltText && entry.imageAltText}
															icon={entry.icon && entry.icon}
														>
															{entry.linkText}
														</Link>
													)
												)}
											</li>
										))}
									</ul>
								</div>
							))}
						</div>
					) : (
						<div className={cx("groupContainer")}>
							{linksGroup.map(group => (
								<div key={group.id} className={cx("group", "groupCustom")}>
									{group.title && (
										<p className={cx("groupHeading", "groupHeadingCustom")}>
											{group.sectionLink ? (
												<Link to={group.sectionLink}>{group.displayTitle}</Link>
											) : (
												group.displayTitle
											)}
										</p>
									)}

									{group.entries && (
										<ul className={cx("groupList", "groupListCustom", "listClean")}>
											{group.entries.map(entry => (
												<li key={entry.id}>
													{entry.onClick ? (
														<button
															type="button"
															onClick={() => {
																setIsVisible(false);
																entry.onClick();
															}}
														>
															{entry.linkText}
														</button>
													) : (
														entry.linkText && (
															<Link
																to={entry.url && entry.url}
																target={entry.target && entry.target}
																image={entry.image && entry.image}
																imageAltText={entry.imageAltText && entry.imageAltText}
																icon={entry.icon && entry.icon}
															>
																{entry.linkText}
															</Link>
														)
													)}
												</li>
											))}
										</ul>
									)}
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

CollapsibleLinksList.propTypes = {
	variant: oneOf(["classic", "simple", "dropdown"]),
	listHeading: string.isRequired,
	listHeadingLevel: oneOf(Object.keys(HeadingAsElement)),
	noToggle: bool,
	linksGroup: arrayOf(
		shape({
			id: string,
			title: string,
			sectionLink: string,
			entries: arrayOf(
				shape({
					id: string,
					linkText: string,
					url: string,
					target: string,
				})
			),
		})
	),
	expanded: bool,
	customStyles: object,
	labels: shape({ allCategories: string }),
	disabled: bool,
};
CollapsibleLinksList.defaultProps = {
	linksGroup: [],
	variant: null,
	noToggle: null,
	expanded: true,
	customStyles: null,
	labels: defaultLabels,
	disabled: false,
	listHeadingLevel: HeadingAsElement.h3,
};

export default CollapsibleLinksList;
export { CollapsibleLinksList };
