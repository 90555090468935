const accents = require("remove-accents");

const addSEOSupportToURL = url => {
	const urlComponents = url.split("/");
	const newURLComponents = [];
	urlComponents.forEach(i => {
		let newURL = i;
		newURL = accents.remove(newURL);
		newURL = newURL.replace(/^\s+|\s+$/g, ""); // Trim leading/trailing spaces
		newURL = newURL.replace(/[-,;_=\s]+/g, "-"); // Convert spaces to hyphens
		newURL = newURL.replace(/[^-\w\s]/g, ""); // Remove unneeded characters
		newURL = newURL.toLowerCase(); // Convert to lowercase
		newURLComponents.push(newURL);
	});
	return newURLComponents.join("/");
};

const getHotelURLRaw = (lang, country, destination, hotel) =>
	`/${lang}/hotel${country && `/${country}`}${destination && `/${destination}`}/${hotel}`;
const getHotelURL = (lang, country, destination, hotel) =>
	addSEOSupportToURL(getHotelURLRaw(lang, country, destination, hotel));

const getThingsToDoDestinationURLRaw = (language, slug) =>
	`/${language}/${language === "fr" ? "activités" : "things-to-do"}/${slug}`;

const getThingsToDoDestinationURL = (slug, destination) =>
	addSEOSupportToURL(getThingsToDoDestinationURLRaw(slug, destination));
const getThingsToDoURLRaw = (language, slug, product) =>
	`${getThingsToDoDestinationURLRaw(language, slug)}/${product}`;
const getThingsToDoURL = (language, slug, product) =>
	addSEOSupportToURL(getThingsToDoURLRaw(language, slug, product));

// Create contct-us slug dynamically
const getSlugByLocal = (pageData, locale) => {
	const lang = locale.slice(0, 2);
	const filterPage = pageData.edges.find(edge => edge.node.node_locale === locale);
	return `/${lang}/${filterPage.node.slug}`;
};

module.exports = {
	addSEOSupportToURL,
	getHotelURLRaw,
	getHotelURL,
	getThingsToDoDestinationURLRaw,
	getThingsToDoDestinationURL,
	getThingsToDoURLRaw,
	getThingsToDoURL,
	getSlugByLocal,
};
