import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

const GatewayField = ({ label, id, selected, error, resetError, onChange }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const [gatewayData, setGatewayData] = useState([]);
	const [query, setQuery] = useState();
	const [isFocus, setFocus] = useState(false);

	/* /////////////////////////////////////////////////////////////
	// Gateway typeahead logic
	///////////////////////////////////////////////////////////// */

	const transformGatewayData = _gateways => {
		// Transform data from API response to type-ahead data format
		let data = [];

		_gateways.forEach(gateway => {
			data.push({
				value: gateway.code,
				label: gateway.name,
				heading: gateway.location.split(",")[0],
				search: gateway.location,
				id: gateway.name,
			});
		});

		// Sort gateways by location
		data = data.sort((a, b) => {
			const sortMethod = (a.heading > b.heading) - (b.heading > a.heading);
			return sortMethod;
		});

		const flatArray = [...data];

		// Remove duplicate headings
		let previousGateway = null;
		flatArray.forEach(gateway => {
			if (gateway.search === previousGateway) {
				// eslint-disable-next-line no-param-reassign
				gateway.heading = "";
			}

			previousGateway = gateway.search;
		});

		const exportArray = [];
		let tempArray = [];
		let heading = "";
		let previousHeading = "";

		flatArray.forEach(faItem => {
			if (previousHeading !== faItem.heading && faItem.heading !== "") {
				// Save the last group
				exportArray.push({
					title: heading,
					label: "",
					value: "",
					search: heading,
					items: [...tempArray],
				});

				// Reset temp vars
				tempArray = [];

				// Start the new group
				heading = faItem.heading;
			}

			tempArray.push(faItem);

			previousHeading = faItem.heading;
		});

		// Handle last array item
		exportArray.push({
			title: heading,
			label: "",
			value: "",
			search: heading,
			className: "headingOption",
			items: [...tempArray],
		});

		return exportArray.filter(array => array.items.length > 0);
	};

	const loadGatewayData = async () => {
		const apiHeaders = new Headers();

		const apiConfig = {
			method: "GET",
			headers: apiHeaders,
		};

		const api = `${process.env.GATSBY_INTAIR_API_BASE}`;
		const apiRequest = new Request(`${api}${query}`);

		return fetch(apiRequest, apiConfig)
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(res => {
				resetError(true);
				return res;
			})
			.catch(err => {
				resetError(true);
				console.error("Failed to load gateways: ", err);
				return null;
			});
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Form initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		// Initial component load sequence
		(async () => {
			let gateways;

			if (query?.length > 2) {
				gateways = await loadGatewayData();
			}

			if (gateways?.length > 0) {
				setGatewayData(transformGatewayData(gateways));
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				taStyles.wide,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: taStyles.open,
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={gatewayData}
			selectedItem={selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onInput={textEntered => {
				setQuery(textEntered);
			}}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null) {
					onChange(selection);
					resetError(true);
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

GatewayField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
};

GatewayField.defaultProps = {
	label: undefined,
	selected: undefined,
	error: false,
	resetError: () => {},
};

export default GatewayField;
export { GatewayField };
