// extracted by mini-css-extract-plugin
export var contact = "Contact-module--contact--3nGhf";
export var contactMap = "Contact-module--contactMap--1hKck";
export var contactMapLink = "Contact-module--contactMapLink--2NlHc";
export var contactMapIcon = "Contact-module--contactMapIcon--29lZU";
export var contactHoursItem = "Contact-module--contactHoursItem--2ljpW";
export var contactHours = "Contact-module--contactHours--2lNmz";
export var contactNote = "Contact-module--contactNote--aMJ8t";
export var weekDays = "Contact-module--weekDays--3_tAd";
export var contactPhonesLink = "Contact-module--contactPhonesLink--3tqz2";
export var secondary = "Contact-module--secondary--2PFq1";
export var contactTitle = "Contact-module--contactTitle--1ilU6";
export var contactPhones = "Contact-module--contactPhones--2oozg";
export var googleReviewBtn = "Contact-module--googleReviewBtn--2SXxa";
export var icon = "Contact-module--icon--3rkVO";
export var content = "Contact-module--content--3Ti1U";