/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Paragraph } from "../../components/Paragraph/Paragraph";

const ParagraphConnector = ({ data }) => (
	<Paragraph
		heading={data.heading}
		headingAs={data.headingAs ?? undefined}
		headingSize={data.headingSize ?? undefined}
		subHeading={data.subheading}
		subHeadingAs={data.subheadingAs ?? undefined}
		subHeadingSize={data.subheadingSize ?? undefined}
		content={data.content}
		alignment={data.alignment}
	/>
);

ParagraphConnector.propTypes = {
	data: PropTypes.shape({
		heading: PropTypes.string,
		headingAs: PropTypes.string,
		headingSize: PropTypes.string,
		subheading: PropTypes.string,
		subheadingAs: PropTypes.string,
		subheadingSize: PropTypes.string,
		alignment: PropTypes.oneOf(["left", "center", "right"]),
		content: PropTypes.shape({
			raw: PropTypes.string.isRequired,
			references: PropTypes.array,
		}),
	}).isRequired,
};

export default ParagraphConnector;
export { ParagraphConnector };
