// extracted by mini-css-extract-plugin
export var adModule = "AdModule-module--adModule--yHtbw";
export var adModuleImage = "AdModule-module--adModuleImage--1_fRE";
export var adModuleCardLink = "AdModule-module--adModuleCardLink--2JTtP";
export var adModuleBody = "AdModule-module--adModuleBody--1QPPO";
export var adModuleImageWrapper = "AdModule-module--adModuleImageWrapper--3Kv9b";
export var adModuleHeading = "AdModule-module--adModuleHeading--12Acm";
export var adModuleSubheading = "AdModule-module--adModuleSubheading--25dP9";
export var flight = "AdModule-module--flight--1ThLE";
export var adModuleRatingsContainer = "AdModule-module--adModuleRatingsContainer--3Knge";
export var adModuleDetailsStars = "AdModule-module--adModuleDetailsStars--2hx18";
export var adModuleDetailsDays = "AdModule-module--adModuleDetailsDays--1vQwT";
export var adModuleDetailsPrice = "AdModule-module--adModuleDetailsPrice--3f5Ju";
export var adModuleDetailsSave = "AdModule-module--adModuleDetailsSave--2XoTq";
export var adModuleDetailsAmount = "AdModule-module--adModuleDetailsAmount--q4WWr";
export var adModuleDetailsWas = "AdModule-module--adModuleDetailsWas--3yDTw";
export var adModuleDetailsTax = "AdModule-module--adModuleDetailsTax--184b9";
export var adModuleButtonsContainer = "AdModule-module--adModuleButtonsContainer--1KJXs";
export var adModuleButtonsLink = "AdModule-module--adModuleButtonsLink--WX_aM";
export var adModuleButtonsLinkText = "AdModule-module--adModuleButtonsLinkText--3juUk";
export var icon = "AdModule-module--icon--3NhFJ";
export var adModuleContentContainer = "AdModule-module--adModuleContentContainer--3hHCz";
export var hotel = "AdModule-module--hotel--Xhuwh";