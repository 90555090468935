import React from "react";
import PropTypes from "prop-types";
import { SEO } from "../../components/SEO/SEO";

const SEOConnector = ({ metaData }) => (
	<SEO
		title={metaData.title}
		description={metaData.description}
		author={metaData.author}
		robots={metaData.robots}
		keywords={metaData.keywords}
		revisitAfterNumberOfDays={metaData.revisitAfterNumberOfDays}
		canonicals={metaData.canonicals}
	/>
);

SEOConnector.propTypes = {
	metaData: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.object,
		author: PropTypes.string,
		robots: PropTypes.arrayOf(PropTypes.string),
		keywords: PropTypes.arrayOf(PropTypes.string),
		revisitAfterNumberOfDays: PropTypes.number,
		canonicals: PropTypes.arrayOf(PropTypes.string),
	}),
};
SEOConnector.defaultProps = {
	metaData: null,
};

export default SEOConnector;
export { SEOConnector };
