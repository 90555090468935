import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import * as dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import en from "rc-picker/lib/locale/en_US";
import fr from "rc-picker/lib/locale/fr_FR";
import { RangePicker as RCRangePicker } from "rc-picker";
import { Icon } from "../../../../Icons";
import * as styles from "./styles.module.scss";

const RangePicker = React.forwardRef(
	(
		{
			inputRef,
			locale,
			format,
			className,
			selectedDates,
			minDate,
			maxDate,
			disabled,
			onFocus,
			onBlur,
			onChange,
			onOpen,
		},
		ref
	) => {
		const [isOpened, setIsOpened] = useState(false);
		const rangePickerRef = useRef(ref);

		useEffect(() => {
			if (isOpened) {
				disableBodyScroll(rangePickerRef.current);
				onOpen(true);
			} else {
				clearAllBodyScrollLocks();
				onOpen(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isOpened, ref]);

		return (
			<RCRangePicker
				ref={rangePickerRef}
				prefixCls={styles.swgPicker}
				generateConfig={dayjsGenerateConfig}
				picker="date"
				mode={["date", "date"]}
				locale={locale === "fr" ? { ...fr, monthFormat: "MMMM" } : { ...en, monthFormat: "MMMM" }}
				format={format}
				className={cx(className, styles.inputGroup, isOpened && styles.inputGroupOpen)}
				value={
					selectedDates || [
						dayjs().startOf("day").add(1, "days"),
						dayjs().startOf("day").add(8, "days"),
					]
				}
				open={isOpened}
				disabled={disabled}
				onFocus={onFocus}
				onBlur={onBlur}
				onCalendarChange={onChange}
				onOpenChange={open => {
					setIsOpened(open);
					if (open) {
						onFocus();
					}
				}}
				getPopupContainer={() => inputRef?.current}
				disabledDate={current =>
					current.isBefore(minDate || dayjs().startOf("day")) ||
					current.isAfter(maxDate || dayjs().startOf("day").add(365, "days"))
				}
				allowClear={false}
				inputReadOnly={true}
				clearIcon={null}
				separator="–"
				suffixIcon={
					<Icon
						name="calendar-alt"
						className={styles.calendarIcon}
						onClick={() => {
							if (!disabled) {
								setIsOpened(!isOpened);
								if (!isOpened) {
									onFocus();
								}
							}
						}}
					/>
				}
				prevIcon={
					<Icon
						name="arrow-left"
						className={cx(styles.navigationIcon, styles.navigationIconPrev)}
					/>
				}
				nextIcon={
					<Icon
						name="arrow-right"
						className={cx(styles.navigationIcon, styles.navigationIconNext)}
					/>
				}
			/>
		);
	}
);

RangePicker.propTypes = {
	inputRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	locale: PropTypes.oneOf(["en", "fr"]),
	format: PropTypes.string,
	className: PropTypes.string,
	selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onOpen: PropTypes.func,
};

RangePicker.defaultProps = {
	locale: "en",
	format: "D MMM YYYY",
	className: undefined,
	selectedDates: undefined,
	minDate: undefined,
	maxDate: undefined,
	disabled: false,
	onFocus: () => {},
	onBlur: () => {},
	onOpen: () => {},
};

export default RangePicker;
export { RangePicker };
