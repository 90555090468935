import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Radio } from "./Radio";
import * as styles from "./Radio.module.scss";

const RadioGroup = ({ className, inline, options, name, selectedOption, error, onChange }) => (
	<div className={cx(className, inline ? styles.radiosInline : styles.radiosStacked)}>
		{options.map(option => (
			<Radio
				key={option.label}
				className={styles.radioWrapper}
				label={option.label}
				name={name}
				id={option.id}
				value={option.value}
				selected={selectedOption === option.value}
				error={error}
				onChange={onChange}
			/>
		))}
	</div>
);

RadioGroup.propTypes = {
	className: PropTypes.string,
	inline: PropTypes.bool,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedOption: PropTypes.string,
	error: PropTypes.bool,
	onChange: PropTypes.func,
};

RadioGroup.defaultProps = {
	className: undefined,
	inline: false,
	selectedOption: undefined,
	error: false,
	onChange: () => {},
};

export default RadioGroup;
export { RadioGroup };
