import React from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import { Link as LinkComponent } from "../../sharedComponents/Link";
import * as styles from "./Link.module.scss";

const Link = ({ to, target, image, imageAltText, children, onClick, className }) => (
	<LinkComponent
		to={to}
		target={target}
		image={image}
		imageStyle={styles.img}
		imageAltText={imageAltText}
		onClick={onClick}
		className={className}
	>
		{children}
	</LinkComponent>
);

Link.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	image: PropTypes.arrayOf(
		PropTypes.shape({
			secure_url: PropTypes.string,
			width: PropTypes.string,
			height: PropTypes.string,
			raw_transformation: PropTypes.string,
			version: PropTypes.string,
			public_id: PropTypes.string,
			format: PropTypes.string,
		})
	),
	imageAltText: PropTypes.string,
	to: PropTypes.string.isRequired,
	target: PropTypes.string,
	onClick: PropTypes.func,
	className: PropTypes.string,
};
Link.defaultProps = {
	image: undefined,
	imageAltText: undefined,
	target: undefined,
	onClick: undefined,
	className: undefined,
};
export default Link;
export { Link };
