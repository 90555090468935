// extracted by mini-css-extract-plugin
export var searchForm = "Flights-module--searchForm--3S_01";
export var hideDDButton = "Flights-module--hideDDButton--3IdWw";
export var formRow = "Flights-module--formRow--1vT53";
export var flightMode = "Flights-module--flightMode--3jHiA";
export var flightToggle = "Flights-module--flightToggle--ZQpSi";
export var formRowWrapper = "Flights-module--formRowWrapper--1ACUU";
export var single = "Flights-module--single--3mbgo";
export var staticRow = "Flights-module--staticRow--FSaTH";
export var dynamicRow = "Flights-module--dynamicRow--kM97b";
export var formColumn = "Flights-module--formColumn--3c5_j";
export var multiple = "Flights-module--multiple--2L4W-";
export var addRow = "Flights-module--addRow--3jm-D";
export var removeRow = "Flights-module--removeRow--1b1Ob";
export var searchButton = "Flights-module--searchButton--1S3NK";
export var popover = "Flights-module--popover--2-28p";
export var AgeSelect = "Flights-module--AgeSelect--1HGVL";
export var AgeSelectSecond = "Flights-module--AgeSelectSecond--1bNMl";
export var childAges = "Flights-module--childAges--BP1B6";
export var description = "Flights-module--description--2tYqd";