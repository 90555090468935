import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useModal as useModalHook } from "@sunwing/shared-components";
import { containerSize } from "./Modal";
import { Icon } from "../Icons";
import * as styles from "./Modal.module.scss";

const useModal = () => {
	const { setIsOpen, Modal: ModalComponent } = useModalHook();

	const Modal = ({
		children,
		className,
		overlayClassName,
		closeClassName,
		container,
		isPadding,
	}) => (
		<ModalComponent
			className={cx(styles.modal, className)}
			overlayClassName={cx(styles.overlay, overlayClassName)}
		>
			<ModalComponent.Content
				className={cx(
					styles.content,
					container || containerSize.lg,
					!isPadding ? styles.noPadding : ""
				)}
			>
				{children}
			</ModalComponent.Content>
			<ModalComponent.Close className={cx(styles.close, closeClassName)}>
				<Icon name="close--n" />
			</ModalComponent.Close>
		</ModalComponent>
	);
	Modal.Content = ModalComponent.Content;
	Modal.Close = ModalComponent.Close;
	Modal.Trigger = ModalComponent.Trigger;

	Modal.propTypes = {
		children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
		className: PropTypes.string,
		overlayClassName: PropTypes.string,
		container: PropTypes.oneOf([Object.keys(containerSize)]),
		closeClassName: PropTypes.string,
		isPadding: PropTypes.bool,
	};
	Modal.defaultProps = {
		className: undefined,
		overlayClassName: undefined,
		container: containerSize.lg,
		closeClassName: undefined,
		isPadding: true,
	};
	return { setIsOpen, Modal };
};

export default useModal;
export { useModal };
