// extracted by mini-css-extract-plugin
export var sovIcon = "Footer-module--sov-icon--1FTWv";
export var sovIconAdd = "Footer-module--sov-icon-add--3ggI2";
export var sovIconAlertO = "Footer-module--sov-icon-alert--o--3URiV";
export var sovIconAlert = "Footer-module--sov-icon-alert--uZo4K";
export var sovIconArrowDouble = "Footer-module--sov-icon-arrow-double--33bhF";
export var sovIconArrowLeft = "Footer-module--sov-icon-arrow-left--3v1KE";
export var sovIconArrowRight = "Footer-module--sov-icon-arrow-right--3c3U6";
export var sovIconArrowThickDown = "Footer-module--sov-icon-arrow-thick-down--180Ao";
export var sovIconArrowThickUp = "Footer-module--sov-icon-arrow-thick-up--1gCgK";
export var sovIconCalendarAlt = "Footer-module--sov-icon-calendar-alt--10dTg";
export var sovIconCalendar = "Footer-module--sov-icon-calendar--1CFFj";
export var sovIconCheck = "Footer-module--sov-icon-check--QD4Fd";
export var sovIconChecked = "Footer-module--sov-icon-checked--34_ze";
export var sovIconCheckmark = "Footer-module--sov-icon-checkmark--UH9Lb";
export var sovIconCloseN = "Footer-module--sov-icon-close--n--3HWhE";
export var sovIconClose = "Footer-module--sov-icon-close--jK2BY";
export var sovIconCruiseAlt = "Footer-module--sov-icon-cruise-alt--1PiPS";
export var sovIconCruise = "Footer-module--sov-icon-cruise--229ko";
export var sovIconEurope = "Footer-module--sov-icon-europe--2xp6_";
export var sovIconExternal = "Footer-module--sov-icon-external--3Q6Gu";
export var sovIconFacebook = "Footer-module--sov-icon-facebook--e8YP_";
export var sovIconFaq = "Footer-module--sov-icon-faq--HtXpY";
export var sovIconFavorite = "Footer-module--sov-icon-favorite--D0WLI";
export var sovIconFilters = "Footer-module--sov-icon-filters--3AlN4";
export var sovIconFlight = "Footer-module--sov-icon-flight--Oqo3o";
export var sovIconFlights = "Footer-module--sov-icon-flights--2Clre";
export var sovIconGlobe = "Footer-module--sov-icon-globe--2iZAF";
export var sovIconHotel = "Footer-module--sov-icon-hotel--1WJTL";
export var sovIconInclusive = "Footer-module--sov-icon-inclusive--ZZeAT";
export var sovIconInformation = "Footer-module--sov-icon-information--3fRUW";
export var sovIconInstagram = "Footer-module--sov-icon-instagram--YVfyz";
export var sovIconLastmin = "Footer-module--sov-icon-lastmin--nKd_D";
export var sovIconMap = "Footer-module--sov-icon-map--3YLuX";
export var sovIconMenu = "Footer-module--sov-icon-menu--3jXrd";
export var sovIconMinusN = "Footer-module--sov-icon-minus--n--1Yj5S";
export var sovIconParking = "Footer-module--sov-icon-parking--326UF";
export var sovIconPhone = "Footer-module--sov-icon-phone--1vUIp";
export var sovIconPinterest = "Footer-module--sov-icon-pinterest--2Ufqj";
export var sovIconPlusN = "Footer-module--sov-icon-plus--n--3ur9Q";
export var sovIconStarHalf = "Footer-module--sov-icon-star-half--3e--z";
export var sovIconStar = "Footer-module--sov-icon-star--PbnqK";
export var sovIconTimer = "Footer-module--sov-icon-timer--3IxHq";
export var sovIconTravel = "Footer-module--sov-icon-travel--3sFe-";
export var sovIconTwitter = "Footer-module--sov-icon-twitter--2EY-5";
export var sovIconUplift = "Footer-module--sov-icon-uplift--1vr_W";
export var sovIconVideo = "Footer-module--sov-icon-video--iquBL";
export var sovIconYoutube = "Footer-module--sov-icon-youtube--1rFwB";
export var sovIconSearch = "Footer-module--sov-icon-search--K2Wfx";
export var sovIconComplaints = "Footer-module--sov-icon-complaints--fRv8C";
export var sovIconCustomerAssistance = "Footer-module--sov-icon-customer-assistance--2hhTR";
export var sovIconReview = "Footer-module--sov-icon-review--34mWd";
export var sovIconEmailO = "Footer-module--sov-icon-email--o--x2-DL";
export var sovIconRefreshO = "Footer-module--sov-icon-refresh--o--mVMOa";
export var sovIconArrowThickRight = "Footer-module--sov-icon-arrow-thick-right--BobLk";
export var sovIconAtm = "Footer-module--sov-icon-atm--1QNs4";
export var sovIconBeach = "Footer-module--sov-icon-beach--1QFK-";
export var sovIconBusiness = "Footer-module--sov-icon-business--1lkYM";
export var sovIconDoctor = "Footer-module--sov-icon-doctor--3Ym-k";
export var sovIconGolf = "Footer-module--sov-icon-golf--bnGL2";
export var sovIconGroupsconference = "Footer-module--sov-icon-groupsconference--3gfv1";
export var sovIconGym = "Footer-module--sov-icon-gym--2eR7x";
export var sovIconHealthsaftey = "Footer-module--sov-icon-healthsaftey--27DQ5";
export var sovIconIron = "Footer-module--sov-icon-iron--3k9Fi";
export var sovIconKids = "Footer-module--sov-icon-kids--qknFD";
export var sovIconMinibar = "Footer-module--sov-icon-minibar--1hQ1y";
export var sovIconNightclub = "Footer-module--sov-icon-nightclub--36Ktx";
export var sovIconSafe = "Footer-module--sov-icon-safe--2IilE";
export var sovIconShowers = "Footer-module--sov-icon-showers--2qsH6";
export var sovIconSnorkelling = "Footer-module--sov-icon-snorkelling--2wuYZ";
export var sovIconSpa = "Footer-module--sov-icon-spa--1viBF";
export var sovIconSportsrecreation = "Footer-module--sov-icon-sportsrecreation--2yMWc";
export var sovIconSteambath = "Footer-module--sov-icon-steambath--4XLX_";
export var sovIconSunloungers = "Footer-module--sov-icon-sunloungers--1Fatv";
export var sovIconSwimmingpool = "Footer-module--sov-icon-swimmingpool--1LJY2";
export var sovIconWaterpark = "Footer-module--sov-icon-waterpark--388Hy";
export var sovIconWifi = "Footer-module--sov-icon-wifi--3R4IE";
export var sovIconAccommodation = "Footer-module--sov-icon-accommodation--3pvsn";
export var sovIconAmenities = "Footer-module--sov-icon-amenities--1g-VV";
export var sovIconAmericanexpress = "Footer-module--sov-icon-americanexpress--2yycs";
export var sovIconDestination = "Footer-module--sov-icon-destination--21Kli";
export var sovIconEdit = "Footer-module--sov-icon-edit--2wr5R";
export var sovIconFaq1 = "Footer-module--sov-icon-faq1--lsGs1";
export var sovIconHotelpolicies = "Footer-module--sov-icon-hotelpolicies--Z843R";
export var sovIconHotels = "Footer-module--sov-icon-hotels--1hz6G";
export var sovIconLowestprice = "Footer-module--sov-icon-lowestprice--1kqbf";
export var sovIconMastercard = "Footer-module--sov-icon-mastercard--2Bcoo";
export var sovIconMeals = "Footer-module--sov-icon-meals--3XcLP";
export var sovIconOverview = "Footer-module--sov-icon-overview--1GsR0";
export var sovIconPorts = "Footer-module--sov-icon-ports--3fV8b";
export var sovIconPreorder = "Footer-module--sov-icon-preorder--2Ah4i";
export var sovIconSort = "Footer-module--sov-icon-sort--eoR9m";
export var sovIconSunny = "Footer-module--sov-icon-sunny--1AWRG";
export var sovIconTrophy = "Footer-module--sov-icon-trophy--3CXtG";
export var sovIconVisa = "Footer-module--sov-icon-visa--23yhe";
export var sovIconPricematchpolicy = "Footer-module--sov-icon-pricematchpolicy--1uG1U";
export var sovIconDeals = "Footer-module--sov-icon-deals--39HSD";
export var sovIconArcade = "Footer-module--sov-icon-arcade--2Hmbr";
export var sovIconBowling = "Footer-module--sov-icon-bowling--1YRMn";
export var sovIconCasino = "Footer-module--sov-icon-casino--3l81q";
export var sovIconEntertain = "Footer-module--sov-icon-entertain--3QUa3";
export var sovIconFacilites = "Footer-module--sov-icon-facilites--3tWXl";
export var sovIconLaundry = "Footer-module--sov-icon-laundry--3Cqqf";
export var sovIconLobby = "Footer-module--sov-icon-lobby--2nscF";
export var sovIconOtherentertainment = "Footer-module--sov-icon-otherentertainment--3rThW";
export var sovIconShop = "Footer-module--sov-icon-shop--21-Rj";
export var sovIconTheatre = "Footer-module--sov-icon-theatre--3aM-v";
export var sovIconTransfers = "Footer-module--sov-icon-transfers--oUsh-";
export var footer = "Footer-module--footer--3mBiF";
export var footerMobilePadding = "Footer-module--footerMobilePadding--1Hd3G";
export var footerSections = "Footer-module--footerSections--33_JS";
export var footerSection = "Footer-module--footerSection--1bAU6";
export var footerSectionTitle = "Footer-module--footerSectionTitle--2gQZp";
export var footerSectionContent = "Footer-module--footerSectionContent---Vut0";
export var footerSites = "Footer-module--footerSites--3Jz79";
export var footerSites__title = "Footer-module--footerSites__title--2_uN0";
export var footerCopyright = "Footer-module--footerCopyright--2qge0";
export var footerCorps = "Footer-module--footerCorps--1QfI5";
export var footerCorpsImage = "Footer-module--footerCorpsImage--GtDQ1";
export var footerBottom = "Footer-module--footerBottom--3pMYV";
export var footerLang = "Footer-module--footerLang--oQxR8";
export var footerLangIcon = "Footer-module--footerLangIcon--vI-JM";
export var social = "Footer-module--social--1qeaN";
export var socialTitle = "Footer-module--socialTitle--3M1L3";
export var socialNav = "Footer-module--socialNav--2CXtT";
export var footerLinks = "Footer-module--footerLinks--2gkQs";
export var langToggleCurrent = "Footer-module--langToggleCurrent--1-Qrl";
export var langToggleAlt = "Footer-module--langToggleAlt--1WcXX";
export var activeLink = "Footer-module--activeLink---sxEw";
export var tabLabel = "Footer-module--tabLabel--1QAXZ";
export var tab = "Footer-module--tab--4Dixk";
export var tabLabelChevron = "Footer-module--tabLabelChevron--3AM2f";
export var tabContent = "Footer-module--tabContent--3LP3a";
export var toTop = "Footer-module--toTop--3RGqL";