import React from "react";
import { shape, any, bool, string } from "prop-types";
import classNames from "classnames/bind";
import { Icon } from "../../../../components/Icons/Icons";
import * as styles from "./SliderNavigation.module.scss";

const cx = classNames.bind(styles);

const SliderNavigation = ({ swiperRefLoaded, swiperRef, navigationClasses }) => {
	const handlePrevSlide = () => {
		if (swiperRefLoaded && swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	const handleNextSlide = () => {
		if (swiperRefLoaded && swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	return (
		<>
			<button
				className={cx(
					"swiper-button-prev",
					"button",
					"buttonPrev",
					navigationClasses.button,
					navigationClasses.prevButton
				)}
				type="button"
				onClick={handlePrevSlide}
			>
				<Icon name="arrow-left" />
			</button>
			<button
				className={cx(
					"swiper-button-next",
					"button",
					"buttonNext",
					navigationClasses.button,
					navigationClasses.nextButton
				)}
				type="button"
				onClick={handleNextSlide}
			>
				<Icon name="arrow-right" />
			</button>
		</>
	);
};

SliderNavigation.propTypes = {
	swiperRefLoaded: bool.isRequired,
	swiperRef: shape({ current: any }).isRequired,
	navigationClasses: shape({
		button: string,
		prevButton: string,
		nextButton: string,
	}).isRequired,
};

export default SliderNavigation;
export { SliderNavigation };
