import { LOCAL_FR } from "../../const/const";

const FormattedMoney = ({ number, local }) => {
	switch (local) {
		case LOCAL_FR:
			return `${number}$`;
		default:
			return `$${number}`;
	}
};

export default FormattedMoney;
export { FormattedMoney };
