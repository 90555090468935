import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import DesktopWidget from "./Desktop/Desktop";
// eslint-disable-next-line import/no-cycle
import MobileWidget from "./Mobile/Mobile";

const EmailAcquisitionWidget = ({
	config,
	mode,
	isWidgetOpen,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	offsetTop,
	setExitIntentActive,
	exitIntentActive,
	exitIntentCookieName,
	exitIntentEnabled,
	handleExitDismiss,
}) => {
	let renderedComponent = null;

	if (mode === "desktop") {
		renderedComponent = (
			<DesktopWidget
				embed={false}
				config={config}
				mode={mode}
				isWidgetOpen={isWidgetOpen}
				setWidgetOpen={setWidgetOpen}
				lang={lang}
				userEmail={userEmail}
				setUserEmail={setUserEmail}
				userGateway={userGateway}
				setUserGateway={setUserGateway}
				setRegistered={setRegistered}
				isRegistered={isRegistered}
				viewportBreakpoint={viewportBreakpoint}
				setExitIntentActive={setExitIntentActive}
				exitIntentActive={exitIntentActive}
				exitIntentCookieName={exitIntentCookieName}
				exitIntentEnabled={exitIntentEnabled}
				handleExitDismiss={handleExitDismiss}
			/>
		);
	} else if (mode === "mobile") {
		renderedComponent = (
			<MobileWidget
				embed={false}
				config={config}
				mode={mode}
				isWidgetOpen={isWidgetOpen}
				setWidgetOpen={setWidgetOpen}
				lang={lang}
				userEmail={userEmail}
				setUserEmail={setUserEmail}
				userGateway={userGateway}
				setUserGateway={setUserGateway}
				setRegistered={setRegistered}
				isRegistered={isRegistered}
				viewportBreakpoint={viewportBreakpoint}
				offsetTop={offsetTop}
				setExitIntentActive={setExitIntentActive}
				exitIntentActive={exitIntentActive}
				exitIntentCookieName={exitIntentCookieName}
				exitIntentEnabled={exitIntentEnabled}
				handleExitDismiss={handleExitDismiss}
			/>
		);
	}

	return renderedComponent;
};

EmailAcquisitionWidget.propTypes = {
	config: PropTypes.object.isRequired,
	mode: PropTypes.string.isRequired,
	isWidgetOpen: PropTypes.bool.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	offsetTop: PropTypes.number.isRequired,
	setExitIntentActive: PropTypes.func.isRequired,
	exitIntentCookieName: PropTypes.string.isRequired,
	exitIntentEnabled: PropTypes.bool.isRequired,
	handleExitDismiss: PropTypes.func.isRequired,
};

EmailAcquisitionWidget.defaultProps = {
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
};

export default EmailAcquisitionWidget;
export { EmailAcquisitionWidget };
