const LOCAL_EN = "en-CA";
const LOCAL_FR = "fr-CA";
const RCL_LOCALE_EN = "en-US";
const RCL_LOCALE_FR = "fr";
const URL_LOCALE_EN = "en";
const URL_LOCALE_FR = "fr";
const DEFAULT_GATEWAY_EN = "YYZ";
const DEFAULT_GATEWAY_FR = "YUL";
const AD_MODULE_OFFER_TYPE_PACKAGE = "Package";
const AD_MODULE_OFFER_TYPE_RETURN_FLIGHT = "Flight";
const AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT = "FlightOneWay";
const LOCAL_STORAGE_KEY_PREFERRED_GATEWAY = "preferredGateway";
const DAYS_NAMES = {
	en: {
		monday: "Mon",
		tuesday: "Tue",
		wednesday: "Wed",
		thursday: "Thu",
		friday: "Fri",
		saturday: "Sat",
		sunday: "Sun",
	},
	fr: {
		monday: "lun",
		tuesday: "mar",
		wednesday: "mer",
		thursday: "jeu",
		friday: "ven",
		saturday: "sam",
		sunday: "dim",
	},
};
const FUNCTIONS_PATH = "/.netlify/functions";
const TEMPLATES = {
	FLUID_PAGE: "pages",
	HOME_PAGE: "homepage",
	UI_PAGE: "ui",
	ALERTS_PAGE: "alerts",
	CRUISE_RESULTS_PAGE: "cruise-results",
	AFFILIATES_PAGE: "affiliates",
	ERROR_PAGE: "error",
};

const ITEMS_PER_ROW = [2, 3, 4, 5, 6];
const NAVIGATION_TYPE = [true, false, "desktopOnly"];
const PAGINATION_TYPE = [true, false, "desktopOnly"];
const CARD_VARIANT = [
	"primary",
	"primary--condensed",
	"primary--horizontal",
	"info",
	"info--horizontal",
];

module.exports = {
	LOCAL_EN,
	LOCAL_FR,
	DEFAULT_GATEWAY_EN,
	DEFAULT_GATEWAY_FR,
	AD_MODULE_OFFER_TYPE_PACKAGE,
	AD_MODULE_OFFER_TYPE_RETURN_FLIGHT,
	AD_MODULE_OFFER_TYPE_ONE_WAY_FLIGHT,
	LOCAL_STORAGE_KEY_PREFERRED_GATEWAY,
	DAYS_NAMES,
	FUNCTIONS_PATH,
	RCL_LOCALE_FR,
	RCL_LOCALE_EN,
	URL_LOCALE_FR,
	URL_LOCALE_EN,
	TEMPLATES,
	ITEMS_PER_ROW,
	NAVIGATION_TYPE,
	PAGINATION_TYPE,
	CARD_VARIANT,
};
