const encodeURL = URL =>
	URL.split("/")
		.map(i => encodeURIComponent(i))
		.join("/");
const CloudinaryImageAdapter = ({
	cloudinaryImage,
	maxWidth = 600,
	sizes,
	title,
	desc,
	aspectRatio,
	// https://cloudinary.com/documentation/resizing_and_cropping#automatic_cropping_g_auto
	disableAutoGravity = false,
	transformations: customTransformations,
	preLoad = false,
	imageQuality,
}) => {
	if (!cloudinaryImage) return { src: "" };
	const srcSetPoints = imageQuality || [0.25, 0.5, 1, 2];
	let transformations = customTransformations || cloudinaryImage?.raw_transformation || "c_lfill";

	const transformGravity = transformations.indexOf("g_") >= 0;
	if (!transformGravity && !disableAutoGravity) {
		transformations += ",g_auto";
		if (transformations.indexOf("c_") < 0) {
			transformations += ",c_lfill";
		}
	}

	let width;
	let transformationsAspectRatio;

	// Calculate width, height, aspect ratios
	try {
		const transformPredefinedWidth = transformations?.match(/w_[0-9]*/g);
		const transformPrefixes = transformations?.match(/t.*[0-9]*x[0-9]*/g);

		// Predefined takes precedence over prefixes
		if (transformPredefinedWidth) {
			width = parseInt(transformPredefinedWidth[0].substring(2), 10);

			const transformPredefinedHeight = transformations?.match(/h_[0-9]*/g);
			if (transformPredefinedHeight) {
				const _height = parseInt(transformPredefinedHeight[0].substring(2), 10);
				transformationsAspectRatio = width / _height;
			} else {
				// Use initial image's aspect ratio
				transformationsAspectRatio = cloudinaryImage.width / cloudinaryImage.height;

				if (width > maxWidth) {
					width = maxWidth;
				}
			}
		} else if (transformPrefixes) {
			const dimensions = transformPrefixes[0].match(/\d+x\d+/g)[0]?.split("x");
			const _height = parseInt(dimensions[1], 10);

			width = parseInt(dimensions[0], 10);
			if (_height === 0) {
				transformationsAspectRatio = cloudinaryImage.width / cloudinaryImage.height;
			} else {
				transformationsAspectRatio = width / _height;
			}
			if (width > cloudinaryImage.width) {
				width = cloudinaryImage.width;
			}

			if (width >= maxWidth) {
				width = maxWidth;
			}
		} else {
			width = cloudinaryImage.width;
			transformationsAspectRatio = width / cloudinaryImage.height;

			if (width > cloudinaryImage.width) {
				width = cloudinaryImage.width;
			}

			if (width >= maxWidth) {
				width = maxWidth;
			}
		}

		width = parseInt(width, 10);
		if (aspectRatio) {
			transformationsAspectRatio = aspectRatio;
		}
	} catch (e) {
		transformationsAspectRatio = cloudinaryImage.width / cloudinaryImage.height;
	}

	// TODO:: move this to .env
	const baseURL = "https://assets.sunwingtravelgroup.com/image/upload";
	const cleanTransformation = encodeURL(
		transformations
			?.replace(/,?q_auto/, "")
			?.replace(/,?f_auto/, "")
			.replace(/\/,/, "/")
			.replace(/^,/, "")
	);
	const imageURL = `${cloudinaryImage?.version ? `v${cloudinaryImage?.version}/` : ""}${encodeURL(
		cloudinaryImage?.public_id
	)}`;

	const createTransformation = (_cleanTransformation, _width, _height) => {
		let _transformation = `${_cleanTransformation && `${_cleanTransformation},`}q_auto,f_auto`;

		if (_width) {
			_transformation += `,w_${_width}`;
		}

		if (_height) {
			_transformation += `,h_${_height}`;
		}

		return _transformation;
	};

	const srcSet = srcSetPoints
		.map(point => {
			const pointWidth = parseInt(point * width, 10);
			const transformWidth =
				pointWidth <= cloudinaryImage.width ? pointWidth : cloudinaryImage.width;
			const transformHeight = parseInt(transformWidth / transformationsAspectRatio, 10);
			const transformation = createTransformation(
				cleanTransformation,
				transformWidth,
				transformHeight
			);

			return `${baseURL}/${transformation}/${imageURL} ${transformWidth}w`;
		})
		.join(", ");
	return {
		data: {
			aspectRatio: aspectRatio || transformationsAspectRatio,
			src: `${baseURL}/${createTransformation(
				cleanTransformation,
				width,
				parseInt(width / transformationsAspectRatio, 10)
			)}/${imageURL}`,
			srcSet,
			sizes: sizes || `(max-width:400px) 50vw, ${maxWidth}px`,
			preLoad,
		},
		alt: desc || title || "",
		title: title || "",
		width,
		height: parseInt(width / transformationsAspectRatio, 10),
	};
};

const CloudinaryHotelImageAdapter = ({ hotelCode, aspectRatio, maxWidth, sizes, title, desc }) =>
	CloudinaryImageAdapter({
		cloudinaryImage: hotelCode && {
			width: 1024,
			height: 576,
			public_id: `sunwing-prod/HotelImages/${hotelCode}/16x9/001`,
		},
		aspectRatio,
		maxWidth,
		sizes,
		title,
		desc,
	});

export default CloudinaryImageAdapter;
export { CloudinaryImageAdapter, CloudinaryHotelImageAdapter };
