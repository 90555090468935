import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Cell, Row } from "../../Grid";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../../FluidSections";
import * as styles from "./TileCollection.module.scss";

// TODO:: Task 99842: CardCollection, Tile Slider and Paginated collection Update

const TileCollection = ({ items, itemsPerRow, sortAlphabetically }) => {
	const sortItems = (collectionSortAlphabetically, CollectionItems) => {
		if (collectionSortAlphabetically) {
			return CollectionItems.sort((a, b) => a?.heading?.localeCompare(b.heading));
		}
		return CollectionItems;
	};

	return (
		<div className={cx(styles.tilesCollectionWrapper)}>
			<Row cols={itemsPerRow}>
				{sortItems(sortAlphabetically, items).map(item => (
					<Cell key={item.id}>
						<FluidSections data={[item]} useWrapper={false} />
					</Cell>
				))}
			</Row>
		</div>
	);
};
TileCollection.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sys: PropTypes.shape({
				contentType: PropTypes.shape({
					sys: PropTypes.shape({
						id: PropTypes.string.isRequired,
					}).isRequired,
				}).isRequired,
			}).isRequired,
		})
	).isRequired,
	itemsPerRow: PropTypes.number.isRequired,
	sortAlphabetically: PropTypes.bool,
};

TileCollection.defaultProps = {
	sortAlphabetically: false,
};

export default TileCollection;
export { TileCollection };
