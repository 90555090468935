import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { MobileEmbed } from "./Mobile/MobileEmbed";
// eslint-disable-next-line import/no-cycle
import { DesktopEmbed } from "./Desktop/DesktopEmbed";
import DesktopWidget from "./Desktop/Desktop";

const EmailAcquisitionEmbed = ({
	config,
	isWidgetOpen,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	offsetTop,
	setExitIntentActive,
	exitIntentActive,
	exitIntentCookieName,
	exitIntentEnabled,
	handleExitDismiss,
}) => {
	const [viewportWidth, setViewportWidth] = useState(null);
	let renderedComponent = null;

	const handleResize = () => {
		// Continue tracking viewport width with fallback
		setViewportWidth(window.innerWidth || document.documentElement.clientWidth);
	};

	useEffect(() => {
		// Resize event
		window.addEventListener("resize", handleResize, false);

		// Init to display desktop form
		handleResize();

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (viewportWidth) {
		if (viewportWidth < viewportBreakpoint) {
			renderedComponent = (
				<MobileEmbed
					embed={true}
					config={config}
					mode="mobile"
					isWidgetOpen={isWidgetOpen}
					setWidgetOpen={setWidgetOpen}
					lang={lang}
					userEmail={userEmail}
					setUserEmail={setUserEmail}
					userGateway={userGateway}
					setUserGateway={setUserGateway}
					setRegistered={setRegistered}
					isRegistered={isRegistered}
					viewportBreakpoint={viewportBreakpoint}
					offsetTop={offsetTop}
					setExitIntentActive={setExitIntentActive}
					exitIntentActive={exitIntentActive}
					exitIntentCookieName={exitIntentCookieName}
					exitIntentEnabled={exitIntentEnabled}
					handleExitDismiss={handleExitDismiss}
				/>
			);
		} else if (viewportWidth >= viewportBreakpoint) {
			renderedComponent = (
				<>
					<DesktopEmbed
						embed={true}
						type={config?.type}
						config={config}
						mode="desktop"
						setWidgetOpen={setWidgetOpen}
						lang={lang}
						userEmail={userEmail}
						setUserEmail={setUserEmail}
						userGateway={userGateway}
						setUserGateway={setUserGateway}
						setRegistered={setRegistered}
						isRegistered={isRegistered}
						viewportBreakpoint={viewportBreakpoint}
						setExitIntentActive={setExitIntentActive}
						exitIntentActive={exitIntentActive}
						exitIntentCookieName={exitIntentCookieName}
						exitIntentEnabled={exitIntentEnabled}
						handleExitDismiss={handleExitDismiss}
					/>
					{exitIntentEnabled && (
						<DesktopWidget
							embed={false}
							config={{ ...config, type: "inline" }}
							mode="desktop"
							isWidgetOpen={isWidgetOpen}
							setWidgetOpen={setWidgetOpen}
							lang={lang}
							userEmail={userEmail}
							setUserEmail={setUserEmail}
							userGateway={userGateway}
							setUserGateway={setUserGateway}
							setRegistered={setRegistered}
							isRegistered={isRegistered}
							viewportBreakpoint={viewportBreakpoint}
							setExitIntentActive={setExitIntentActive}
							exitIntentActive={exitIntentActive}
							exitIntentCookieName={exitIntentCookieName}
							exitIntentEnabled={exitIntentEnabled}
							handleExitDismiss={handleExitDismiss}
						/>
					)}
				</>
			);
		}
	}
	return renderedComponent;
};

EmailAcquisitionEmbed.propTypes = {
	config: PropTypes.object.isRequired,
	isWidgetOpen: PropTypes.bool.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	offsetTop: PropTypes.number.isRequired,
	setExitIntentActive: PropTypes.func.isRequired,
	exitIntentActive: PropTypes.bool.isRequired,
	exitIntentCookieName: PropTypes.string.isRequired,
	exitIntentEnabled: PropTypes.bool.isRequired,
	handleExitDismiss: PropTypes.func.isRequired,
};

EmailAcquisitionEmbed.defaultProps = {
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
};

export default EmailAcquisitionEmbed;
export { EmailAcquisitionEmbed };
