/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { InputText as InputTextComponent } from "@sunwing/shared-components";

import * as styles from "./InputText.module.scss";

const InputText = ({
	id,
	className,
	label,
	name,
	value,
	disabled,
	readOnly,
	placeholder,
	onChange,
	helperText,
	prefix,
	suffix,
	toolTip,
	error,
	variant,
	...props
}) => (
	<InputTextComponent
		id={id}
		className={cx(styles?.wrapper, styles[variant])}
		classNames={styles}
		label={label}
		type="text"
		name={name}
		value={value}
		disabled={disabled}
		readOnly={readOnly}
		placeholder={placeholder}
		onChange={onChange}
		helperText={helperText}
		prefix={prefix}
		suffix={suffix}
		tooltip={toolTip}
		error={error}
		{...props}
	/>
);

InputText.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	helperText: PropTypes.string,
	prefix: PropTypes.node,
	suffix: PropTypes.node,
	toolTip: PropTypes.shape({
		isOpen: PropTypes.bool,
		label: PropTypes.node,
		message: PropTypes.node,
	}),
	error: PropTypes.string,
	variant: PropTypes.string,
};

InputText.defaultProps = {
	className: undefined,
	value: "",
	disabled: false,
	readOnly: false,
	placeholder: undefined,
	onChange: () => true,
	helperText: undefined,
	prefix: undefined,
	suffix: undefined,
	toolTip: undefined,
	error: undefined,
	variant: undefined,
};

export default InputText;
export { InputText };
