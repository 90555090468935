import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { IframeResizer } from "../../utils/iframeResizer";

const FormAssembly = ({ config }) => {
	const breakPoints = config?.breakPoints || null;
	const [iframeStyle, setFrameHeight] = useState(0);

	useEffect(() => {
		// Determine the minimum height for Google Core Vitals - CLS fix
		if (breakPoints) {
			breakPoints.forEach(bpItem => {
				if (bpItem.minWidth && bpItem.maxWidth && bpItem.minHeight) {
					if (window.innerWidth >= bpItem.minWidth && window.innerWidth <= bpItem.maxWidth) {
						setFrameHeight(bpItem.minHeight);
					}
				} else if (bpItem.minWidth && bpItem.minHeight) {
					if (window.innerWidth >= bpItem.minWidth) {
						setFrameHeight(bpItem.minHeight);
					}
				} else if (bpItem.maxWidth && bpItem.minHeight) {
					if (window.innerWidth <= bpItem.maxWidth) {
						setFrameHeight(bpItem.minHeight);
					}
				}
			});
		}
	}, [breakPoints, iframeStyle]);

	const renderedComponent = config.iframeUrl.length > 0 && config.iframeUrl.startsWith("http") && (
		<iframe
			className={cx("fa-iframe")}
			src={config.iframeUrl}
			frameBorder="0"
			height={iframeStyle}
			width="100%"
			title={config?.title}
			onLoad={() => {
				IframeResizer(window);
			}}
		/>
	);

	return renderedComponent;
};

FormAssembly.propTypes = {
	config: PropTypes.shape({
		iframeUrl: PropTypes.string,
		title: PropTypes.string,
		type: PropTypes.string,
		breakPoints: PropTypes.arrayOf(
			PropTypes.shape({
				minWidth: PropTypes.number,
				maxWidth: PropTypes.number,
				minHeight: PropTypes.number,
			})
		),
	}),
};

FormAssembly.defaultProps = {
	config: {
		breakPoints: null,
	},
};

export default FormAssembly;
export { FormAssembly };
