/* eslint-disable react/no-danger */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

const HardCodedComponent = ({
	javaScriptContainer,
	cssContainer,
	markup,
	javaScriptFiles,
	onFileLoad,
}) => {
	useEffect(() => {
		let script;
		if (javaScriptContainer && !onFileLoad) {
			script = document.createElement("script");
			script.type = "text/javascript";
			script.innerHTML = javaScriptContainer;
			document.head.appendChild(script);
		}
		return () => {
			if (typeof window.___UNMOUNT_SCRIPT___ === "function") {
				window.___UNMOUNT_SCRIPT___.call();
			}
			if (script) {
				script.parentNode.removeChild(script);
			}
		};
	}, [javaScriptContainer, onFileLoad]);

	useEffect(() => {
		let style;
		if (cssContainer) {
			style = document.createElement("style");
			style.type = "text/css";
			document.head.appendChild(style);
			style.appendChild(document.createTextNode(cssContainer));
		}
		return () => {
			if (style) {
				style.parentNode.removeChild(style);
			}
		};
	}, [cssContainer]);

	useEffect(() => {
		const scripts = [];
		let script;
		if (javaScriptFiles) {
			// eslint-disable-next-line no-unused-expressions
			javaScriptFiles?.forEach((url, i) => {
				scripts[i] = document.createElement("script");
				scripts[i].type = "text/javascript";
				scripts[i].src = url;
				if (onFileLoad) {
					if (javaScriptContainer) {
						scripts[i].onload = () => {
							script = document.createElement("script");
							script.type = "text/javascript";
							script.innerHTML = javaScriptContainer;
							document.head.appendChild(script);
						};
					}
				}
				document.head.appendChild(scripts[i]);
			});
		}

		return () => {
			scripts.forEach(fileScript => {
				fileScript.parentNode.removeChild(fileScript);
			});
			if (onFileLoad) {
				if (script) {
					script.parentNode.removeChild(script);
				}
			}
		};
	}, [javaScriptFiles, onFileLoad, javaScriptContainer]);

	return markup && <div dangerouslySetInnerHTML={{ __html: markup }} />;
};

HardCodedComponent.propTypes = {
	javaScriptContainer: PropTypes.object,
	cssContainer: PropTypes.object,
	markup: PropTypes.object,
	javaScriptFiles: PropTypes.arrayOf(PropTypes.string),
	onFileLoad: PropTypes.bool,
};

HardCodedComponent.defaultProps = {
	javaScriptContainer: null,
	cssContainer: null,
	markup: null,
	javaScriptFiles: null,
	onFileLoad: false,
};

export default HardCodedComponent;
export { HardCodedComponent };
