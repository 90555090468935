import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { PageContext } from "../PageContext";
import { FUNCTIONS_PATH } from "../../const/const";

// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../FluidSections";

dayjs.extend(utc);
const getIniView = (startDate, endDate, placeholder, content, contentTimeList) => {
	const now = dayjs().utc();
	const start = dayjs(startDate).utc();
	const startTimeDifference = start.diff(now);
	const end = dayjs(endDate).utc();
	const endTimeDifference = end.diff(now);
	let iniView = "";
	let fetchContent = false;
	if (content && startTimeDifference <= 0 && endTimeDifference > 0) {
		iniView = content;
	} else {
		if (contentTimeList)
			contentTimeList.forEach(c => {
				const _startTimeDifference = dayjs(c.startAt).utc().diff(now);
				const _endTimeDifference = dayjs(c.endAt).utc().diff(now);
				if (_startTimeDifference <= 0 && _endTimeDifference > 0) {
					fetchContent = true;
				}
			});
		if (!fetchContent) iniView = placeholder;
	}
	return iniView;
};
const useTimer = (id, startDate, endDate, placeholder, content, contentTimeList, lang) => {
	const [data, setData] = useState("");
	useEffect(() => {
		const renderContent = getIniView(startDate, endDate, placeholder, content, contentTimeList);
		if (renderContent === "") {
			fetch(
				`${
					process.env.GATSBY_FUNCTIONS_BASE_URL || ""
				}${FUNCTIONS_PATH}/timed-promo?lang=${lang}&id=${id}`
			)
				.then(res => res.json())
				.then(jsonResponse => setData(jsonResponse.data));
		} else {
			setData(renderContent);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, placeholder, content, contentTimeList]);

	return data;
};

const TimedContent = ({ data }) => {
	const pageContext = useContext(PageContext);
	const lang = pageContext.locale.slice(0, 2);
	const content = useTimer(
		data.id,
		data.activeContent?.startAt,
		data.activeContent?.endAt,
		data.placeHolder,
		data.activeContent?.components,
		data.contentTimeList,
		lang
	);
	return content ? (
		<FluidSections data={content} useWrapper={true} locale={pageContext.locale} />
	) : (
		<></>
	);
};

TimedContent.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TimedContent;
export { TimedContent };
