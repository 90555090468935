import React from "react";
import { shape, string } from "prop-types";
import classNames from "classnames/bind";
import { Icon } from "../../../../components/Icons";
import * as styles from "./SliderNavigation.module.scss";

const cx = classNames.bind(styles);

const SliderNavigation = ({ navigationClasses }) => (
	<>
		<button
			className={cx("button", "buttonPrev", navigationClasses.button, navigationClasses.prevButton)}
			type="button"
		>
			<Icon name="arrow-left" />
		</button>
		<button
			className={cx("button", "buttonNext", navigationClasses.button, navigationClasses.nextButton)}
			type="button"
		>
			<Icon name="arrow-right" />
		</button>
	</>
);

SliderNavigation.propTypes = {
	navigationClasses: shape({
		button: string,
		prevButton: string,
		nextButton: string,
	}).isRequired,
};

export default SliderNavigation;
export { SliderNavigation };
