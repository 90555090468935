/* eslint-disable import/no-cycle */
import React from "react";
import { object, string } from "prop-types";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { RichText as RichTextComponent } from "@sunwing/shared-components";

import { Link } from "../../components/Link";
import { FluidSections } from "../../components/FluidSections";

/*
Upgrade check list
Update Label sorites and test
Update Paragraph section stories and test
Add stories and test to rich text (shared component and sw component)
Review all the rich text fragments
*/
const RichTextConnector = ({ data, className, listClassName }) => {
	const renderComponents = entry => {
		const fields = data?.references?.find(i => i.contentful_id === entry?.sys?.id);
		if (!fields) {
			return <></>; // This line should never be executed
		}

		return <FluidSections data={[fields]} locale="en-CA" useWrapper={false} />;
	};

	return (
		<RichTextComponent
			data={data?.raw ? JSON.parse(data?.raw) : data.json}
			className={className}
			renderComponents={renderComponents}
			options={{
				renderNode: {
					[INLINES.HYPERLINK]: ({ data: richtextData }, children) =>
						richtextData.uri.startsWith("http") ? (
							<a href={richtextData.uri} target="_blank" rel="noreferrer">
								{children}
							</a>
						) : (
							<Link to={richtextData.uri}>{children}</Link>
						),
					[BLOCKS.UL_LIST]: (node, children) => <ul className={listClassName}>{children}</ul>,
				},
			}}
		/>
	);
};

RichTextConnector.propTypes = {
	className: string,
	data: object.isRequired,
	listClassName: string,
};

RichTextConnector.defaultProps = {
	className: null,
	listClassName: null,
};

export default RichTextConnector;
export { RichTextConnector };
