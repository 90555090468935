import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { LOCAL_EN, LOCAL_FR } from "../../../const/const";
import { RCL } from "../../RCL";
import { PageContext } from "../../PageContext";
import { Icon, iconsList } from "../../Icons";
import { StarRating } from "../../StarRating";
import { FormattedMoney } from "../../FormattedMoney";
import { Card } from "../Card";
import { HeadingAsElement, HeadingSize, HeadingWeight } from "../../Heading";
import * as styles from "./AdModule.module.scss";

const AdModuleCard = ({
	header,
	link,
	button,
	className,
	subheader,
	stars,
	minAge,
	days,
	save,
	price,
	local,
	labels,
	headerAs,
	headerSize,
	headerWeight,
	variant,
	...props
}) => {
	const pageContext = useContext(PageContext);
	const lang = pageContext?.locale || "en";
	return (
		<Card
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			className={cx(styles.adModule, {
				[styles.hotel]: variant === "hotel",
				[styles.flight]: variant === "flight",
				[className]: className,
			})}
		>
			<Card.Header
				className={cx(styles.adModuleHeading)}
				size={variant === "flight" ? "h3" : headerSize}
				as={variant === "flight" ? "h3" : headerAs}
				weight={variant === "flight" ? "bold" : headerWeight}
			>
				{header?.length >= 50 ? `${header.substring(0, 50)}...` : header}
			</Card.Header>
			<Card.Body className={cx(styles.adModuleBody)}>
				{(subheader || days) && (
					<Card.Subheader title={subheader} className={cx(styles.adModuleSubheading)}>
						{variant === "flight" ? days : subheader}
					</Card.Subheader>
				)}
				{stars && variant === "hotel" && (
					<div className={styles.adModuleRatingsContainer}>
						<div className={styles.adModuleDetailsStars}>
							<StarRating rating={stars} />
						</div>
					</div>
				)}
				{minAge >= 16 && <p>{`Adults only ${minAge}+`}</p>}
				<div className={styles.adModuleContentContainer}>
					{days && variant === "hotel" && (
						<div className={cx(styles.adModuleDetailsDays)}>
							<span>{labels?.departureDate}</span>
							<span>{days}</span>
							<span>{labels?.allInc && labels?.allInc}</span>
						</div>
					)}
					{labels && variant === "flight" && (
						<div className={cx(styles.adModuleDetailsDays)}>
							<span>
								{RCL({ searchKey: "sov-departure" })} {labels?.departureDate}
							</span>
							<span>
								{RCL({ searchKey: "sov-return" })} {labels?.returnDate}
							</span>
						</div>
					)}
					{price && (
						<div className={cx(styles.adModuleDetailsPrice)}>
							{variant === "hotel" && <div className={cx(styles.adModuleDetailsSave)}>{save}</div>}
							<div className={cx(styles.adModuleDetailsAmount)}>
								<FormattedMoney number={price} local={lang} />
							</div>
							<span className={cx(styles.adModuleDetailsTax)}>
								{labels?.taxAndFee && labels?.taxAndFee}
							</span>
						</div>
					)}
				</div>
				{button && (
					<div className={styles.adModuleButtonsContainer}>
						{button?.to && (
							<Card.Button to={button?.to} size="sm">
								{button?.icon && <Icon name={button?.icon} />}
								{button?.text}
							</Card.Button>
						)}
						{link?.to && (
							<Card.Link
								to={link.to}
								target={link?.target}
								className={cx(styles.adModuleButtonsLink)}
							>
								<span className={cx(styles.adModuleButtonsLinkText)}>
									{link?.text}
									{link?.icon && <Icon name={link?.icon} />}
								</span>
							</Card.Link>
						)}
					</div>
				)}
			</Card.Body>
			{link?.to && (
				<Card.AsLink
					link={link.to}
					target={link?.target}
					text={`${subheader ? `${subheader}: ` : ""}${header || ""}`}
					className={styles.adModuleCardLink}
				/>
			)}
		</Card>
	);
};

AdModuleCard.propTypes = {
	variant: PropTypes.oneOf(["hotel", "flight"]),
	header: PropTypes.string.isRequired,
	subheader: PropTypes.string,
	link: PropTypes.shape({
		to: PropTypes.string.isRequired,
		target: PropTypes.string,
		icon: PropTypes.oneOf(iconsList),
		text: PropTypes.string,
	}).isRequired,
	icon: PropTypes.oneOf(iconsList),
	button: PropTypes.shape({
		icon: PropTypes.oneOf(iconsList),
		iconPlacement: PropTypes.oneOf(["right", "left"]),
		onClick: PropTypes.func,
		text: PropTypes.string,
		to: PropTypes.string,
	}),
	stars: PropTypes.number,
	minAge: PropTypes.number,
	days: PropTypes.string,
	save: PropTypes.string,
	price: PropTypes.number,
	local: PropTypes.oneOf([LOCAL_FR, LOCAL_EN]),
	labels: PropTypes.shape({
		allInc: PropTypes.string,
		taxAndFee: PropTypes.string,
		departureDate: PropTypes.string,
		returnDate: PropTypes.string,
	}),
	className: PropTypes.string,
	headerAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	headerSize: PropTypes.oneOf(Object.keys(HeadingSize)),
	headerWeight: PropTypes.oneOf(Object.keys(HeadingWeight)),
};

AdModuleCard.defaultProps = {
	variant: "hotel",
	className: undefined,
	subheader: null,
	icon: "arrow-right",
	button: undefined,
	stars: undefined,
	minAge: undefined,
	days: undefined,
	save: undefined,
	price: undefined,
	local: LOCAL_EN,
	labels: undefined,
	headerAs: "h2",
	headerSize: "small",
	headerWeight: "normal",
};

export default AdModuleCard;
export { AdModuleCard };
