// Reference https://www.npmjs.com/package/keycode-js
const KeyCode = {
	CODE_ENTER: 13,
	CODE_SPACE: 32,
	CODE_UP: 38,
	CODE_DOWN: 40,
	CODE_LEFT: 37,
	CODE_RIGHT: 39,
	CODE_ESCAPE: 27,
	CODE_DELETE: 46,
	CODE_TAB: 9,
	CODE_END: 35,
	CODE_HOME: 36,
	CODE_BACK_SPACE: 8,
};
export default KeyCode;
export { KeyCode };
