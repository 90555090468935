import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// eslint-disable-next-line import/no-cycle
import { Button } from "../Button";
// eslint-disable-next-line import/no-cycle
import { Link } from "../../sharedComponents/Link";
import { Icon } from "../Icons";
import * as styles from "./Table.module.scss";

// eslint-disable-next-line react/prop-types
const ListItem = ({ text }) => (
	<div className={styles.listItem}>
		<Icon name="checked--n" className={styles.listItemCheck} />
		{text}
	</div>
);
const Cell = ({ content, config }) => {
	const type = typeof content;
	switch (type) {
		case "boolean":
			return content ? (
				<Icon name="checked--n" className={styles.icon} />
			) : (
				<Icon name={config?.boolIcon || "minus--n"} className={styles.icon} />
			);
		case "object":
			if (content.type === "button") {
				return (
					<Button
						to={content.to}
						target={content.target}
						// className={styles.button}
					>
						{content.text}
					</Button>
				);
			}
			if (content.type === "link") {
				return (
					<Link to={content.to} target={content.target}>
						{content.text}
					</Link>
				);
			}
			if (content.type === "list") {
				return content.list.map(i => <ListItem key={i} text={i} />);
			}
			if (content.type === "string") {
				return content.text;
			}
			return content.text;
		default:
			return content;
	}
};
const Table = ({ header, rows, subtext, headerStyle, tableStyle, className, config }) => (
	<div className={cx("tableContainer", className)} style={{ overflowX: "auto" }}>
		<table className={styles.table} style={tableStyle}>
			<tr>
				{header?.map((i, index) => (
					<th
						key={i.title}
						className={cx(
							styles.header,
							index === 0 && config?.stickyIndex && styles.sticky,
							(i.background || headerStyle?.background) && styles.background,
							(i.bold || headerStyle?.bold) && styles.bold
						)}
						style={{ ...i, background: !i.background && i?.columnBackground }}
					>
						{i.title}
						<span>{i.subTitle}</span>
					</th>
				))}
			</tr>
			{rows?.map(r => (
				<tr key={r.title}>
					{r.columns?.map((c, index) =>
						c !== null ? (
							<td
								colSpan={c?.colSpan}
								rowSpan={c?.rowSpan}
								// eslint-disable-next-line react/no-array-index-key
								key={index}
								className={cx(
									styles.cell,
									index === 0 && config?.stickyIndex && styles.sticky,
									(r.background || c.background) && styles.background,
									(header[index]?.bold || r.bold || c.bold === true) && styles.bold,
									(header[index]?.small || r.small || c.small === true) && styles.small
								)}
								style={{
									textAlign: header[index]?.textAlign || c.textAlign,
									verticalAlign: header[index]?.verticalAlign || c.verticalAlign,
									background: header[index]?.columnBackground,
									color: c.textColor || r.textColor,
									fontSize: (index !== 0 && c.fontSize) || (index !== 0 && r.fontSize),
								}}
							>
								<Cell content={c} config={config} />
							</td>
						) : (
							<></>
						)
					)}
				</tr>
			))}
		</table>
		{/* eslint-disable-next-line react/no-danger */}
		{subtext && <div className={cx("subtext")} dangerouslySetInnerHTML={{ __html: subtext }} />}
	</div>
);

Table.propTypes = {
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			small: PropTypes.bool,
			background: PropTypes.bool,
			bold: PropTypes.bool,
			textColor: PropTypes.string,
			columns: PropTypes.arrayOf(
				PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object])
			),
		})
	).isRequired,
	header: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			subTitle: PropTypes.string,
			width: PropTypes.string,
			textAlign: PropTypes.oneOf(["left", "right", "center"]),
			padding: PropTypes.string,
			verticalAlign: PropTypes.oneOf(["top", "bottom", "middle"]),
			columnBackground: PropTypes.string,
			bold: PropTypes.bool,
			small: PropTypes.bool,
		})
	).isRequired,
	config: PropTypes.shape({
		boolIcon: PropTypes.string,
		stickyIndex: PropTypes.bool,
	}),
	headerStyle: PropTypes.shape({
		small: PropTypes.bool,
		background: PropTypes.bool,
		bold: PropTypes.bool,
	}),
	tableStyle: PropTypes.shape({
		minWidth: PropTypes.string,
	}),
	subtext: PropTypes.string,
	className: PropTypes.string,
};

Cell.propTypes = {
	config: PropTypes.shape({
		boolIcon: PropTypes.bool,
		stickyIndex: PropTypes.bool,
	}),
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
};

Table.defaultProps = {
	subtext: null,
	headerStyle: null,
	tableStyle: null,
	className: "",
	config: null,
};

Cell.defaultProps = {
	config: null,
	content: null,
};

export default Table;
export { Table };
