import React from "react";
import PropTypes from "prop-types";
import { motion, useReducedMotion } from "framer-motion";
import { Icon } from "../../../Icons";
import * as styles from "./SlideInMenuHeader.module.scss";
import { sideAnimation } from "../../../../utils/animations";

const defaultLabels = {
	closeMenu: "Close menu",
};

const SlideInMenuHeader = ({ id, title, labels: providedLabels, isOpen, handleClose }) => {
	const labels = { ...defaultLabels, ...providedLabels };
	const shouldReduceMotion = useReducedMotion();

	return (
		<motion.div
			className={styles.container}
			initial={false}
			variants={{ ...sideAnimation(0.2, shouldReduceMotion) }}
			animate={isOpen ? "open" : "closed"}
		>
			<span className={styles.title}>{title}</span>
			<button
				type="button"
				className={styles.closeButton}
				aria-label={labels.closeMenu}
				aria-expanded={isOpen}
				aria-controls={id}
				onClick={handleClose}
				title={defaultLabels.closeMenu}
			>
				<Icon name="close--n" title={defaultLabels.closeMenu} />
			</button>
		</motion.div>
	);
};

SlideInMenuHeader.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	labels: PropTypes.shape({
		closeMenu: PropTypes.string,
	}),
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
};

SlideInMenuHeader.defaultProps = {
	labels: defaultLabels,
	isOpen: false,
};

export default SlideInMenuHeader;
export { SlideInMenuHeader };
