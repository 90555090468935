import React from "react";
import PropTypes from "prop-types";
import { Heading } from "../Heading";

import * as styles from "./LinkList.module.scss";

const LinkList = ({ list, renderLink }) => {
	const groupedItems = [...list]
		.sort((a, b) => a.linkText.localeCompare(b.linkText))
		.reduce((acc, item) => {
			const startingLetter = item.linkText[0].toUpperCase();
			if (!acc[startingLetter]) acc[startingLetter] = { startingLetter, list: [item] };
			else acc[startingLetter].list.push(item);
			return acc;
		}, {});
	return (
		<ul className={styles?.listItems}>
			{Object.values(groupedItems).map(item => (
				<li className={styles?.item}>
					<Heading as="h3" key={item.id}>
						{item.startingLetter}
					</Heading>
					<ul className={styles?.subItem}>
						{item.list.map(subItem => (
							<li className={styles?.subItemTitle} key={subItem.id}>
								{renderLink(subItem, { className: styles?.subItemUrl })}
							</li>
						))}
					</ul>
				</li>
			))}
		</ul>
	);
};

LinkList.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			linkText: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		})
	).isRequired,
	renderLink: PropTypes.func,
};

LinkList.defaultProps = {
	renderLink: (link, { className }) => (
		<a href={link.url} className={className}>
			{link.linkText}
		</a>
	),
};

export default LinkList;
export { LinkList };
