import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Card as CardComponent } from "@sunwing/shared-components";
import {
	Heading,
	HeadingAlign,
	HeadingAsElement,
	HeadingSize,
	HeadingWeight,
	headingStyles,
} from "../../../Heading";

const CardHeaderComponent = CardComponent.Header;
const CardHeader = ({ style, className, as, align, uppercase, size, weight, children }) => (
	<CardHeaderComponent
		style={style}
		as={as}
		align={align}
		uppercase={uppercase}
		className={cx(className)}
		classNames={headingStyles}
		HeadingComponent={Heading}
		size={size}
		weight={weight}
	>
		{children}
	</CardHeaderComponent>
);

CardHeader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	className: PropTypes.string,
	as: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	align: PropTypes.oneOf(Object.keys(HeadingAlign)),
	size: PropTypes.oneOf(Object.keys(HeadingSize)),
	weight: PropTypes.oneOf(Object.keys(HeadingWeight)),
	uppercase: PropTypes.bool,
};

CardHeader.defaultProps = {
	style: {},
	className: undefined,
	as: HeadingAsElement.p,
	align: "left",
	size: undefined,
	weight: undefined,
	uppercase: false,
};

export default CardHeader;
export { CardHeader };
