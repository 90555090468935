import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import GoogleMapReact from "google-map-react";
import * as sharedStyles from "./GoogleMap.module.scss";

const defaultMarkerClassNames = {
	markerWrapper: sharedStyles.markerWrapper,
	markerWrapperActive: sharedStyles.markerWrapperActive,
	closeDescription: sharedStyles.closeDescription,
	descriptionLink: sharedStyles.descriptionLink,
	markerTitle: sharedStyles.markerTitle,
};

const MapMarker = ({
	lat,
	lng,
	name,
	link,
	classNames: providedClassNames,
	closeIcon,
	markerIcon,
}) => {
	const markerClassNames = {
		...defaultMarkerClassNames,
		...providedClassNames,
	};
	const [isHidden, toggleHidden] = useState(true);
	function toggleCheckOpen() {
		if (isHidden) {
			toggleHidden(false);
		}
	}

	let stateClasses = cx(sharedStyles.markerWrapper, markerClassNames.markerWrapper);
	let openStateClass = "";

	if (isHidden) {
		stateClasses = cx(sharedStyles.markerWrapper, markerClassNames.markerWrapper);
		openStateClass = "";
	} else {
		stateClasses = cx(
			sharedStyles.markerWrapper,
			markerClassNames.markerWrapperCustom,
			sharedStyles.markerWrapperActive,
			markerClassNames.markerWrapperActive
		);
		openStateClass = "markerIsOpen";
	}

	return (
		<div
			lat={lat}
			lng={lng}
			text={name}
			aria-label={name}
			className={cx(stateClasses, openStateClass)}
			role="button"
			onKeyPress={() => toggleHidden(!isHidden)}
			onClick={() => toggleCheckOpen()}
			onBlur={() => toggleHidden(!isHidden)}
			tabIndex={0}
			label={name}
		>
			<div id={`${"marker-"}${lat}-${lng}`} className={sharedStyles.markerDescription}>
				<div className={sharedStyles.descriptionNav}>
					<button
						className={cx(sharedStyles.closeDescription, markerClassNames.closeDescription)}
						type="button"
					>
						{closeIcon}
					</button>
				</div>
				<div className={cx(sharedStyles.markerTitle, markerClassNames.markerTitle)}>{name}</div>
				{link && (
					<button
						type="button"
						className={cx(sharedStyles.descriptionLink, markerClassNames.descriptionLink)}
						onMouseDown={() => {
							window.open(link.url, "_self"); // Can not use link because blur on parent will prevent it from working.
						}}
					>
						{link.linkText}
					</button>
				)}
			</div>
			{markerIcon}
		</div>
	);
};

const defaultMapClassNames = {
	map: sharedStyles.map,
	mapContainer: sharedStyles.mapContainer,
	list: sharedStyles.list,
	listContainer: sharedStyles.listContainer,
	descriptionLink: sharedStyles.descriptionLink,
};

const GoogleMap = ({
	mapEntries,
	initZoom,
	displayList,
	mapKey,
	classNames: providedClassNames,
	markerClassNames,
	markerIcon,
	closeIcon,
}) => {
	const mapClassNames = { ...defaultMapClassNames, ...providedClassNames };
	// each array's values corresponds to [latitude, longitude]
	const topRight = [null, null];
	const bottomLeft = [null, null];
	let centerLat = 0;
	let centerLng = 0;

	for (let i = 0; i < mapEntries.length; i++) {
		const entry = mapEntries[i];

		if (topRight[0] < entry.latitude || topRight[0] === null) {
			topRight[0] = entry.latitude;
			topRight[1] = entry.longitude;
		}

		if (bottomLeft[1] < entry.longitude || bottomLeft[1] === null) {
			bottomLeft[0] = entry.latitude;
			bottomLeft[1] = entry.longitude;
		}
	}

	if (
		topRight[0] !== null &&
		topRight[1] !== null &&
		bottomLeft[0] !== null &&
		bottomLeft[0] !== null
	) {
		centerLat = (topRight[0] - bottomLeft[0]) / 2 + parseFloat(bottomLeft[0]);
		centerLng = (topRight[1] - bottomLeft[1]) / 2 + parseFloat(bottomLeft[1]);
	}

	const mapProps = {
		center: {
			lat: centerLat,
			lng: centerLng,
		},
		zoom: initZoom,
	};

	function toggleMarker(markerID) {
		const currentMarker = document.getElementById(markerID).parentElement;
		currentMarker.focus();
		currentMarker.click();
	}

	return (
		mapEntries && (
			<div className={cx(sharedStyles.map, mapClassNames.map)}>
				<div className={cx(sharedStyles.mapContainer, mapClassNames.mapContainer)}>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: mapKey,
						}}
						defaultCenter={mapProps.center}
						defaultZoom={mapProps.zoom}
					>
						{mapEntries.map(entry => (
							<MapMarker
								key={`${entry.name}${entry.latitude}-${entry.longitude}`}
								lat={entry.latitude}
								lng={entry.longitude}
								name={entry.name}
								link={entry.link}
								classNames={markerClassNames}
								closeIcon={closeIcon}
								markerIcon={markerIcon}
							/>
						))}
					</GoogleMapReact>
				</div>
				{displayList && (
					<div className={cx(sharedStyles.listContainer, mapClassNames.listContainer)}>
						<ol className={cx(sharedStyles.list, mapClassNames.list)}>
							{mapEntries.map(entry => {
								const markerId = `${"marker-"}${entry.latitude}-${entry.longitude}`;

								return (
									<li key={markerId}>
										<button
											className={cx(sharedStyles.descriptionLink, mapClassNames.descriptionLink)}
											type="button"
											onClick={() => toggleMarker(markerId)}
										>
											{entry.name}
										</button>
									</li>
								);
							})}
						</ol>
					</div>
				)}
			</div>
		)
	);
};

GoogleMap.propTypes = {
	mapEntries: PropTypes.arrayOf(
		PropTypes.shape({
			latitude: PropTypes.number.isRequired,
			longitude: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			link: PropTypes.shape({
				url: PropTypes.string,
				linkText: PropTypes.string,
			}),
		})
	).isRequired,
	initZoom: PropTypes.number,
	displayList: PropTypes.bool,
	mapKey: PropTypes.string.isRequired,
	closeIcon: PropTypes.node,
	markerIcon: PropTypes.node,
	classNames: PropTypes.shape({
		map: PropTypes.string,
		mapContainer: PropTypes.string,
		list: PropTypes.string,
		listContainer: PropTypes.string,
		listButton: PropTypes.string,
	}),
	markerClassNames: PropTypes.shape({
		markerWrapper: PropTypes.string,
		markerWrapperActive: PropTypes.string,
	}),
};

GoogleMap.defaultProps = {
	initZoom: 11,
	displayList: true,
	classNames: null,
	closeIcon: null,
	markerIcon: null,
	markerClassNames: defaultMarkerClassNames,
};

MapMarker.propTypes = {
	lat: PropTypes.number.isRequired,
	lng: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	link: PropTypes.shape({
		url: PropTypes.string,
		linkText: PropTypes.string,
	}),
	closeIcon: PropTypes.node,
	markerIcon: PropTypes.node,
	classNames: PropTypes.shape({
		markerWrapper: PropTypes.string,
		markerWrapperActive: PropTypes.string,
	}),
};
MapMarker.defaultProps = {
	link: null,
	closeIcon: null,
	markerIcon: null,
	classNames: defaultMarkerClassNames,
};
export default GoogleMap;
export { GoogleMap };
