// extracted by mini-css-extract-plugin
export var table = "Table-module--table--1h1PM";
export var activeLink = "Table-module--activeLink--QwRTK";
export var header = "Table-module--header--3W3yV";
export var cell = "Table-module--cell--1oyst";
export var description = "Table-module--description--27p__";
export var subtext = "Table-module--subtext--2L-_D";
export var bold = "Table-module--bold--37t9G";
export var small = "Table-module--small--3EOox";
export var icon = "Table-module--icon--2TO4G";
export var check = "Table-module--check--1RL1j";
export var minus = "Table-module--minus--2rcRm";
export var listItem = "Table-module--listItem--2PYV5";
export var listItemCheck = "Table-module--listItemCheck--2gKF1";
export var sticky = "Table-module--sticky--1w6DG";
export var background = "Table-module--background--FuZaF";