/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { propTypes, defaultProps } from "./Link.PropTypes";
import { Img } from "../Images";

import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter/CloudinaryImageAdapter";
// eslint-disable-next-line import/no-cycle
import { Modal } from "../../components/Modal";
import { Icon } from "../../components/Icons";
// eslint-disable-next-line import/no-cycle
import { Button } from "../../components/Button";
import { RCL } from "../../components/RCL";
import * as styles from "./Link.module.scss";

const LinkImage = ({ image, imageStyle, imageAltText }) =>
	image && (
		<Img
			src={image && image}
			wrapperClassName={imageStyle && imageStyle}
			alt={imageAltText && imageAltText}
		/>
	);

const LinkIcon = ({ icon, iconStyle }) => icon && <Icon style={iconStyle} name={icon} />;

const LinkModal = ({
	children,
	externalModalMessage,
	to,
	className,
	onOpenModal,
	innerTabIndexButton,
}) => {
	const [isModalOpen, setModalIsOpen] = useState(false);
	return (
		<>
			<button
				type="button"
				className={className}
				tabIndex={innerTabIndexButton}
				onClick={() => {
					setModalIsOpen(true);
					if (onOpenModal) onOpenModal();
				}}
			>
				{children}
			</button>
			<Modal
				contentLabel="content details"
				container="fluid"
				isOpen={isModalOpen}
				onClose={() => {
					setModalIsOpen(false);
				}}
			>
				<Modal.Content className={styles.modalContent}>
					<div className={styles.modalContentInner}>
						<p className={styles.modalContentMessage}>{externalModalMessage}</p>
						<div className={styles.modalContentActions}>
							<Button
								buttonType="primary"
								to={to}
								target="_blank"
								rel="noreferrer noopener"
								className={styles.modalContentLink}
								onClick={() => {
									setModalIsOpen(false);
								}}
							>
								<RCL searchKey="ok" />
							</Button>
							<Button
								type="button"
								theme="secondary"
								className={styles.modalContentCancel}
								onClick={() => {
									setModalIsOpen(false);
								}}
							>
								<RCL searchKey="cancel" />
							</Button>
						</div>
					</div>
				</Modal.Content>
			</Modal>
		</>
	);
};

LinkModal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	externalModalMessage: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	className: PropTypes.string,
	onOpenModal: PropTypes.func.isRequired,
	innerTabIndexButton: PropTypes.number,
};

LinkModal.defaultProps = {
	className: undefined,
	innerTabIndexButton: 0,
};

const Link = React.forwardRef((props, ref) => {
	const {
		children,
		to,
		activeClassName,
		partiallyActive,
		target,
		style,
		state,
		className,
		innerTabIndex,
		image,
		icon,
		iconStyle,
		imageStyle,
		imageAltText,
		onClick,
	} = props;
	const internal = /^\/(?!\/)/.test(to);

	if (internal && target !== "_blank") {
		return (
			<GatsbyLink
				tabIndex={innerTabIndex}
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				className={className}
				style={style}
				state={state}
				ref={ref}
				onClick={onClick}
			>
				{image?.[0]?.secure_url ? (
					<LinkImage
						image={CloudinaryImageAdapter(image?.[0])}
						imageStyle={imageStyle && imageStyle}
						imageAltText={imageAltText && imageAltText}
					/>
				) : icon ? (
					<LinkIcon icon={icon} iconStyle={iconStyle && iconStyle} />
				) : (
					children
				)}
			</GatsbyLink>
		);
	}
	return (
		<a
			href={to}
			target={target}
			className={className}
			style={style}
			ref={ref}
			tabIndex={innerTabIndex}
			onClick={onClick}
		>
			{image?.[0]?.secure_url ? (
				<LinkImage
					image={CloudinaryImageAdapter(image?.[0])}
					imageStyle={imageStyle && imageStyle}
					imageAltText={imageAltText && imageAltText}
				/>
			) : icon ? (
				<LinkIcon icon={icon} iconStyle={iconStyle && iconStyle} />
			) : (
				children
			)}
		</a>
	);
});

LinkImage.propTypes = {
	image: PropTypes.string,
	imageStyle: PropTypes.object,
	imageAltText: PropTypes.string,
};
LinkImage.defaultProps = {
	image: null,
	imageStyle: null,
	imageAltText: null,
};

LinkIcon.propTypes = {
	icon: PropTypes.object,
	iconStyle: PropTypes.object,
};
LinkIcon.defaultProps = {
	icon: null,
	iconStyle: null,
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
export { Link };
