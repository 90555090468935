import React from "react";
import PropTypes from "prop-types";
import { GoogleMap as GoogleMapComponent } from "../../sharedComponents/GoogleMap/GoogleMap";
import { Icon } from "../Icons";
import * as styles from "./GoogleMap.module.scss";

const GoogleMap = ({ mapEntries, initZoom, displayList, mapKey }) => (
	<GoogleMapComponent
		mapEntries={mapEntries}
		initZoom={initZoom}
		displayList={displayList}
		mapKey={mapKey}
		classNames={{
			map: styles.map,
			mapContainer: styles.mapContainer,
			list: styles.list,
			listContainer: styles.listContainer,
			listButton: styles.listButton,
		}}
		markerClassNames={{
			markerWrapper: styles.markerWrapper,
			markerWrapperActive: styles.markerWrapperActive,
			markerPin: styles.markerPin,
			markerTitle: styles.markerTitle,
			closeDescription: styles.closeDescription,
			descriptionLink: styles.descriptionLink,
		}}
		closeIcon={<Icon name="close--n" />}
		markerIcon={<Icon name="map" className="swg-text-2xl" />}
	/>
);

GoogleMap.propTypes = {
	mapEntries: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				latitude: PropTypes.number.isRequired,
				longitude: PropTypes.number.isRequired,
				name: PropTypes.string.isRequired,
				link: PropTypes.shape({
					url: PropTypes.string,
					linkText: PropTypes.string,
				}),
			}),
		})
	).isRequired,
	initZoom: PropTypes.number,
	displayList: PropTypes.bool,
	mapKey: PropTypes.string,
};

GoogleMap.defaultProps = {
	initZoom: 11,
	displayList: true,
	mapKey: null,
};

export default GoogleMap;
export { GoogleMap };
