import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Img } from "../../../sharedComponents/Images/Image/Img";
import * as styles from "./CardLogo.module.scss";

const CardLogo = ({ className, style, logo, alt }) => (
	<>
		{!Object.keys(logo).length === 0 && (
			<div
				className={cx(styles.logo, {
					[className]: className,
				})}
				style={style}
			>
				<Img
					wrapperClassName={cx(styles.logoImage)}
					image={logo}
					src={logo?.src || logo.secure_url}
					source={logo?.srcSet && logo}
					alt={logo?.alt || alt}
				/>
			</div>
		)}
	</>
);

CardLogo.propTypes = {
	logo: PropTypes.arrayOf(PropTypes.object),
	className: PropTypes.string,
	alt: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

CardLogo.defaultProps = {
	logo: {},
	className: "",
	style: {},
	alt: null,
};

export default CardLogo;
export { CardLogo };
