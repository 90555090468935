import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { Button as ButtonComponent } from "../../../Button";

const CardButton = ({
	to,
	onClick,
	target,
	icon,
	iconPlacement,
	className,
	label,
	theme,
	size,
	disabled,
	full,
	type,
	defaultStyle,
}) => (
	<ButtonComponent
		to={to}
		onClick={onClick}
		target={target}
		icon={icon}
		iconPlacement={iconPlacement}
		className={className}
		label={label}
		theme={theme}
		defaultStyle={defaultStyle}
		size={size}
		disabled={disabled}
		full={full}
		type={type}
	/>
);

CardButton.propTypes = {
	type: PropTypes.oneOf(["button", "submit", "reset"]),
	label: PropTypes.string,
	to: PropTypes.string,
	target: PropTypes.string,
	onClick: PropTypes.func,
	icon: PropTypes.string,
	iconPlacement: PropTypes.oneOf(["right", "left"]),
	theme: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary"]),
	defaultStyle: PropTypes.bool,
	size: PropTypes.oneOf(["sm", "md", "lg", "auto"]),
	disabled: PropTypes.bool,
	full: PropTypes.bool,
	className: PropTypes.string,
};

CardButton.defaultProps = {
	type: "button",
	label: undefined,
	to: undefined,
	target: "_self",
	onClick: undefined,
	icon: undefined,
	iconPlacement: undefined,
	theme: "primary",
	defaultStyle: true,
	size: "md",
	disabled: false,
	full: false,
	className: undefined,
};

export default CardButton;
export { CardButton };
