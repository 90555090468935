import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// eslint-disable-next-line import/no-cycle
import { RichTextConnector } from "../../connectors/RichTextConnector";
import { Heading, HeadingAsElement, HeadingSize } from "../Heading";
import * as styles from "./Paragraph.module.scss";

const Paragraph = ({
	heading,
	headingAs,
	headingSize,
	subHeading,
	subHeadingAs,
	subHeadingSize,
	alignment,
	content,
}) => (
	<>
		<div className={cx({ [styles[alignment]]: alignment })}>
			{heading && (
				<Heading as={headingAs} size={headingSize} className={styles?.sectionTitle}>
					{heading}
				</Heading>
			)}
			{subHeading && (
				<Heading as={subHeadingAs} size={subHeadingSize} className={styles.sectionContent}>
					{subHeading}
				</Heading>
			)}
		</div>
		{content && (
			<div className={cx({ [styles[alignment]]: alignment })}>
				<RichTextConnector
					data={content}
					className={styles.sectionContent}
					listClassName={styles.list}
				/>
			</div>
		)}
	</>
);

Paragraph.propTypes = {
	heading: PropTypes.string,
	headingAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	headingSize: PropTypes.oneOf(Object.keys(HeadingSize)),
	subHeading: PropTypes.string,
	subHeadingAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	subHeadingSize: PropTypes.oneOf(Object.keys(HeadingSize)),
	alignment: PropTypes.oneOf(["left", "center", "right"]),
	content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array,
	}).isRequired,
};
Paragraph.defaultProps = {
	heading: undefined,
	headingAs: HeadingAsElement.h2,
	headingSize: undefined,
	subHeading: undefined,
	subHeadingAs: HeadingAsElement.h3,
	subHeadingSize: undefined,
	alignment: "left",
};
export default Paragraph;
export { Paragraph };
