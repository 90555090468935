import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Row as RowComponent, Cell as CellComponent } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/Grid/index.css";
// import { validReactComponentsByDisplayName } from "UTILS"; FIXME:
// import { validReactComponentsByDisplayName } from "../../utils"; FIXME:
import * as styles from "./Grid.module.scss";

const GridCustomColumns = {
	md3Lg5: "md3Lg5",
	md3Lg6: "md3Lg6",
};

const Row = ({ className, cols, customCols, children }) => {
	let _cols = cols;
	let customColClass;
	if (cols > 4) {
		_cols = null;
		switch (cols) {
			case 5:
				customColClass = GridCustomColumns.md3Lg5;
				break;
			case 6:
				customColClass = GridCustomColumns.md3Lg6;
				break;
			default:
				break;
		}
		if (customCols) {
			customColClass = customCols;
		}
	}

	return (
		<RowComponent
			cols={_cols}
			customCols={customColClass}
			className={cx(className, {
				[styles[customColClass]]: customColClass && styles[customColClass],
			})}
		>
			{children}
		</RowComponent>
	);
};

Row.displayName = "Row";

const Cell = ({ className, children }) => (
	<CellComponent className={cx(styles.gridItem, className)}>{children}</CellComponent>
);

Cell.displayName = "Cell";

Row.propTypes = {
	className: PropTypes.string,
	cols: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
	/**
	 * Naming Convention = (breakpoint)[columns]
	 *
	 * Import GridCustomColumns for list of `customCols` available
	 *
	 */
	customCols: PropTypes.oneOf(Object.keys(GridCustomColumns)),
	/**
	 * All children must be using the `Cell` component.
	 *
	 * `Cell[] | Cell`
	 *
	 * @type {Cell[]|Cell}
	 */
	// children: validReactComponentsByDisplayName(Cell.displayName, true).isRequired, // FIXME:
	children: PropTypes.node.isRequired,
};

Row.defaultProps = {
	className: undefined,
	cols: undefined,
	customCols: undefined,
};

Cell.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Cell.defaultProps = {
	className: undefined,
};

export { Row, Cell, GridCustomColumns };
