import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Alert as AlertComponent } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/Alert/index.css";
import { HeadingAsElement as AlertTitle } from "../Heading";
import { Icon } from "../Icons";
import { Section } from "../Section";
import * as styles from "./Alert.module.scss";
import * as stylesSection from "../Section/Section.module.scss";

const defaultClassNames = {
	heading: styles.alertHeading,
	body: styles.alertBody,
	dismissibleButton: styles.alertClose,
};

const defaultLabels = {
	closeAlert: "Close alert",
};

const Alert = ({
	className,
	classNames: providedClassNames,
	children,
	heading,
	headingAs,
	variant,
	hide,
	dismissible,
	labels: providedLabels,
	icon,
	textWrapper,
}) => {
	const classNames = { ...defaultClassNames, ...providedClassNames };

	const labels = {
		...defaultLabels,
		...providedLabels,
	};

	const classes = cx(styles.alert, {
		[styles[variant]]: variant,
		[className]: className,
		[styles.noChildren]: !children,
		[styles.noIcon]: !icon,
	});

	return (
		<AlertComponent
			className={classes}
			classNames={classNames}
			heading={heading}
			headingAs={headingAs}
			hide={hide}
			SectionComponent={Section}
			SectionComponentClasses={stylesSection}
			dismissible={dismissible}
			labels={labels.closeAlert}
			dismissibleIcon={<Icon name="close--n" />}
			icon={icon && <Icon className={styles.alertIcon} name={icon} />}
			textWrapper={textWrapper}
		>
			{children}
		</AlertComponent>
	);
};

Alert.displayName = "Alert";

Alert.propTypes = {
	className: PropTypes.string,
	classNames: PropTypes.shape({
		heading: PropTypes.string,
		wrapper: PropTypes.string,
		body: PropTypes.string,
		dismissibleButton: PropTypes.string,
	}),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	heading: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	headingAs: PropTypes.oneOf(Object.keys(AlertTitle)),
	variant: PropTypes.oneOf(["success", "danger", "warning", "info", "none"]),
	hide: PropTypes.bool,
	dismissible: PropTypes.bool,
	labels: PropTypes.shape({
		closeAlert: PropTypes.string,
	}),
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	textWrapper: PropTypes.bool,
};

Alert.defaultProps = {
	className: undefined,
	classNames: {},
	children: undefined,
	heading: undefined,
	headingAs: AlertTitle.h2,
	variant: "none",
	hide: false,
	dismissible: true,
	labels: defaultLabels,
	icon: undefined,
	textWrapper: false,
};

export default Alert;
export { Alert };
