import React from "react";
import PropTypes from "prop-types";
import { Section, SectionTitleAlign, SectionTheme, SectionPadding } from "../../components/Section";

const SectionConnector = ({ data, id, useWrapper, children }) => {
	const responsiveBanner = useWrapper && id === "responsiveBanner";
	let padding;

	if (responsiveBanner) {
		padding = "bottom";
	} else if (data?.padding) {
		padding = data.padding;
	}

	return useWrapper ? (
		<Section
			key={data.id}
			title={data?.heading}
			titleAs={data?.headingAs || "h2"}
			titleSize={data?.headingSize || "h1"}
			titleAlignment={data?.titleAlignment || "center"}
			titleId={data?.titleId ? data?.titleId : undefined}
			padding={padding}
			theme={data.theme}
			relativeContainer={data?.relativeContainer ? data?.relativeContainer : undefined}
			relativeInnerContainer={
				data?.relativeInnerContainer ? data?.relativeInnerContainer : undefined
			}
		>
			{children}
		</Section>
	) : (
		children
	);
};

SectionConnector.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string,
		heading: PropTypes.string,
		headingAs: PropTypes.string,
		headingSize: PropTypes.string,
		titleAlignment: PropTypes.oneOf(Object.keys(SectionTitleAlign)),
		titleId: PropTypes.string,
		relativeContainer: PropTypes.bool,
		relativeInnerContainer: PropTypes.bool,
		theme: PropTypes.oneOf(Object.keys(SectionTheme)),
		padding: PropTypes.oneOf(Object.keys(SectionPadding)),
		backgroundColor: PropTypes.oneOf(["light", "dark", "none", "transparent"]),
		backgroundImage: PropTypes.arrayOf(
			PropTypes.shape({
				secure_url: PropTypes.string,
				width: PropTypes.string,
				height: PropTypes.string,
				raw_transformation: PropTypes.string,
				version: PropTypes.string,
				public_id: PropTypes.string,
				format: PropTypes.string,
			})
		),
		wrapperSections: PropTypes.object,
	}),
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	useWrapper: PropTypes.bool,
};

SectionConnector.defaultProps = {
	data: null,
	id: undefined,
	useWrapper: true,
};

export default SectionConnector;
export { SectionConnector };
