/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { ComponentsSlider } from "../../ComponentsSlider/ComponentsSlider";
// eslint-disable-next-line import/no-cycle
import { Link } from "../../../sharedComponents/Link";
import { CardsContainerGrid } from "../Grid";
import { RCL as useTranslation } from "../../RCL";
import { Icon } from "../../Icons";

const getBreakpoints = (slidesPerView, breakpoints) => {
	if (slidesPerView && !breakpoints) {
		switch (slidesPerView) {
			case 2:
				return {
					320: {
						slidesPerView: 2,
						spaceBetween: 12,
					},
					769: {
						slidesPerView: 2,
						spaceBetween: 16,
					},
				};
			case 3:
				return {
					320: {
						slidesPerView: 1,
						spaceBetween: 12,
					},
					576: {
						slidesPerView: 2,
						spaceBetween: 1,
						centeredSlides: false,
					},
					769: {
						slidesPerView: 3,
						spaceBetween: 16,
						centeredSlides: false,
					},
				};
			case 4:
				return {
					320: {
						slidesPerView: 1,
						spaceBetween: 12,
					},
					576: {
						slidesPerView: 2,
						spaceBetween: 12,
						centeredSlides: false,
					},
					769: {
						slidesPerView: 3,
						spaceBetween: 16,
						centeredSlides: false,
					},
					993: {
						slidesPerView: 4,
						spaceBetween: 16,
						centeredSlides: false,
					},
				};
			default:
				return null;
		}
	}
	return breakpoints;
};

const CardsContainerSlide = ({ children, id, link, slidesPerView, breakpoints }) => {
	const slides = React.Children.map(children, component => ({
		id: component.key || component.props.id,
		view: component,
	}));
	const loadAllLabel = useTranslation({ searchKey: "load-all" });
	const loadMoreLabel = useTranslation({ searchKey: "load-more" });
	return (
		<div>
			{slides.length > 1 ? (
				<ComponentsSlider
					id={id}
					slides={slides}
					breakpoints={getBreakpoints(slidesPerView, breakpoints)}
					slidesPerView={slidesPerView}
					link={link}
					navigation="desktopOnly"
					paging={true}
					linkIcon={true}
					boxShadow={{
						blur: 5,
						opacity: 0.26,
						x: 0,
						y: 5,
					}}
				/>
			) : (
				<>
					<CardsContainerGrid
						labels={{
							loadAll: loadAllLabel,
							loadMore: loadMoreLabel,
						}}
					>
						{children}
					</CardsContainerGrid>
					{!!link && (
						<Link to={link.urlLink}>
							{link.linkText}
							<Icon name="arrow-right" />
						</Link>
					)}
				</>
			)}
		</div>
	);
};

CardsContainerSlide.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.arrayOf(PropTypes.node).isRequired,
	link: PropTypes.object,
	slidesPerView: PropTypes.oneOf([1, 2, 3, 4]),
	breakpoints: PropTypes.object,
};
CardsContainerSlide.defaultProps = {
	link: null,
	slidesPerView: 3,
	breakpoints: undefined,
};

export default CardsContainerSlide;
export { CardsContainerSlide };
