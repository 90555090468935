/* eslint-disable import/no-cycle */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import { FluidSections } from "../../components/FluidSections";
import { useModal } from "../../components/Modal";

const LinkConnector = ({ data }) => {
	const { setIsOpen, Modal } = useModal();
	return (
		<>
			{data.modal && (
				<Modal>
					<FluidSections data={data.modal} />
				</Modal>
			)}
			{data.type === "button" ? (
				<Button
					icon={data?.iconName ?? undefined}
					linkText={data?.link}
					size={data?.size}
					to={data?.url}
					target={data?.target}
					type={data?.type}
					theme={data?.variant}
					align={data?.align}
					onClick={
						data.modal &&
						(e => {
							e.preventDefault();
							setIsOpen(true);
						})
					}
				>
					{data?.linkText}
				</Button>
			) : (
				<Link
					image={data?.image}
					imageAltText={data?.imageAltText}
					to={data?.url}
					theme={data?.variant}
					target={data?.target}
					onClick={
						data.modal &&
						(e => {
							e.preventDefault();
							setIsOpen(true);
						})
					}
				>
					{data?.linkText}
				</Link>
			)}
		</>
	);
};
LinkConnector.propTypes = {
	data: PropTypes.shape({
		image: PropTypes.arrayOf(
			PropTypes.shape({
				secure_url: PropTypes.string,
				width: PropTypes.string,
				height: PropTypes.string,
				raw_transformation: PropTypes.string,
				version: PropTypes.string,
				public_id: PropTypes.string,
				format: PropTypes.string,
			})
		),
		imageAltText: PropTypes.string,
		iconName: PropTypes.string,
		link: PropTypes.string,
		linkText: PropTypes.string,
		size: PropTypes.string,
		target: PropTypes.string,
		type: PropTypes.string,
		url: PropTypes.string.isRequired,
		variant: PropTypes.string,
		align: PropTypes.oneOf(["left", "right", "center"]),
		modal: PropTypes.object,
	}),
};
LinkConnector.defaultProps = {
	data: null,
};

export default LinkConnector;
export { LinkConnector };
