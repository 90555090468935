import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as dayjs from "dayjs";
import { Packages } from "./Packages";
import { Flights } from "./Flights";
import { Hotels } from "./Hotels";
import { Cruises } from "./Cruises";
import * as styles from "./SearchBox.module.scss";
import { PageContext } from "../PageContext";

const SearchBox = ({ type, language, appSettings }) => {
	let config = {};

	const ctx = useContext(PageContext);

	// Determine the search type to display
	let searchComponent = null;
	const searchType = type === "lastminute" ? "packages" : type;

	switch (searchType) {
		case "packages":
			config = {
				code_ag: appSettings?.packages?.code_ag || "drv",
				alias: ctx?.alias?.alias || appSettings?.packages?.alias || "drv",
				calendar: {
					defaultMin:
						typeof appSettings?.packages?.calendar.defaultMin === "number" &&
						appSettings?.packages?.calendar.defaultMin >= 0
							? appSettings.packages.calendar.defaultMin
							: 1,
					minDate: dayjs(appSettings?.packages?.calendar?.startDate).isValid()
						? dayjs(appSettings?.packages?.calendar?.startDate).diff(dayjs(), "days")
						: 0,
					maxDate:
						typeof appSettings?.packages?.calendar.maxDate === "number" &&
						appSettings?.packages?.calendar.maxDate > 0
							? appSettings.packages.calendar.maxDate
							: 365,
				},
			};

			searchComponent = <Packages language={language} config={config} />;
			break;

		case "flights":
			config = {
				calendar: {
					minRange:
						typeof appSettings?.flights?.calendar.minRange === "number" &&
						appSettings?.flights?.calendar.minRange >= 0
							? appSettings.flights.calendar.minRange
							: 1,
					maxRange:
						typeof appSettings?.flights?.calendar.maxRange === "number" &&
						appSettings?.flights?.calendar.maxRange > 0
							? appSettings.flights.calendar.maxRange
							: 7,
					minDate: dayjs(appSettings?.flights?.calendar?.startDate).isValid()
						? dayjs(appSettings?.flights?.calendar?.startDate).diff(dayjs(), "days")
						: 0,
					maxDate:
						typeof appSettings?.flights?.calendar.maxDate === "number" &&
						appSettings?.flights?.calendar.maxDate > 0
							? appSettings.flights.calendar.maxDate
							: 365,
				},
			};

			searchComponent = <Flights language={language} config={config} />;
			break;
		case "hotels":
			config = {
				code_ag: appSettings?.hotels?.code_ag || "drv",
				alias: appSettings?.hotels?.alias || "drv",
				calendar: {
					minRange:
						typeof appSettings?.hotels?.calendar.minRange === "number" &&
						appSettings?.hotels?.calendar.minRange >= 0
							? appSettings.hotels.calendar.minRange
							: 1,
					maxRange:
						typeof appSettings?.hotels?.calendar.maxRange === "number" &&
						appSettings?.hotels?.calendar.maxRange > 0
							? appSettings.hotels.calendar.maxRange
							: 7,
					minDate: dayjs(appSettings?.hotels?.calendar?.startDate).isValid()
						? dayjs(appSettings?.hotels?.calendar?.startDate).diff(dayjs(), "days")
						: 0,
					maxDate:
						typeof appSettings?.hotels?.calendar.maxDate === "number" &&
						appSettings?.hotels?.calendar.maxDate > 0
							? appSettings.hotels.calendar.maxDate
							: 365,
				},
			};

			searchComponent = <Hotels language={language} config={config} />;
			break;
		case "cruises":
			config = {
				calendar: {
					defaultMin:
						typeof appSettings?.cruises?.calendar.defaultMin === "number" &&
						appSettings?.cruises?.calendar.defaultMin >= 0
							? appSettings.cruises.calendar.defaultMin
							: 1,
					minDate: dayjs(appSettings?.cruises?.calendar?.startDate).isValid()
						? dayjs(appSettings?.cruises?.calendar?.startDate).diff(dayjs(), "days")
						: 0,
					maxDate:
						typeof appSettings?.cruises?.calendar.maxDate === "number" &&
						appSettings?.cruises?.calendar.maxDate > 0
							? appSettings.cruises.calendar.maxDate
							: 365,
				},
				duration: Array.isArray(appSettings?.cruises?.duration)
					? appSettings?.cruises?.duration
					: [],
				showOceanRiverButton: appSettings?.cruises?.showOceanRiverButton,
			};

			searchComponent = <Cruises language={language} config={config} />;
			break;
		case "small groups":
			config = {
				code_ag: appSettings?.smallGroups?.code_ag || "drv",
				alias: appSettings?.smallGroups?.alias || "grp",
				calendar: {
					defaultMin:
						typeof appSettings?.packages?.calendar.defaultMin === "number" &&
						appSettings?.packages?.calendar.defaultMin >= 0
							? appSettings.packages.calendar.defaultMin
							: 1,
					minDate: dayjs(appSettings?.packages?.calendar?.startDate).isValid()
						? dayjs(appSettings?.packages?.calendar?.startDate).diff(dayjs(), "days")
						: 0,
					maxDate:
						typeof appSettings?.packages?.calendar.maxDate === "number" &&
						appSettings?.packages?.calendar.maxDate > 0
							? appSettings.packages.calendar.maxDate
							: 365,
				},
			};

			searchComponent = <Packages language={language} config={config} />;
			break;
		default:
			searchComponent = null;
	}

	return searchComponent && <div className={cx(styles.searchbox)}>{searchComponent}</div>;
};

SearchBox.propTypes = {
	type: PropTypes.oneOf([
		"packages",
		"flights",
		"hotels",
		"cruises",
		"other",
		"lastmin",
		"smallGroups",
	]),
	language: PropTypes.oneOf(["en", "fr"]),
	appSettings: PropTypes.shape({
		packages: PropTypes.shape({
			code_ag: PropTypes.string,
			alias: PropTypes.string,
			calendar: PropTypes.shape({
				startDate: PropTypes.string,
				maxDate: PropTypes.number,
				defaultMin: PropTypes.number,
			}),
		}),
		flights: PropTypes.shape({
			code_ag: PropTypes.string,
			alias: PropTypes.string,
			calendar: PropTypes.shape({
				minRange: PropTypes.number,
				maxRange: PropTypes.number,
				startDate: PropTypes.string,
				maxDate: PropTypes.number,
			}),
		}),
		cruises: PropTypes.any,
		hotels: PropTypes.shape({
			code_ag: PropTypes.string,
			alias: PropTypes.string,
			calendar: PropTypes.shape({
				minRange: PropTypes.number,
				maxRange: PropTypes.number,
				startDate: PropTypes.string,
				maxDate: PropTypes.number,
			}),
		}),
		smallGroups: PropTypes.any,
	}),
};

SearchBox.defaultProps = {
	type: "packages",
	language: "en",
	appSettings: {
		packages: {
			code_ag: "drv",
			alias: "drv",
			calendar: {
				startDate: undefined,
				maxDate: 365,
				defaultMin: 0,
			},
		},
		flights: {
			code_ag: "drv",
			alias: "drv",
			calendar: {
				minRange: 1,
				maxRange: 7,
				startDate: undefined,
				maxDate: 365,
			},
		},
		cruises: undefined,
		hotels: {
			code_ag: "drv",
			alias: "drv",
			calendar: {
				minRange: 1,
				maxRange: 7,
				startDate: undefined,
				maxDate: 365,
			},
		},
		smallGroups: {
			code_ag: "grp",
		},
	},
};

export default SearchBox;
export { SearchBox };
