import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../FluidSections";
import * as styles from "./SplitContainer.module.scss";

const SplitContainer = ({ columns, locale }) => (
	<div className={styles.container}>
		{columns?.map((column, index) => (
			<div
				// eslint-disable-next-line react/no-array-index-key
				key={index}
				className={cx(
					styles[`mobile${column.columnSizes.mobile.value}`],
					styles[`tablet${column.columnSizes.tablet.value}`],
					styles[`desktop${column.columnSizes.desktop.value}`]
				)}
			>
				{column.components?.map(component => (
					<FluidSections data={[component]} useWrapper={false} locale={locale} />
				))}
			</div>
		))}
	</div>
);

SplitContainer.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			columnSizes: PropTypes.shape({
				mobile: PropTypes.shape({
					isCustom: PropTypes.bool,
					value: PropTypes.string,
				}),
				tablet: PropTypes.shape({
					isCustom: PropTypes.bool,
					value: PropTypes.string,
				}),
				desktop: PropTypes.shape({
					isCustom: PropTypes.bool,
					value: PropTypes.string,
				}),
			}),
			components: PropTypes.arrayOf(PropTypes.object),
		})
	),
	locale: PropTypes.string,
};

SplitContainer.defaultProps = {
	columns: undefined,
	locale: null,
};

export default SplitContainer;
export { SplitContainer };
