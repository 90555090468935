/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MainSlider } from "../MainSlider";
import { ThumbSlider } from "../ThumbSlider/ThumbSlider";
import { VideoPlayer } from "../../../components/VideoPlayer";
import { Img } from "../../Images";
import "./ReactZoom.scss";
import * as styles from "./MediaGallery.module.scss";

const SECTIONS = { VIDEO: 1, SLIDES: 2, MAP: 3 };
const MediaGallery = ({
	gallery,
	thumb,
	logo,
	video,
	map,
	detailMap,
	a11y,
	mainSliderClasses,
	thumbSliderClasses,
	className,
	accountId,
	lang,
	playerId,
	isModal,
	slideSelected,
	setSlider,
	activeSlideIndex,
	mapButton,
	videoButton,
	isVideoOpen,
	isMapOpen,
	onMapOpen,
	pagination,
	onVideoOpen,
	footerClasses,
	embeddedFooterClasses,
	otherMiscButton,
}) => {
	const getDefaultSection = () => {
		if (gallery) {
			return SECTIONS.SLIDES;
		}
		if (video) {
			return SECTIONS.VIDEO;
		}
		if (map) {
			return SECTIONS.MAP;
		}
		return null;
	};
	const [mainSlider, setMainSlider] = useState(null);
	const [videoPlayer, setVideoPlayer] = useState(null);
	const [thumbsSlider, setThumbsSlider] = useState(null);
	const [currentSection, setCurrentSection] = useState(getDefaultSection());

	useEffect(() => {
		if (thumbsSlider) {
			thumbsSlider.on("click", () => {
				setCurrentSection(SECTIONS.SLIDES);
				if (videoPlayer) {
					videoPlayer.pause();
				}
			});
		}
	}, [thumbsSlider, videoPlayer]);

	useEffect(() => {
		if (mainSlider && activeSlideIndex) {
			mainSlider.slideTo(activeSlideIndex);
		}
	}, [activeSlideIndex, mainSlider]);

	useEffect(() => {
		if (mainSlider && setSlider) {
			setSlider(mainSlider);
		}
	}, [mainSlider, setSlider]);

	useEffect(() => {
		if (isMapOpen) {
			setCurrentSection(SECTIONS.MAP);
		} else if (isVideoOpen) {
			setCurrentSection(SECTIONS.VIDEO);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMapOpen, isVideoOpen]);

	const onSectionChange = section => {
		setCurrentSection(section);
		if (mainSlider) {
			mainSlider.autoplay.stop();
		}
		if (section !== SECTIONS.video) {
			if (videoPlayer) {
				videoPlayer.pause();
			}
		}
	};

	const onMapSelected = () => {
		if (isModal) onSectionChange(SECTIONS.MAP);
		if (onMapOpen) {
			onMapOpen();
		}
	};

	const onVideoSelected = () => {
		if (isModal) onSectionChange(SECTIONS.VIDEO);
		if (onVideoOpen) onVideoOpen();
	};

	return (
		<div className={cx(styles.media, className)}>
			<div className={styles.main}>
				<div
					style={{
						display: currentSection === SECTIONS.SLIDES ? "block" : "none",
					}}
					className={styles.mainContainer}
				>
					<MainSlider
						logo={logo}
						slides={gallery}
						thumbsSlider={thumbsSlider}
						setMainSlider={setMainSlider}
						a11y={a11y}
						slideClasses={mainSliderClasses?.slide}
						navigationClasses={mainSliderClasses?.navigation}
						className={cx(className, mainSliderClasses?.slider)}
						pagination={pagination}
						slideSelected={slideSelected}
						isModal={isModal}
					/>
				</div>
				{video && video.publicId && (
					<div
						style={{
							width: "100%",
							display: currentSection === SECTIONS.VIDEO ? "block" : "none",
						}}
					>
						<VideoPlayer
							publicId={video.publicId}
							cloudName={video.cloudName}
							posterUrl={video?.poster}
							setPlayer={setVideoPlayer}
						/>
					</div>
				)}
				{map && (
					<div
						style={{
							width: "100%",
							display: currentSection === SECTIONS.MAP ? "block" : "none",
						}}
						className="MediaGallery_ReactZoom"
					>
						<TransformWrapper>
							<TransformComponent>
								<Img
									className={styles.img}
									wrapperClassName={styles.img}
									image={map}
									alt={map.alt}
									title={map.title}
								/>
							</TransformComponent>
						</TransformWrapper>
					</div>
				)}
				{!map && (
					<div
						style={{
							width: "100%",
							height: "100%",
							display: currentSection === SECTIONS.MAP ? "block" : "none",
						}}
					>
						{detailMap}
					</div>
				)}
			</div>

			<div className={cx(styles.footer, isModal ? footerClasses?.nav : embeddedFooterClasses?.nav)}>
				<div
					className={cx(
						styles.options,
						isModal ? footerClasses?.wrapper : embeddedFooterClasses?.wrapper
					)}
				>
					<div
						className={cx(
							styles.thumbs,
							isModal ? footerClasses?.thumbs : embeddedFooterClasses?.thumbs
						)}
					>
						<ThumbSlider
							slides={thumb}
							setThumbsSlider={setThumbsSlider}
							a11y={a11y}
							slideClasses={thumbSliderClasses?.slide}
							navigationClasses={thumbSliderClasses?.navigation}
							className={className}
						/>
					</div>
					{(map || detailMap || video) && (
						<div
							className={cx(
								styles.miscContainer,
								isModal ? footerClasses?.miscContainer : embeddedFooterClasses?.miscContainer
							)}
						>
							{video && video?.publicId && (
								<button
									type="button"
									className={cx(
										styles.misc,
										isModal ? footerClasses?.miscButtons : embeddedFooterClasses?.miscButtons
									)}
									onClick={onVideoSelected}
								>
									{" "}
									{videoButton}
								</button>
							)}
							{(map || detailMap) && (
								<button
									type="button"
									className={cx(
										styles.misc,
										isModal ? footerClasses?.miscButtons : embeddedFooterClasses?.miscButtons
									)}
									onClick={onMapSelected}
								>
									{mapButton}
								</button>
							)}
							{otherMiscButton && (
								<div
									className={cx(
										styles.misc,
										embeddedFooterClasses?.otherButtons,
										isModal ? footerClasses?.miscButtons : embeddedFooterClasses?.miscButtons
									)}
								>
									{otherMiscButton}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

MediaGallery.propTypes = {
	gallery: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,

			fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			title: PropTypes.string,
			alt: PropTypes.string.isRequired,

			imageTitle: PropTypes.string.isRequired,

			source: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			src: PropTypes.string,
		})
	),
	thumb: PropTypes.arrayOf(
		PropTypes.shape({
			alt: PropTypes.string.isRequired,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	),
	logo: PropTypes.arrayOf(PropTypes.object),
	video: PropTypes.shape({
		cloudName: PropTypes.string,
		poster: PropTypes.string,
		publicId: PropTypes.string,
	}),
	map: PropTypes.shape({
		id: PropTypes.string.isRequired,

		fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		title: PropTypes.string,
		alt: PropTypes.string.isRequired,

		imageTitle: PropTypes.string.isRequired,

		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		src: PropTypes.string,
	}),
	className: PropTypes.string,
	/**
	 *  Used for aria labels - please provide ALL labels
	 */
	a11y: PropTypes.shape({
		prevSlideLabel: PropTypes.string,
		nextSlideLabel: PropTypes.string,
		firstSlideLabel: PropTypes.string,
		lastSlideLabel: PropTypes.string,
		paginationBulletLabel: PropTypes.string,
		stopSliderLabel: PropTypes.string,
		startSliderLabel: PropTypes.string,
	}).isRequired,

	lang: PropTypes.oneOf(["en", "fr"]),
	accountId: PropTypes.string,
	playerId: PropTypes.string,

	mainSliderClasses: PropTypes.shape({
		slide: PropTypes.string,
		slider: PropTypes.string,
		navigation: PropTypes.shape({
			button: PropTypes.string,
			nextButton: PropTypes.string,
			prevButton: PropTypes.string,
		}),
	}).isRequired,

	thumbSliderClasses: PropTypes.shape({
		slide: PropTypes.string,
		navigation: PropTypes.shape({
			button: PropTypes.string,
			nextButton: PropTypes.string,
			prevButton: PropTypes.string,
		}),
	}).isRequired,
	mapButton: PropTypes.node,
	videoButton: PropTypes.node,
	pagination: PropTypes.func,
	footerClasses: PropTypes.shape({
		nav: PropTypes.string,
		wrapper: PropTypes.string,
		thumbs: PropTypes.string,
		miscContainer: PropTypes.string,
		miscButtons: PropTypes.string,
	}).isRequired,
	embeddedFooterClasses: PropTypes.shape({
		nav: PropTypes.string,
		wrapper: PropTypes.string,
		thumbs: PropTypes.string,
		miscContainer: PropTypes.string,
		miscButtons: PropTypes.string,
		otherButtons: PropTypes.string,
	}),
	detailMap: PropTypes.shape({
		zoom: PropTypes.number,
		markers: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				longitude: PropTypes.string,
				latitude: PropTypes.string,
				link: PropTypes.shape({ url: PropTypes.string, linkText: PropTypes.string }),
			})
		),
	}),
	otherMiscButton: PropTypes.node,
	isModal: PropTypes.bool,
	slideSelected: PropTypes.func,
	setSlider: PropTypes.func,
	activeSlideIndex: PropTypes.number,
	isVideoOpen: PropTypes.bool,
	isMapOpen: PropTypes.bool,
	onMapOpen: PropTypes.func,
	onVideoOpen: PropTypes.func,
};

MediaGallery.defaultProps = {
	className: "",
	gallery: null,
	thumb: null,
	logo: {},
	video: null,
	map: null,
	detailMap: null,
	lang: "en",
	accountId: null,
	playerId: null,
	mapButton: null,
	videoButton: null,
	pagination: null,
	otherMiscButton: null,
	isModal: false,
	slideSelected: null,
	setSlider: null,
	activeSlideIndex: 0,
	isVideoOpen: false,
	isMapOpen: false,
	onMapOpen: null,
	onVideoOpen: null,
	embeddedFooterClasses: null,
};

export default MediaGallery;
export { MediaGallery };
