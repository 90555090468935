import React from "react";
import { object } from "prop-types";
// eslint-disable-next-line import/no-cycle
import { Table } from "./Table";

const TableConnector = ({ data }) => (
	<Table
		header={data?.headers}
		rows={data?.rows}
		subtext={data?.subtext}
		headerStyle={data?.headerStyle}
		tableStyle={data?.tableStyle}
		config={data?.config}
	/>
);

TableConnector.propTypes = {
	data: object.isRequired,
};
export default TableConnector;
export { TableConnector };
