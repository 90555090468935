import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../../RCL";
import { Icon } from "../../../../Icons";

import * as inputStyles from "../../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "./HotelSearch.module.scss";

const HotelSearch = ({
	label,
	id,
	language,
	selected,
	error,
	resetError,
	onChange,
	gatewaySelected,
	isGatewayChanged,
	setGatewayChanged,
	destination,
	isDestinationChanged,
	setDestinationChanged,
}) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const [isFocus, setFocus] = useState(false);
	const [hotelData, setHotelData] = useState([]);
	const [updateHotel, setUpdateHotel] = useState(false);

	/* /////////////////////////////////////////////////////////////
	// Hotel Search typeahead logic
	///////////////////////////////////////////////////////////// */
	const chainlabel = GetDictionary("chain");
	const hotellabel = GetDictionary("hotels");
	const defaultDestination =
		"1_2_4_6_7_8_9_10_11_12_13_14_15_16_17_18_21_22_24_25_27_28_29_30_31_32_33_34_35_36_37_38_39_40_44_45_46_47_52_53_56_57_59_60_61_62_64_69_70_71_73_74_76_77_78_79_81_83_84_85_86_87_92_93_104_115_116_129_131_140_143_148_152_154_156_162_163_169_170_171_172_173_175_177_178_184_185_186_188_191_192_214_226_237_245_251_1685_1843_1860_2488_2521_2974_3006_3281_3422_4135_4244_346792_347581_348147_348362_349344_349355_349389_350814_568546_569962_710451_761711_771584_828182_835346_864654_864750_875285_893296_974878_1328096_1341400_1341882_2138511_2142208_2144022_2741642_2742791_2743497_2744551_2750814_2751301_2751661_2889252_3045371_3049109_3049111_3049115_3049117_3049121";
	const defaultchainsOnly = true;

	const transformHotelData = _hotels => {
		const template = [];
		const chainItems = [];
		const hotelItems = [];
		if (_hotels.totalHotelChains > 0) {
			_hotels.hotelChains.forEach(hotelChainsItem => {
				chainItems.push({
					value: hotelChainsItem.code,
					label: hotelChainsItem.name,
					className: cx(taStyles.childOption),
					id: hotelChainsItem.code,
				});
			});

			template.push({
				title: chainlabel,
				label: chainlabel,
				search: chainlabel,
				className: taStyles.sectionLab,
				value: chainlabel,
				items: [...chainItems],
			});
		}

		if (_hotels.totalHotels > 0) {
			_hotels.hotels.forEach(hotelsItem => {
				hotelItems.push({
					value: hotelsItem.code,
					label: hotelsItem.name,
					className: cx(taStyles.childOption),
					id: hotelsItem.code,
				});
			});
			template.push({
				title: hotellabel,
				label: hotellabel,
				search: hotellabel,
				className: taStyles.sectionLab,
				value: hotellabel,
				items: [...hotelItems],
			});
		}

		return template;
	};

	const loadHotelData = async () => {
		const apiHeaders = new Headers();
		let chainsOnlyValue;
		apiHeaders.append("Content-Type", "application/json");

		if (destination === null) {
			chainsOnlyValue = defaultchainsOnly;
		} else if (destination.label.startsWith("All_")) {
			chainsOnlyValue = true;
		} else {
			chainsOnlyValue = false;
		}

		const querybody = {
			brand: "sov",
			lang: language,
			gateway: gatewaySelected?.value,
			destinationId: destination === null ? defaultDestination : destination.value,
			chainsOnly: chainsOnlyValue,
		};

		const apiConfig = {
			method: "POST",
			headers: apiHeaders,
			body: JSON.stringify(querybody),
		};

		const apiRequest = new Request(`${process.env.GATSBY_SVHANDLER_API_BASE}/hotels`);

		return fetch(apiRequest, apiConfig)
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(res => {
				resetError(true);
				return res;
			})
			.catch(err => {
				resetError(true);
				console.error("Failed to load destinations: ", err);
				return null;
			});
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Form initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		if (isGatewayChanged) {
			(async () => {
				const hotels = await loadHotelData();

				if (hotels?.totalHotelChains > 0 || hotels?.totalHotels > 0) {
					// Update destination type-ahead
					setHotelData(transformHotelData(hotels));
					setUpdateHotel(true);
				}
			})();

			setGatewayChanged(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGatewayChanged, gatewaySelected]);

	useEffect(() => {
		if (isDestinationChanged) {
			(async () => {
				const hotels = await loadHotelData();

				if (hotels?.totalHotelChains > 0 || hotels?.totalHotels > 0) {
					// Update destination type-ahead
					setHotelData(transformHotelData(hotels));
					setUpdateHotel(true);
				}
			})();

			setDestinationChanged(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDestinationChanged, destination]);

	useEffect(() => {
		if (updateHotel) {
			setUpdateHotel(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateHotel]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={<Icon name="arrow-thick-down" />}
			className={cx(
				taStyles.hotelsearchbox,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				selectWrapper: taStyles.wrapper,
				select: taStyles.container,
				icon: taStyles.icon,
				value: taStyles.value,
				open: taStyles.open,
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={hotelData}
			selectedItem={selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onEscapeResetSelected={false}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null) {
					onChange({ type: "no_hotel", no_hotel: selection });
					resetError(true);
					if (!isFocus) {
						setFocus(false);
					} else {
						setFocus(true);
					}
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

HotelSearch.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	gatewaySelected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	destination: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	isGatewayChanged: PropTypes.bool.isRequired,
	setGatewayChanged: PropTypes.func.isRequired,
	isDestinationChanged: PropTypes.bool.isRequired,
	setDestinationChanged: PropTypes.func.isRequired,
};

HotelSearch.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
	gatewaySelected: { label: "YYZ", value: "YYZ" },
	destination: { label: "", value: "" },
};

export default HotelSearch;
export { HotelSearch };
