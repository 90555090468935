import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import * as dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import en from "rc-picker/lib/locale/en_US";
import fr from "rc-picker/lib/locale/fr_FR";
import RCMonthPicker from "rc-picker";
import { Icon } from "../../../../Icons";
import * as styles from "./styles.module.scss";

const MonthPicker = React.forwardRef(
	(
		{
			inputRef,
			locale,
			format,
			className,
			selectedDate,
			minDate,
			maxDate,
			disabled,
			onFocus,
			onBlur,
			onChange,
		},
		ref
	) => {
		const [isOpened, setIsOpened] = useState(false);
		const datePickerRef = useRef(ref);

		useEffect(() => {
			if (isOpened) {
				disableBodyScroll(datePickerRef.current);
			} else {
				clearAllBodyScrollLocks();
			}
		}, [isOpened, ref]);

		return (
			<RCMonthPicker
				ref={datePickerRef}
				prefixCls={styles.swgPicker}
				generateConfig={dayjsGenerateConfig}
				picker="month"
				locale={locale === "fr" ? { ...fr, monthFormat: "MMMM" } : { ...en, monthFormat: "MMMM" }}
				format={format}
				// Replicate default month cell render but with different formatting
				monthCellRender={month => (
					<div className={`${styles.swgPicker}-cell-inner`}>{month.format("MMM")}</div>
				)}
				className={cx(
					className,
					styles.inputGroup,
					isOpened && styles.inputGroupOpen,
					styles.monthPicker
				)}
				value={selectedDate || dayjs().startOf("day").add(1, "days")}
				open={isOpened}
				disabled={disabled}
				onFocus={onFocus}
				onBlur={onBlur}
				onChange={onChange}
				onOpenChange={open => {
					setIsOpened(open);
					if (open) {
						onFocus();
					}
				}}
				getPopupContainer={() => inputRef?.current}
				disabledDate={
					current =>
						current.isBefore(minDate || dayjs().startOf("day")) ||
						current.isAfter(maxDate || dayjs().startOf("day").add(365, "days"))
					// eslint-disable-next-line react/jsx-curly-newline
				}
				allowClear={false}
				inputReadOnly={true}
				clearIcon={null}
				suffixIcon={
					<Icon
						name="calendar-alt"
						className={styles.calendarIcon}
						onClick={() => {
							if (!disabled) {
								setIsOpened(!isOpened);
								if (!isOpened) {
									onFocus();
								}
							}
						}}
					/>
				}
				superPrevIcon={
					<Icon
						name="arrow-left"
						className={cx(styles.navigationIcon, styles.navigationIconPrev)}
					/>
				}
				superNextIcon={
					<Icon
						name="arrow-right"
						className={cx(styles.navigationIcon, styles.navigationIconNext)}
					/>
				}
				prevIcon={
					<Icon
						name="arrow-left"
						className={cx(styles.navigationIcon, styles.navigationIconPrev)}
					/>
				}
				nextIcon={
					<Icon
						name="arrow-right"
						className={cx(styles.navigationIcon, styles.navigationIconNext)}
					/>
				}
			/>
		);
	}
);

MonthPicker.propTypes = {
	inputRef: PropTypes.shape({
		current: PropTypes.any,
	}).isRequired,
	locale: PropTypes.oneOf(["en", "fr"]),
	format: PropTypes.string,
	className: PropTypes.string,
	selectedDate: PropTypes.instanceOf(dayjs),
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
	disabled: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
};

MonthPicker.defaultProps = {
	locale: "en",
	format: "D MMM YYYY",
	className: undefined,
	selectedDate: undefined,
	minDate: undefined,
	maxDate: undefined,
	disabled: false,
	onFocus: () => {},
	onBlur: () => {},
};

export default MonthPicker;
export { MonthPicker };
