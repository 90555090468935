import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as dayjs from "dayjs";
import { RCL as useTranslation } from "../../../RCL";
import { InputWrapper } from "../../Common/Components/InputWrapper";
import { RangePicker } from "../../Common/Components/DatePicker";
import * as parentStyles from "../../SearchBox.module.scss";

const DateField = ({
	id,
	language,
	onChange,
	selectedDates,
	error,
	resetError,
	minDate,
	maxDate,
}) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	// Date state
	const datePickerRef = useRef();
	const [isOpen, setOpen] = useState(false);

	return (
		<React.Fragment>
			<InputWrapper
				ref={datePickerRef}
				id={id}
				label={GetDictionary("when")}
				error={error}
				className={cx(parentStyles.date, isOpen ? parentStyles.dateOpen : "")}
			>
				{({ handleOnFocus, handleOnBlur }) => (
					<RangePicker
						id={id}
						locale={language}
						format="D MMM YYYY"
						inputRef={datePickerRef}
						selectedDates={selectedDates}
						minDate={minDate}
						maxDate={maxDate}
						onFocus={() => {
							handleOnFocus();
							resetError();
						}}
						onBlur={() => {
							handleOnBlur();
							resetError();
							setOpen(false);
						}}
						onChange={value => {
							onChange(value);
							resetError();
							setOpen(false);
						}}
						onOpen={open => {
							setOpen(open);
						}}
						default
					/>
				)}
			</InputWrapper>
		</React.Fragment>
	);
};

DateField.propTypes = {
	id: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	onChange: PropTypes.func.isRequired,
	selectedDates: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.instanceOf(dayjs),
		PropTypes.string,
	]),
	error: PropTypes.bool,
	resetError: PropTypes.func,
	minDate: PropTypes.instanceOf(dayjs),
	maxDate: PropTypes.instanceOf(dayjs),
};

DateField.defaultProps = {
	selectedDates: undefined,
	error: false,
	resetError: () => {},
	minDate: dayjs().startOf("day"),
	maxDate: dayjs().startOf("day").add(365, "days"),
};

export default DateField;
export { DateField };
