import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import gatewaysGroup from "./Menu/HeaderGateways.mock.json";
import { HeaderGatewayMenu } from "./Menu";
import * as styles from "./HeaderGateway.module.scss";

const HeaderGateway = ({ labels, currentGateway, handleChangeGateway }) => {
	const gatewaySwitcherButtonRef = useRef(null);
	const [isMenuOpened, setIsMenuOpened] = useState(false);

	const handleGatewayMenu = open => {
		setIsMenuOpened(open);

		// On close Gateway menu, set focus back to gateway switcher button
		if (!open && gatewaySwitcherButtonRef.current) {
			setTimeout(() => {
				gatewaySwitcherButtonRef.current.focus();
			}, 0);
		}
	};

	return (
		<>
			<div className={styles.gatewaySwitcher}>
				<strong>{currentGateway.label}</strong>
				<button
					type="button"
					className={styles.gatewaySwitcherButton}
					onClick={() => handleGatewayMenu(true)}
					aria-label={labels.openGatewayMenu}
					aria-expanded={isMenuOpened}
					aria-controls="main-menu-gateway-nav"
					ref={gatewaySwitcherButtonRef}
				>
					{labels.changeGateway}
				</button>
			</div>
			<HeaderGatewayMenu
				id="main-menu-gateway-nav"
				labels={{
					closeMenu: labels.closeMenu,
					gatewayHeader: labels.gatewayHeader,
				}}
				gatewaysGroup={gatewaysGroup}
				currentGateway={currentGateway}
				isOpened={isMenuOpened}
				handleChangeGateway={handleChangeGateway}
				handleGatewayMenu={handleGatewayMenu}
			/>
		</>
	);
};

HeaderGateway.propTypes = {
	labels: PropTypes.shape({
		closeMenu: PropTypes.string,
		openGatewayMenu: PropTypes.string,
		changeGateway: PropTypes.string,
		gatewayHeader: PropTypes.string,
	}),
	currentGateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	handleChangeGateway: PropTypes.func.isRequired,
};
HeaderGateway.defaultProps = {
	labels: {
		closeMenu: "Close menu",
		openGatewayMenu: "Open gateway menu",
		changeGateway: "Change gateway",
		gatewayHeader: "Gateway",
	},
	currentGateway: {
		label: "Toronto",
		value: "YYZ",
	},
};

export default HeaderGateway;
export { HeaderGateway };
