import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";
import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

const DestinationField = ({
	label,
	id,
	language,
	selected,
	error,
	resetError,
	onChange,
	onUpdateData,
	onUpdate,
	gateway,
	isGatewayChanged,
	setGatewayChanged,
}) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const [isFocus, setFocus] = useState(false);
	const [destinationData, setDestinationData] = useState([]);
	const [updateDestination, setUpdateDestination] = useState(false);

	/* /////////////////////////////////////////////////////////////
	// Destinations typeahead logic
	///////////////////////////////////////////////////////////// */

	const transformDestinationData = _destinations => {
		const template = [];

		_destinations.destinationGroups.forEach((groupItem, groupIndex) => {
			const { destinations, groupName, groupCode, durations } = groupItem;
			let destNameSingle = "";

			if (destinations?.length === 1) {
				destinations.forEach(destinationItem => {
					destNameSingle = destinationItem?.destName;
				});
			}

			const groupLabel =
				groupName === destNameSingle || destNameSingle === null || destNameSingle === ""
					? groupName
					: `${groupName} (${destNameSingle})`;

			template.push({
				value: groupCode,
				label: groupLabel,
				durations: durations.split("_").map(Number),
				id: groupLabel,
			});

			if (destinations?.length > 1) {
				template[groupIndex].items = [];

				destinations.forEach(destinationItem => {
					const { destName, destCode, durations: destDurations } = destinationItem;

					template[groupIndex].items.push({
						value: destCode,
						label: `${destName}`,
						durations: destDurations.split("_").map(Number),
						className: cx(taStyles.childOption),
						id: `${destName}`,
					});
				});
			}
		});

		return template;
	};

	const loadDestinationData = async () => {
		const apiHeaders = new Headers();
		apiHeaders.append("Content-Type", "application/json");

		const apiConfig = {
			method: "GET",
			headers: apiHeaders,
		};

		const apiRequest = new Request(
			`${process.env.GATSBY_SVHANDLER_API_BASE}/destinations/sov/${language}/${gateway?.value}`
		);

		return fetch(apiRequest, apiConfig)
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(res => {
				resetError(true);
				return res;
			})
			.catch(err => {
				resetError(true);
				console.error("Failed to load destinations: ", err);
				return null;
			});
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	const findDestinationMatch = _match => {
		let destinationMatch = [];
		if (destinationData.length > 0) {
			destinationMatch = destinationData.filter(destination => destination.value === _match.value);
		}

		if (destinationMatch?.length <= 0) {
			destinationData.forEach(regionItem => {
				if (destinationMatch?.length <= 0 && regionItem?.items?.length > 0) {
					destinationMatch = regionItem.items.filter(
						destination => destination.value === _match.value
					);
				}
			});
		}

		return destinationMatch;
	};

	/* /////////////////////////////////////////////////////////////
	// Form initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		if (isGatewayChanged) {
			(async () => {
				const destinations = await loadDestinationData();

				if (destinations?.totalDestinationGroups > 0) {
					// Update destination type-ahead
					setDestinationData(transformDestinationData(destinations));
					setUpdateDestination(true);
				}
			})();

			setGatewayChanged(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGatewayChanged, gateway]);

	useEffect(() => {
		if (updateDestination) {
			setUpdateDestination(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateDestination]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={
				<span className={inputStyles.chevron}>
					<span className="sr-only">{GetDictionary("a11y-open-menu")}</span>
				</span>
			}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				taStyles.wide,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: cx(taStyles.open, inputStyles.open),
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={destinationData}
			selectedItem={selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onEscapeResetSelected={false}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null) {
					const selectedDestination = findDestinationMatch(selection);
					onChange(selectedDestination[0]);
					onUpdateData(selectedDestination[0]);
					onUpdate(true);
					resetError(true);

					if (!isFocus) {
						setFocus(false);
					} else {
						setFocus(true);
					}
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

DestinationField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	onUpdateData: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	gateway: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	isGatewayChanged: PropTypes.bool.isRequired,
	setGatewayChanged: PropTypes.func.isRequired,
};

DestinationField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
	gateway: undefined,
};

export default DestinationField;
export { DestinationField };
