import React from "react";
import { string, shape } from "prop-types";
import { AnchorTag } from "./AnchorTag";

const AnchorTagConnector = ({ data }) => <AnchorTag id={data.id} />;

AnchorTagConnector.propTypes = {
	data: shape({
		id: string,
	}).isRequired,
};

export default AnchorTagConnector;
export { AnchorTagConnector };
