import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { RadioGroup } from "../../Common/Components/Radio/RadioGroup";

import * as parentStyles from "../../SearchBox.module.scss";
import * as styles from "../Flights.module.scss";

const FlightMode = ({ id, data, label, error, onChange, resetError, selectedOption }) => (
	<RadioGroup
		className={cx(styles.flightToggle, parentStyles.radio)}
		id={id}
		label={label}
		name={id}
		hideLabel={true}
		onChange={selection => {
			resetError(true);
			onChange(selection);
		}}
		selectedOption={selectedOption}
		error={error}
		options={data}
		inline={true}
	/>
);

FlightMode.propTypes = {
	label: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	selectedOption: PropTypes.string.isRequired,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func.isRequired,
};

FlightMode.defaultProps = {
	error: false,
};

export default FlightMode;
export { FlightMode };
