import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as ddStyles from "../../Common/CSS/Dropdown/Dropdown.module.scss";

const ClassField = ({ id, label, selected, data, error, resetError, onChange }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const [isFocus, setFocus] = useState(false);

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={<span className={inputStyles.chevron} />}
			className={cx(
				inputStyles.inputWrapper,
				ddStyles.dropdown,
				ddStyles.wide,
				ddStyles.midWide,
				isFocus ? ddStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				value: ddStyles.value,
				open: cx(ddStyles.open, inputStyles.open),
				input: ddStyles.input,
				selected: ddStyles.selected,
				highlighted: ddStyles.highlighted,
				noResults: ddStyles.noResults,
				listbox: ddStyles.listbox,
			}}
			type="dropdown"
			data={data}
			selectedItem={selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null) {
					onChange(selection);
					resetError(true);
					setFocus(false);
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

ClassField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			items: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string,
					value: PropTypes.string,
				})
			),
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
};

ClassField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
};

export default ClassField;
export { ClassField };
