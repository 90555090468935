import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { FluidSections } from "../../FluidSections";
// eslint-disable-next-line import/no-cycle
import { CardsContainerSlide } from "../../CardsContainer";
import { ITEMS_PER_ROW, NAVIGATION_TYPE, PAGINATION_TYPE } from "../../../const/const";

// TODO:: Task 99842: CardCollection, Tile Slider and Paginated collection Update

const TileSlider = ({ items, id, itemsPerRow, navigationType, paginationType }) =>
	items && (
		<CardsContainerSlide
			id={id}
			slidesPerView={itemsPerRow}
			navigationType={navigationType}
			paginationType={paginationType}
		>
			{items.map(item => (
				<FluidSections key={item.id} data={[item]} useWrapper={false} />
			))}
		</CardsContainerSlide>
	);

TileSlider.propTypes = {
	id: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sys: PropTypes.shape({
				contentType: PropTypes.shape({
					sys: PropTypes.shape({
						id: PropTypes.string.isRequired,
					}).isRequired,
				}).isRequired,
			}).isRequired,
		})
	).isRequired,
	itemsPerRow: PropTypes.oneOf(ITEMS_PER_ROW),
	navigationType: PropTypes.oneOf(NAVIGATION_TYPE).isRequired,
	paginationType: PropTypes.oneOf(PAGINATION_TYPE).isRequired,
};

TileSlider.defaultProps = {
	itemsPerRow: 3,
};

export default TileSlider;
export { TileSlider };
