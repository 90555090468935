/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { Section } from "../../../Section";
import { Row, Cell } from "../../../Grid";
import { RCL as useTranslation } from "../../../RCL";
// eslint-disable-next-line import/no-cycle
import { Button } from "../../../Button";
// eslint-disable-next-line import/no-cycle
import { EmailAcquisitionWidget } from "../EmailAcquisitionWidget";

import * as styles from "./MobileEmbed.module.scss";

const fallbackLabels = {
	title: "Subscribe now!",
	subtitle:
		"Sign up for access to exclusive Sunwing deals, promotional offers and vacation inspiration",
	submit: "Sign up",
	marketingimage:
		"https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto,h_372/SellOffVacations/Image%20Gallery/Backgrounds/shutterstock_1019613943.jpg",
};

const MobileEmbed = ({
	config,
	mode,
	isWidgetOpen,
	setWidgetOpen,
	lang,
	userEmail,
	setUserEmail,
	userGateway,
	setUserGateway,
	setRegistered,
	isRegistered,
	viewportBreakpoint,
	offsetTop,
	setExitIntentActive,
	exitIntentActive,
	exitIntentCookieName,
	exitIntentEnabled,
	handleExitDismiss,
	embed,
}) => {
	const defaultLabels = {
		title: useTranslation({ searchKey: "subscribe-to-our-sov-newsletter" }),
		subtitle: useTranslation({
			searchKey: "sign-up-for-access-to-exclusive-sov-deals-promotional-offers",
		}),
		submit: useTranslation({ searchKey: "sign-up" }),
		marketingimage: useTranslation({
			searchKey: "sign-up-for-access-to-exclusive-sov-deals-image",
		}),
	};

	let labels = {
		...fallbackLabels,
		...defaultLabels,
	};

	const marketingContent = {
		marketingimage: config?.marketing?.inline?.mobileBanner?.image || labels.marketingimage,
		title: config?.marketing?.inline?.mobileBanner?.title || labels.title,
		subtitle: config?.marketing?.inline?.mobileBanner?.subtitle || labels.subtitle,
	};

	if (marketingContent?.marketingimage) {
		const needsCropping = /\/v[\d]+\//.test(marketingContent.marketingimage);

		if (needsCropping) {
			const getMatch = marketingContent.marketingimage.match(/\/(v[\d]+)\//);
			marketingContent.marketingimage = marketingContent.marketingimage.replace(
				getMatch[1],
				"f_auto,q_auto,h_372"
			);
		}
	}

	labels = {
		...labels,
		...marketingContent,
	};

	const displaySignUp = () => {
		setWidgetOpen(true);
	};

	return (
		<>
			<Section padding="none">
				<div className={styles.formSection}>
					<div className={styles.formWrapper}>
						<Cell className={styles.imageBanner}>
							<img src={labels.marketingimage} alt="" />
						</Cell>
						<Cell className={styles.formContainer}>
							<Row cols={1} className={styles.formElements}>
								<Cell>
									<Section padding="none">
										<span className={styles.heading}>{labels.title}</span>

										<div
											className={styles.subtitle}
											dangerouslySetInnerHTML={{ __html: labels.subtitle }}
										/>

										<div className={styles.inputContainer}>
											<div className={styles.submitButton}>
												<Button type="button" size="md" onClick={() => displaySignUp()}>
													{labels.submit}
												</Button>
											</div>
										</div>
									</Section>
								</Cell>
							</Row>
						</Cell>
					</div>
				</div>
			</Section>
			<EmailAcquisitionWidget
				embed={embed}
				config={config}
				mode={mode}
				isWidgetOpen={isWidgetOpen}
				setWidgetOpen={setWidgetOpen}
				lang={lang}
				userEmail={userEmail}
				setUserEmail={setUserEmail}
				userGateway={userGateway}
				setUserGateway={setUserGateway}
				setRegistered={setRegistered}
				isRegistered={isRegistered}
				viewportBreakpoint={viewportBreakpoint}
				offsetTop={offsetTop}
				setExitIntentActive={setExitIntentActive}
				exitIntentActive={exitIntentActive}
				exitIntentCookieName={exitIntentCookieName}
				exitIntentEnabled={exitIntentEnabled}
				handleExitDismiss={handleExitDismiss}
			/>
		</>
	);
};

MobileEmbed.propTypes = {
	mode: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	isWidgetOpen: PropTypes.bool.isRequired,
	setWidgetOpen: PropTypes.func.isRequired,
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	userEmail: PropTypes.string,
	setUserEmail: PropTypes.func.isRequired,
	userGateway: PropTypes.object,
	setUserGateway: PropTypes.func.isRequired,
	setRegistered: PropTypes.func.isRequired,
	isRegistered: PropTypes.string,
	viewportBreakpoint: PropTypes.number.isRequired,
	offsetTop: PropTypes.number.isRequired,
	setExitIntentActive: PropTypes.func.isRequired,
	exitIntentActive: PropTypes.bool.isRequired,
	exitIntentCookieName: PropTypes.string.isRequired,
	exitIntentEnabled: PropTypes.bool.isRequired,
	handleExitDismiss: PropTypes.func.isRequired,
	embed: PropTypes.bool,
};

MobileEmbed.defaultProps = {
	userEmail: "",
	userGateway: undefined,
	isRegistered: null,
	embed: true,
};

export default MobileEmbed;
export { MobileEmbed };
