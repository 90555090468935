import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { MediaGalleryEmbedded as MediaGalleryComponent } from "../../sharedComponents/MediaGallery";
import { GoogleMap } from "../GoogleMap";
import { RCL, RCL as useTranslation } from "../RCL";
import { Icon } from "../Icons";
import "./ThumbSlider.scss";
import * as modalStyles from "../Modal/Modal.module.scss";
import * as styles from "./MediaGallery.module.scss";

const MediaGallery = ({
	gallery,
	thumb,
	logo,
	video,
	map,
	detailMap,
	lang,
	otherMiscButton,
	accountId,
	playerId,
	onModalOpen,
	onModalClose,
}) => {
	const dictionary = {
		"gallery-pagination": useTranslation({ searchKey: "gallery-pagination" }),
	};
	const [showContent, setShowContent] = useState(true);
	useEffect(() => {
		if (gallery.length <= 1) {
			setShowContent(false);
		}
	}, [gallery.length]);
	return (
		<MediaGalleryComponent
			className={styles.mediaGallery}
			gallery={gallery}
			thumb={thumb}
			otherMiscButton={otherMiscButton}
			a11y={{
				prevSlideLabel: "prevSlideLabel",
				nextSlideLabel: "nextSlideLabel",
				firstSlideLabel: "firstSlideLabel",
				lastSlideLabel: "lastSlideLabel",
				paginationBulletLabel: "paginationBulletLabel {{index}}",
				stopSliderLabel: "stopSliderLabel",
				startSliderLabel: "startSliderLabel",
			}}
			accountId={accountId}
			playerId={playerId}
			lang={lang}
			video={video}
			map={map}
			onModalOpen={onModalOpen}
			onModalClose={onModalClose}
			detailMap={
				detailMap && (
					<GoogleMap
						mapEntries={detailMap.markers}
						initZoom={detailMap.zoom}
						displayList={true}
						mapKey={process.env.GATSBY_GOOGLE_CLOUD_PLATFORM_API_KEY}
					/>
				)
			}
			// eslint-disable-next-line no-unused-vars
			pagination={(swiper, current, total) => {
				let paginationText = dictionary["gallery-pagination"];
				paginationText = paginationText?.replace("{0}", current).replace("{1}", total);

				if (total > 1) {
					return `<span class="${styles.pagination}">${paginationText}</span>`;
				}

				return null;
			}}
			mapButton={
				<>
					<Icon name="map" />
					<RCL searchKey="map" />
				</>
			}
			closeButton={<Icon name="close--n" className="swg-text-3xl" />}
			modalClasses={{
				content: styles.modalContent,
				modal: styles.modal,
				wrapper: styles.modalContentWrapper,
				overlay: modalStyles.overlay,
				close: modalStyles.close,
			}}
			videoButton={
				<>
					<Icon name="video" />
					<RCL searchKey="video" />
				</>
			}
			footerClasses={{
				nav: cx(styles.nav, !showContent ? styles.hide : undefined),
				wrapper: styles.wrapper,
				thumbs: styles.thumbs,
				miscContainer: styles.miscContainer,
				miscButtons: styles.misc,
			}}
			embeddedFooterClasses={{
				nav: cx(styles.nav, !showContent ? styles.hide : undefined),
				wrapper: styles.wrapper,
				thumbs: styles.thumbs,
				miscContainer: styles.embMiscContainer,
				miscButtons: cx(styles.misc, styles.embMisc),
				otherButtons: styles.embOtherMisc,
			}}
			sliderClasses={{
				main: {
					slide: styles.mainSlide,
					slider: styles.mainSlider,
					navigation: {
						button: cx(styles.mainNavigationButton, !showContent ? styles.hide : undefined),
						nextButton: styles.mainNavigationNextButton,
						prevButton: styles.mainNavigationPrevButton,
					},
				},
				thumb: {
					slide: styles.thumbSlide,
					navigation: {
						button: styles.thumbNavigationButton,
						nextButton: styles.thumbNavigationNextButton,
						prevButton: styles.thumbNavigationPrevButton,
					},
				},
			}}
			logo={logo}
		/>
	);
};
MediaGallery.propTypes = {
	gallery: PropTypes.arrayOf(
		PropTypes.shape({
			data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
			source: PropTypes.shape({
				src: PropTypes.string,
				srcSet: PropTypes.string,
				sizes: PropTypes.string,
			}),
			alt: PropTypes.string.isRequired,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	).isRequired,
	thumb: PropTypes.arrayOf(
		PropTypes.shape({
			alt: PropTypes.string.isRequired,
			src: PropTypes.string,
			width: PropTypes.number,
			height: PropTypes.number,
		})
	).isRequired,
	logo: PropTypes.arrayOf(PropTypes.object),
	lang: PropTypes.oneOf(["en", "fr"]),
	video: PropTypes.shape({
		cloudName: PropTypes.string,
		poster: PropTypes.string,
		publicId: PropTypes.string,
	}),
	map: PropTypes.shape({
		data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		source: PropTypes.shape({
			src: PropTypes.string,
			srcSet: PropTypes.string,
			sizes: PropTypes.string,
		}),
		alt: PropTypes.string.isRequired,
		src: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	detailMap: PropTypes.shape({
		zoom: PropTypes.number,
		markers: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
				longitude: PropTypes.number,
				latitude: PropTypes.number,
				link: PropTypes.shape({ url: PropTypes.string, linkText: PropTypes.string }),
			})
		),
	}),
	otherMiscButton: PropTypes.node,
	accountId: PropTypes.string,
	playerId: PropTypes.string,
	onModalClose: PropTypes.func,
	onModalOpen: PropTypes.func,
};
MediaGallery.defaultProps = {
	logo: null,
	lang: "en",
	video: null,
	map: null,
	detailMap: null,
	otherMiscButton: null,
	accountId: process.env.GATSBY_BRIGHTCOVE_ACCOUNT_ID,
	playerId: process.env.GATSBY_BRIGHTCOVE_PLAYER_ID,
	onModalClose: null,
	onModalOpen: null,
};

export default MediaGallery;
export { MediaGallery };
