import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { SlideInMenuSubMenuItem } from "./SlideInMenuSubMenuItem";
import * as styles from "./SlideInMenuSubMenu.module.scss";

const variants = {
	open: {
		overflow: "auto",
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.05,
		},
	},
	closed: {
		overflow: "hidden",
		transition: {
			delay: 0.4,
			staggerChildren: 0.05,
			staggerDirection: 1,
		},
	},
};

const SlideInMenuSubMenu = ({ menuLinks, menuOpenState, unmountTrap, renderMenuLink }) => (
	<motion.ul
		className={styles.subMenuContainer}
		initial={false}
		variants={variants}
		animate={menuOpenState ? "open" : "closed"}
	>
		{menuLinks.map(menuLink => (
			<SlideInMenuSubMenuItem
				key={menuLink.id}
				menuLink={menuLink}
				menuOpenState={menuOpenState}
				unmountTrap={unmountTrap}
				renderMenuLink={renderMenuLink}
			/>
		))}
	</motion.ul>
);

SlideInMenuSubMenu.propTypes = {
	menuLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
	menuOpenState: PropTypes.bool,
	unmountTrap: PropTypes.func.isRequired,
	renderMenuLink: PropTypes.func,
};

SlideInMenuSubMenu.defaultProps = {
	menuOpenState: false,
	renderMenuLink: (link, { classNames }) => (
		<a href={link.url} target={link.target} className={classNames?.link}>
			{link.linkText}
		</a>
	),
};

export default SlideInMenuSubMenu;
export { SlideInMenuSubMenu };
