/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Accordion as AccordionComponent, PanelCard } from "@sunwing/shared-components";
import { ToggleTemplate } from "./ToggleTemplate";
import * as styles from "./Accordion.module.scss";

const AccordionThemes = ["default", "image", "custom", "qa"];

const Accordion = ({
	activeKey,
	tabTheme,
	className,
	activeClassName,
	tabData,
	iconActiveClass,
	iconInactiveClass,
}) => {
	const renderImage = imageData => (
		<img
			src={imageData.src}
			className={cx(styles.accordionImage, imageData?.className || "")}
			alt={imageData.alt || ""}
		/>
	);

	return (
		<AccordionComponent activeKey={activeKey}>
			{tabData?.map(tab => (
				<PanelCard
					key={tab.key}
					eventKey={tab.key}
					className={cx(styles.accordion, styles[`${tabTheme}Card`], className || "")}
				>
					<PanelCard.Toggle
						isDisabled={tab.disabled}
						onToggle={tab.handleOnToggle}
						className={cx(styles[`${tabTheme}Toggle`], tab.disabled && styles.disabled)}
						activeClassName={activeClassName}
					>
						<ToggleTemplate
							variant={tabTheme}
							className={cx({
								[styles[`${tabTheme}Toggle`]]: tabTheme,
								[styles.disabled]: tab.disabled,
							})}
							icon={tab.icon}
							customClass={activeClassName}
							iconActiveClass={iconActiveClass}
							iconInactiveClass={iconInactiveClass}
							isDisabled={tab.disabled}
						>
							<span className={cx(styles.accordionLabel)}>
								{tab?.toggle?.image && (
									<span className={cx(styles.imageContainer)}>
										{
											// RENDER IMAGE OVERRIDE METHOD
											tab?.toggle?.image?.renderImage &&
												!tab?.toggle?.image?.src &&
												tab.toggle.image.renderImage(
													styles.accordionImage,
													tab.toggle.image?.className || ""
												)
										}
										{
											// RENDER INCLUDED IMAGE
											tab?.toggle?.image?.src &&
												renderImage(
													tab.toggle.image,
													cx(styles.accordionImage, tab.toggle.image?.className || "")
												)
										}
									</span>
								)}

								{tab?.toggle?.label && (
									<span className={styles.accordionHeader}>
										{tab.toggle.label?.heading}
										{tab.toggle.label?.subHeading && tabTheme !== "default" && (
											<span>{tab.toggle.label.subHeading}</span>
										)}
									</span>
								)}
							</span>
						</ToggleTemplate>
					</PanelCard.Toggle>
					<PanelCard.Panel>{tab?.panel}</PanelCard.Panel>
				</PanelCard>
			))}
		</AccordionComponent>
	);
};

Accordion.propTypes = {
	/**
	 * Accordion panel default to open
	 */
	activeKey: PropTypes.string,
	/**
	 * Classname is set on the outer container accordion-section="wrapper"
	 */
	className: PropTypes.string,
	/**
	 * Classname is set on the toggle anchor tag
	 */
	activeClassName: PropTypes.string,
	/**
	 * image.src requires the use of a 16 by 9 ratio
	 */
	tabData: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
			icon: PropTypes.string,
			handleOnToggle: PropTypes.func,
			toggle: PropTypes.shape({
				label: PropTypes.shape({
					heading: PropTypes.string.isRequired,
					subHeading: PropTypes.string,
				}).isRequired,
				/**
				 * This design make use of a 16 by 9 ratio
				 */
				image: PropTypes.shape({
					src: PropTypes.string.isRequired,
					alt: PropTypes.string,
					className: PropTypes.string,
					renderImage: PropTypes.func,
				}),
			}),
			panel: PropTypes.node,
		})
	),
	tabTheme: PropTypes.oneOf([...AccordionThemes]),
	iconActiveClass: PropTypes.string,
	iconInactiveClass: PropTypes.string,
};

Accordion.defaultProps = {
	activeKey: undefined,
	className: undefined,
	activeClassName: "active",
	tabData: undefined,
	tabTheme: "default",
	iconActiveClass: undefined,
	iconInactiveClass: undefined,
};

export default Accordion;
export { Accordion, AccordionThemes, PanelCard };
