import React from "react";
import PropTypes from "prop-types";

const CardContent = ({ children, className }) => <div className={className}>{children}</div>;

CardContent.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

CardContent.defaultProps = {
	className: undefined,
};

export default CardContent;
export { CardContent };
