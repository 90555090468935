const validateRoomOccupancies = ({ rooms, adults, children, infants } = {}) => {
	// Validate equal number of adults per room
	const validRoomAdultCombination = adults % rooms === 0;

	// Validate max 1 room if there are children
	const validRoomChildrenCombination = !(rooms > 1 && children.length + infants);

	// Validate children ages are entered
	const validChildrenAges = children?.map(age => age !== "--");

	const isValid =
		validRoomAdultCombination &&
		validRoomChildrenCombination &&
		validChildrenAges.every(validAge => validAge);

	return {
		isValid,
		isValidRoomCombination: validRoomAdultCombination && validRoomChildrenCombination,
		isValidAdultsCombination: validRoomAdultCombination,
		isValidChildrenCombination: validRoomChildrenCombination || !children.length,
		isValidChildAges: validChildrenAges,
		isValidInfantsCombination: validRoomChildrenCombination || !infants,
	};
};

export default validateRoomOccupancies;
export { validateRoomOccupancies };
