import React from "react";
import PropTypes from "prop-types";
import { Button } from "../Button";
import * as styles from "./WelcomeBar.module.scss";
import { Icon } from "../Icons";

// eslint-disable-next-line no-unused-vars
const WelcomeBar = ({ labels, setExitModal }) => (
	<div className={styles.WelcomeBar}>
		{typeof labels.welcomeLabel === "object" ? (
			labels?.welcomeLabel
		) : (
			// eslint-disable-next-line react/no-danger
			<span dangerouslySetInnerHTML={{ __html: labels.welcomeLabel }} />
		)}
		<Button noStyle className={styles.WelcomeBar__button} onClick={() => setExitModal(true)}>
			<span className={styles.WelcomeBar__buttonLabel}>{labels.exit}</span>
			<Icon className={styles?.WelcomeBar__buttonIcon} name="close" />
		</Button>
	</div>
);

WelcomeBar.propTypes = {
	labels: PropTypes.shape({
		welcomeLabel: PropTypes.string,
		exit: PropTypes.string,
	}),
	setExitModal: PropTypes.func,
};

WelcomeBar.defaultProps = {
	labels: {
		welcomeMessage: "",
		exit: "Exit",
	},
	setExitModal: () => {},
};

export default WelcomeBar;
export { WelcomeBar };
