import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Card as CardComponent } from "@sunwing/shared-components";
import {
	Heading,
	HeadingAlign,
	HeadingAsElement,
	HeadingSize,
	HeadingWeight,
} from "../../../Heading";
import * as headingStyles from "../../../Heading/Heading.module.scss";

const CardSubheaderComponent = CardComponent.Subheader;
const CardSubheader = ({ className, as, align, uppercase, size, weight, children }) => (
	<CardSubheaderComponent
		as={as}
		align={align}
		uppercase={uppercase}
		className={cx(className)}
		classNames={headingStyles}
		SubheadingComponent={Heading}
		size={size}
		weight={weight}
	>
		{children}
	</CardSubheaderComponent>
);

CardSubheader.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	as: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	align: PropTypes.oneOf(Object.keys(HeadingAlign)),
	size: PropTypes.oneOf(Object.keys(HeadingSize)),
	weight: PropTypes.oneOf(Object.keys(HeadingWeight)),
	uppercase: PropTypes.bool,
};

CardSubheader.defaultProps = {
	className: undefined,
	as: HeadingAsElement.p,
	align: "left",
	size: undefined,
	weight: undefined,
	uppercase: false,
};

export default CardSubheader;
export { CardSubheader };
