// extracted by mini-css-extract-plugin
export var primary = "PrimaryCard-module--primary--2yrMF";
export var compact = "PrimaryCard-module--compact--3DOv1";
export var primaryImage = "PrimaryCard-module--primaryImage--Ad6g-";
export var primaryImageWrapper = "PrimaryCard-module--primaryImageWrapper--b5RE6";
export var primaryBody = "PrimaryCard-module--primaryBody--2oY2I";
export var primaryHeading = "PrimaryCard-module--primaryHeading--2v2Od";
export var primarySubheading = "PrimaryCard-module--primarySubheading--2ZEHH";
export var primaryHeader = "PrimaryCard-module--primaryHeader--2QtXV";
export var primaryFooter = "PrimaryCard-module--primaryFooter--1N0SN";
export var primaryLogo = "PrimaryCard-module--primaryLogo--3yoKH";
export var square = "PrimaryCard-module--square--3X3si";
export var wide = "PrimaryCard-module--wide--MIk2v";
export var landscape = "PrimaryCard-module--landscape--rnZw3";
export var primaryCardLink = "PrimaryCard-module--primaryCardLink--1r1ZB";
export var primaryButton = "PrimaryCard-module--primaryButton--2F0LJ";
export var activeLink = "PrimaryCard-module--activeLink--3FI7s";