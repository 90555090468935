/* eslint-disable camelcase */
import { shape, string } from "prop-types";

const CloudinaryImageAdapter = cloudinaryImage => {
	let transformedURL = "";

	// get the user set transformations
	const transformations = cloudinaryImage?.raw_transformation;

	if (cloudinaryImage?.width && cloudinaryImage?.width <= 1200) {
		// if the image is less than or equal to 1200px wide
		transformedURL = cloudinaryImage?.secure_url;
	} else if (cloudinaryImage?.width && cloudinaryImage?.width > 1200) {
		// if the image is greater than 1200px wide

		// split the transformations into presets and custom rules
		const transformationSets = transformations.split("/");
		transformedURL = "https://assets.sunwingtravelgroup.com/image/upload";

		// process the transformations
		for (let i = 0; i < transformationSets.length; i++) {
			if (i < transformationSets.length - 1) {
				// Apply any preset transformations
				transformedURL = `${transformedURL}/${transformationSets[i]}`;
			} else {
				// Process custom rule transformations
				const customTransformations = transformationSets[i].split(",");
				let autoQuality = false;
				let autoFormat = false;
				let widthSet = false;
				let imageFit = false;

				for (let j = 0; j < customTransformations.length; j++) {
					// Determine if required custom rules are already set
					if (customTransformations[j] === "f_auto") {
						autoQuality = true;
					} else if (customTransformations[j] === "q_auto") {
						autoFormat = true;
					} else if (customTransformations[j] === "w_1200") {
						widthSet = true;
					} else if (customTransformations[j] === "c_fit") {
						imageFit = true;
					}
				}

				// Apply the existing custom rule transformations
				transformedURL = `${transformedURL}/${transformationSets[i]}`;

				// If no preset transformations existed, scale down the image quality
				if (transformationSets.length < 2) {
					if (!imageFit) {
						transformedURL = `${transformedURL},c_fit`;
					}

					if (!widthSet) {
						transformedURL = `${transformedURL},w_1200`;
					}
				}

				// If not already applied, set image quality and format to auto
				if (!autoFormat) {
					transformedURL = `${transformedURL},f_auto`;
				}

				if (!autoQuality) {
					transformedURL = `${transformedURL},q_auto`;
				}
			}
		}

		// Finish building the formatted url
		transformedURL = `${transformedURL}/v${cloudinaryImage?.version}/${cloudinaryImage?.public_id}.${cloudinaryImage?.format}`;
	} else if (cloudinaryImage?.secure_url) {
		// if no image width could be found retun the original secure url
		transformedURL = cloudinaryImage?.secure_url;
	} else {
		// if no image url could be found retun null
		transformedURL = null;
	}

	return transformedURL;
};

CloudinaryImageAdapter.propTypes = {
	cloudinaryImage: shape({
		secure_url: string,
		width: string,
		height: string,
		raw_transformation: string,
		version: string,
		public_id: string,
		format: string,
	}),
};
CloudinaryImageAdapter.defaultProps = {
	cloudinaryImage: null,
};

export default CloudinaryImageAdapter;
export { CloudinaryImageAdapter };
