import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import deepmerge from "deepmerge";
import * as styles from "./BookingSearchBox.module.scss";

let _isLoaded = false;
const _eventQueue = [];

const setBookingSearchBox = (
	{ from, to, isHotel = false, force = false } = {},
	priority = 100,
	trigger = false
) => {
	if (!trigger) {
		const settings = {
			packages: {},
			flights: {},
			cruises: {},
		};

		if (from) {
			settings.packages.selectedFrom = {
				value: from,
				force,
			};
			settings.flights.selectedFrom = {
				value: from,
				force,
			};
			settings.cruises.selectedFrom = {
				value: from,
				force,
			};
		}

		if (to) {
			settings.packages.selectedTo = {
				value: to,
				isHotel,
				force,
			};
		}

		_eventQueue.push({ settings, priority });
	}

	if (!_isLoaded && !trigger) {
		return false;
	}

	const _prioritizedQueue = _eventQueue
		.sort((a, b) => a.priority - b.priority)
		.map(event => event.settings);

	if (_prioritizedQueue.length) {
		const settings = deepmerge.all(_prioritizedQueue);
		window.WEB_CDN_BSB.overrideDefaults(settings);
		_eventQueue.length = 0;
	}

	return true;
};

const BookingSearchBox = ({ lang, type, mode }) => {
	useEffect(() => {
		let bsbScript;

		return () => {
			if (bsbScript) {
				_isLoaded = false;
				if (window.WEB_CDN_BSB && window.WEB_CDN_BSB.destroy) {
					window.WEB_CDN_BSB.destroy();
				}
				bsbScript.parentNode.removeChild(bsbScript);
			}
		};
	}, []);

	return (
		<div className={cx(styles.bar, styles[type === "other" ? "packages" : type])}>
			<div
				id="booking-search-box-wrapper"
				className={styles.placeholder}
				data-lang={lang}
				data-alias="btd"
				data-type={type}
				data-mode={mode}
			/>
		</div>
	);
};

BookingSearchBox.propTypes = {
	lang: PropTypes.oneOf(["en", "fr"]).isRequired,
	type: PropTypes.oneOf(["packages", "flights", "hotels", "cruises", "other"]),
	mode: PropTypes.oneOf(["search", "tabs"]),
};

BookingSearchBox.defaultProps = {
	type: "packages",
	mode: "search",
};

export default BookingSearchBox;
export { BookingSearchBox, setBookingSearchBox };
