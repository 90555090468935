import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const SocialMeta = ({ location, type, image, lang, pageUrl }) => (
	<Helmet>
		{type && <meta property="og:type" content={type} />}
		<meta itemProp="url" property="og:url" content={pageUrl ?? location.href} />

		{image?.url && <meta property="og:image" content={image.url} />}
		{image?.width && <meta property="og:image:width" content={image.width} />}
		{image?.height && <meta property="og:image:height" content={image.height} />}
		{image?.url && <meta name="twitter:image" content={image.url} />}

		<meta property="og:site_name" content={process.env.GATSBY_SITE_NAME} />

		{lang && <meta property="fb:locale" content={lang} />}
		{lang && <meta property="og:locale" content={lang} />}

		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:site" content={process.env.GATSBY_SITE_NAME} />

		<meta name="pinterest-rich-pin" content="true" />
	</Helmet>
);

SocialMeta.propTypes = {
	location: PropTypes.shape({
		href: PropTypes.string,
	}),
	pageUrl: PropTypes.string,
	type: PropTypes.string,
	image: PropTypes.object,
	lang: PropTypes.string,
};
SocialMeta.defaultProps = {
	location: undefined,
	pageUrl: undefined,
	type: null,
	image: null,
	lang: null,
};

export default SocialMeta;
export { SocialMeta };
