import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { setLanguageCookie } from "../../utils/helper";
import { LanguageToggle } from "../../components/LanguageToggle";

const LanguageToggleConnector = ({ locale, classNames, altLangPages }) => (
	<LanguageToggle
		locale={locale}
		classNames={classNames}
		altLangPages={altLangPages}
		renderLanguageSwitcher={(id, path, lang, className, label) => (
			<button
				key={id}
				data-id={id}
				type="button"
				data-lang={lang.toLowerCase()}
				onClick={() => {
					setLanguageCookie(lang);
					navigate(path);
				}}
				className={className}
			>
				{label}
			</button>
		)}
	/>
);

LanguageToggleConnector.propTypes = {
	locale: PropTypes.string,
	classNames: PropTypes.shape({
		container: PropTypes.string,
		currentLanguage: PropTypes.string,
		altLanguage: PropTypes.string,
	}),
	altLangPages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
			lang: PropTypes.string.isRequired,
		})
	).isRequired,
};

LanguageToggleConnector.defaultProps = {
	classNames: {},
	locale: "en-CA",
};

export default LanguageToggleConnector;
export { LanguageToggleConnector };
