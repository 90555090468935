import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { renderReactChildrenWithData } from "../../../../../utils";
import * as styles from "./InputWrapper.module.scss";

const cx = classNames.bind(styles);

const InputWrapper = React.forwardRef(
	({ id, label, children, className, error, onFocus, onBlur }, ref) => {
		const [isFocused, setIsFocused] = useState(false);

		const handleOnFocus = (overrideCondition = false) => {
			if (onFocus() || (typeof overrideCondition === "boolean" && overrideCondition)) {
				setIsFocused(true);
			}
		};

		const handleOnBlur = (overrideCondition = false) => {
			if (onBlur() || (typeof overrideCondition === "boolean" && overrideCondition)) {
				setIsFocused(false);
			}
		};

		return (
			<div
				ref={ref}
				className={cx("container", className, isFocused && "focused", error && styles.error)}
			>
				<label id={id} htmlFor={`${id}-input`}>
					<span className={cx("label")}>{label}</span>
				</label>
				{renderReactChildrenWithData(children, {
					className: "input",
					isFocused,
					handleOnFocus,
					handleOnBlur,
				})}
			</div>
		);
	}
);

InputWrapper.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	className: PropTypes.string,
	error: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

InputWrapper.defaultProps = {
	className: undefined,
	error: false,
	onFocus: () => true,
	onBlur: () => true,
};

export default InputWrapper;
export { InputWrapper };
