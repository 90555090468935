import Cookie from "universal-cookie";

const getURLQueries = _query => {
	const allURLData = {
		queries: [],
		hash: "",
	};

	const valuePairs = [];
	const urlHash = window.location.hash;
	const hashExists = !!urlHash;

	const checkForQuery =
		window.location.search.indexOf("?") + 1 < window.location.search.length
			? window.location.href.indexOf("?") + 1
			: false;
	let query;
	if (checkForQuery) {
		const queries = window.location.href.replace(urlHash, "").slice(checkForQuery).split("&");
		const tempURLVars = [];

		if (queries?.length > 0) {
			for (let i = 0; i < queries.length; i++) {
				query = queries[i].split("=");
				/* eslint-disable prefer-destructuring */
				valuePairs[query[0]] = query[1];
				tempURLVars[query[0]] = query[1];
			}

			allURLData.queries = tempURLVars;
		} else {
			allURLData.queries = null;
		}
	}

	if (hashExists) {
		allURLData.hash = urlHash;
	}

	if (_query && _query.length > 0) {
		return allURLData.queries[_query];
	}
	return allURLData;
};

const setCookie = (cName, cValue, cPath, cDomain, cExpiry) => {
	const cookie = new Cookie();
	cookie.set(cName, cValue, {
		path: cPath,
		domain: cDomain,
		expires: cExpiry,
	});
};

const getCookie = cName => {
	const cookie = new Cookie();
	return cookie.get(cName);
};

const removeCookie = (cName, cPath, cDomain) => {
	const cookie = new Cookie();
	cookie.remove(cName, { path: cPath, domain: cDomain });
};

const setLanguageCookie = language => {
	// Set cookie date
	const expiration = new Date();
	expiration.setUTCMonth(expiration.getMonth() + 1);

	// const cookieDomain = window.location.hostname.replace(/^www\./, ".");
	const subdomainRegex = /^[^.]+\./;

	const cookieDomain = subdomainRegex.test(window.location.hostname)
		? window.location.hostname.replace(subdomainRegex, ".")
		: `.${window.location.hostname}`;

	setCookie("nf_lang", language.toLowerCase(), "/", cookieDomain, expiration);
};

const validateReturnURL = _url => {
	// Handle Base64 URLs
	let cleanedDestination = typeof _url !== "undefined" ? _url : "";
	if (cleanedDestination.length > 4 && cleanedDestination.substring(0, 4) !== "http") {
		cleanedDestination = atob(cleanedDestination);

		// Check if it is valid URL, otherwise revert back
		if (cleanedDestination.substring(0, 4) !== "http") {
			cleanedDestination = "";
		}
	}

	return cleanedDestination;
};

export default getURLQueries;
export { getURLQueries, setLanguageCookie, setCookie, getCookie, removeCookie, validateReturnURL };
