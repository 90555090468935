import React from "react";
import PropTypes from "prop-types";
import * as styles from "./AnchorTag.module.scss";

const AnchorTag = ({ id }) => <div id={id} className={styles.tag} />;

AnchorTag.propTypes = {
	id: PropTypes.string.isRequired,
};

export default AnchorTag;
export { AnchorTag };
