// extracted by mini-css-extract-plugin
export var formSection = "EmailAcquisitionForm-module--formSection--14Cvm";
export var formWrapper = "EmailAcquisitionForm-module--formWrapper--2h2TW";
export var imageBanner = "EmailAcquisitionForm-module--imageBanner--2MYgO";
export var exitIntent = "EmailAcquisitionForm-module--exitIntent--1wj52";
export var closeIcon = "EmailAcquisitionForm-module--closeIcon--_eBbK";
export var formContainer = "EmailAcquisitionForm-module--formContainer--3C1rH";
export var formElements = "EmailAcquisitionForm-module--formElements--1nrT_";
export var heading = "EmailAcquisitionForm-module--heading--3UMqz";
export var subtitle = "EmailAcquisitionForm-module--subtitle--2uSh8";
export var inputContainer = "EmailAcquisitionForm-module--inputContainer--2v8QF";
export var splitContainer = "EmailAcquisitionForm-module--splitContainer---98NF";
export var submitButton = "EmailAcquisitionForm-module--submitButton--3AipB";
export var legal = "EmailAcquisitionForm-module--legal--2QpAi";
export var link = "EmailAcquisitionForm-module--link--28juY";
export var alignRight = "EmailAcquisitionForm-module--alignRight--3P2Ei";
export var nativeSelectContainer = "EmailAcquisitionForm-module--nativeSelectContainer--x39qe";
export var nativeSelect = "EmailAcquisitionForm-module--nativeSelect--2f25T";
export var nativeSelectError = "EmailAcquisitionForm-module--nativeSelectError--3_-oh";
export var chevron = "EmailAcquisitionForm-module--chevron--3uWW4";
export var signUpFormAlert = "EmailAcquisitionForm-module--signUpFormAlert--2Yr9F";
export var confirmationWrapper = "EmailAcquisitionForm-module--confirmationWrapper--fQlwe";
export var confirmation = "EmailAcquisitionForm-module--confirmation--37Jct";
export var message = "EmailAcquisitionForm-module--message--2yhzv";
export var confirmButton = "EmailAcquisitionForm-module--confirmButton--2hJ2n";
export var hiddenFields = "EmailAcquisitionForm-module--hiddenFields--2RXuR";
export var modal = "EmailAcquisitionForm-module--modal--1tPKP";
export var errorText = "EmailAcquisitionForm-module--errorText--3K37H";
export var inputError = "EmailAcquisitionForm-module--inputError--1JZvE";
export var gatewayInput = "EmailAcquisitionForm-module--gatewayInput--1J2Me";
export var dropdownMobile = "EmailAcquisitionForm-module--dropdownMobile--2p3Y6";
export var dropdownDesktop = "EmailAcquisitionForm-module--dropdownDesktop--2DozC";