import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { HotelCardTabs } from "../../components/HotelCardTabs";
import { LOCAL_EN, LOCAL_FR } from "../../const/const";

const PromotionRuleConnector = ({ data, locale }) =>
	data?.fields?.admodule && data?.fields?.admodule.length > 0 ? (
		<HotelCardTabs
			data={data?.fields?.admodule.filter(i => i)}
			locale={locale}
			numberToShow={data?.numberToShow ?? undefined}
			moreToShow={data?.moreToShow ?? undefined}
			cardsPerRow={data?.cardsPerRow ?? undefined}
		/>
	) : (
		<></>
	);
PromotionRuleConnector.propTypes = {
	data: PropTypes.shape({
		numberToShow: PropTypes.number,
		moreToShow: PropTypes.number,
		cardsPerRow: PropTypes.number,
		fields: PropTypes.shape({
			admodule: PropTypes.arrayOf(
				PropTypes.shape({
					Gateway: PropTypes.shape({
						Code: PropTypes.string,
						Name: PropTypes.string,
					}),
					PromotionGroups: PropTypes.arrayOf(
						PropTypes.shape({
							Description: PropTypes.string,
							Offers: PropTypes.arrayOf(
								PropTypes.shape({
									AccommodationInfo: PropTypes.shape({
										AccommodationCode: PropTypes.string,
										AccommodationName: PropTypes.string,
										StarRating: PropTypes.number,
									}),
									CalendarLink: PropTypes.string,
									DeepLink: PropTypes.string,
									DepartureDate: PropTypes.string,
									Destination: PropTypes.shape({
										CountryName: PropTypes.string,
										Name: PropTypes.string,
										ProviderCode: PropTypes.string,
									}),
									Duration: PropTypes.number,
									Gateway: PropTypes.shape({
										Code: PropTypes.string,
										Name: PropTypes.string,
									}),
									MealPlan: PropTypes.string,
									OfferType: PropTypes.string,
									Price: PropTypes.number,
									RegPrice: PropTypes.number,
									SaveUpto: PropTypes.number,
									tax: PropTypes.string,
								})
							),
							title: PropTypes.string,
						})
					),
				})
			),
		}),
	}).isRequired,
	locale: PropTypes.oneOf([LOCAL_FR, LOCAL_EN]),
};
PromotionRuleConnector.defaultProps = {
	locale: LOCAL_EN,
};
export default PromotionRuleConnector;
export { PromotionRuleConnector };
