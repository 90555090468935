// extracted by mini-css-extract-plugin
export var hotelsearchbox = "HotelSearch-module--hotelsearchbox--2cMKW";
export var container = "HotelSearch-module--container--1IKvc";
export var open = "HotelSearch-module--open--DrD9W";
export var input = "HotelSearch-module--input--3dGWg";
export var icon = "HotelSearch-module--icon--2nyJx";
export var listbox = "HotelSearch-module--listbox--1mwoQ";
export var noResults = "HotelSearch-module--noResults--3b5rb";
export var item = "HotelSearch-module--item--1IkHD";
export var highlighted = "HotelSearch-module--highlighted--3voWn";
export var selected = "HotelSearch-module--selected--1I79v";
export var flat = "HotelSearch-module--flat--1MqQp";
export var focus = "HotelSearch-module--focus--R7VYu";
export var wide = "HotelSearch-module--wide--2axYJ";
export var disabled = "HotelSearch-module--disabled--2EUzL";
export var wrapper = "HotelSearch-module--wrapper--3BcjJ";
export var childOption = "HotelSearch-module--childOption--2XDDe";
export var sectionLab = "HotelSearch-module--sectionLab--1_W8H";