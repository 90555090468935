import { Card as CardComponent } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/Card/index.css";
import { CardButton } from "./CardComponents/CardButton/CardButton";
import { CardAsLink } from "./CardComponents/CardAsLink/CardAsLink";
import { CardHeader } from "./CardComponents/CardHeader/CardHeader";
import { CardSubheader } from "./CardComponents/CardSubheader/CardSubheader";
import { CardContent } from "./CardComponents/CardContent/CardContent";

const Card = CardComponent;

Card.Header = CardHeader;
Card.Subheader = CardSubheader;
Card.Button = CardButton;
Card.AsLink = CardAsLink;
Card.Content = CardContent;

export default Card;
export { Card };
