import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";
import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

const CruiseLineField = ({
	label,
	id,
	selected,
	error,
	resetError,
	onChange,
	destination,
	cruiseMode,
}) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });
	const allCruiseLinesRCLKey = useTranslation({ searchKey: "all-cruise-lines" });

	const [isFocus, setFocus] = useState(false);
	const [cruiseLineData, setCruiseLineData] = useState([]);
	const [isInit, setInit] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Cruise line typeahead logic
	///////////////////////////////////////////////////////////// */

	const transformCruiseLineData = (_cruiselines, _keys) => {
		// Transform data from API response to type-ahead data format
		let template = [];

		_keys.forEach((key, cruiseLineIndex) => {
			const cruiseItem = _cruiselines[key];

			template.push({
				value: cruiseItem.value,
				label:
					cruiseItem?.text.toLowerCase() === "all cruise lines"
						? allCruiseLinesRCLKey
						: cruiseItem.text,
				id: cruiseItem.id,
			});

			const cruiseLineItems = cruiseItem.s ?? {};

			if (cruiseLineItems?.length === undefined) {
				template[cruiseLineIndex].items = [];
				const regionKeys = Object.keys(cruiseLineItems);

				regionKeys.forEach(regionKey => {
					const regionItem = cruiseLineItems[regionKey];

					const regionSubItems = regionItem.s;

					if (regionSubItems && regionSubItems?.length === undefined) {
						const regionSupKeys = Object?.keys(regionSubItems);

						regionSupKeys.forEach(regionSubKey => {
							const regionSubItem = regionSubItems[regionSubKey];

							template[cruiseLineIndex].items.push({
								value: regionSubItem.value,
								label: regionSubItem.text,
								id: regionSubItem.id,
								className: cx(taStyles.childOption),
							});
						});
					}
					template[cruiseLineIndex].items.push({
						value: regionItem.value,
						label: regionItem.text,
						id: regionItem.id,
						className: cx(taStyles.childOption),
					});
				});
			}
		});

		let sortedArray = [template[0]];

		template.shift();

		// Sort cruise lines by label
		template = template.sort((a, b) => {
			const sortMethod = (a.label > b.label) - (b.label > a.label);
			return sortMethod;
		});

		sortedArray = [...sortedArray, ...template];
		return sortedArray;
	};

	const loadCruiseData = async did => {
		try {
			const option = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					did,
				}),
			};

			let url = "/api/cruise-vendors";
			if (cruiseMode === "river") {
				url = "/api/cruise-river-vendors";
			}

			const response = await fetch(url, option);
			const returnObject = await response.json();

			resetError(true);
			return returnObject;
		} catch (err) {
			resetError(true);
			console.error("Failed to load vendors: ", err);
			return null;
		}
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Input initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		// Initial component load sequence
		(async () => {
			if (destination === null) return;

			const cruises = await loadCruiseData(destination.value);
			const data = cruises?.v || null;
			const keys = data ? Object.keys(cruises?.v) : [];

			if (keys.length > 0 && data) {
				setCruiseLineData(transformCruiseLineData(data, keys));
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destination]);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={
				<span className={inputStyles.chevron}>
					<span className="sr-only">{GetDictionary("a11y-open-menu")}</span>
				</span>
			}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				taStyles.wide,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: cx(taStyles.open, inputStyles.open),
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: taStyles.listbox,
			}}
			type="typeahead"
			data={cruiseLineData}
			selectedItem={selected === null ? cruiseLineData[0] : selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onEscapeResetSelected={false}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null && selection !== selected) {
					onChange(selection);
					resetError(true);
					setInit(false);

					if (isInit || (selection?.value === "" && !isFocus)) {
						setFocus(false);
					} else {
						setFocus(true);
					}
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

CruiseLineField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	destination: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	cruiseMode: PropTypes.string.isRequired,
};

CruiseLineField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
	destination: null,
};

export default CruiseLineField;
export { CruiseLineField };
