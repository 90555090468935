import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import * as styles from "./LanguageToggle.module.scss";

const LanguageToggle = ({ altLangPages, classNames, locale, renderLanguageSwitcher }) => (
	<div className={cx(styles.container, classNames.container)}>
		{altLangPages?.map(({ id, path, lang }) => {
			if (locale?.toUpperCase()?.substring(0, 2) === lang?.toUpperCase()?.substring(0, 2)) {
				return (
					<span key={id} data-id={id} className={classNames.currentLanguage}>
						{lang === "FR" ? "Français" : "English"}
					</span>
				);
			}

			return renderLanguageSwitcher(
				id,
				path,
				lang,
				classNames.altLanguage,
				lang === "FR" ? "Français" : "English"
			);
		})}
	</div>
);

LanguageToggle.propTypes = {
	altLangPages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
			lang: PropTypes.string.isRequired,
		})
	).isRequired,
	classNames: PropTypes.shape({
		container: PropTypes.string,
		currentLanguage: PropTypes.string,
		altLanguage: PropTypes.string,
	}),
	locale: PropTypes.string,
	renderLanguageSwitcher: PropTypes.func,
};

LanguageToggle.defaultProps = {
	classNames: {},
	locale: "en-CA",
	renderLanguageSwitcher: (id, path, lang, className, label) => (
		<a key={id} className={className} href={path}>
			{label}
		</a>
	),
};

export default LanguageToggle;
