import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { SplitContainer } from "../../components/SplitContent/SplitContainer";

const SplitContentContainer = ({ data, locale }) => (
	<SplitContainer columns={data.columns} locale={locale} />
);

SplitContentContainer.propTypes = {
	data: PropTypes.shape({
		columns: PropTypes.arrayOf(
			PropTypes.shape({
				columnSizes: PropTypes.shape({
					mobile: PropTypes.shape({
						isCustom: PropTypes.bool,
						value: PropTypes.string,
					}),
					tablet: PropTypes.shape({
						isCustom: PropTypes.bool,
						value: PropTypes.string,
					}),
					desktop: PropTypes.shape({
						isCustom: PropTypes.bool,
						value: PropTypes.string,
					}),
				}),
				components: PropTypes.arrayOf(PropTypes.object),
			})
		),
	}),
	locale: PropTypes.string,
};
SplitContentContainer.defaultProps = {
	data: null,
	locale: null,
};

export default SplitContentContainer;
export { SplitContentContainer };
