import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { motion, useReducedMotion } from "framer-motion";
import cx from "classnames";
import { staggerList } from "../../../../utils/animations";
import * as styles from "./SlideInMenuSubMenu.module.scss";

const SlideInMenuSubMenuItem = ({ menuLink, menuOpenState, unmountTrap, renderMenuLink }) => {
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const shouldReduceMotion = useReducedMotion();

	const handleSubMenuDropdown = () => {
		setIsMenuOpened(!isMenuOpened);
	};

	// Close sub menu if main menu closes
	useEffect(() => {
		if (!menuOpenState) {
			setIsMenuOpened(false);
		}
	}, [menuOpenState]);

	return (
		<motion.li className={styles.item} variants={{ ...staggerList(shouldReduceMotion) }}>
			{menuLink.links ? (
				<>
					{menuLink.displayTitle && (
						<button
							className={styles.dropdownToggle}
							type="button"
							onClick={handleSubMenuDropdown}
							aria-label={menuLink.displayTitle}
							aria-haspopup={true}
							aria-expanded={isMenuOpened}
						>
							<span>{menuLink.displayTitle}</span>
							<span className={styles.accordionChevron} />
						</button>
					)}
					<ul
						className={cx(styles.dropdownContainer, isMenuOpened && styles.dropdownContainerOpen)}
					>
						{menuLink.links.map(nestedLink => (
							<li className={styles.dropdownCategory} key={nestedLink.id}>
								{nestedLink.displayTitle && (
									<p className={styles.dropdownCategoryTitle}>{nestedLink.displayTitle}</p>
								)}
								{nestedLink.links ? (
									<ul>
										{nestedLink.links.map(link =>
											link.links ? (
												<li key={link.displayTitle}>
													<ul>
														{link.links.map(subLink => (
															<li key={subLink.linkText}>
																{renderMenuLink(subLink, {
																	activeClassName: styles.active,
																	unmountTrap,
																})}
															</li>
														))}
													</ul>
												</li>
											) : (
												<li key={link.linkText}>
													{renderMenuLink(link, {
														activeClassName: styles.active,
														unmountTrap,
													})}
												</li>
											)
										)}
									</ul>
								) : (
									renderMenuLink(nestedLink, {
										className: styles.dropdownCategoryItem,
										activeClassName: styles.active,
										unmountTrap,
									})
								)}
							</li>
						))}
					</ul>
				</>
			) : (
				renderMenuLink(menuLink, {
					className: styles.link,
					activeClassName: styles.active,
					unmountTrap,
				})
			)}
		</motion.li>
	);
};

SlideInMenuSubMenuItem.propTypes = {
	menuLink: PropTypes.object,
	menuOpenState: PropTypes.bool.isRequired,
	unmountTrap: PropTypes.func.isRequired,
	renderMenuLink: PropTypes.func,
};
SlideInMenuSubMenuItem.defaultProps = {
	menuLink: {},
	renderMenuLink: (link, { classNames }) => (
		<a href={link.url} target={link.target} className={classNames}>
			{link.linkText}
		</a>
	),
};

export default SlideInMenuSubMenuItem;
export { SlideInMenuSubMenuItem };
