import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-cycle
import { ResponsiveBanner } from "./ResponsiveBanner";

const ResponsiveBannerConnector = ({ data }) => {
	const layout = data.layout === "Contained" ? "contained" : "full";

	return (
		<ResponsiveBanner
			layout={layout}
			url={data.bannerUrl}
			target={data.target}
			description={data.bannerDescription}
			alignment={data.buttonAlignment}
			button={data.button}
			banners={data.banners}
			margins={data.margins}
			useDynamicButton={data.useDynamicButton}
		/>
	);
};

ResponsiveBannerConnector.propTypes = {
	data: PropTypes.shape({
		layout: PropTypes.string,
		bannerUrl: PropTypes.string,
		bannerDescription: PropTypes.string,
		target: PropTypes.string,
		buttonAlignment: PropTypes.string,
		button: PropTypes.shape({
			variant: PropTypes.string,
			url: PropTypes.string,
			target: PropTypes.string,
			size: PropTypes.string,
			linkText: PropTypes.string,
		}),
		margins: PropTypes.arrayOf(PropTypes.string),
		useDynamicButton: PropTypes.bool,
		banners: PropTypes.arrayOf([
			PropTypes.shape({
				aspectRatio: PropTypes.string,
				imageWidth: PropTypes.number,
				viewportWidth: PropTypes.number,
				cloudinaryAsset: PropTypes.arrayOf([
					PropTypes.shape({
						format: PropTypes.string,
						height: PropTypes.number,
						width: PropTypes.number,
						version: PropTypes.number,
						public_id: PropTypes.string,
						raw_transformation: PropTypes.string,
						secure_url: PropTypes.string,
						url: PropTypes.string,
					}),
				]),
			}),
		]),
	}),
};
ResponsiveBannerConnector.defaultProps = {
	data: undefined,
};

export default ResponsiveBannerConnector;
export { ResponsiveBannerConnector };
