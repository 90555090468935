// extracted by mini-css-extract-plugin
export var tabs = "HotelCardTabs-module--tabs--3NqH8";
export var dropdownPanWrap = "HotelCardTabs-module--dropdownPanWrap--1Sw16";
export var dropdownPan = "HotelCardTabs-module--dropdownPan--JR_Su";
export var singleTabHeader = "HotelCardTabs-module--singleTabHeader--3ugiD";
export var flightsMessage = "HotelCardTabs-module--flightsMessage--3uJ29";
export var listContainerNavCustom = "HotelCardTabs-module--listContainerNavCustom--1j7UG";
export var listContainerToggleButtons = "HotelCardTabs-module--listContainerToggleButtons--YE8J9";
export var button = "HotelCardTabs-module--button--kjILp";
export var chevron = "HotelCardTabs-module--chevron--23HVE";
export var buttonToggledOff = "HotelCardTabs-module--buttonToggledOff--1UY_U";
export var buttonIcon = "HotelCardTabs-module--buttonIcon--LWeQw";
export var buttonToggled = "HotelCardTabs-module--buttonToggled--3EDOn";
export var listContainerHeadingCustom = "HotelCardTabs-module--listContainerHeadingCustom--1ZZdi";
export var listContainerCustom = "HotelCardTabs-module--listContainerCustom--2pAnc";
export var groupCustom = "HotelCardTabs-module--groupCustom--mVTJv";
export var groupHeadingCustom = "HotelCardTabs-module--groupHeadingCustom--dhJhd";
export var groupListCustom = "HotelCardTabs-module--groupListCustom--3OgBT";