import React from "react";
import PropTypes from "prop-types";
import { SocialMeta } from "./SocialMeta";
import { CloudinaryImageAdapter } from "../CloudinaryImageAdapter/CloudinaryImageAdapter";

const SocialMetaConnector = ({ location, pageUrl, metaData, locale }) => (
	<SocialMeta
		location={location}
		pageUrl={pageUrl}
		type={metaData.type && metaData.type}
		image={
			metaData?.cloudinaryImage?.[0]
				? {
						url: CloudinaryImageAdapter(metaData?.cloudinaryImage?.[0]),
						width: metaData?.cloudinaryImage?.[0].width,
						height: metaData?.cloudinaryImage?.[0].height,
				  }
				: metaData?.image?.file?.url && {
						url: `https:${metaData.image.file.url}`,
						width: metaData.image.file.details.image.width,
						height: metaData.image.file.details.image.height,
				  }
		}
		lang={locale && locale.replace(/-/g, "_")}
	/>
);

SocialMetaConnector.propTypes = {
	location: PropTypes.shape({
		href: PropTypes.string,
	}),
	pageUrl: PropTypes.string,
	metaData: PropTypes.shape({
		type: PropTypes.string,
		image: PropTypes.object,
		cloudinaryImage: PropTypes.arrayOf(PropTypes.object),
	}),
	locale: PropTypes.string,
};
SocialMetaConnector.defaultProps = {
	location: undefined,
	pageUrl: undefined,
	metaData: undefined,
	locale: undefined,
};

export default SocialMetaConnector;
export { SocialMetaConnector };
