import React from "react";
import PropTypes from "prop-types";
import { RCL as useTranslation } from "../RCL";
import { HeadingAsElement } from "../Heading";
import { Alert } from "./Alert";
import * as styles from "./Alert.module.scss";

const defaultClassNames = {
	heading: styles.alertHeading,
};

const AlertConnector = ({
	className,
	classNames: providedClassNames,
	children,
	heading,
	headingAs,
	variant,
	hide,
	dismissible,
	icon,
	textWrapper,
}) => {
	const classNames = { ...defaultClassNames, ...providedClassNames };

	return (
		<Alert
			className={className}
			classNames={classNames}
			heading={heading}
			headingAs={headingAs}
			variant={variant}
			hide={hide}
			dismissible={dismissible}
			labels={{
				closeAlert: useTranslation({ searchKey: "close-alert" }),
			}}
			icon={icon}
			textWrapper={textWrapper}
		>
			{children}
		</Alert>
	);
};

AlertConnector.propTypes = {
	className: PropTypes.string,
	classNames: PropTypes.shape({
		heading: PropTypes.string,
	}),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	heading: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	headingAs: PropTypes.oneOf(Object.keys(HeadingAsElement)),
	variant: PropTypes.oneOf(["success", "danger", "warning", "info", "none"]),
	hide: PropTypes.bool,
	dismissible: PropTypes.bool,
	icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
	textWrapper: PropTypes.bool,
};

AlertConnector.defaultProps = {
	className: undefined,
	classNames: undefined,
	children: undefined,
	heading: undefined,
	headingAs: HeadingAsElement.div,
	variant: "danger",
	hide: false,
	dismissible: true,
	icon: undefined,
	textWrapper: false,
};

export default AlertConnector;
export { AlertConnector };
