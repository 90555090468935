import React from "react";
import PropTypes from "prop-types";
import { MultiImageBlock } from "@sunwing/shared-components";
import "@sunwing/shared-components/dist/MultiImageBlock/index.css";
import { CloudinaryImageAdapter } from "../../components/CloudinaryImageAdapter";
import { Img } from "../../sharedComponents/Images";

const MultiImageBlockConnector = ({ data }) => {
	const images = data?.cloudinaryImages?.map(image => ({
		...CloudinaryImageAdapter({
			cloudinaryImage: image,
			maxWidth: 1200,
			title: image.title,
			desc: image.title,
			sizes: "(max-width:400px) 80vw, 1200px",
			imageQuality: [1, 2, 3],
			disableAutoGravity: true,
		}),
	}));
	return (
		<MultiImageBlock
			images={images}
			logo={{
				...CloudinaryImageAdapter({
					cloudinaryImage: data.logo?.[0],
					title: data.title,
					aspectRatio: 2.17,
				}),
			}}
			populateImagesRightToLeft={data.populateImagesRightToLeft}
			populateImagesAsRows={data.populateImagesAsRows}
			columnProportions={data.columnProportions}
			photoCredit={data.photoCredit}
			// eslint-disable-next-line react/jsx-props-no-spreading
			renderImage={image => <Img image={image} />}
		/>
	);
};

MultiImageBlockConnector.propTypes = {
	data: PropTypes.shape({
		cloudinaryImages: PropTypes.arrayOf(PropTypes.object),
		images: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				title: PropTypes.string,
				file: PropTypes.shape({
					url: PropTypes.string,
				}),
			})
		),
		logo: PropTypes.arrayOf(PropTypes.object),
		populateImagesRightToLeft: PropTypes.bool,
		populateImagesAsRows: PropTypes.bool,
		columnProportions: PropTypes.string,
		photoCredit: PropTypes.string,
		title: PropTypes.string,
	}),
};
MultiImageBlockConnector.defaultProps = {
	data: null,
};

export default MultiImageBlockConnector;
export { MultiImageBlockConnector };
