/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { Heading, HeadingAsElement } from "../Heading";
import { Icon } from "../Icons";
import { Button } from "../Button";
import { RCL as useTranslation } from "../RCL";
import { PageContext } from "../PageContext";
import { DAYS_NAMES } from "../../const/const";
import * as styles from "./Contact.module.scss";
import { Link } from "../Link";

const defaultLabels = {
	closed: "Closed",
};

const daysList = (_days, _lang) => {
	const days = Object.keys(_days).filter(key => _days[key]);
	return days
		.map(d => DAYS_NAMES[_lang][d])
		.filter(d => d)
		.join(", ");
};
const getTiming = (openAt, closeAt, _lang) =>
	_lang === "fr"
		? `${dayjs(openAt).format("HH:mm")} - ${dayjs(closeAt).format("HH:mm")}`
		: `${dayjs(openAt).format("hh:mma")} - ${dayjs(closeAt).format("hh:mma")}`;

const Timings = ({ timings }) => {
	const pageContext = useContext(PageContext);

	const closed = useTranslation({ searchKey: "closed" });
	const lang = pageContext?.lang || "en";
	return (
		<>
			<span className={styles.weekDays}>{daysList(timings, lang)}</span>
			{lang === "fr" ? " " : ""}:{" "}
			<strong>
				{timings?.close
					? closed || defaultLabels.closed
					: getTiming(timings?.openAt, timings?.closeAt, lang)}
			</strong>
		</>
	);
};

Timings.propTypes = {
	timings: PropTypes.shape({
		close: PropTypes.bool,
		openAt: PropTypes.string,
		closeAt: PropTypes.string,
	}),
};

Timings.defaultProps = {
	timings: [],
};

const Contact = ({
	title,
	titleAs,
	address,
	addressLink,
	googleReviewLink,
	timings,
	note,
	phones,
	variant,
}) => {
	const pageContext = useContext(PageContext);
	const googleReview = useTranslation({ searchKey: "google-review" }) || "Google review";
	const googleReviewLinkButton =
		useTranslation({ searchKey: "google-review-link-button" }) || "Google review link button";

	return (
		<div className={cx(styles.contact, { [styles[variant]]: variant })}>
			<Heading as={titleAs} size="h1" className={styles.contactTitle}>
				{title}
			</Heading>
			{address && (
				<div className={cx(styles.contactMap)}>
					<Icon name="map" className={cx(styles.contactMapIcon)} />
					<a
						target="_blank"
						href={addressLink ?? `https://www.google.com/maps/search/${address}`}
						className={styles.contactMapLink}
						rel="noreferrer"
					>
						{address}
					</a>
				</div>
			)}

			{timings && (
				<div className={cx(styles.contactHours)}>
					<ul>
						{timings.map((item, idx) => (
							// eslint-disable-next-line react/no-array-index-key
							<li key={idx}>
								<Timings timings={item} />
							</li>
						))}
					</ul>
				</div>
			)}

			{note && (
				<div
					className={styles.contactNote}
					dangerouslySetInnerHTML={{ __html: note.childMarkdownRemark.html }}
				/>
			)}

			{phones && (
				<div className={styles.contactPhones}>
					<ul>
						{phones.map(item => {
							const number = item.content?.childMarkdownRemark?.html?.replace(/<[^>]+>/g, "");
							const primaryPhone = (
								<>
									{item.label}
									{pageContext?.lang === "fr" ? " " : ""}:{" "}
									<a href={`tel:${number}`} className={styles.contactPhonesLink}>
										{number}
									</a>
								</>
							);
							const secondaryPhone = <Button to={`tel:${number}`}>{number}</Button>;
							return (
								<li key={item.id}>{variant === "secondary" ? secondaryPhone : primaryPhone}</li>
							);
						})}
					</ul>
				</div>
			)}
			{googleReviewLink && (
				<div className={styles.googleReviewBtn}>
					<Icon name="review" title={googleReviewLinkButton} className={styles.icon} />
					<Link
						to={googleReviewLink?.childMarkdownRemark?.rawMarkdownBody}
						target="_blank"
						className={styles.content}
					>
						{googleReview}
					</Link>
				</div>
			)}
		</div>
	);
};

Contact.displayName = "Contact";

Contact.propTypes = {
	title: PropTypes.string.isRequired,
	titleAs: PropTypes.oneOf([...Object.keys(HeadingAsElement)]),
	address: PropTypes.string,
	addressLink: PropTypes.string,
	googleReviewLink: PropTypes.shape({
		childMarkdownRemark: PropTypes.shape({
			rawMarkdownBody: PropTypes.string,
		}),
	}),
	phones: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			content: PropTypes.shape({
				childMarkdownRemark: PropTypes.shape({
					html: PropTypes.string,
				}),
			}),
		})
	),
	timings: PropTypes.arrayOf(
		PropTypes.shape({
			close: PropTypes.bool,
			closeAt: PropTypes.string,
			friday: PropTypes.bool,
			monday: PropTypes.bool,
			openAt: PropTypes.string,
			saturday: PropTypes.bool,
			sunday: PropTypes.bool,
			thursday: PropTypes.bool,
			tuesday: PropTypes.bool,
			wednesday: PropTypes.bool,
		})
	),
	note: PropTypes.shape({
		childMarkdownRemark: PropTypes.shape({
			html: PropTypes.string,
		}),
	}),
	variant: PropTypes.oneOf(["primary", "secondary"]),
};
Contact.defaultProps = {
	address: {},
	addressLink: {},
	googleReviewLink: {},
	phones: [],
	timings: [],
	note: {},
	variant: "primary",
	titleAs: HeadingAsElement.h2,
};

export default Contact;
export { Contact };
