import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Section as SectionComponent } from "@sunwing/shared-components";
import {
	Heading,
	HeadingAsElement as SectionTitleAsElement,
	HeadingSize as SectionTitleSize,
	HeadingWeight as SectionTitleWeight,
	headingStyles,
} from "../Heading";
import * as styles from "./Section.module.scss";

const SectionAsElement = {
	section: "section",
	header: "header",
	footer: "footer",
};

const SectionTheme = {
	light: "light",
	dark: "dark",
	none: "none",
	transparent: "transparent",
};

const SectionPadding = {
	none: "none",
	default: "normal",
	top: "top",
	bottom: "bottom",
};

const SectionTitleAlign = {
	left: "left",
	center: "center",
};

const sectionClassNames = {
	"section__background-image-container": styles.sectionBackgroundImageContainer,
};

const Section = ({
	className,
	as,
	theme,
	padding,
	full,
	backgroundImage,
	titleId,
	title,
	titleAs,
	titleSize,
	titleWeight,
	titleAlignment,
	titleUppercase,
	relativeContainer,
	relativeInnerContainer,
	children,
}) => {
	const classNames = cx({
		[styles[theme]]: theme && styles[theme],
		[styles[padding]]: padding && styles[padding],
		[styles.full]: full,
		[styles.bgImg]: backgroundImage,
		[styles.wrapperRelative]: relativeContainer,
		[styles.contentRelative]: relativeInnerContainer,
		[className]: className,
	});
	return (
		<SectionComponent
			className={classNames}
			classNames={{ ...styles, ...sectionClassNames }}
			as={as}
			theme={theme}
			padding={padding}
			full={full}
			backgroundImage={backgroundImage}
			TitleComponent={Heading}
			titleId={titleId}
			titleClassNames={headingStyles}
			title={title}
			titleAs={titleAs}
			titleSize={titleSize}
			titleWeight={titleWeight}
			titleAlignment={titleAlignment}
			titleUppercase={titleUppercase}
		>
			{children}
		</SectionComponent>
	);
};

Section.displayName = "Section";

Section.propTypes = {
	className: PropTypes.string,
	/**
	 * Import SectionAsElement for list of `as` available
	 */
	as: PropTypes.oneOf(Object.keys(SectionAsElement)),
	/**
	 * Import SectionTheme for list of `theme` available
	 */
	theme: PropTypes.oneOf(Object.keys(SectionTheme)),
	/**
	 * Import SectionPadding for list of `padding` available
	 */
	padding: PropTypes.oneOf(Object.values(SectionPadding)),
	full: PropTypes.bool,
	backgroundImage: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	titleId: PropTypes.string,
	title: PropTypes.node,
	/**
	 * Import SectionTitleAsElement for list of `titleAs` available
	 */
	titleAs: PropTypes.oneOf(Object.keys(SectionTitleAsElement)),
	/**
	 * Import SectionTitleSize for list of `titleSize` available
	 */
	titleSize: PropTypes.oneOf(Object.keys(SectionTitleSize)),
	/**
	 * Import SectionTitleAlign for list of `titleAlignment` available
	 */
	titleAlignment: PropTypes.oneOf(Object.keys(SectionTitleAlign)),
	/**
	 * Import SectionTitleWeight for list of `titleWeight` available
	 */
	titleWeight: PropTypes.oneOf(Object.keys(SectionTitleWeight)),
	titleUppercase: PropTypes.bool,
	relativeContainer: PropTypes.bool,
	relativeInnerContainer: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Section.defaultProps = {
	className: undefined,
	as: SectionAsElement.section,
	theme: SectionTheme.light,
	padding: SectionPadding.default,
	full: false,
	backgroundImage: undefined,
	titleId: undefined,
	title: undefined,
	titleAs: SectionTitleAsElement.h2,
	titleSize: undefined,
	titleWeight: undefined,
	titleAlignment: SectionTitleAlign.center,
	titleUppercase: false,
	children: undefined,
	relativeContainer: false,
	relativeInnerContainer: false,
};

export default Section;
export {
	Section,
	SectionAsElement,
	SectionTheme,
	SectionPadding,
	SectionTitleAsElement,
	SectionTitleSize,
	SectionTitleWeight,
	SectionTitleAlign,
};
