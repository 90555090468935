import React from "react";
import PropTypes from "prop-types";
import { HardCodedComponent } from "../../components/HardCodedComponent";

const HardCodedComponentConnector = ({ data }) => (
	<HardCodedComponent
		javaScriptContainer={data?.javaScriptContainer?.childMarkdownRemark?.rawMarkdownBody}
		cssContainer={data?.cssContainer?.childMarkdownRemark?.rawMarkdownBody}
		markup={data?.markup?.markup}
		javaScriptFiles={data?.javaScriptFiles}
		onFileLoad={data?.onFileLoad}
	/>
);

HardCodedComponentConnector.propTypes = {
	data: PropTypes.shape({
		javaScriptContainer: PropTypes.shape({
			childMarkdownRemark: PropTypes.shape({
				rawMarkdownBody: PropTypes.string,
			}),
		}),
		cssContainer: PropTypes.shape({
			childMarkdownRemark: PropTypes.shape({
				rawMarkdownBody: PropTypes.string,
			}),
		}),
		markup: PropTypes.shape({
			markup: PropTypes.string,
		}),
		javaScriptFiles: PropTypes.arrayOf([PropTypes.string]),
		onFileLoad: PropTypes.bool,
	}),
};
HardCodedComponentConnector.defaultProps = {
	data: null,
};

export default HardCodedComponentConnector;
export { HardCodedComponentConnector };
