import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import iconsList from "@sunwing/icons/dist/sov/icons.json";
import * as styles from "./Icons.module.scss";

const Icon = ({ name, className, title, hidden }) => (
	<span
		className={cx(`sov-icon sov-icon-${name}`, styles.icon, className)}
		aria-label={title}
		aria-hidden={hidden}
	/>
);
Icon.displayName = "Icon";

Icon.propTypes = {
	name: PropTypes.oneOf(iconsList).isRequired,
	className: PropTypes.string,
	title: PropTypes.string,
	hidden: PropTypes.bool,
};
Icon.defaultProps = {
	className: undefined,
	title: undefined,
	hidden: true,
};

export default Icon;
export { Icon, iconsList };
