const openLinkWithPostMethod = (path, params, target = "_self") => {
	const formElement = document.createElement("form");
	formElement.method = "post";
	formElement.action = path;
	formElement.target = target;

	// eslint-disable-next-line no-restricted-syntax
	for (const key in params) {
		// eslint-disable-next-line no-prototype-builtins
		if (params.hasOwnProperty(key)) {
			const hiddenField = document.createElement("input");
			hiddenField.type = "hidden";
			hiddenField.name = encodeURIComponent(key);
			hiddenField.value = encodeURIComponent(params[key]);

			formElement.appendChild(hiddenField);
		}
	}

	document.body.appendChild(formElement);
	formElement.submit();
};

export default openLinkWithPostMethod;
export { openLinkWithPostMethod };
