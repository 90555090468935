import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as styles from "./CookieAcceptanceBanner.module.scss";
import { Button } from "../Button";
import { getCookie, setCookie } from "../../utils/helper";
import { RCL as useTranslation } from "../RCL";

const CookieAcceptanceBanner = ({ onLoad, onUnload }) => {
	const bannerText = useTranslation({ searchKey: "cookie-accept-banner-sov" });
	const buttonText = useTranslation({ searchKey: "accept" });

	const [shouldShowBanner, setShouldShowBanner] = useState(false);

	useEffect(() => {
		const cookieAcceptance = getCookie("cookie-acceptance");
		if (!cookieAcceptance) {
			setShouldShowBanner(true);
			if (onLoad && typeof onLoad === "function") {
				onLoad();
			}
		}
	}, []);

	const handleClick = () => {
		const expiration = new Date();
		expiration.setUTCMonth(expiration.getMonth() + 1);

		const cookieDomain = window.location.hostname.replace(/^www\./, ".");
		setCookie("cookie-acceptance", true, "/", cookieDomain, expiration);
		setShouldShowBanner(false);
		if (onUnload && typeof onUnload === "function") {
			onUnload();
		}
	};

	if (!shouldShowBanner) {
		return null;
	}
	return (
		<div className={styles?.banner}>
			<div className={styles?.bannerContainer}>
				<span className={styles?.text}>{bannerText} </span>
				<Button
					className={styles?.acceptBtn}
					label={buttonText}
					theme="secondary"
					onClick={handleClick}
				/>
			</div>
		</div>
	);
};

CookieAcceptanceBanner.propTypes = {
	onLoad: PropTypes.func,
	onUnload: PropTypes.func,
};
CookieAcceptanceBanner.defaultProps = {
	onLoad: undefined,
	onUnload: undefined,
};
export default CookieAcceptanceBanner;
export { CookieAcceptanceBanner };
