// extracted by mini-css-extract-plugin
export var banner = "ResponsiveBanner-module--banner--3nplx";
export var contained = "ResponsiveBanner-module--contained--1atTp";
export var background = "ResponsiveBanner-module--background--Iui1R";
export var topMargin = "ResponsiveBanner-module--topMargin--1ovQe";
export var bottomMargin = "ResponsiveBanner-module--bottomMargin--8zHJw";
export var buttonWrapper = "ResponsiveBanner-module--buttonWrapper--2fQlI";
export var leftAlign = "ResponsiveBanner-module--leftAlign--3cud9";
export var centerAlign = "ResponsiveBanner-module--centerAlign--3iK9z";
export var rightAlign = "ResponsiveBanner-module--rightAlign--nfF8_";
export var buttonContainer = "ResponsiveBanner-module--buttonContainer--ZPKw5";
export var anchorLink = "ResponsiveBanner-module--anchorLink--2L7ij";
export var showButton = "ResponsiveBanner-module--showButton--3RWGR";