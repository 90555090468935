import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { SelectInput } from "@sunwing/shared-components";
import { RCL as useTranslation } from "../../../RCL";
import { LOCAL_STORAGE_KEY_PREFERRED_GATEWAY } from "../../../../const/const";

import * as inputStyles from "../../Common/CSS/InputWrapper/InputWrapper.module.scss";
import * as taStyles from "../../Common/CSS/Typeahead/Typeahead.module.scss";

const GatewayField = ({ label, id, language, selected, error, resetError, onChange, onUpdate }) => {
	const GetDictionary = _key => useTranslation({ searchKey: _key });

	const [isFocus, setFocus] = useState(false);
	const [gatewayData, setGatewayData] = useState([]);
	const [isInit, setInit] = useState(true);

	/* /////////////////////////////////////////////////////////////
	// Gateway typeahead logic
	///////////////////////////////////////////////////////////// */

	const transformGatewayData = _gateways => {
		// Transform data from API response to type-ahead data format
		const template = [];

		_gateways.gateways.forEach(gateway => {
			template.push({
				value: gateway.code,
				label: `${gateway.name}`,
				id: `${gateway.name}`,
			});
		});

		return template;
	};

	const loadGatewayData = async () => {
		const apiHeaders = new Headers();
		apiHeaders.append("Content-Type", "application/json");

		const apiConfig = {
			method: "GET",
			headers: apiHeaders,
		};

		const apiRequest = new Request(
			`${process.env.GATSBY_SVHANDLER_API_BASE}/gateways/sov/${language}`
		);

		return fetch(apiRequest, apiConfig)
			.then(res => {
				if (!res.ok) {
					throw res.error;
				}
				return res.json();
			})
			.then(res => {
				resetError(true);
				return res;
			})
			.catch(err => {
				resetError(true);
				console.error("Failed to load gateways: ", err);
				return null;
			});
	};

	const findGatewayOrDestinationMatch = _match => {
		if (gatewayData.length > 0) {
			return gatewayData.filter(
				gateway => gateway.value.split(",").includes(_match) || gateway.value === _match
			);
		}
		return null;
	};

	const setDefaultGateway = () => {
		const storedGateway = localStorage?.getItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY)
			? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_PREFERRED_GATEWAY))
			: {};

		const fallbackGateway = language === "fr" ? "YUL" : "YYZ";

		// Default to preferred gateway or fallback to language specific gateways
		const filteredGateway = findGatewayOrDestinationMatch(storedGateway?.value ?? fallbackGateway);

		if (filteredGateway?.length > 0) {
			return filteredGateway[0];
		}

		if (filteredGateway.length <= 0) {
			return findGatewayOrDestinationMatch(fallbackGateway)[0];
		}

		return gatewayData[0];
	};

	const handleInputFocusSelect = event => {
		if (event?.target) {
			setTimeout(() => {
				// Select input
				/* Add a delay to allow focus to pickup selection on Safari browsers */
				event.target.select();
			}, 100);
		}
	};

	/* /////////////////////////////////////////////////////////////
	// Form initialization logic
	///////////////////////////////////////////////////////////// */

	useEffect(() => {
		// Intial component load sequence
		(async () => {
			const gateways = await loadGatewayData();

			if (gateways?.totalGateways > 0) {
				setGatewayData(transformGatewayData(gateways));
			}
		})();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<SelectInput
			id={id}
			name={id}
			label={label}
			error={error}
			icon={
				<span className={inputStyles.chevron}>
					<span className="sr-only">{GetDictionary("a11y-open-menu")}</span>
				</span>
			}
			className={cx(
				inputStyles.inputWrapper,
				taStyles.typeahead,
				isFocus ? taStyles.focus : "",
				error ? inputStyles.inputWrapperError : ""
			)}
			classNames={{
				select: taStyles.container,
				open: cx(taStyles.open, inputStyles.open),
				input: taStyles.input,
				selected: taStyles.selected,
				highlighted: taStyles.highlighted,
				item: taStyles.item,
				noResults: taStyles.noResults,
				listbox: cx(taStyles.listbox, taStyles.flat),
			}}
			type="typeahead"
			data={gatewayData}
			selectedItem={selected === null && gatewayData.length > 0 ? setDefaultGateway() : selected}
			isSelectedItem={(selectedItem, item) => selectedItem?.id === item?.id}
			placeholder={label}
			showAllSuggestionsOnClickIcon={true}
			onEscapeResetSelected={false}
			onBlur={() => {
				setFocus(false);
			}}
			onFocus={event => {
				handleInputFocusSelect(event);
				setFocus(true);
			}}
			onChange={selection => {
				if (selection !== null && selection !== selected) {
					onChange(selection);
					onUpdate(true);
					resetError(true);
					setInit(false);

					if (isInit) {
						setFocus(false);
					} else {
						setFocus(true);
					}
				}
			}}
			labels={{
				noResults: GetDictionary("no-results-found"),
			}}
		/>
	);
};

GatewayField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	selected: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	resetError: PropTypes.func,
	onUpdate: PropTypes.func.isRequired,
};

GatewayField.defaultProps = {
	selected: undefined,
	error: false,
	resetError: () => {},
};

export default GatewayField;
export { GatewayField };
